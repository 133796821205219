import React, { useEffect } from "react";
import { ILegalTransaction } from "../Interfaces/ILegalTransaction";
import { useFetch } from "../hooks/useFetch";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { Box, Card, CardContent, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

interface ISortedLegalTransaction {
    [id: string] : ILegalTransaction[];
}

interface IProps {
    idLegalTransactionType: number;
}


export const checkDate = (currentDate: string) => {
    let toDay = new Date();
    let targetDate = new Date(currentDate);
    let testDate = new Date();
    testDate.setMonth(testDate.getMonth() - 2);

    return (targetDate > testDate);
}

export const KanbanBody:React.FC<IProps> = (props) => {
    const [sortedObject, setSortedObject, wasSuccessfullySortedObject] = useFetch<ISortedLegalTransaction>("/legaltransaction/sortByType/",props.idLegalTransactionType);

    useEffect(() => {
        if (sortedObject !== undefined) {
            Object.entries(sortedObject).forEach(
                ([key, value]) => console.log(key, value)
              );
        }
    },[sortedObject])



    const getCurrentBgColor = (currentPhase: string, localLegalTransaction:ILegalTransaction) => {
        let defaultColor = "#f2f2f2";
        let warningColor = "#FFCCCC";
        console.log(currentPhase)

        if (currentPhase === "1_Abgeschlossen" || localLegalTransaction.LastPhaseUpdate_at === null) {
            return defaultColor;
        }
        else if (checkDate(localLegalTransaction.LastPhaseUpdate_at)) {
            return defaultColor;
        }
        else {
            return warningColor;
        }
    } 


    if (!wasSuccessfullySortedObject) { return <>Fehler!</>}
    else if (sortedObject === undefined) { return <CustomCircularProgress/> }
    else {
        return(
            <>
                <table>
                    <thead>
                        <tr style={{borderRight: "solid"}}>
                            {Object.keys(sortedObject).map(k => <th style={{width: 300}}>{k.split("_")[1]}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{verticalAlign: "top"}}>
                        {Object.keys(sortedObject).map(k => 
                            <>
                                <td style={{borderRight: "1px solid"}}>
                                    {sortedObject[k].map(x =>
                                        <Box sx={{p: 1}}>
                                            <Card sx={{backgroundColor: getCurrentBgColor(k,x)}}>
                                                <CardContent>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        {x.LegalTransactionState}
                                                    </Typography>
                                                    <Typography variant="h6" component="div">
                                                        {x.Title}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                                        <Link sx={{float: "right"}} href={`/legaltransaction/${x.idLegalTransaction}`}>{x.idLegalTransaction}</Link>
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    )}
                                </td>
                            </>
                            
                        )}
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }
}