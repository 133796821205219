import React, { useEffect, useState } from "react";
import { ILanguageSectionFullObject } from "../../Interfaces/ILanguageSection";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LanguageEntryRow } from "./Childs/LanguageEntryRow";
import { ILanguageEntry } from "../../Interfaces/ILanguageEntry";


interface IProps {
    languageSectionFullObject: ILanguageSectionFullObject;
    setLanguageSectionFullObject: Function;
}



export const LanguageSectionEdit:React.FC<IProps> = (props) => {
    const [languageSectionObject,setLanguageSectionObject] = useState(props.languageSectionFullObject);
    //
    const [languageEntryArray,setLanguageEntryArray] = useState(props.languageSectionFullObject.LanguageEntryArray);

    const addEntry = () => {
        let tmpId = -1;

        if (languageEntryArray.length > 0) {
            let tmpIdArray = Math.min(...languageEntryArray.map(x => x.idLanguageEntry)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray -1;
            }
        }

        let tmpObject:ILanguageEntry = {
            idLanguageEntry: tmpId,
            idLanguageSection: -1,
            de: "",
            en: "",
            fr : "",
            KeyValue: ""
        }

        setLanguageEntryArray([
            ...languageEntryArray,
            tmpObject
        ])
    }




    const tsxNewEntry = () => {
        return(
            <>
                <Button variant="outlined" onClick={addEntry} sx={{float: "right", mt: 2}}>Neuer Eintrag</Button>
            </>
        )
    }


    useEffect(() => {
        props.setLanguageSectionFullObject({
            ...languageSectionObject,
            LanguageEntryArray: languageEntryArray
        } as ILanguageSectionFullObject)
    },[languageSectionObject,languageEntryArray])

    return(
        <>
            {tsxNewEntry()}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 400}}>Schlüssel</TableCell>
                        <TableCell>Deutsch</TableCell>
                        <TableCell>English</TableCell>
                        <TableCell>Français</TableCell>
                        <TableCell sx={{width: 100}}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {languageEntryArray.map(x =>
                        <LanguageEntryRow
                            key={`idLanguageEntry-${x.idLanguageEntry}`}
                            languageEntry={x}
                            languageEntryArray={languageEntryArray}
                            setLanguageEntryArray={setLanguageEntryArray}
                        />
                    )}
                </TableBody>
            </Table>
            {tsxNewEntry()}
        </>
    )
}