import React from "react";
import { IDocument } from "../../Interfaces/IDocument";
import { Grid, Typography } from "@mui/material";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { DocumentRow } from "../../Document/DocumentRow";
import { useSelector } from "react-redux";
import { State } from "../../redux/mainReducer";


interface IProps  {
    draftArray: IDocument[];
    setDraftArray: Function;
}


export const DraftDocumentOverview:React.FC<IProps> = (props) => {
    const mimeTypeArray = useSelector((state: State) => state.mimeTypeArray.storeAcademicTitle);

    return(
        <>
            <DocumentOverview
                documentArray={props.draftArray}
                setDocumentArray={props.setDraftArray}
                title="Entwürfe"
                allowRemove
                allowUpload
            >
                <>
                    {(props.draftArray.length === 0) &&
                        <>--- Keine Entwürfe vorhanden ---</>
                    }

                    <Timeline>
                        {props.draftArray.map(x => 
                            <>
                                <TimelineItem>
                                    <TimelineOppositeContent  >
                                        {new Date(x.Created_at).toLocaleString()}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent color="text.secondary"  sx={{ mb: 3 }}>
                                        {x.FileName}
                                        <br/>
                                        <DocumentRow
                                            documentObject={x}
                                            documentArray={props.draftArray}
                                            setDocumentArray={props.setDraftArray}
                                            mimeTypeArray={mimeTypeArray}
                                            allowRemove
                                            justButtons
                                        />
                                    </TimelineContent>
                                </TimelineItem>
                            </>
                        )}
                    </Timeline>
                </>
            </DocumentOverview>
        </>
    )
}