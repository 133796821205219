import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridRowSelectionModel, GridValueGetterParams, deDE } from "@mui/x-data-grid";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { ShareHolderEdit } from "./ShareHolder/ShareHolderEdit";
import { Edit } from "@mui/icons-material";
import { IShareHolderFullObject } from "../../../Interfaces/IShareHolder";
import { ICompanyFormationHasShareHolder } from "../../../Interfaces/ICompanyFormationHasShareHolder";

interface IProps {
    idShareHolderType: number;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    setShareHolderRelation: Function;
    disableAddNewButton?: boolean;
    enableAutoslectNewShareHolder?: boolean;
    disableSelectCompanyType?: boolean;
    resetCounter?: number;
}



export const SelectShareHolderEdit:React.FC<IProps> = (props) => {
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([
        ...props.shareHolderRelation.filter(x => x.idShareHolderType === props.idShareHolderType).map(x => x.idShareHolder)
    ]);

    const columns: GridColDef[] = [
        { 
            field: 'Company', 
            headerName: 'Firma', 
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>  
                (params.row.idBusinessForm === 1) ? <></>
                : <>{params.row.CompanyObject.Company}</>
        },
        { field: 'FirstName', headerName: 'Vorname', flex: 2 },
        { field: 'LastName', headerName: 'Nachname', flex: 2 },    
        { 
            field: 'Actions', 
            headerName: 'Aktionen', 
            width: 90,
            renderCell: (params: GridRenderCellParams) =>  
                <IconButton onClick={() => setIdShareHolder(Number(params.row.idShareHolder))}><Edit /></IconButton>
        },    
    ];

    const [idShareHolder,setIdShareHolder] = useState<number|null>(null);

    const handleClose = () => {
        setIdShareHolder(null);
    }

    const handleSave = () => {
        let tmpArray:ICompanyFormationHasShareHolder[] = []

        rowSelectionModel.map(x => {
            tmpArray.push({
                idCompanyFormation: -1,
                idCompanyFormationHasShareHolder: -1,
                idShareHolder: Number(x),
                idShareHolderType: props.idShareHolderType
            } as ICompanyFormationHasShareHolder)
        })

        props.setShareHolderRelation([
            ...props.shareHolderRelation.filter(x => x.idShareHolderType !== props.idShareHolderType),
            ...tmpArray
        ])
    }

    useEffect(() => { handleSave() },[rowSelectionModel])

    useEffect(() => {
        if (props.resetCounter !== undefined && props.resetCounter !== 0) {
            setRowSelectionModel([]);
        }
    },[props.resetCounter])

    return(
        <>
            <Dialog open={idShareHolder !== null } onClose={() => setIdShareHolder(null)} maxWidth="md" fullWidth>
                <DialogTitle>{(idShareHolder === null || idShareHolder ===0) ? <>Neue Person</> : <>Person bearbeiten</>}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}>
                    {(idShareHolder !== null) && 
                        <ShareHolderEdit
                            key={`idShareHolder-${idShareHolder}`}
                            idShareHolder={idShareHolder}
                            handleClose={handleClose}
                            shareHolderArray={props.shareHolderArray}
                            setShareHolderArray={props.setShareHolderArray}
                            //
                            rowSelectionModel={rowSelectionModel}
                            setRowSelectionModel={setRowSelectionModel}
                            enableAutoslectNewShareHolder={props.enableAutoslectNewShareHolder}
                            disableSelectCompanyType={props.disableSelectCompanyType}
                        />
                    }
                    </Box>
                </DialogContent>
            </Dialog>


            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {(props.disableAddNewButton === undefined || props.disableAddNewButton == false) &&
                        <Button variant="contained" sx={{float: "right"}} onClick={() => {setIdShareHolder(0); }}>Person hinzufügen</Button>
                    }
                </Grid>

                <Grid item xs={12}>
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={props.shareHolderArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                            }}
                        rowSelectionModel={rowSelectionModel}
                        columns={columns}
                        getRowId={(row) => row.idShareHolder}
                        disableRowSelectionOnClick
                        isRowSelectable={(params: GridRowParams) => 
                            (props.disableSelectCompanyType === undefined || props.disableSelectCompanyType === false)
                            || Number(params.row.idBusinessForm) === 1
                        }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}