import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { IInheritFullObject } from "../../../../Interfaces/IInherit";
import { IDocument } from "../../../../Interfaces/IDocument";


interface IProps {
    inheritObject : IInheritFullObject
}


export const ExportPersons:React.FC<IProps> = (props) => {
    const [idShareHolder, setIdShareHolder] = useState<number|null>(null);

    const castBlobToFile = (documentObject:IDocument|undefined) => {
        if (documentObject != undefined && documentObject.Data != undefined) {
            let currentType = "application/json";
            // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            let byteCharacters = atob(documentObject?.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: currentType});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            // Erstelle html-Objekt für den Download
            let a = document.createElement("a");
            // setze link auf den neu erstellen Link zum Blolb
            a.href = blobUrl;
            // Setze Downloadtitle für den Datenname
            a.download = documentObject.FileName;
            // Click 
            a.click();
        }
    }

    async function getExport() {

        //console.log(props.inheritObject)
        if (props.inheritObject !== null) {
            fetch(`/api/inherit_has_person/exportPersons`,{
                method: "POST",
                headers :  { 'Authorization': `Bearer ${localStorage.getItem("SessionToken")}`, 'Content-Type': 'application/json' },
                body : JSON.stringify(props.inheritObject)
            })
            .then(res => {
                if (res.status === 200) { return res.json() }
                else {throw Error(`ERROR GET: ${res.status}`)}
            })
            .then(res => {
                let fileArray:IDocument[] = res;
                fileArray.map(x => castBlobToFile(x));
                setIdShareHolder(null);
            })
            .catch((error) => {
                console.error(error);
            })
        }
    }
    return(
        <>
            <Button variant="outlined" onClick={() => { getExport()}}>
                "Export"
            </Button>
        </>
    )
} 