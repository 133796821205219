import React from 'react'
import { ITopic } from '../../Interfaces/ITopic'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'

interface NotificationPreferencesProps {
  topics: ITopic[] | undefined
  showAtLogin?: boolean
  setShowAtLogin?: (showAtLogin: boolean) => void
  preferences: { [key: number]: boolean }
  setPreferences: (preferences: { [key: number]: boolean }) => void
}

export const NotificationPreferences: React.FC<NotificationPreferencesProps> = ({
  topics,
  preferences,
  setPreferences,
  showAtLogin,
  setShowAtLogin
}) => {
  const handleToggle = (topicId: number) => {
    setPreferences({
      ...preferences,
      [topicId]: !preferences[topicId]
    })
  }
  if (!topics) {
    return (
      <Box>
        <Typography variant='h6'>Benachrichtigungseinstellungen</Typography>
        <Typography>Fehler beim Laden der Themen</Typography>
      </Box>
    )
  }
  return (
    <Box>
      <Typography variant='h6'>Benachrichtigungseinstellungen</Typography>
      {setShowAtLogin && (
        <Box display='flex' alignItems='center' justifyContent='space-between' mt={2}>
          <Typography>Benachrichtigungen beim Login anzeigen</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={showAtLogin}
                onChange={() => setShowAtLogin(showAtLogin == null ? false : !Boolean(showAtLogin))}
                name='showAtLogin'
                color='primary'
              />
            }
            label={showAtLogin ? 'An' : 'Aus'}
          />
        </Box>
      )}
      {topics.map((topic) => (
        <Box key={topic.idTopic} display='flex' alignItems='center' justifyContent='space-between' mt={2}>
          <Typography>{topic.Description}</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={preferences[topic.idTopic] || false}
                onChange={() => handleToggle(topic.idTopic)}
                name={topic.Name}
                color='primary'
              />
            }
            label={preferences[topic.idTopic] ? 'An' : 'Aus'}
          />
        </Box>
      ))}
    </Box>
  )
}
