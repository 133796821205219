import React, { useEffect, useState } from "react";
import { IDayOffWorkCategory } from "../../Interfaces/IDayOffWorkCategory";
import { IDayOffWork } from "../../Interfaces/IDayOffWork";
import { Grid } from "@mui/material";
import { IEmploymentContract } from "../../Interfaces/IEmploymentContract";


interface IProps {
    idUser: number | null;
    dayOffWorkCategoryArray: IDayOffWorkCategory[];
    dayOffWorkArray: IDayOffWork[];
    employmentContractArray: IEmploymentContract[];
}



export const DayOffWorkUserSummary:React.FC<IProps> = ({idUser,dayOffWorkCategoryArray,dayOffWorkArray, employmentContractArray}) => {
    const [filteredForUser,setFilteredForUser] = useState(
        dayOffWorkArray
            .filter( x => x.idUser === Number(idUser))
            .filter(x => new Date(x.FromDate).getFullYear() === new Date().getFullYear()) 
    ) 
    const [vacationDays,setVacationDays] = useState(
        filteredForUser
            .filter(x => x.isConfirmed !== null && Boolean(x.isConfirmed) === true)
            .map(x => x.DaysOfWork)
            .reduce((a,b) => a+b,0)
    )


    const [freeDays, setFreeDays] = useState(
        employmentContractArray
            .filter(x => x.idUser === Number(idUser))
            .filter(x => x.End === null || new Date(x.End) <= new Date() )
            .map(x => x.VacationPerYear)
            .reduce((a,b) => a+b,0)
    )
    

    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={4}><b>Kategorie</b></Grid>
                <Grid item sm={2}><b>Geplante Fehltage</b></Grid>
                <Grid item sm={2}><b>Genommene Fehltage</b></Grid>
                <Grid item sm={2}><b>Frei Total</b></Grid>
                <Grid item sm={2}><b>Frei</b></Grid>
                {dayOffWorkCategoryArray.map( category => 
                    <>
                        <Grid item sm={4}>
                            {category.DayOffWorkCategory}
                        </Grid>
                        <Grid item sm={2}>
                            {
                                filteredForUser
                                    .filter(x => x.idDayOffWorkCategory === category.idDayOffWorkCategory)
                                    .filter(x => x.isConfirmed === null)
                                    .map(x => x.DaysOfWork)
                                    .reduce((a,b) => a+b,0)
                            }
                        </Grid>
                        <Grid item sm={2}>
                            {
                                filteredForUser
                                    .filter(x => x.idDayOffWorkCategory === category.idDayOffWorkCategory)
                                    .filter(x => x.isConfirmed !== null && Boolean(x.isConfirmed) === true)
                                    .map(x => x.DaysOfWork)
                                    .reduce((a,b) => a+b,0)
                            }
                        </Grid>

                        {(category.idDayOffWorkCategory !== 1) 
                            ?
                                <>
                                    <Grid item sm={2} />
                                    <Grid item sm={2} />
                                </>
                            :
                                <>
                                    <Grid item sm={2}>{freeDays}</Grid>
                                    <Grid item sm={2}>{freeDays - vacationDays}</Grid>
                                </>
                        }
                    </>
                )}
            </Grid>
        </>
    )
}