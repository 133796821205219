import { DataGrid, GridColDef, GridRenderCellParams, deDE } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useFetch } from '../hooks/useFetch'
import { IUser } from '../Interfaces/IUser'
import { IRole } from '../Interfaces/IRole'
import { Alert, Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { CustomCircularProgress } from '../generic/CustomCircularProgress'
import { Add, Check, Clear } from '@mui/icons-material'
import { ShowWasSaved } from '../core/ShowWasSaved'
import { FullScreenDialog } from '../core/FullScreenDialog'
import { UserFullEdit } from './UserFullEdit'
import { IDayOffWorkCategory } from '../Interfaces/IDayOffWorkCategory'
import { IUserGroup, IUserGroupFullObject } from '../Interfaces/IUserGroup'

const columns: GridColDef[] = [
  { field: 'idUser', headerName: 'Nr.', width: 90 },
  {
    field: 'isBlocked',
    headerName: 'Aktiv',
    width: 90,
    renderCell: (params: GridRenderCellParams) =>
      Boolean(params.row.isBlocked) ? <Clear color='error' /> : <Check color='success' />
  },
  {
    field: 'isNotary',
    headerName: 'Notar',
    width: 90,
    renderCell: (params: GridRenderCellParams) => (Boolean(params.row.isNotary) ? <Check color='success' /> : <Clear />)
  },
  { field: 'Role', headerName: 'Rolle', width: 150 },
  { field: 'Login', headerName: 'Benutzername', width: 150 },
  { field: 'FirstName', headerName: 'Vorname', width: 150 },
  { field: 'LastName', headerName: 'Nachname', flex: 1 }
]

export const UserOverview: React.FC = () => {
  const [userArray, setUserArray, wasSuccessfullyUserArray] = useFetch<IUser[]>('/user')
  const [roleArray, setRoleArray, wasSuccessfullyRoleArray] = useFetch<IRole[]>('/role')
  const [userGroupArray, setUserGroupArray, wasSuccessfullyUserGroupArray] = useFetch<IUserGroup[]>('/usergroup')
  const [dayOffWorkCategoryArray, setDayOffWorkCategoryArray, setWasSuccessfullyCategory] =
    useFetch<IDayOffWorkCategory[]>('/dayoffworkcategory')
  //
  const [idUser, setIdUser] = useState<number | null>(null)
  //
  const [wasSaved, setWasSaved] = useState(false)
  const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false)

  if (
    !wasSuccessfullyUserArray ||
    !wasSuccessfullyRoleArray ||
    !setWasSuccessfullyCategory ||
    !wasSuccessfullyUserGroupArray
  ) {
    return <Alert severity='error'>Fehler!</Alert>
  } else if (
    userArray === undefined ||
    roleArray === undefined ||
    dayOffWorkCategoryArray === undefined ||
    userGroupArray === undefined
  ) {
    return <CustomCircularProgress />
  } else {
    return (
      <>
        <ShowWasSaved wasSaved={wasSaved} setWasSaved={setWasSaved} wasSuccessfullySaved={wasSavedSuccesfully} />
        <FullScreenDialog title='Benutzer' isOpen={idUser !== null} onClose={() => setIdUser(null)}>
          <>
            {idUser !== null && (
              <UserFullEdit
                key={`idUSer-${idUser}`}
                idUser={idUser}
                setIdUser={setIdUser}
                userArray={userArray}
                userGroupArray={userGroupArray}
                setUserArray={setUserArray}
                roleArray={roleArray}
                setWasSaved={setWasSaved}
                setWasSavedSuccesfully={setWasSavedSuccesfully}
                dayOffWorkCategoryArray={dayOffWorkCategoryArray}
              />
            )}
          </>
        </FullScreenDialog>

        {/*
                <Dialog open={idUser !== null} onClose={() => setIdUser(null)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        {(idUser !== null) &&
                            <UserEdit 
                                key={`idUSer-${idUser}`}
                                idUser={idUser}
                                setIdUser={setIdUser}
                                userArray={userArray}
                                setUserArray={setUserArray}
                                roleArray={roleArray}
                                setWasSaved={setWasSaved}
                                setWasSavedSuccesfully={setWasSavedSuccesfully}
                            />
                        }

                    </DialogContent>
                </Dialog>
                 */}

        <Box sx={{ m: 2, mt: 0 }}>
          <Typography variant='h4'>
            Benutzer
            <IconButton sx={{ float: 'right' }} onClick={() => setIdUser(-1)}>
              <Add />
            </IconButton>
          </Typography>

          <DataGrid
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={userArray}
            columns={columns}
            getRowId={(row) => row.idUser}
            onRowClick={(params, event, details) => setIdUser(Number(params.id))}
          />
        </Box>
      </>
    )
  }
}
