import React, { useEffect, useState } from "react";
import { ICompanyFormation, ICompanyFormationFullObject } from "../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../Interfaces/ICompanyFormationHasShareHolder";

import { Box, Typography } from "@mui/material";
import { ICompanyFormationAnswer } from "../../Interfaces/ICompanyFormationAnswer";
import {ILegalTransaction, ILegalTransactionFullObject} from "../../Interfaces/ILegalTransaction";
import {ExportCompanyFormation} from "../CompanyFormation/ExportCompanyFormation";
import {
    ShareHolderRepresentationWarning
} from "../CompanyFormation/Childs/ShareHolder/ShareHolderRepresentationWarning";
import {Summary} from "../CompanyFormation/Childs/Summary";


interface IProps {
    companyFormationObject: ICompanyFormationFullObject;
    setCompanyFormationObject: Function;

    legalTransactionObject? : ILegalTransaction;
}

export const CompanyChangeMain:React.FC<IProps> = (props) => {
    const [companyFormationObject, setCompanyFormationObject] = useState<ICompanyFormation>(props.companyFormationObject)
    const [shareHolderArray, setShareHolderArray] = useState<IShareHolderFullObject[]>(props.companyFormationObject.ShareHolderArray);
    const [companyFormationShareArray, setCompanyFormationShareArray] = useState<ICompanyFormationShare[]>(props.companyFormationObject.CompanyFormationShareArray);
    const [shareHolderRelation,setShareHolderRelation] = useState<ICompanyFormationHasShareHolder[]>(props.companyFormationObject.CompanyFormationHasShareHolderArray);
    const [companyFormationAnswerArray, setCompanyFormationAnswerArray] = useState<ICompanyFormationAnswer[]>(props.companyFormationObject.CompanyFormationAnswerArray);



    useEffect(() => {
        props.setCompanyFormationObject({
            ...companyFormationObject,
            ShareHolderArray: shareHolderArray,
            CompanyFormationHasShareHolderArray: shareHolderRelation,
            CompanyFormationShareArray: companyFormationShareArray,
            CompanyFormationAnswerArray: companyFormationAnswerArray
        } as ICompanyFormationFullObject)
    },[companyFormationObject,shareHolderArray,companyFormationShareArray,shareHolderRelation,companyFormationAnswerArray])

    return(
        <>
            <Typography variant="h5">
                Firmenänderung
                <Box sx={{float: "right"}}>
                    <ExportCompanyFormation
                        idCompanyFormation={props.companyFormationObject.idCompanyFormation}
                    />
                </Box>
            </Typography>

            <ShareHolderRepresentationWarning
                shareHolderArray={shareHolderArray}
                shareHolderRelationArray={shareHolderRelation}
            />

            <Summary
                companyFormationObject={companyFormationObject}
                setCompanyFormationObject={setCompanyFormationObject}
                companyFormationShareArray={companyFormationShareArray}
                setCompanyFormationShareArray={setCompanyFormationShareArray}
                shareHolderArray={shareHolderArray}
                setShareHolderArray={setShareHolderArray}
                shareHolderRelation={shareHolderRelation}
                setShareHolderRelation={setShareHolderRelation}
                companyFormationAnswerArray={companyFormationAnswerArray}
                setCompanyFormationAnswerArray={setCompanyFormationAnswerArray}
                legalTransactionObject={props.legalTransactionObject}
            />
        </>
    )
}