import React, { useState } from "react";
import { IMortgage, IMortgageFullObject } from "../../../../Interfaces/IMortgage";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Beneficiary } from "../Components/Beneficiary";



interface IProps {
    mortgageObject: IMortgage;
    setMortgageObject: Function;
}

const detailsObject = {

}

export const MortgagePersons:React.FC<IProps> = (props) => {

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<GridExpandMoreIcon/>}>
                    <Typography variant="h6">Begünstigter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Beneficiary
                        mortgageObject={props.mortgageObject}
                        setMortgageObject={props.setMortgageObject}
                    ></Beneficiary>
                </AccordionDetails>
            </Accordion>
        </>
    )
}