import React from "react";
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { IRequiredFiles } from "./MortgageDocument";
import { GridBaseDate } from "../../../../Person/GridBaseData";
import { IMortgage, MortgageDocument } from "../../../../Interfaces/IMortgage";
import { IMortgagePerson } from "../../../../Interfaces/IPerson";



interface IProps {
    personArray: IMortgagePerson[];
    setPersonArray: Function;

    currentPerson: IMortgagePerson;
    setCurrentPerson: Function;
    isOpen: boolean;
    setIsOpen: Function;
    ownerId: number;
    setOwnerId: Function;
}

export const Owner:React.FC<IProps> = (props) => {
    return(
        <>
            {props.personArray.map((person, index) =>
                <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{person.FirstName}</TableCell>
                    <TableCell>{person.LastName}</TableCell>
                    <TableCell>{person.Surname}</TableCell>
                    <TableCell>{person.Birthday}</TableCell>
                    <TableCell>{person.Birthplace}</TableCell>
                    <TableCell>
                        <Button onClick={() => {
                            props.setIsOpen(true);
                            props.setOwnerId(person.idPerson);
                            props.setCurrentPerson(person);
                        }}>Bearbeiten</Button>
                    </TableCell>
                </TableRow>
            )}
            
            <Box sx={{mt: 10}} />
        </>
    )
}
