import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, DialogActions } from "@mui/material";
import { InheritPerson, getInheritPerson } from "../Components/InheritPerson";
import { IRequiredFiles } from "../Components/InheritDocument";
import { IInheritHasPerson } from "../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../Interfaces/IPersonHasDocument";
import { IInherit } from "../../../../Interfaces/IInherit";
import {
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from "../../../../services/familyTree.service";

interface IProps {
  inheritObject: IInherit;
  setInheritObject: Function;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

export const Inherit: React.FC<IProps> = (props) => {
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(
      Number(
        props.personArray.find(
          (x) => x.idPerson === props.inheritObject.idPerson
        )?.idPerson
      ),
      props.personArray
    )
  );
  const formRef = useRef<HTMLFormElement>(null);
  const [showSuccesAlert, setShowSuccesAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const deepEqual = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    const updatedPerson = getInheritPerson(
      Number(
        props.personArray.find(
          (x) => x.idPerson === props.inheritObject.idPerson
        )?.idPerson
      ),
      props.personArray
    );

    if (deepEqual(currentPerson, updatedPerson)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentPerson, props.personArray]);

  useEffect(() => {
    const updatedPerson = getInheritPerson(
      Number(
        props.personArray.find(
          (x) => x.idPerson === props.inheritObject.idPerson
        )?.idPerson
      ),
      props.personArray
    );
    setCurrentPerson(updatedPerson);
  }, [props.personArray, props.inheritObject.idPerson]);

  /**
   * Form Function
   */
  const handleSave = () => {
    props.setPersonArray([
      ...props.personArray.map((x) =>
        x.idPerson === currentPerson.idPerson ? currentPerson : x
      ),
    ]);
    setShowSuccesAlert(true);
  };

  const handleStop = () => {
    const updatedPerson = getInheritPerson(
      Number(
        props.personArray.find(
          (x) => x.idPerson === props.inheritObject.idPerson
        )?.idPerson
      ),
      props.personArray
    );
    setCurrentPerson(updatedPerson);
  };

  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  useEffect(() => {
    if (showSuccesAlert) {
      const timer = setTimeout(() => {
        setShowSuccesAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccesAlert]);

  return (
    <>
      <form ref={formRef}>
        <InheritPerson
          currentPerson={currentPerson}
          setCurrentPerson={setCurrentPerson}
          inheritObject={props.inheritObject}
          setInheritObject={props.setInheritObject}
          personArray={props.personArray}
          title={FamilyTreeService.getGenderNaming(
            FamilyTreePersonNodeType.TESTATOR_NODE,
            currentPerson.idGender
          )}
          requiredFiles={
            [
              {
                Title: "Sterbeurkunde",
                isOptional: false,
                idPersonDocumentType: 1,
              },
            ] as IRequiredFiles[]
          }
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          titleAdress="Letzter Wohnsitz"
          forceDeathSettings={true}
          showDeathSettings={false}
          forceAdress
          disableDeathDateWarning
        />
        {showSuccesAlert && (
          <Alert
            severity="success"
            onClose={() => {
              setShowSuccesAlert(false);
            }}
          >
            Erfolgreich gespeichert!
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            disabled={isDisabled}
            variant="contained"
            onClick={handleStop}
          >
            Abbrechen
          </Button>
          <Button
            disabled={isDisabled}
            variant="contained"
            onClick={handleOnSave}
          >
            Speichern
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
