import React, {useEffect, useState } from "react";
import { getFetch, useFetch } from "../hooks/useFetch";
import { Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Popover, TextField, Typography } from "@mui/material";
import { Add, Tune } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRowParams, deDE } from "@mui/x-data-grid";
import { ILegalTransaction } from "../Interfaces/ILegalTransaction";
import { LegalTransactionEdit } from "./LegalTransactionEdit";
import { ILegalTransactionType } from "../Interfaces/ILegalTransactionType";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { ILegalTransactionState } from "../Interfaces/ILegalTransactionState";
import { useNavigate, useParams } from "react-router-dom";
import { ShowWasSaved } from "../core/ShowWasSaved";
import { ILegalTransactionProgress } from "../Interfaces/ILegalTransactionProgress";
import { IUser } from "../Interfaces/IUser";
import { ILegalRequirementState } from "../Interfaces/ILegalRequirementState";
import { IMeetingEmailTemplate } from "../Interfaces/IMeetingEmailTemplate";
import { ISearchOption } from "../Interfaces/ISearchOption";
import { debounce, SearchCache } from "../helper/Utils";
import { SearchType } from "../Interfaces/ISearchType";
import { IUserGroup } from "../Interfaces/IUserGroup";

const columns: GridColDef[] = [
    { field: 'idLegalTransaction', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'LegalTransactionType', 
      headerName: 'Art', 
      width: 150 
    },
    { field: 'LegalTransactionProgress', 
      headerName: 'Phase', 
      width: 150 
    },
    { field: 'LegalTransactionState', 
      headerName: 'Status', 
      width: 150 
    },
    { field: 'Title', 
      headerName: 'Bezeichnung', 
      flex: 1
    }
]

const possibleSearchOptions: ISearchOption[] = [
    {SearchOptionName: "Titel", SearchOptionKey: "Title"},
    {SearchOptionName: "Vorgangsnummer", SearchOptionKey: "TransactionNumberEntry"},
    {SearchOptionName: "Urkundennummer", SearchOptionKey: "RecordNumberEntry"}
]

const searchCache = new SearchCache<ILegalTransaction[]>(100, 300000);

const rowsPerPages = 15;

const handleSearchTerm = async (searchTerm: string, searchOptions: ISearchOption[], searchtype: string, setLegalTransactionArray: Function, page: number, appendResults : boolean = false, setHasMore : Function, setIsSearching: Function, abortController: AbortController, setWasSuccessfully : Function) => {
    
    console.log("Starting search with term: ", searchTerm);
    setIsSearching(true);
    let stopWatch = new Date().getTime();

    let searchquery = '';
    for (let i = 0; i < searchOptions.length; i++) {
        
        searchquery += `${searchOptions[i].SearchOptionKey}:${searchTerm};`
        
    }

    let searchUri = searchTerm === ''? `page=${page}&rowsPerPage=${rowsPerPages}`:`search=${searchquery}&searchtype=${searchtype}&page=${page}&rowsPerPage=${rowsPerPages}`

    if (!checkCache(searchUri, setLegalTransactionArray, setHasMore, appendResults)) {

        getFetch<ILegalTransaction[]>(`/legaltransaction?${searchUri}`, null, (array: ILegalTransaction[]) => {
            setLegalTransactionArray((prev:ILegalTransaction[]) => appendResults? [...prev, ...array] : array);
            if (array.length < rowsPerPages) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
            stopWatch = new Date().getTime() - stopWatch;
            console.log("Search took: ", stopWatch, "ms");
            searchCache.add(searchUri, array);
        }, (successfull: boolean)=>{
            setWasSuccessfully(successfull);
            setIsSearching(false);
        }, abortController);
        
    } else {
        stopWatch = new Date().getTime() - stopWatch;
        console.log("Search took: ", stopWatch, "ms");
        setIsSearching(false);
    }
}

const checkCache = (cacheSearch : string, setLegalTransactionArray: Function, setHasMore: Function, appendResults: boolean) => {
    let searchResults = searchCache.get(cacheSearch);
    let precachedLegalTransactions: ILegalTransaction[] = [];
    if (searchResults !== null) {
        console.log("Cache hit");
            precachedLegalTransactions.push(...searchResults);
            setLegalTransactionArray((prev:ILegalTransaction[]) => appendResults? [...prev, ...precachedLegalTransactions] : precachedLegalTransactions);
            if (searchResults.length < rowsPerPages) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        return true;
    } else {
        return false;
    }
}

const debouncedSearch = debounce(handleSearchTerm, 500);


export const LegalTransactionOverview:React.FC = () => {
    const params = useParams();
    const navigation = useNavigate();

    const [legalTransactionArray, setLegalTransactionArray] = useState<ILegalTransaction[]>([]);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [legalStateArray, setLegalStateArray, wasSuccessfullyLegalStateArray] = useFetch<ILegalTransactionState[]>("/legaltransactionstate");
    const [LegalTransactionTypeArray, setLegalTransactionType, wasSuccessfullyLegalTransactionType] = useFetch<ILegalTransactionType[]>("/legaltransactiontype");
    const [legalTransactionProgressArray, setLegalTransactionProgress, wasSuccessfullyLegalTransactionProgress] = useFetch<ILegalTransactionProgress[]>("/legaltransactionprogress");
    const [legalReqState, setLegalReqState, wasSuccessfullyLegalLegalReqState] = useFetch<ILegalRequirementState[]>("/legalrequirementstate");
    const [userArray, setUserArray, wasSuccessfullyUserArray] = useFetch<IUser[]>("/userlist");
    const [userGroupArray, setUserGroupArray, wasSuccessfullyUserGroupArray] = useFetch<IUserGroup[]>("/usergroup");
    const [meetingEmailTemplateArray,setMeetingEmailTemplateArray,wasSuccessfullyMeetingEmailTemp] = useFetch<IMeetingEmailTemplate[]>("/meetingemailtemplate");
    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const [searchOptions, setSearchOptions] = useState<ISearchOption[]>(possibleSearchOptions);
    const [searchtype, setSearchType] = useState<string>(SearchType.INCLUDES);
    const [page, setPage] = useState<number>(1);
    const [textFieldRef , setTextFieldRef] = useState<HTMLDivElement | null>(null);
    
    //
    const [idLegalTransaction, setIdLegalTransaction] = useState<number|null>((params.idLegalTransaction == undefined) ? null : Number(params.idLegalTransaction));
    //
    const [isOpenAreYourSure,setIsOpenAreYourSure] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);
    // Suche
    const [searchTerm, setSearchTerm] = useState("");
    // 
    const [wasRemoved,setWasRemoved] = useState(false);

    const [hasMore, setHasMore] = useState(true);

    const [isSearching, setIsSearching] = useState(false);

    const [abortController, setAbortController] = useState(new AbortController());


    const handleClose = () => {
        setIsOpenAreYourSure(true)
    }

    const handleFinalOpen = () => {
        setIsOpenAreYourSure(false);
        setIdLegalTransaction(null);
    }
    

    const handleChangeId = (params: GridRowParams<any>) => {
        setIdLegalTransaction(Number(params.row.idLegalTransaction));
        navigation(`/legaltransaction/${params.row.idLegalTransaction}`);
    }


    useEffect(() => {

        debouncedSearch(searchTerm, searchOptions,searchtype, setLegalTransactionArray, page, page > 1 ? true : false, setHasMore, setIsSearching, abortController, setWasSuccessfully);
    }, [searchTerm, setLegalTransactionArray, searchOptions, page, searchtype, abortController]);


    const handleAfterRemove = () => {
        if (legalTransactionArray !== undefined && idLegalTransaction !== null)  {
            setLegalTransactionArray([
                ...legalTransactionArray.filter(x => x.idLegalTransaction !== idLegalTransaction)
            ])
            setWasRemoved(true);
            setIdLegalTransaction(null);
            navigation(`/legaltransaction`);
        }
    }

    if (
        !wasSuccessfully 
        || !wasSuccessfullyLegalStateArray 
        || !wasSuccessfullyLegalTransactionType 
        || !wasSuccessfullyLegalTransactionProgress 
        || !wasSuccessfullyUserArray
        || !wasSuccessfullyLegalLegalReqState
        || !wasSuccessfullyMeetingEmailTemp
        || !wasSuccessfullyUserGroupArray
    ) { return <>Fehler!</> }
    else if (
        legalTransactionArray == undefined 
        || legalStateArray === undefined 
        || LegalTransactionTypeArray === undefined 
        || legalTransactionProgressArray === undefined 
        || userArray === undefined
        || legalReqState === undefined
        || meetingEmailTemplateArray === undefined
        || userGroupArray === undefined
    ) { return <CustomCircularProgress/> }
    else {
        return(
            <>
            <ShowWasSaved
                wasSaved={wasSaved}
                setWasSaved={setWasSaved}
                wasSuccessfullySaved={wasSavedSuccesfully}
            />

            <ShowWasSaved
                wasSaved={wasRemoved}
                setWasSaved={setWasRemoved}
                wasSuccessfullySaved={true}
                text="Erfolgreich gelöscht!"
            />

            <Dialog
                open={isOpenAreYourSure}
                onClose={() => {}}
            >
                <DialogTitle>Warnung</DialogTitle>
                <DialogContent>
                    Sind Sie sicher, dass Sie den Vorgang ohne Speichern schließen wollen?
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleFinalOpen}>Schließen</Button>
                    <Button variant="contained" onClick={() => setIsOpenAreYourSure(false)} color="success">Abbruch</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={idLegalTransaction !== null}
                onClose={handleClose}
                maxWidth="xl" fullWidth
            >
                {(idLegalTransaction !== null ) &&
                    <DialogContent>
                        <LegalTransactionEdit
                            key={`idLegalTransaction-${idLegalTransaction}`}
                            idLegalTransaction={idLegalTransaction}
                            setIdLegalTransaction={setIdLegalTransaction}
                            legalTransactionArray={legalTransactionArray}
                            setLegalTransactionArray={setLegalTransactionArray}
                            legalStateArray={legalStateArray}
                            legalTypeArray={LegalTransactionTypeArray}
                            legalProgressArray={legalTransactionProgressArray}
                            legalReqState={legalReqState}
                            userArray={userArray}
                            userGroupArray={userGroupArray}
                            meetingEmailTemplateArray={meetingEmailTemplateArray}

                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setWasSaved={setWasSaved}
                            setWasSavedSuccesfully={setWasSavedSuccesfully}
                            handleAfterRemove={handleAfterRemove}
                        />
                    </DialogContent>
                }
            </Dialog>


            <Box sx={{m: 2, mt: 0}}>
                <Typography variant="h4">
                    Vorgänge
                    <IconButton sx={{float: "right"}} onClick={() => setIdLegalTransaction(-1)}><Add/></IconButton>
                </Typography>

               
                <Grid container>
                    <Grid item sm={2}>
                        <TextField
                            sx={{mt: 2, mb: 2}}
                            label="Suchbedingung"
                            value={searchtype}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchType(event.target.value);
                                setPage(1);
                                abortController.abort();
                                setAbortController(new AbortController())
                            }}
                            fullWidth
                            select
                        >
                            <MenuItem value={SearchType.STARTS_WITH}>Beginnt mit Sucheingabe</MenuItem>
                            <MenuItem value={SearchType.INCLUDES}>Enthält Sucheingabe</MenuItem>
                            <MenuItem value={SearchType.EXACT_MATCH}>Identisch mit Sucheingabe</MenuItem>

                        </TextField>
                        
                            
                    </Grid>
                    <Grid item sm={10}>
                        <TextField 
                            ref = {setTextFieldRef}
                            sx={{mt: 2, mb: 2}}
                            label="Suche"
                            fullWidth
                            value={searchTerm}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (event.target.value !== searchTerm) {
                                    setPage(1);
                                    abortController.abort();
                                    setAbortController(new AbortController())
                                    setSearchTerm(event.target.value)
                                }
                            }}
                            InputProps={{endAdornment:
                                <InputAdornment position="end">
                                    <IconButton edge='end' onClick={(event: React.MouseEvent<HTMLElement>) => {
                                        setShowSearchOptions(!showSearchOptions)
                                        }
                                    }>
                                        <Tune/>
                                    </IconButton>
                                </InputAdornment>
                            }}

                        />
                    </Grid>
                    <Popover
                id={'filter'}
                open={showSearchOptions}
                anchorEl={textFieldRef}
                onClose={() => {setShowSearchOptions(false)}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Autocomplete
                    multiple
                    options={possibleSearchOptions}
                    getOptionLabel={(option) => option.SearchOptionName}
                    value={searchOptions}
                    onChange={(event, newValue) => {
                        setSearchOptions(newValue)
                        setPage(1)
                        abortController.abort();
                        setAbortController(new AbortController())
                    }}
                    renderTags={(value: ISearchOption[], getTagProps) =>
                        value.map((option: ISearchOption, index: number) => (
                            <Chip label={option.SearchOptionName} {...getTagProps({ index })} />
                        ))
                    }
                    style={{ padding: 10, width: textFieldRef?.clientWidth }}
                    isOptionEqualToValue={(option: ISearchOption, value: ISearchOption) => {
                        return option.SearchOptionKey === value.SearchOptionKey
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Suche nach"
                        />
                    )}
                />
            </Popover>
                </Grid>
                   
              
                

                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={legalTransactionArray}
                            columns={columns}
                            getRowId={(row) => row.idLegalTransaction}
                            onRowClick={(params,event,details) => handleChangeId(params)}
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    {(hasMore && !isSearching) && <Button onClick={() => {setPage(page+1)}}>Mehr laden</Button>}
                    {isSearching && <CircularProgress/>}
                    </Grid>

                </Grid>
            </Box>
            </>
        )
    }

}