import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IContactGroup } from "../../Interfaces/IContactGroup";

const initialState =  { storeContactGroup: [] as IContactGroup[] }; 

const sliceContactGroup = createSlice({
    name: "CONTACTGROUPARRAY",
    initialState,
    reducers: {
        setContactGroupArray: (state, action: PayloadAction<IContactGroup[]>) => {
            state.storeContactGroup = [...action.payload];
        },
        addUpdateContactGroupArray: (state, action: PayloadAction<IContactGroup>) => {
            let testObject = state.storeContactGroup.find(x => x.idContactGroup === action.payload.idContactGroup);
            
            if (testObject) {
                state.storeContactGroup = [...state.storeContactGroup.map(x => x.idContactGroup === action.payload.idContactGroup ? action.payload : x)];
            } else {
                state.storeContactGroup = [action.payload, ...state.storeContactGroup];
            }
        },
    }
})
export const { setContactGroupArray, addUpdateContactGroupArray } = sliceContactGroup.actions;
export default sliceContactGroup.reducer;