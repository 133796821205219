import React, { useState } from "react";
import { IMortgage, IMortgageFullObject } from "../../../../Interfaces/IMortgage";
import { Alert, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";



interface IProps {
    mortgageObject: IMortgage;
    setMortgageObject: Function;
}

const transcriptOptions = [
    'simple_transcription',
    'certified_copy',
    'simple_execution',
    'enforceable_copy'
] as const;

type TranscriptOption = typeof transcriptOptions[number];

export const MortgageDetails:React.FC<IProps> = (props) => {

    const initialCheckedOptions: Record<TranscriptOption, boolean> = {
        simple_transcription: props.mortgageObject.Transcripts?.includes('simple_transcription') || false,
        certified_copy: props.mortgageObject.Transcripts?.includes('certified_copy') || false,
        simple_execution: props.mortgageObject.Transcripts?.includes('simple_execution') || false,
        enforceable_copy: props.mortgageObject.Transcripts?.includes('enforceable_copy') || false,
    };

    const [checkedOptions, setCheckedOptions] = useState(initialCheckedOptions);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedOptions({
            ...checkedOptions,
            [event.target.name as TranscriptOption]: event.target.checked,
        });

        const selectedTranscripts = transcriptOptions.filter(option => checkedOptions[option]);

        props.setMortgageObject({
            ...props.mortgageObject,
            Transcripts: selectedTranscripts,
        });
    };

    return (
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4">
                Grundpfand
            </Typography>

            <Typography sx={{ mb: 1 }}>
                Neue/ zu löschende Grundschuld
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="for-who"
                    name="who"
                    value={props.mortgageObject.ToBeDeleted ? "delete" : "new"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, ToBeDeleted: event.target.value === "delete"  })}
                >
                    <FormControlLabel value="delete" control={<Radio />} label="Zu löschen" />
                    <FormControlLabel value="new" control={<Radio />} label="Neu" />
                </RadioGroup>
            </FormControl>

            <Typography sx={{ mb: 3, mt: 3 }}>
                Für wen soll die Grundschuld bestellt werden?
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="for-who"
                    name="who"
                    value={props.mortgageObject.Beneficiary ? "private" : "institution"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, Beneficiary: event.target.value })}
                >
                    <FormControlLabel value="institution" control={<Radio />} label="Eine Bank (auch (ggf. Bau-)Sparkasse) oder eine Versicherung" />
                    <FormControlLabel value="private" control={<Radio />} label="Eine Privatperson oder sonstiges Unternehmen" />
                </RadioGroup>
            </FormControl>
            <Box sx={{ mt: 5 }} />
            <Typography sx={{ mb: 3 }}>
                Wird die Grundschuld zur Finanzierung eines Immobilienkaufs benötigt?
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="property"
                    name="property-buy"
                    value={props.mortgageObject.PropertyForBuying ? "yes" : "no"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, PropertyForBuying: event.target.value })}
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                    <FormControlLabel value="no" control={<Radio />} label="Nein" />
                </RadioGroup>
            </FormControl>
            {props.mortgageObject.PropertyForBuying && (
                <>
                    <Box sx={{ mt: 5 }} />
                    <Typography sx={{ mb: 3 }}>
                        Ist der Immobilienkauf bereits erfolgt?
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="property-owned"
                            name="property-owned"
                            value={props.mortgageObject.PropertyOwned ? "yes" : "no"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, PropertyOwned: event.target.value })}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                            <FormControlLabel value="no" control={<Radio />} label="Nein" />
                        </RadioGroup>
                    </FormControl>
                    {props.mortgageObject.PropertyOwned && (
                        <>
                            <Box sx={{ mt: 5 }} />
                            <Typography sx={{ mb: 3 }}>
                                Geben Sie die Urkundenverzeichnisnummer ein (sog. UVZ-Nummer)?
                            </Typography>
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    label="Urkundenverzeichnisnummer"
                                    size="small"
                                    fullWidth
                                    value={props.mortgageObject.DocumentIndexNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, DocumentIndexNumber: event.target.value })}
                                    required
                                />
                            </Grid>
                            <Box sx={{ mt: 5 }} />
                            <Typography sx={{ mb: 3 }}>
                                Geben Sie die Vorgangsnummer (sechsstellig, befindet sich ausgegraut auf der ersten Seite des Entwurfs) an.
                            </Typography>
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    label="Vorgangsnummer"
                                    size="small"
                                    fullWidth
                                    value={props.mortgageObject.OperationNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, OperationNumber: event.target.value })}
                                    required
                                />
                            </Grid>
                        </>
                    )}

                    <Box sx={{ mt: 5 }} />
                    <Grid item xs={6} sm={4}>
                        <TextField
                            label="Betrag der Grundschuld"
                            size="small"
                            fullWidth
                            value={props.mortgageObject.AmountMortgage}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, AmountMortgage: event.target.value })}
                            required
                        />
                    </Grid>

                    <Box sx={{ mt: 5 }} />
                    <Grid item xs={6} sm={4}>
                        <TextField
                            label="Grundschuldzinssatz"
                            size="small"
                            fullWidth
                            value={props.mortgageObject.MortgageInterest}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, MortgageInterest: event.target.value })}
                            required
                        />
                    </Grid>

                    <Box sx={{ mt: 5 }} />
                    <Grid item xs={6} sm={4}>
                        <TextField
                            label="Nebenleistungen"
                            size="small"
                            fullWidth
                            value={props.mortgageObject.AdditionalServices}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, AdditionalServices: event.target.value })}
                            required
                        />
                    </Grid>

                    <Box sx={{ mt: 5 }} />
                    <Grid item xs={6} sm={4}>
                        <TextField
                            label="Remarks"
                            size="small"
                            fullWidth
                            value={props.mortgageObject.remarks}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, remarks: event.target.value })}
                            required
                        />
                    </Grid>

                    <Box sx={{ mt: 5 }}>
                        <Typography sx={{ mb: 3 }}>
                            Welche Abschriften soll der Gläubiger erhalten?
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedOptions.simple_transcription}
                                        onChange={handleChange}
                                        name="simple_transcription"
                                    />
                                }
                                label="Einfache Abschrift"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedOptions.certified_copy}
                                        onChange={handleChange}
                                        name="certified_copy"
                                    />
                                }
                                label="Beglaubigte Abschrift"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedOptions.simple_execution}
                                        onChange={handleChange}
                                        name="simple_execution"
                                    />
                                }
                                label="Einfache Ausfertigung"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedOptions.enforceable_copy}
                                        onChange={handleChange}
                                        name="enforceable_copy"
                                    />
                                }
                                label="Vollstreckbare Ausfertigung"
                            />
                        </FormGroup>
                        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>Soll die Grundschuld nach §873 Abs. 2 BGB entgegengenommen werden?</Typography>
                        <FormControl component="fieldset">
                            <Alert severity={"info"} icon={false} sx={{ mb: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.mortgageObject.Paragraph}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({ ...props.mortgageObject, Paragraph: event.target.value })}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            required
                                        />}
                                    label={
                                        <Typography component="span">
                                            Ja.
                                        </Typography>
                                    }
                                />
                            </Alert>
                        </FormControl>
                    </Box>
                </>
            )}
        </>
    )
}