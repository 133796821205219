import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { uploadFetch, useFetch } from "../hooks/useFetch";
import { IUser } from "../Interfaces/IUser";


export const SupportDialog:React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    //
    const [title, setTitle] = useState("");
    const [priority,setPriority] = useState("middle");
    const [category,setCategory] = useState("support");
    const [descripton, setDescription] = useState("");
    //
    const [idUser, setIdUser] = useState(Number(localStorage.getItem("idUser")));
    const [userArray, setUserArray, wasSuccessfullyUserArray] = useFetch<IUser[]>("/userlist");
    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSend,setWasSend] = useState(false);


    const handleOpen = () => {
        setWasSend(false);
        setIsLoading(false);
        setIdUser(Number(localStorage.getItem("idUser")));
        setTitle("");
        setPriority("middle");
        setCategory("support");
        setDescription("");
        setIsOpen(true);
    }


    const handleSave = () => {
        setIsLoading(true);
        let supportObject:any = {
            Title: title,
            Priority: priority,
            Category: category,
            For_idUser: idUser,
            Description: descripton
        }
        uploadFetch("/support",true,supportObject,() => {setIsLoading(false);setWasSend(true)})
    }


    const handleBodyDialog = () => {
        if (wasSend) {
            return(
                <>
                    <DialogContent>
                        <Alert severity="success">
                            Ihr Anliegen wurde erfolgreich übermittelt!
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpen(false)} variant="contained">Schließen</Button>
                    </DialogActions>
                </>
            )
        }
        else {
            return(
                <>
                    <DialogContent>
                        <>
                            {(isLoading) && <LinearProgress/>}
                            <Grid container spacing={2} sx={{mt: 2}}>
                                <Grid item sm={12}>
                                    <TextField
                                        label="Betreff"
                                        size="small"
                                        value={title}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
                                        fullWidth
                                        error={title === ""}
                                    />
                                </Grid>

                                <Grid item sm={12}>
                                    <TextField
                                        label="Von"
                                        size="small"
                                        value={idUser}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setIdUser(Number(event.target.value))}
                                        fullWidth
                                        select
                                    >
                                        {(userArray !== undefined) && userArray.map(x =>
                                            <MenuItem key={`support-idUser-${x.idUser}`} value={x.idUser}>
                                                {x.FirstName} {x.LastName}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>


                                <Grid item sm={6}>
                                    <TextField
                                        label="Priorität"
                                        size="small"
                                        value={priority}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setPriority(event.target.value)}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem key={`support-low`} value="low">Niedrig</MenuItem>
                                        <MenuItem key={`support-middle`} value="middle">Normal</MenuItem>
                                        <MenuItem key={`support-high`} value="high">Hoch</MenuItem>
                                        <MenuItem key={`support-critical`} value="critical">Kritisch</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item sm={6}>
                                    <TextField
                                        label="Kategorie"
                                        size="small"
                                        value={category}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCategory(event.target.value)}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem key={`support-support`} value="support">Hilfe</MenuItem>
                                        <MenuItem key={`support-error`} value="error">Fehlermeldung</MenuItem>
                                        <MenuItem key={`support-wish`} value="high">Vorschlag</MenuItem>
                                        <MenuItem key={`support-experience`} value="experience">Benutzererfahrung</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item sm={12}>
                                    <TextField
                                        label="Beschreibung"
                                        size="small"
                                        value={descripton}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
                                        fullWidth
                                        error={descripton === ""}
                                        multiline
                                        rows={5}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                        <Button 
                         onClick={handleSave} 
                         variant="contained"
                         disabled={isLoading || title === "" || descripton === ""}
                        >
                            Senden
                        </Button>
                    </DialogActions>
                </>
            )
        }
    }


    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>Neues Anliegen</DialogTitle>
                {handleBodyDialog()}
            </Dialog>



            <Button onClick={handleOpen} variant='outlined' sx={{color: "black", borderColor: "black", mr: 2}}>Support</Button>
        </>
    )
} 