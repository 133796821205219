import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";

import L, { latLng, LatLngExpression } from 'leaflet';
import { LatLngTuple, Map } from "leaflet";
import { MapContainer, Marker, Polygon, Polyline, Popup, TileLayer, useMap, useMapEvents, WMSTileLayer } from "react-leaflet";
import { GeoSearchControl, MapBoxProvider, OpenStreetMapProvider } from 'leaflet-geosearch';
import { Grid, TextField } from "@mui/material";
import CustomWMSLayer, { IAlkisResult } from "./CustomeWMSLayer";
import { IResult } from "../LegalTransaction/DocumentDirectory/LandregisterSerach";
import { MPSearchField } from "./lib/MPSearchField";
import { MPMarker } from "./lib/MPMarker";
import { MasterportalLandRegisterMarker } from "./MasterportalLandRegisterMarker";



declare global {
    interface Window { 
        MpConfigPath: string;
        MpMastercodePath: string;
     }
}

interface IGeoSearchResult {
    location: {
        bounds: number[][];
        x: number;
        y: number;
        label: string;
    }
}








const SetMapView:React.FC<{currentPosition:LatLngExpression}> = ({currentPosition}) => {
    const map = useMap();
    map.eachLayer((layer:L.Layer) => {
        if( layer instanceof L.TileLayer ) {
            console.log(layer)
        }
    })

    useEffect(() => {
        map.setView(currentPosition)
    },[currentPosition])
    return null;
}


export const MasterportalViewer:React.FC<{resultArray?:IResult[]|null}> = ({resultArray}) => {
    const [currentPosition,setCurrentPosition] = useState<LatLngExpression>({ lat: 52.78423283209073, lng: 13.24504470798317 })
    const [posMarker,setPosMarker] = useState<LatLngExpression>({ lat: 52.65359325876985, lng: 13.387664627338557 });
    const [returnValue,setReturnValue] = useState<string|null>(null);
    //
    const [selectedResult,setSelectedResult] = useState<null|IGeoSearchResult>(null);
    //
    const [alkisRestultObject,setAlkisRestultObject] = useState<IAlkisResult | null>(null);
    //
    const [triggerMarkerPopup,setTriggerMarkerPopup] = useState(0);

    useEffect(() => {
        if (selectedResult !== null) {
            //getSearch(selectedResult.location.label)
            setCurrentPosition({lat: selectedResult.location.y, lng: selectedResult.location.x} as LatLngExpression)
            setPosMarker({lat: selectedResult.location.y, lng: selectedResult.location.x} as LatLngExpression)
        }
        
        console.log("SELECTED RESULT!");
        console.log(selectedResult)
    },[selectedResult])


    const getSearch = (query: string) => {
        fetch("https://search.geobasis-bb.de/search/?"
            + `query=${query}&`
            + "limit=5&"
            + "sortOrder=desc&"
            + "sortBy=relevance&"
            + "complex=true&"
            + "filter[category]=gazetteer|kataster|strassendaten|haltestellen|kartenblatt|oebvi&"
            + "lang=de&", {
            headers: {
            'User-Agent': 'DiNoPublic'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setReturnValue(res)
        })
    }




    const handleInfoResults = (latlng: L.LatLng, alkisResult: IAlkisResult) => {
        setCurrentPosition({ lat: latlng.lat , lng: latlng.lng } as LatLngExpression);
        setPosMarker({ lat: latlng.lat , lng: latlng.lng } as LatLngExpression);
        setAlkisRestultObject(alkisResult);
        setTriggerMarkerPopup(triggerMarkerPopup+1);
    } 


    return(
        <>  
            <link
                rel="stylesheet"
                href="https://unpkg.com/leaflet-geosearch@3.0.0/dist/geosearch.css"
            />
            <MapContainer 
                key={`MapContainer-112`}
                style={{height: 400, width: 800, alignContent: "center",alignItems: "center"}} 
                center={currentPosition} 
                zoom={16} 
                scrollWheelZoom={true}
            >

                <SetMapView currentPosition={currentPosition}/>

                <MPSearchField setSelectedResult={setSelectedResult}/>

                <WMSTileLayer
                    url="https://isk.geobasis-bb.de/mapproxy/webatlasde_topplus/service/wms"
                    format="image/png"
                    layers="topplus_farbe,webatlas_farbe"
                    version="1.3.0"
                    key="tile-karte"
                />
             {/*
             <WMSTileLayer
                url="https://isk.geobasis-bb.de/ows/alkis_wms"
                format="image/png"
                layers="adv_alkis_flurstuecke"
                version="1.3.0"
                transparent
                
             />
              */}


                <CustomWMSLayer
                    layers={['adv_alkis_flurstuecke']}
                    options={{
                        "format": "image/png",
                        "transparent": "true",
                        "attribution": "<a href='https://ows.terrestris.de/'>terrestris</a>",
                        "info_format": "text/html",
                        "version" :"1.1.1",
                        "layers" : "adv_alkis_flurstuecke"
                    }}
                    url="https://isk.geobasis-bb.de/ows/alkis_wms"
                    handleInfoResults={handleInfoResults}
                    key="tile-flurstücke"
                />

                <MPMarker
                    posMarker={posMarker}
                    triggerMarkerPopup={triggerMarkerPopup}
                    alkisRestultObject={alkisRestultObject}
                />

                {(resultArray) && resultArray.map((x,i) =>
                    <MasterportalLandRegisterMarker
                        key={`mp-landregister-${i}-${x.CadastralUnit}`}
                        index={i}
                        resultObject={x}
                    />
                ) }

            </MapContainer>
            <script src="https://unpkg.com/leaflet-geosearch@latest/dist/bundle.min.js"></script>
        </>
    )
}