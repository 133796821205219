import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Add, ArrowDownward, ArrowUpward, Edit } from "@mui/icons-material";
import { ILegalPhase, ILegalPhaseFullObject } from "../../../Interfaces/ILegalPhase";
import { ILegalRequirement } from "../../../Interfaces/ILegalRequirement";
import { ConfirmDelete } from "../../../core/ConfirmDelete";
import { LegalPhaseDialogEdit } from "./LegalPhaseDialogEdit";
import { LegalRequirementRow } from "./LegalRequirementRowEdit";
import { LegalPhaseCopyRequirement } from "../../../LegalTransactionType/LegalPhaseCopyRequirement";
import { ILegalRequirementTemplate } from "../../../Interfaces/ILegalRequirementTemplate";


interface IProps {
    currentPhase: ILegalPhaseFullObject;
    legalPhaseArray: ILegalPhaseFullObject[];
    setLegalPhaseArray: Function;
    legalRequirementTemplateArray: ILegalRequirementTemplate[];
}


export const LegalPhaseRowEdit:React.FC<IProps> = (props) => {
    const [currentPhaseEdit, setCurrentPhaseEdit] = useState(props.currentPhase);
    const [currentPreconditionArray, setCurrentPreconditionArray] = useState(props.currentPhase.PreconditionArray);
    //
    //const [isOpenEdit, setIsOpenEdit] = useState(props.currentPhase.idLegalPhase < 0 && props.currentPhase.LegalPhase === "");

    const handleAddNewReq = () => {
        let tmpId = -1;

        if (props.currentPhase.LegalRequirementArray.length > 0) {
            let tmpIdArry = Math.min(...props.currentPhase.LegalRequirementArray.map(x => x.idLegalRequirement));
            
            if (tmpIdArry <= tmpId) {
                tmpId = tmpIdArry - 1;
            }
        }

        let tmpObject = {...props.currentPhase};
        tmpObject.LegalRequirementArray.push({
            idLegalPhase: -1,
            idLegalRequirement: tmpId,
            canUploadFilesClient: false,
            canUploadFilesEmployees: false,
            hasError: false,
            hasErrorFreeText: false,
            LegalRequirement: "",
            sendMail: false, 
            EmailBody: null,
            EmailSubject: null,
            Predecessor_idLegalRequirement: null,
            TextOfError: null,
            Position: props.currentPhase.LegalRequirementArray.length,
            enableError: false,
            enableFreeTextError: false,
            isComplete: false,
            idLegalRequirementState: 10,
            User_idUser: null,
            InfoText: null
        } as ILegalRequirement)

        props.setLegalPhaseArray([
            ...props.legalPhaseArray.map(x => (x.idLegalPhase === tmpObject.idLegalPhase) ? tmpObject : x)
        ])
    }

    const updateArry = (localLegalReqArray:ILegalRequirement[]) => {
        let tmpObject = {...props.currentPhase};
        tmpObject.LegalRequirementArray = localLegalReqArray;
        props.setLegalPhaseArray([
            ...props.legalPhaseArray.map(x => (x.idLegalPhase === tmpObject.idLegalPhase) ? tmpObject : x)
        ])
    }

    const handleTemplateToRequirement = (localLegalReqArray:ILegalRequirementTemplate[]) => {
        let tmpArray:ILegalRequirement[] = [];
        let maxPosition = Math.max(...props.currentPhase.LegalRequirementArray.map(x => x.Position)) +1;

        localLegalReqArray.map((x,i) => {
            let newRequirement = {
                ...x,
                idLegalPhase: props.currentPhase.idLegalPhase,
                idLegalRequirement: x.idLegalRequirementTemplate,
                LegalRequirement: x.LegalRequirementTemplate,
                enableError: x.hasError,
                enableFreeTextError: x.hasErrorFreeText,
                isComplete: false,
                User_idUser: null,
                Position: maxPosition+i

            } as ILegalRequirement
            tmpArray.push(newRequirement)
        })
        
        updateArry([...props.currentPhase.LegalRequirementArray,...tmpArray])

    }

    const isFirstDisabled = () => {
        let currentIndex:number = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(props.currentPhase.idLegalPhase);
        return (currentIndex === 0)
    }
    const isLastDisabled = () => {
        let currentIndex:number = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(props.currentPhase.idLegalPhase);
        return (currentIndex === props.legalPhaseArray.length-1)
    }

    /*
    const getUp = () => {
        let copyOfArray = [...props.legalPhaseArray];
        let targetPositionn = currentPhaseEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentPhaseEdit, Position: currentPhaseEdit.Position-1}

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(currentPhaseEdit.idLegalPhase);
            let targetIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(toChangeObject.idLegalPhase);
            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position-1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position+1;

            setCurrentPhaseEdit(currentObject);
            props.setLegalPhaseArray(copyOfArray)
        }        
    }
    */
    const getUp = () => {
        let targetObject = props.legalPhaseArray.find(x => x.Position === currentPhaseEdit.Position -1);

        if (targetObject === undefined) {
            console.log("Can't determine target Position")
        } else {
            targetObject.Position = targetObject.Position +1;
            currentPhaseEdit.Position = currentPhaseEdit.Position -1;

            props.setLegalPhaseArray([
                ...props.legalPhaseArray.map(x =>
                    (x.idLegalPhase === targetObject?.idLegalPhase) ? targetObject
                    : (x.idLegalPhase === currentPhaseEdit.idLegalPhase) ? currentPhaseEdit
                    : x
                )
            ])
        }
    }

    const getDown = () => {
        let targetObject = props.legalPhaseArray.find(x => x.Position === currentPhaseEdit.Position +1);

        if (targetObject === undefined) {
            console.log("Can't determine target Position")
        } else {
            targetObject.Position = targetObject.Position -1;
            currentPhaseEdit.Position = currentPhaseEdit.Position +1;

            props.setLegalPhaseArray([
                ...props.legalPhaseArray.map(x =>
                    (x.idLegalPhase === targetObject?.idLegalPhase) ? targetObject
                    : (x.idLegalPhase === currentPhaseEdit.idLegalPhase) ? currentPhaseEdit
                    : x
                )
            ])
        }

        /*
        let copyOfArray = [...props.legalPhaseArray];
        let targetPositionn = currentPhaseEdit.Position+1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentPhaseEdit, Position: currentPhaseEdit.Position+1}

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(currentPhaseEdit.idLegalPhase);
            let targetIndex = props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(toChangeObject.idLegalPhase);

            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position+1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position-1;

            setCurrentPhaseEdit(currentObject);
            props.setLegalPhaseArray(copyOfArray)
        }
        */          
    }

    const handleDelete = () => {
        props.setLegalPhaseArray([
            ...props.legalPhaseArray.filter(x => x.idLegalPhase !== props.currentPhase.idLegalPhase)
        ])
    }

    useEffect(() => {
        props.setLegalPhaseArray([
            ...props.legalPhaseArray.map(x => x.idLegalPhase === currentPhaseEdit.idLegalPhase ? currentPhaseEdit : x )
        ])
    },[currentPhaseEdit])

    return(
        <>


            <TableRow>
                <TableCell colSpan={2}>
                    {props.currentPhase.LegalPhase}
                </TableCell>
                <TableCell>
                    {props.currentPhase.PreconditionArray.map(x => 
                        props.legalPhaseArray
                            .filter(y => y.idLegalPhase === x.idLegalPhase)
                            .map(y =>
                                <>
                                    <Typography sx={{mr: 3}} variant="caption">{y.LegalPhase}</Typography>
                                    <br />
                                </>
                            )
                    )}
                </TableCell>
                <TableCell>
                    <IconButton 
                        sx={{float: "right"}}  
                        size="small"
                        disabled={isLastDisabled()}
                        onClick={getDown}
                    ><ArrowDownward/></IconButton>

                    <IconButton 
                        sx={{float: "right"}}  
                        size="small"
                        disabled={isFirstDisabled()}
                        onClick={getUp}
                    ><ArrowUpward/></IconButton>

                    <IconButton sx={{float: "right"}} size="small" onClick={handleAddNewReq}><Add/></IconButton>

                    <LegalPhaseCopyRequirement
                        idLegalPhaseTemplate={props.currentPhase.idLegalPhase}
                        startId={Math.min(
                            ...props.currentPhase.LegalRequirementArray.map(x => x.idLegalRequirement)
                        )}
                        setArray={handleTemplateToRequirement}
                        legalRequirementTemplateArray={props.legalRequirementTemplateArray}
                    />
                </TableCell>
                <TableCell sx={{width: 100}}/>
                <TableCell sx={{width: 100}}/>
                <TableCell sx={{width: 100}}/>
                <TableCell sx={{ width: 150 }}>
                    <LegalPhaseDialogEdit
                        currentPhase={props.currentPhase}
                        currentPhaseEdit={currentPhaseEdit}
                        setCurrentPhaseEdit={setCurrentPhaseEdit}
                        legalPhaseArray={props.legalPhaseArray}
                        setLegalPhaseArray={props.setLegalPhaseArray}
                    />
                    <ConfirmDelete itemText="die aktulle Phase und deren Bedingungen" functionToDelete={handleDelete}/>
                </TableCell>
            </TableRow>

            {props.currentPhase.LegalRequirementArray.sort((a,b) => a.Position < b.Position ? -1 : 1).map(x =>
                <LegalRequirementRow
                    key={`idLegalRequirement-${x.idLegalRequirement}`}
                    currentRequirement={x}
                    legalRequirementArray={props.currentPhase.LegalRequirementArray}
                    setLegalRequirementArray={updateArry}
                />
            )}
        </>
    )
}