import React from "react";
import {ILegalTransactionGwGFullObject} from "../../../Interfaces/ILegalTransactionGwG";
import {ILegalPhaseFullObject} from "../../../Interfaces/ILegalPhase";
import {Box, Button, Grid, Typography} from "@mui/material";
import {GwGElement} from "./GwGElement";


interface IProps {
    gwgArray: ILegalTransactionGwGFullObject[];
    setGwgArray: Function;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
}


export const GwGMain: React.FC<IProps> = ({gwgArray, setGwgArray, legalPhaseFullObjectArray}) => {
    const handleAdd = () => {
        /*
        {
            let minId = -1;

            if (props.gwgArray.length > 0) {
                let minIdArray = Math.min(...props.gwgArray.map(x => x.idLegalTransactionGwG)) -1;

                if (minIdArray <= minId) {
                    minId = minIdArray -1
                }
            }

            let newObject = {
                idLegalTransactionGwG: minId,
                idLegalTransaction: -1,
                idLegalRequirement: null,
                LegalTransactionGwGEntryArray: [],
                total: 0,
                title: "GwG Kaufvertrag"
            } as ILegalTransactionGwGFullObject

            props.setGwgArray([
                ...props.gwgArray,
                newObject
            ])
        }
         */

    }


    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Typography variant="h5" sx={{mt: 3}}>GwG</Typography>
                </Grid>
                <Grid item sm={12}>
                    {gwgArray.map((gwgObject, idx) =>
                        <>
                            {(idx !== 0) && <Box sx={{mt: 10}}/>}
                            <GwGElement
                                key={`gwg-${gwgObject.idLegalTransactionGwG}`}
                                gwgObject={gwgObject}
                                gwgArray={gwgArray}
                                setGwgArray={setGwgArray}
                                legalPhaseFullObjectArray={legalPhaseFullObjectArray}
                            />
                        </>
                    )}
                </Grid>
            </Grid>


            {
                /*
                    <Typography variant="h5" sx={{mb: 3}}>
                            GwG
                        <Button sx={{float: "right"}} onClick={handleAdd} variant="outlined">Hinzufügen</Button>
                    </Typography>
                 */
            }


        </>
    )
}