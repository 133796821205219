import { Alert, AlertTitle, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, MenuItem, Tab, Tabs, TextField, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import { ILegalPhaseTemplateFullObject } from "../Interfaces/ILegalPhaseTemplate";
import { ILegalTransactionType, ILegalTransactionTypeFullObject } from "../Interfaces/ILegalTransactionType";
import { deleteFetch, uploadFetch, useFetch } from "../hooks/useFetch";
import { LegalPhaseTable } from "./LegalPhaseTable";
import { LegalTransactionTypeCopy } from "./LegalTransactionTypeCopy";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { ILegalRoleTemplate } from "../Interfaces/ILegalRoleTemplate";
import { LegalRoleTemplateOverview } from "./LegalRoleTemplate/LegalRoleTemplateOverview";
import { ILegalRoleTemplate_canInvite } from "../Interfaces/ILegalRoleTemplate_canInvite";
import {  LegalRoleTemplateCanInviteTable } from "./LegalRoleTemplate/LegalRoleTemplateCanInviteTable";
import { ILegalRequirementTemplate } from "../Interfaces/ILegalRequirementTemplate";
import { IDocumentDirectoryTemplate } from "../Interfaces/IDocumentDirectoryTemplate";
import { IDocument } from "../Interfaces/IDocument";
import { IDocumentDirectoryTemplateHasDocument } from "../Interfaces/IDocumentDirectoryTemplateHasDocument";
import { DocumentDirectoryTemplateOverview } from "./DocumentDirectoryTemplate/DocumentDirectoryTemplateOverview";
import { IMeetingEmailTemplate } from "../Interfaces/IMeetingEmailTemplate";
import { MeetingEmailTemplateOverview } from "./MeetingEmailTemplate/MeetingEmailTemplateOverview";



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}








interface IProps {
    idLegalTransactionType: number;
    setIdLegalTransactionType: Function;
    transactionTypeArray: ILegalTransactionType[];
    setTransactionTypeArray: Function;
    legalRequirementTemplateArray: ILegalRequirementTemplate[];
    setWasSaved: Function;
    setWasSavedSuccessfully: Function;
    handleAfterRemove: Function;
}


const getEmptyLegalTransactionTypeFullObject = () => {
    return {
        idLegalTransactionType: -1,
        LegalTransactionType: "",
        LegalPhaseTemplateArray: [],
        LegalRoleTemplateArray: [],
        LegalRoleTemplateCanInviteArray: [],
        isTemplate: false,
        withDialog: false,
        DocumentDirectoryTemplateArray: [],
        DocumentArray: [],
        MeetingEmailTemplateArray: []
    } as ILegalTransactionTypeFullObject
}

const getEmptyLegalPhaseTemplateFullObject = (localArray:ILegalPhaseTemplateFullObject[]) => {
    let tmpId = -1;

    if (localArray.length > 0) {
        let tmpIdArray = Math.min(...localArray.map(x => x.idLegalPhaseTemplate));
        
        if (tmpIdArray <=  tmpId) {
            tmpId = tmpIdArray - 1;
        }
    }

    return {
        idLegalPhaseTemplate: tmpId,
        idLegalTransactionType: -1,
        LegalPhaseTemplate: "",
        LegalRequirementArray: [],
        Position: localArray.length,
        PreconditionArray: [],
        InfoText: null
    } as ILegalPhaseTemplateFullObject
}

const columns: GridColDef[] = [
    { field: 'idLegalPhaseTemplate', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'LegalPhaseTemplate', 
      headerName: 'Phasen', 
      flex: 1
    }
]

export const LegalTransactionTypeEdit:React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [legalTransactionTypeFullObject, setLegalTransactionTypeFullObject, wasSuccessfully] = useFetch<ILegalTransactionTypeFullObject>("/legaltransactiontype/fullObject/",props.idLegalTransactionType);
    const [legalTransactionType, setLegalTransactionType] = useState<ILegalTransactionType>(getEmptyLegalTransactionTypeFullObject());
    const [legalPhaseTemplateArray, setLegalPhaseTemplateArray] = useState<ILegalPhaseTemplateFullObject[]>([]);
    const [legalRoleTemplateArray,setLegalRoleTemplateArray] = useState<ILegalRoleTemplate[]>([]);
    const [legalRoleTemplateCanInviteArray,setLegalRoleTemplateCanInviteArray] = useState<ILegalRoleTemplate_canInvite[]>([]);
    const [currentLegaPhase, setCurrentLegaPhase] = useState<ILegalPhaseTemplateFullObject>(getEmptyLegalPhaseTemplateFullObject(legalPhaseTemplateArray));
    const [isOpenDelete,setIsOpenDelete] = useState(false);
    //
    const [documentDirectoryTemplateArray, setDocumentDirectoryTemplateArray] = useState<IDocumentDirectoryTemplate[]>([]);
    const [documentArray, setDocumentArray] = useState<IDocumentDirectoryTemplateHasDocument[]>([]);
    //
    const [meetingEmailTemplateArray,setMeetingEmailTemplateArray] = useState<IMeetingEmailTemplate[]>([])
    //
    const [currenTab, setCurrenTab] = useState(0);

    useEffect(() => {
        if (props.idLegalTransactionType === -1) {
            setLegalTransactionTypeFullObject(getEmptyLegalTransactionTypeFullObject());
        }
    },[])

    useEffect(() => {
        if (legalTransactionTypeFullObject !== undefined) {
            setLegalTransactionType(legalTransactionTypeFullObject);
            setLegalPhaseTemplateArray(legalTransactionTypeFullObject.LegalPhaseTemplateArray);
            setLegalRoleTemplateArray(legalTransactionTypeFullObject.LegalRoleTemplateArray);
            setLegalRoleTemplateCanInviteArray(legalTransactionTypeFullObject.LegalRoleTemplateCanInviteArray);
            setDocumentArray(legalTransactionTypeFullObject.DocumentArray);
            setDocumentDirectoryTemplateArray(legalTransactionTypeFullObject.DocumentDirectoryTemplateArray);
            setMeetingEmailTemplateArray(legalTransactionTypeFullObject.MeetingEmailTemplateArray);
        }
    },[legalTransactionTypeFullObject])



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrenTab(newValue);
    };


    const handleAddNewLegalPhase = () => {
        setCurrentLegaPhase(getEmptyLegalPhaseTemplateFullObject(legalPhaseTemplateArray));
        setIsOpen(true);
    }

    const handleAddNewLegalPhaseNew = () => {
        setLegalPhaseTemplateArray([
            ...legalPhaseTemplateArray,
            getEmptyLegalPhaseTemplateFullObject(legalPhaseTemplateArray)
        ]);
    }

    const handleOpenLegalPhaseEdit = (idLegalPhaseTemplate:number) => {
        let tmpObject = legalPhaseTemplateArray.find(x => x.idLegalPhaseTemplate === idLegalPhaseTemplate);

        if (tmpObject !== undefined) {
            setCurrentLegaPhase(tmpObject);
        } else {
            setCurrentLegaPhase(getEmptyLegalPhaseTemplateFullObject(legalPhaseTemplateArray));
        }
        setIsOpen(true);
    }

    const handleAddLegalPhase = () => {
        if (currentLegaPhase !== null) {
            let testObject = legalPhaseTemplateArray.find(x => x.idLegalPhaseTemplate === currentLegaPhase.idLegalPhaseTemplate);
            let insertObject = {...currentLegaPhase}

            if (testObject === undefined) {
                let tmpId = -1;

                if (legalPhaseTemplateArray.length > 0) {
                    let tmpIdArray = Math.min(...legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate));

                    if (tmpIdArray <= tmpId) {
                        tmpId = tmpIdArray - 1
                    }
                    insertObject.idLegalPhaseTemplate = tmpId;
                }

                setLegalPhaseTemplateArray([
                    ...legalPhaseTemplateArray,
                    insertObject
                ]);

                setIsOpen(false);
            }
        }
    }

    const wrapperSave = (localTransactionType:ILegalTransactionTypeFullObject) => {
        if (legalTransactionType.idLegalTransactionType < 0) {
            props.setTransactionTypeArray([
                ...props.transactionTypeArray,
                localTransactionType
            ])
        } else {
            props.setTransactionTypeArray([
                ...props.transactionTypeArray.map(x => x.idLegalTransactionType === localTransactionType.idLegalTransactionType ? localTransactionType : x)
            ])
        }
        setLegalTransactionTypeFullObject(localTransactionType);
    }

    const handleSave = () => {
        if (legalTransactionTypeFullObject != undefined) {
            setIsLoading(true);
            let tmpObject = {
                ...legalTransactionType, 
                withDialog: Boolean(legalTransactionType.withDialog),
                isTemplate: Boolean(legalTransactionType.isTemplate),
                LegalPhaseTemplateArray: legalPhaseTemplateArray,
                LegalRoleTemplateArray: legalRoleTemplateArray,
                LegalRoleTemplateCanInviteArray: legalRoleTemplateCanInviteArray,
                DocumentArray: documentArray,
                DocumentDirectoryTemplateArray: documentDirectoryTemplateArray,
                MeetingEmailTemplateArray: meetingEmailTemplateArray
            } as ILegalTransactionTypeFullObject
            uploadFetch(
                "/legaltransactiontype/fullObject",
                (legalTransactionTypeFullObject.idLegalTransactionType < 0), 
                tmpObject,
                wrapperSave,
                props.setWasSavedSuccessfully,
                props.setWasSaved,
                setIsLoading
            )
        }
    }

    const handleDelete = () => {
        if (props.idLegalTransactionType !== null) {
            deleteFetch("legaltransactiontype",props.idLegalTransactionType,props.handleAfterRemove)
        }
    }

    if (!wasSuccessfully) { return <>Fehler!</> }
    else if (legalTransactionTypeFullObject === undefined) { return <><CustomCircularProgress/></> }
    else {
        return(
            <>
                <Dialog open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
                    <DialogTitle>Löschen</DialogTitle>
                    <DialogContent>
                        Sind Sie sich sicher, dass Sie den aktuellen Vorgang löschen wollen?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpenDelete(false)}>Abbruch</Button>
                        <Button onClick={handleDelete}>Löschen</Button>
                    </DialogActions>
                </Dialog>

                <Collapse in={legalTransactionType.isTemplate == true && legalTransactionType.idLegalTransactionType > 0}>
                    <Alert severity="warning" sx={{mb: 5}}>
                        <AlertTitle>Warnung</AlertTitle>
                        Sie bearbeiten eine <strong>Vorlage</strong>!
                    </Alert>
                </Collapse>

                <Collapse in={legalTransactionType.withDialog}>
                    <Alert severity="warning" sx={{mb: 5}}>
                        <AlertTitle>Warnung</AlertTitle>
                        Diese Rechtsgeschäftsart hat einen öffentlichen Dialog und kann nicht gelöscht werden.
                    </Alert>
                </Collapse>
    
                <Box sx={{mb: 2, mt: -2}}>
                    <Typography variant="h5">
                        {(legalTransactionType.idLegalTransactionType === -1) ? "Neue Rechtsgeschäftart": `${legalTransactionTypeFullObject.LegalTransactionType}`}
                        <Button variant="contained" sx={{float: "right"}} onClick={handleSave} disabled={isLoading}>Speichern</Button>
                        
                        {(props.idLegalTransactionType > 0 && legalTransactionTypeFullObject !== undefined) &&
                            <IconButton disabled={legalTransactionTypeFullObject.withDialog} sx={{float: "right", mr: 2}} onClick={() => setIsOpenDelete(true)}><Delete/></IconButton>
                        }
                    </Typography>
                </Box>
                {(isLoading) && <LinearProgress sx={{mt: 3, mb: 3}}/>}

                <Grid container spacing={2}>
                    <Grid item sm={3}>
                        <TextField
                            label="Vorlage"
                            value={(legalTransactionType.isTemplate) ? 1 : 0}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                                setLegalTransactionType({
                                    ...legalTransactionType,
                                    isTemplate: (Number(event.target.value) === 1)
                                })
                            }
                            size="small"
                            fullWidth
                            select
                            disabled={legalTransactionType.idLegalTransactionType > 0}
                        >
                            <MenuItem key={`isTemplate-1`} value={1}>Ja</MenuItem>
                            <MenuItem key={`isTemplate-0`} value={0}>Nein</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item sm={9}>
                        <TextField 
                            label="Art"
                            value={legalTransactionType.LegalTransactionType}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLegalTransactionType({...legalTransactionType, LegalTransactionType: event.target.value})}
                            size="small"
                            fullWidth
                        /> 
                    </Grid>
                </Grid>


                <Box sx={{mt: 2}}/>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                <Tabs value={currenTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Statusmeldung" {...a11yProps(0)}/>
                    <Tab label="Berechtigungen" {...a11yProps(1)} />
                    <Tab label="Interne Datenablage" {...a11yProps(2)} />
                    <Tab label="Termin-E-Mails" {...a11yProps(3)} />
                </Tabs>
                </Box>

                <CustomTabPanel value={currenTab} index={0}>
                    <>
                        <Typography variant="h6" sx={{mt: 5}}>
                            Vollzugsphasen
                            <IconButton sx={{float: "right"}} onClick={handleAddNewLegalPhaseNew}><Add/></IconButton>
                            <Box sx={{float: "right"}}>
                                <LegalTransactionTypeCopy
                                    transactionTypeArray={props.transactionTypeArray}
                                    legalPhaseTemplateArray={legalPhaseTemplateArray}
                                    setLegalPhaseTemplateArray={setLegalPhaseTemplateArray}
                                />
                            </Box>
                        </Typography>
                        <LegalPhaseTable
                            legalPhaseTemplateArray={legalPhaseTemplateArray}
                            setLegalPhaseTemplateArray={setLegalPhaseTemplateArray}
                            legalRequirementTemplateArray={props.legalRequirementTemplateArray}
                        />
                    </>
                </CustomTabPanel>


                <CustomTabPanel value={currenTab} index={1}>
                    <>
                        <LegalRoleTemplateOverview
                            legalRoleTemplateArray={legalRoleTemplateArray}
                            setLegalRoleTemplateArray={setLegalRoleTemplateArray}
                        />

                        <LegalRoleTemplateCanInviteTable
                            legalRoleTemplateArray={legalRoleTemplateArray}
                            legalRoleTemplateCanInviteArray={legalRoleTemplateCanInviteArray}
                            setLegalRoleTemplateCanInviteArray={setLegalRoleTemplateCanInviteArray}
                        />
                    </>
                </CustomTabPanel>


                <CustomTabPanel value={currenTab} index={2}>
                    <DocumentDirectoryTemplateOverview
                        documentArray={documentArray}
                        documentDirectoryArray={documentDirectoryTemplateArray}
                        setDocumentArray={setDocumentArray}
                        setDocumentDirectoryArray={setDocumentDirectoryTemplateArray}
                    />
                </CustomTabPanel>


                <CustomTabPanel value={currenTab} index={3}>
                    <MeetingEmailTemplateOverview
                        meetingEmailTemplateArray={meetingEmailTemplateArray}
                        setMeetingEmailTemplateArray={setMeetingEmailTemplateArray}
                    />
                </CustomTabPanel>

            </>
        )
    }
}