import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";
import { Handle, Node, Position } from "reactflow";
import { Edit, Star, CancelSharp } from "@mui/icons-material";
import { FamilyTreePersonNode, FamilyTreeService } from "../../../../../services/familyTree.service";
import { EditableNode } from "../../../../../core/TreeEditor";
import { formatDate } from "../../../../../core/standardFunctions";


interface IProps {
  data: FamilyTreePersonNode;
}

export const TestatorNode = memo((props: Node<FamilyTreePersonNode & EditableNode>) => {
  const { type } = props;
  const { person: data, onNodeEdit } = props.data;

  const getGenderName = (nodeType: string, genderId: number): string => {
    return FamilyTreeService.getGenderNaming(nodeType, genderId);
  };

  const nodeEdit = (node: Node<FamilyTreePersonNode>) => {
    onNodeEdit(node);
  };

  return (
    <>
        <Grid container style={{
          border: `1px solid #808080`,
          borderRadius: 10,
          background: "#fff",
          width: "250px",
          boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.6)'
        }}>
          <Grid item width={"100%"}>
            <div
              style={{
                borderRadius: "6px 6px 0 0",
                backgroundColor: "#808080",
                color: "#FFFF",
                wordBreak: "break-word",
                textAlignLast: "center"
              }}
            > 
            <Box p={1}>
              <Typography variant="h5">{getGenderName(type || "", data.idGender)}</Typography>
            </Box>
            </div>
            <div
              style={{
                backgroundColor: "#d3d3d3",
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            > 
              <Box px={2}>
                <Typography noWrap variant="h6">{data.FirstName}</Typography>
                <Typography noWrap variant="h6">{data.LastName}</Typography>
              </Box>
            </div>
            <Box px={1} py={1}>
              <Box display="flex" alignItems="center">
                <Star fontSize="small" />
                <Typography variant="body1" marginLeft={1}>
                  {formatDate(data.Birthday)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <CancelSharp fontSize="small" />
                <Typography variant="body1" marginLeft={1}>
                  {formatDate(data.DateOfDeath)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                aria-label="edit"
                size="large"
                onClick={() => nodeEdit(props)}
              >
                <Edit fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Handle type="source" position={Position.Bottom} />
        <Handle type="target" position={Position.Top} />
    </>
  );
});
