import React, { useEffect, useState } from "react";
import { ITimeRecording, ITimeRecordingFullObject } from "../Interfaces/ITimeRecording";
import { ITimeRecordingBreak } from "../Interfaces/ITimeRecordingBreak";
import { getFetch, uploadFetch } from "../hooks/useFetch";
import { Alert, Box, Button, CircularProgress, Grid, LinearProgress, Tab, Table, Tabs, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker, deDE } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import { checkIsUnderTen, getStringdateFromDate, castWithZeroTimezone, getZeroTimezoneStringFromDate } from "../core/generic_helper";
import { TimeRecordBreakTable } from "./Childs/TimeRecordBreakTable";
import { TimeRecordAccount } from "./Childs/TimeRecordAccount";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

















interface IProps {
    isBookCurrentDay: boolean;
    currentTimeRecordObjectId?: number;
    idUser?: number;
    enableDateEdit?: boolean;
    timeRecordArray: ITimeRecording[];
    setTimeRecordArray: Function;
    setIsOpenDialog: Function;
}


export const TimeRecordOwn:React.FC<IProps> = (props) => {
    const [idTimeRecording, setIdTimeRecording] = useState<number|null>(null);
    const [wasSuccessfullyLoad, setWasSuccessfullyLoad] = useState(true);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);
    //
    const [triggerToday, setTriggerToday] = useState(false);
    //
    const [currentTimeRecordFullObject, setCurrentTimeRecordFullObject] = useState<ITimeRecordingFullObject | undefined>(() => {
        if (props.isBookCurrentDay) {
            //getFetch("/timerecording/today", undefined, setCurrentTimeRecordFullObject,setWasSuccessfullyLoad);
            setTriggerToday(true);
            return undefined;
        } else if (props.currentTimeRecordObjectId !== undefined && props.currentTimeRecordObjectId > 0) {
            setIdTimeRecording(props.currentTimeRecordObjectId);
            return undefined;
        }
        else {
            return {
                idTimeRecording: -1,
                TimeRecordBreakArray: [ {
                    idTimeRecording: -1,
                    idTimeRecordingBreak: -1,
                    isRegular: true,
                    Title: "Mittagspause",
                    Total: 45
                }],
                idUser: (props.idUser === undefined) ? Number(localStorage.getItem("idUser")) : props.idUser, 
                DateOfRecord: getStringdateFromDate(new Date()),
                FromTime: getZeroTimezoneStringFromDate(new Date()),
                ToTime: null,
                Total: null

            } as ITimeRecordingFullObject
        }
        /*

        let currentDate = new Date();
        let testObject = props.timeRecordArray.find(x => new Date(String(x.DateOfRecord)).getDate() === currentDate.getDate());

        console.log("********")
        console.log(props.timeRecordArray.map(x => new Date(String(x.DateOfRecord)).getDate() ))
        console.log(currentDate.getDate())

        if (props.currentTimeRecordObjectId !== undefined && props.currentTimeRecordObjectId > 0) {
            setIdTimeRecording(props.currentTimeRecordObjectId);
            return undefined;
        }
        else if (testObject !== undefined) {
            setIdTimeRecording(testObject.idTimeRecording);
            return undefined;
        } else {
            return {
                idTimeRecording: -1,
                TimeRecordBreakArray: [ {
                    idTimeRecording: -1,
                    idTimeRecordingBreak: -1,
                    isRegular: true,
                    Title: "Mittagspause",
                    Total: 45
                }],
                idUser: (props.idUser === undefined) ? Number(localStorage.getItem("idUser")) : props.idUser, 
                DateOfRecord: getStringdateFromDate(new Date()),
                FromTime: getZeroTimezoneStringFromDate(new Date()),
                ToTime: null,
                Total: null

            } as ITimeRecordingFullObject
        }
        */
    });
    //
    const [currentTimeRecord, setCurrentTimeRecord] = useState({
        idTimeRecording: -1,
        idUser: (props.idUser === undefined) ? Number(localStorage.getItem("idUser")) : props.idUser, 
        DateOfRecord: getStringdateFromDate(new Date()),
        FromTime: getZeroTimezoneStringFromDate(new Date()),
        ToTime: null,
        Total: null
    } as ITimeRecording);
    const [timeRecordBreakArray, setTimeRecordBreakArray] = useState<ITimeRecordingBreak[]>([]);
    //
    const [fromDate,setFromDate] = useState<Date|null>();
    //
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
 


    useEffect(() => {
        if (idTimeRecording !== null) {
            getFetch("/timerecording/fullObject/",idTimeRecording,setCurrentTimeRecordFullObject,setWasSuccessfullyLoad)
        }
    },[idTimeRecording])


    useEffect(() => {
        if (triggerToday) {
            getFetch("/timerecording/today", undefined, setCurrentTimeRecordFullObject,setWasSuccessfullyLoad);
        }
    },[triggerToday])

    
    useEffect(() => {
        if (currentTimeRecordFullObject !== undefined) {
            setTriggerToday(false);
            setCurrentTimeRecord(currentTimeRecordFullObject);
            setTimeRecordBreakArray(currentTimeRecordFullObject.TimeRecordBreakArray);
        }
    },[currentTimeRecordFullObject])


    const getTotal = () => {
        if (currentTimeRecord.FromTime === null || currentTimeRecord.ToTime === null) {
            return "- Std."
        }
        else {
            let fromTime = castWithZeroTimezone(String(currentTimeRecord.FromTime));
            let toTime = castWithZeroTimezone(String(currentTimeRecord.ToTime));

            if (fromTime === null || toTime === null) {
                return "- Std."
            }
            else {
                let diffMil = toTime.getTime()-fromTime.getTime();
                let diffMin = Math.floor(diffMil / 60000);
                let totalBreaks = 0;

                timeRecordBreakArray.map(x => {
                    totalBreaks = totalBreaks + x.Total;
                })

                diffMin = diffMin - totalBreaks;


                if (diffMin < 0) {
                    return "- Std."
                } else {
                    let minutes = diffMin %60;
                    let hours = (diffMin-minutes)/60;
    
                    return `${checkIsUnderTen(hours)}:${checkIsUnderTen(minutes)} Std.`
                }

            }
        }
    }


    const handleAfterSave = (savedObject:ITimeRecordingFullObject) => {
        

        if (currentTimeRecord.idTimeRecording < 0) {
            props.setTimeRecordArray([
                ...props.timeRecordArray,
                savedObject
            ])
        }
        else {
            props.setTimeRecordArray([
                ...props.timeRecordArray.map(x => x.idTimeRecording === savedObject.idTimeRecording ? savedObject : x)
            ])
        }
        setCurrentTimeRecordFullObject(savedObject);
        props.setIsOpenDialog(false);
    }


    const handleSave = () => {
        let uploadObject:ITimeRecordingFullObject = {
            ...currentTimeRecord,
            TimeRecordBreakArray: timeRecordBreakArray
        }
        if (props.isBookCurrentDay) {
            uploadFetch("/timerecording/today",true,uploadObject,handleAfterSave,setWasSuccessfullySaved,undefined,setIsLoading)
        } else {
            uploadFetch("/timerecording/fullObject",(currentTimeRecord.idTimeRecording < 0),uploadObject,handleAfterSave,setWasSuccessfullySaved,undefined,setIsLoading)
        }
        
    }

    const editToDateOfRecord = (currentDate:Date) => {
        let dateOfRecord = new Date(currentTimeRecord.DateOfRecord);

        if (!isNaN(dateOfRecord.getTime())) {
            let changeDate = new Date(currentDate);
        
            changeDate.setDate(dateOfRecord.getDate());
            changeDate.setMonth(dateOfRecord.getMonth());
            changeDate.setFullYear(dateOfRecord.getFullYear());
            changeDate.setSeconds(0);
            changeDate.setMilliseconds(0);
            return getZeroTimezoneStringFromDate(changeDate);
        }
        else {
            return null;
        }
    }


    const handleChangeDateOfRecord = (value: Date | null) => {
        if (value === null || isNaN(value.getTime())) {
            setCurrentTimeRecord({
                ...currentTimeRecord,
                DateOfRecord: "",
            })
        } else {
            let fromTime:string|null = currentTimeRecord.FromTime;
            let totime:string|null = currentTimeRecord.ToTime;

            if (fromTime !== null) {
                let changeDate = new Date(castWithZeroTimezone(fromTime)!);
                changeDate.setDate(value.getDate());
                changeDate.setMonth(value.getMonth());
                changeDate.setFullYear(value.getFullYear());
                changeDate.setSeconds(0);
                changeDate.setMilliseconds(0);
                fromTime = getZeroTimezoneStringFromDate(changeDate);
            }

            if (totime !== null) {
                let changeDate = new Date(castWithZeroTimezone(totime)!);
                changeDate.setDate(value.getDate());
                changeDate.setMonth(value.getMonth());
                changeDate.setFullYear(value.getFullYear());
                changeDate.setSeconds(0);
                changeDate.setMilliseconds(0);
                totime = getZeroTimezoneStringFromDate(changeDate);
            }


            setCurrentTimeRecord({
                ...currentTimeRecord,
                DateOfRecord: value.toISOString().split("T")[0],
                FromTime: fromTime,
                ToTime: totime
            })
        }
    }




    if (!wasSuccessfullyLoad) {
        return <Alert severity="error">Fehler!</Alert>
    }
    else if (currentTimeRecordFullObject === undefined) {
        return <CircularProgress/>
    }
    else {
        return(
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
                
                {(wasSuccessfullySaved === false) &&
                    <Box>
                        <Alert severity="error">Beim Speichern ist ein Fehler aufgetretten!</Alert>
                    </Box>
                }

                {(isLoading) &&
                    <Box>
                        <LinearProgress/>
                    </Box>
                }

                <Box>
                    <Alert severity="info">
                        Diese Funktion befindet sich im produktiven Testing! 
                        <br />Es wird Ihnen empfohlen Ihre Zeiterfassung parallel im bekannten Dokument zu erfasssen, solange diese Meldung angezeigt wird.
                    </Alert>
                </Box>
                
                <Grid container spacing={2} sx={{mt: 2}}>
                    {(props.enableDateEdit) &&
                        <Grid item sm={12}>
                            <DatePicker
                                label="Datum"
                                slotProps={{ textField: { fullWidth: true } }}
                                value={new Date(currentTimeRecord.DateOfRecord)}
                                onChange={handleChangeDateOfRecord}
                            />
                        </Grid>
                    }

                    <Grid item sm={5}>
                        <TimePicker
                            label="Von"
                            slotProps={{ textField: { fullWidth: true } }}
                            value={castWithZeroTimezone(currentTimeRecord.FromTime)}
                            onChange={(value: Date | null) => {
                                if (value === null) {
                                    setCurrentTimeRecord({
                                        ...currentTimeRecord,
                                        FromTime: null
                                    })
                                } else {
                                    setCurrentTimeRecord({
                                        ...currentTimeRecord,
                                        FromTime: editToDateOfRecord(value)
                                    })
                                }
                            }}
                        />
                    </Grid>

                    <Grid item sm={5}>
                        <TimePicker
                            label="Bis"
                            slotProps={{ textField: { fullWidth: true } }}
                            value={castWithZeroTimezone(currentTimeRecord.ToTime)}
                            onChange={(value: Date | null) => {
                                if (value === null) {
                                    setCurrentTimeRecord({
                                        ...currentTimeRecord,
                                        ToTime: null
                                    })
                                } else {
                                    setCurrentTimeRecord({
                                        ...currentTimeRecord,
                                        ToTime: editToDateOfRecord(value)
                                    })
                                }
                            }}
                            minTime={(currentTimeRecord.FromTime !== null)
                                ? new Date(String(castWithZeroTimezone(currentTimeRecord.FromTime)))
                                : undefined
                            }
                        />
                    </Grid>


                    <Grid item sm={2}>
                        <Typography variant="h6" sx={{float: "right", verticalAlign: "center", mt: 1}}>
                            {getTotal()}
                        </Typography>
                        
                    </Grid>
                </Grid>

                <Box sx={{ width: '100%', mt: 5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label="Pausen" {...a11yProps(0)} />
                            <Tab label="Zeitkonto" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                        <TimeRecordBreakTable 
                            timeRecordingBreakArray={timeRecordBreakArray}
                            setTimeRecordingBreakArray={setTimeRecordBreakArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <TimeRecordAccount
                            timeRecordArray={props.timeRecordArray}
                        />
                    </CustomTabPanel>
                </Box>

                <Box sx={{mt: 5}}>
                    <Button sx={{float: "right"}} disabled={isLoading} variant="contained" onClick={handleSave}>Speichern</Button>
                    <Button sx={{float: "right", mr: 2}} variant="outlined" onClick={() => props.setIsOpenDialog(false)}>Abbruch</Button>
                    
                </Box>
            </LocalizationProvider>
        )
    }
}