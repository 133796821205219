import React, { useState } from "react";
import { Box, Button, Collapse, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { IRealEstate } from "../../../../Interfaces/IRealEstate";
import { CustomeSlide } from "../../../../generic/CustomeSlide";
import { IPurchaseContractRelationType } from "../../../../Interfaces/IPurchaseContractRelationType";


interface IProps {
    pageNumber: number;
    currentStep: number;
    lastStep: number;
    handleNext: Function;
    handleBack: Function;
    newObject: IRealEstate;
    setNewObject: Function;
    purchaseContractRelationTypeArray: IPurchaseContractRelationType[];
}



export const NewRealEstatePartialArea:React.FC<IProps> = (props) => {
    const [wasSetHasPartialArea,setWasSetHasPartialArea] = useState(false);
    const [wasSetIsMeasured,setWasSetIsMeasured] = useState(false);
    const [wasSetHasEasement,setWasSetHasEasement] = useState(false);

    const getIsValidated = () => {
        return(
            // Wenn Ganze fläche
            wasSetHasPartialArea && 
                (
                    !props.newObject.hasPartialArea
                    // Oder wenn nicht, dann muss rest gesetzt sein
                    || (
                        wasSetIsMeasured 
                        && props.newObject.PaymentMeasurement_idPurchaseContractRelationType !== null
                        && wasSetHasEasement
                        // Unterscheidung: vermessen
                        && (
                            // Wenn nicht, dann muss bestimmt sein, wer zahlt, oder bei ja, wie groß die fläsche ist
                            (!props.newObject.isMeasured && props.newObject.OrderMeasurement_idPurchaseContractRelationType !== null)
                            || (props.newObject.isMeasured && props.newObject.Area !== null)
                        )
                        //  Unterscheidung:
                        && (
                            // Entweder Dienstbarkeiten falsch, oder ja, dann muss ich text erfasst sein
                            !props.newObject.hasEasement
                            || (props.newObject.hasEasement && props.newObject.EasementText !== null)
                        )
                    )
                )
            
        )
    }


    return(
        <CustomeSlide currentPage={props.currentStep} pageNumber={props.pageNumber} lastCurrentPage={props.lastStep}>
            <Box>
                <Box
                    display="flex"
                    height={80}
                    alignItems="center"
                    justifyContent="center"
                >
                    Wird das ganze Gründstück verkauft oder eine Teilfläche von diesem?
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        variant={(wasSetHasPartialArea === false || props.newObject.hasPartialArea ) ? "outlined" : "contained"}
                        onClick={() =>{
                            setWasSetHasPartialArea(true);
                            setWasSetHasEasement(false);
                            setWasSetIsMeasured(false);
                            props.setNewObject({
                                ...props.newObject, 
                                hasPartialArea: false,
                                isMeasured: false,
                                hasEasement: false,
                                PaymentMeasurement_idPurchaseContractRelationType: null,
                                OrderMeasurement_idPurchaseContractRelationType: null,
                                Area: null,
                                EasementText: null
                            } as IRealEstate)
                        }}
                        sx={{ mr: 1 }}
                    >
                        Ganzes Grundstück
                    </Button>

                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button 
                        variant={(wasSetHasPartialArea === false || !props.newObject.hasPartialArea ) ? "outlined" : "contained"}
                        onClick={() =>{
                            setWasSetHasPartialArea(true);
                            props.setNewObject({...props.newObject, hasPartialArea: true })
                        }}
                    >
                        Teilbereich
                    </Button>
                </Box>
                
                <Collapse in={(wasSetHasPartialArea && props.newObject.hasPartialArea)}>
                    <>
                        <Box
                            display="flex"
                            height={80}
                            alignItems="center"
                            justifyContent="center"
                        >
                            Wurde das Grundstück vermessen?
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                variant={(wasSetIsMeasured === false || props.newObject.isMeasured ) ? "outlined" : "contained"}
                                onClick={() =>{
                                    setWasSetIsMeasured(true);
                                    setWasSetHasEasement(false);
                                    props.setNewObject({
                                        ...props.newObject, 
                                        isMeasured: false,
                                        hasEasement: false,
                                        EasementText: null,
                                        Area: null
                                        
                                        
                                    } as IRealEstate)
                                }}
                                sx={{ mr: 1 }}
                            >
                                Nein
                            </Button>

                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button 
                                variant={(wasSetIsMeasured === false || !props.newObject.isMeasured ) ? "outlined" : "contained"}
                                onClick={() =>{
                                    setWasSetIsMeasured(true);
                                    setWasSetHasEasement(false);
                                    props.setNewObject({
                                        ...props.newObject, 
                                        isMeasured: true,
                                        hasEasement: false,
                                        EasementText: null,
                                        OrderMeasurement_idPurchaseContractRelationType: null
                                    })
                                }}
                            >
                                Ja
                            </Button>
                        </Box>
                    </>
                </Collapse>



                <Collapse in={(wasSetHasPartialArea && props.newObject.hasPartialArea && wasSetIsMeasured && props.newObject.isMeasured)}>
                    <Typography sx={{mt: 5, mb: 1}}>Wie groß ist die Fläche des Teilbereichs?</Typography>
                    <NumericFormat
                        value={(props.newObject.Area === null) ? "" : props.newObject.Area}
                        suffix=" qm"
                        fixedDecimalScale
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        decimalSeparator=","
                        fullWidth
                        customInput={TextField
                        }
                        onValueChange={(values, sourceInfo) => {
                            if (values.floatValue !== undefined) {
                                props.setNewObject({
                                    ...props.newObject,
                                    Area: values.floatValue
                                })
                            } else {
                                props.setNewObject({
                                    ...props.newObject,
                                    Area: null
                                })
                            }

                        }}
                        allowNegative={false}
                        label="Fläche"
                        size="small"
                        required
                    />
                </Collapse>



                <Collapse in={(wasSetHasPartialArea && props.newObject.hasPartialArea && wasSetIsMeasured && !props.newObject.isMeasured)}>
                    <Typography sx={{mt: 5}}>Wer beauftragt die Vermessung ?</Typography>
                    <TextField
                        sx={{mt: 1}}
                        label="Auftraggeber/inn"
                        value={(props.newObject.OrderMeasurement_idPurchaseContractRelationType === null) ? "" : props.newObject.OrderMeasurement_idPurchaseContractRelationType}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => props.setNewObject({
                            ...props.newObject,
                            OrderMeasurement_idPurchaseContractRelationType: Number(event.target.value)
                        })}
                        size="small"
                        fullWidth
                        select
                        required
                    >
                        {props.purchaseContractRelationTypeArray.map(x =>
                            <MenuItem 
                                key={`idPurchaseContractRelationType-${x.idPurchaseContractRelationType}`}
                                value={x.idPurchaseContractRelationType}
                            >
                                {x.PurchaseContractRelationType}
                            </MenuItem>
                        )}
                    </TextField>
                </Collapse>



                <Collapse in={(wasSetHasPartialArea && props.newObject.hasPartialArea && wasSetIsMeasured)}>
                    <Typography sx={{mt: 3}}>Wer übernimmt die Kosten für die Vermessung ?</Typography>
                        <TextField
                            sx={{mt: 1}}
                            label="Zahlungspflichtiger"
                            value={(props.newObject.PaymentMeasurement_idPurchaseContractRelationType === null) ? "" : props.newObject.PaymentMeasurement_idPurchaseContractRelationType}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => props.setNewObject({
                                ...props.newObject,
                                PaymentMeasurement_idPurchaseContractRelationType: Number(event.target.value)
                            })}
                            size="small"
                            fullWidth
                            select
                            required
                        >
                            {props.purchaseContractRelationTypeArray.map(x =>
                                <MenuItem 
                                    key={`idPurchaseContractRelationType-${x.idPurchaseContractRelationType}`}
                                    value={x.idPurchaseContractRelationType}
                                >
                                    {x.PurchaseContractRelationType}
                                </MenuItem>
                            )}
                        </TextField>
                </Collapse>



                <Collapse in={(wasSetHasPartialArea && props.newObject.hasPartialArea && wasSetIsMeasured)}>
                    <>
                        <Box
                            display="flex"
                            height={80}
                            alignItems="center"
                            justifyContent="center"
                        >
                            Werden Dienstbarkeiten (Wegerecht, Leitungsrecht, Ausblicksrecht, Nießbrauch etc. ) eingeräumt?
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                variant={(wasSetHasEasement === false || props.newObject.hasEasement ) ? "outlined" : "contained"}
                                onClick={() =>{
                                    setWasSetHasEasement(true);
                                    props.setNewObject({
                                        ...props.newObject, 
                                        hasEasement: false,
                                        EasementText: null
                                    })
                                }}
                                sx={{ mr: 1 }}
                            >
                                Nein
                            </Button>

                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button 
                                variant={(wasSetHasEasement === false || !props.newObject.hasEasement ) ? "outlined" : "contained"}
                                onClick={() =>{
                                    setWasSetHasEasement(true);
                                    props.setNewObject({...props.newObject, hasEasement: true })
                                }}
                            >
                                Ja
                            </Button>
                        </Box>
                    </>
                </Collapse>



                <Collapse in={(wasSetHasPartialArea && props.newObject.hasPartialArea && wasSetIsMeasured && wasSetHasEasement && props.newObject.hasEasement)}>
                    <Typography sx={{mt: 5}}>Bitte erfassen Sie alle Dienstbarkeiten</Typography>
                    <TextField
                        sx={{mt: 1}}
                        label="Dienstbarkeiten"
                        value={(props.newObject.EasementText === null) ? "" : props.newObject.EasementText}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => props.setNewObject({
                            ...props.newObject,
                            EasementText: event.target.value
                        })}
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                    />
                </Collapse>




                <Box sx={{mt: 5}}>
                    <Button variant="outlined" onClick={() => props.handleBack()}>
                        Zurück
                    </Button>


                    <Button onClick={() => props.handleNext()} variant="contained" sx={{float: "right"}} disabled={!getIsValidated()}>
                        Weiter
                    </Button>
                </Box>
            </Box>
        </CustomeSlide>
    )
}