function debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

class SearchCache<T> {
  private limit: number;
  private ttl: number;
  private cache: Array<{
    query: string;
    searchResults: T;
    expirationDate: number;
  }>;

  /**
   * @param {number} limit - The maximum number of search results to store
   * @param {number} ttl - The time to live of the search results in milliseconds
   */
  constructor(limit = 4, ttl = 300000) {
    this.limit = limit;
    this.ttl = ttl;
    this.cache = [];
  }

  /**
   * Add results of a search to the cache
   * @param {string} query - The query that was searched for
   * @param {T} searchResults - The results of the search
   */
  add(query: string, searchResults: T) {
    const expirationDate = Date.now() + this.ttl;
    const existingIndex = this.cache.findIndex((item) => item.query === query);

    if (existingIndex !== -1) {
      this.cache[existingIndex] = { query, searchResults, expirationDate };
    } else {
      this.cache.push({ query, searchResults, expirationDate })
      if (this.cache.length > this.limit) {
        this.cache.shift();
      }
    }
  }

  /**
   * Get the results of a search from the cache
   * @param {string} query - The requested search query
   * @returns {T | null} - The results of the previous cached search
   */
  get(query: string): T | null {
    const item = this.cache.find((item) => {
        return item.query === query
    });
    if (item && item.expirationDate > Date.now()) {
      return item.searchResults;
    }

    return null;
  }
}

export { debounce, SearchCache };
