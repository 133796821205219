import React, { useEffect, useState } from "react";
import { IMortgage, IMortgageFullObject, MortgageDocument } from "../../Interfaces/IMortgage";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, ToggleButton, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { IMortgagePerson } from "../../Interfaces/IPerson";
import { Owners } from "./Childs/Dialog/Owners";
import { IRealEstate } from "../../Interfaces/IRealEstate";
import { RealEstate } from "./Childs/Dialog/RealEstate";
import { MortgageDetails } from "./Childs/Dialog/MortgageDetails";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { MortgagePersons } from "./Childs/Dialog/MortgagePersons";

interface IProps {
    mortgageObject: IMortgageFullObject;
    setMortgageObject: Function;
}



export const MortgageMain:React.FC<IProps> = (props) => {
    const [mortgageObject, setMortgageObject] = useState<IMortgage>(props.mortgageObject);
    const [personArray, setPersonArray] = useState<IMortgagePerson[]>(props.mortgageObject.PersonArray);
    const [documentArray, setDocumentArray] = useState<MortgageDocument[]>(props.mortgageObject.DocumentArray);
    const [realEstate, setRealEstate] = useState<IRealEstate>(props.mortgageObject.RealEstate);
    const [beneficiaryDetails, setBeneficiaryDetails] = useState<IMortgagePerson>(props.mortgageObject.BeneficiaryDetails);
    


    useEffect(() => {
        let updatedObject = {
            ...mortgageObject,
            PersonArray: personArray,
            DocumentArray: documentArray,
            RealEstate: realEstate,
            BeneficiaryDetails: beneficiaryDetails,
        } as IMortgageFullObject
        props.setMortgageObject(updatedObject)
    },[
        mortgageObject,
        personArray,
        documentArray,
        realEstate,
        beneficiaryDetails
    ])


    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<GridExpandMoreIcon/>}>
                    <Typography variant="h6">Eigentümer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Owners
                        mortgageObject={mortgageObject}
                        personArray={personArray}
                        setPersonArray={setPersonArray}
                    ></Owners>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<GridExpandMoreIcon/>}>
                    <Typography variant="h6">Objekt / Grundbuchinformationen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RealEstate
                        realEstate={realEstate}
                        setRealEstate={setRealEstate}
                    ></RealEstate>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<GridExpandMoreIcon/>}>
                    <Typography variant="h6">Details Grundschuld</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MortgageDetails
                        mortgageObject={mortgageObject}
                        setMortgageObject={setMortgageObject}
                    ></MortgageDetails>
                </AccordionDetails>
            </Accordion>

            <MortgagePersons
                mortgageObject={mortgageObject}
                setMortgageObject={setMortgageObject}
            ></MortgagePersons>

            <DocumentOverview
                allowRemove
                allowUpload
                documentArray={documentArray}
                setDocumentArray={setDocumentArray}
                title="Bereitgestellte Daten"
                titleVariant="h6"
                marginTop={5}
            />
        </>
    )


}