import React, { useEffect, useState } from "react";
import { IUser } from "../Interfaces/IUser";
import { ILegalTransaction } from "../Interfaces/ILegalTransaction";
import { ILegalRequirement } from "../Interfaces/ILegalRequirement";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, AvatarGroup, Box, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Checklist, FilterFrames, Group, Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { ILegalTransactionProgress } from "../Interfaces/ILegalTransactionProgress";
import { IUserGroup } from "../Interfaces/IUserGroup";


interface IProps {
    userArray: IUser[]
    userGroupArray: IUserGroup[];
    legalTransactionArray: ILegalTransaction[]; 
    legalReqArray: ILegalRequirement[];
    legalTransactionProgressArray: ILegalTransactionProgress[];
}



function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 40,
        height: 40
      }, 
      children: (name.length === 0) ? "" : (name.split(' ').length === 1) ? name[0] : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }


export const DashboardGlobal:React.FC<IProps> = ({userArray,userGroupArray,legalTransactionArray,legalReqArray,legalTransactionProgressArray}) => {
    const navigation = useNavigate();

    const [toCheckNotary,setToCheckNotary] = useState(
        legalReqArray
            .filter(x => Number(x.idLegalRequirementState === 20))
            .filter(x => Number(x.Closed_at == undefined))
            .filter(x => Number(x.Notary_idUser !== null))
    );
    const [toCheckEmployeee,setToCheckEmployeee] = useState(
        legalReqArray
            .filter(x => Number(x.idLegalRequirementState === 15))
            .filter(x => Number(x.Closed_at == undefined))
            .filter(x => Number(x.Employee_idUser !== null))
    );
    const [toCheckUserGroup,setToCheckUserGroup] = useState(
        legalReqArray
            .filter(x => Number(x.idLegalRequirementState === 15))
            .filter(x => Number(x.Closed_at == undefined))
            .filter(x => Number(x.idUserGroup !== null))
    );

    const spanTsx = (bodyState:string,borderProgress: string,txt: string|null = null, size:number = 25) => {
        return(
            <>
                <span 
                    style={{
                        padding: (txt === null) ? undefined : 5, 
                        backgroundColor: bodyState, 
                        borderStyle: "solid", 
                        borderWidth: 5, 
                        borderColor: borderProgress,
                        borderRadius: (txt === null) ? "50%" : 25,
                        width: (txt === null) ? size : undefined,
                        height: (txt === null) ? size : undefined,
                        display: (txt === null) ? "inline-block" : undefined,
                    }}
                >
                    {(txt === null) ? undefined : txt}
                </span>
            </>
        )
    }

    const instantViewLegalTransaction = (testLegalTransaction:ILegalTransaction) => {
        let borderProgress = "#808080";
        let bodyState = "#c8c8c8 ";

        if (testLegalTransaction.idLegalTransactionState === 5)
            {bodyState = "#f2f299"}
        else if (testLegalTransaction.idLegalTransactionState === 10)
            {bodyState = "#c9d8f8"}
        else if (testLegalTransaction.idLegalTransactionState === 50)
            {bodyState = "#f5cfb4"}
        else if (testLegalTransaction.idLegalTransactionState === 100)
            {bodyState = "#19a315"}


        if (testLegalTransaction.idLegalTransactionProgress === 20)
            {borderProgress = "#0f1b73"}
        else if (testLegalTransaction.idLegalTransactionProgress === 30)
            {borderProgress = "#9a9c14"}
        else if (testLegalTransaction.idLegalTransactionProgress === 40)
            {borderProgress = "#14660d"}



        return spanTsx(bodyState,borderProgress,testLegalTransaction.Title)
    }


    const genTableBodyTsx = () => {
        const getTsxForReq = (currentReq:ILegalRequirement,isNotary:boolean) => {
            let testLegalTransaction = legalTransactionArray.find(x => x.idLegalTransaction === currentReq.idLegalTransaction);
            let testUser = userArray.find(x => isNotary ? currentReq.Notary_idUser === x.idUser : currentReq.Employee_idUser === x.idUser) 
            let userGroup:undefined | IUserGroup = undefined;

            // PRüfen bei User === undefined, ob Usergroup
            if (testUser === undefined) {
                userGroup = userGroupArray.find(x => x.idUserGroup === currentReq.idUserGroup)
            }



            if (testLegalTransaction !== undefined && (testUser !== undefined || userGroup !== undefined )) {
                return(
                    <Box 
                        sx={{ cursor: "pointer"}} 
                        //onClick={() => navigation(`/legaltransaction/${testLegalTransaction!.idLegalTransaction}`)}
                        onClick={() => window.open(`/legaltransaction/${testLegalTransaction!.idLegalTransaction}`, "_blank")}
                        
                    >
                        {instantViewLegalTransaction(testLegalTransaction)}
                        { (testUser !== undefined) ?
                            <Avatar 
                                {...stringAvatar(`${testUser.FirstName} ${testUser.LastName}`)} 
                                alt={`${testUser.FirstName} ${testUser.LastName}`}
                            />
                            :
                            <Box sx={{display: "flex"}}>
                                <Avatar 
                                    {...stringAvatar(`${userGroup?.UserGroup}`)} 
                                    alt={`${userGroup?.UserGroup}`}
                                />
                                <Group sx={{ml: -2, mt: 2, zIndex: 100}}/>
                            </Box>
                        }

                    </Box>
                )
            }
            else if (testLegalTransaction === undefined  && (testUser !== undefined || userGroup !== undefined )) {
                return(
                    <>
                        {spanTsx("#FF0000","#FF0000","Fehler: " + String(currentReq.idLegalTransaction))}
                        { (testUser !== undefined) ?
                            <Avatar 
                                {...stringAvatar(`${testUser.FirstName} ${testUser.LastName}`)} 
                                alt={`${testUser.FirstName} ${testUser.LastName}`}
                            />
                            :
                            <>
                                <Avatar 
                                    {...stringAvatar(`${userGroup?.UserGroup}`)} 
                                    alt={`${userGroup?.UserGroup}`}
                                />
                                <Group sx={{ml: -2, mt: 2, zIndex: 100}}/>
                            </>
                        }
                    </>
                )
            }
            else if (testLegalTransaction !== undefined  && (testUser === undefined &&  userGroup === undefined )) {
                return(
                    <Box 
                        sx={{ cursor: "pointer"}} 
                        //onClick={() => navigation(`/legaltransaction/${testLegalTransaction!.idLegalTransaction}`)}
                        onClick={() => window.open(`/legaltransaction/${testLegalTransaction!.idLegalTransaction}`, "_blank")}
                    >
                        {instantViewLegalTransaction(testLegalTransaction)}
                        {spanTsx("#FF0000","#FF0000","Fehler: " +  (isNotary) ? String(currentReq.Notary_idUser) : String(currentReq.Employee_idUser))}
                    </Box>
                )
            }
            else {
                return(
                    <>
                        {spanTsx("#FF0000","#FF0000","Fehler: " + String(currentReq.idLegalTransaction))}
                        {spanTsx("#FF0000","#FF0000","Fehler: "+  (isNotary) ? String(currentReq.Notary_idUser) : String(currentReq.Employee_idUser))}
                    </>
                )
            }
        }


        let counter:number = 0;
        let returnTsx:React.ReactElement[] = [];

        while (counter < toCheckNotary.length || counter < toCheckEmployeee.length || counter < toCheckUserGroup.length) {
            let currentNotaryReq = (counter in toCheckNotary) ? toCheckNotary[counter] : null;
            let currentUserGroupReq = (counter in toCheckUserGroup) ? toCheckUserGroup[counter] : null;
            let currentEmployeeReq = (counter in toCheckEmployeee) ? toCheckEmployeee[counter] : null;


            returnTsx.push(
                <TableRow>
                    <TableCell>
                        {   (currentNotaryReq === null)
                            ? <></>
                            : getTsxForReq(currentNotaryReq,true)
                        }
                    </TableCell>
                    <TableCell>
                        {   (currentUserGroupReq === null)
                            ? <></>
                            : getTsxForReq(currentUserGroupReq,false)
                        }
                        {   (currentEmployeeReq === null)
                            ? <></>
                            : getTsxForReq(currentEmployeeReq,false)
                        }
                    </TableCell>
                </TableRow>
            )


            counter++;
        }

        return returnTsx
    }


    return(
        <>
            <Grid container spacing={2} sx={{mt: 3}}>


                <Grid item xs={12}>
                    <Card variant="outlined" sx={{ backgroundColor: "#fffde7"}}>
                        <CardContent>
                            <Grid container>
                                <Grid item sm={6}>
                                    <Checklist sx={{m: 2, mb: 1, fontSize: 30}}/>
                                    <Typography>
                                        Zu prüfende Maßnahmen im Notarbüro
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<GridExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Legende
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#c8c8c8","#fff")} Entwurf</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#f2f299","#fff")} Laufend</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#c9d8f8","#fff")} Inaktiv</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#f5cfb4","#fff")} Storniert</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#19a315","#fff")} Abgeschlossen</Box>
                                            </Box>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#808080")} Datenerfassung</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#0f1b73")} Entwurf</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#9a9c14")} Abwicklung</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#14660d")} Abschluss</Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>


                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Notar</TableCell>
                                        <TableCell>Mitarbeiter</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {genTableBodyTsx()}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>


                </Grid>


                <Grid item xs={12}>
                    <Card variant="outlined" sx={{mt: 5}}>
                        <CardContent>
                            <Grid container>
                                <Grid item sm={6}>
                                    <FilterFrames sx={{m: 2, mb: 1, fontSize: 30}}/>
                                    <Typography>
                                        Alle offenen Vorgänge
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<GridExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Legende
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#c8c8c8","#fff")} Entwurf</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#f2f299","#fff")} Laufend</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#c9d8f8","#fff")} Inaktiv</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#f5cfb4","#fff")} Storniert</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#19a315","#fff")} Abgeschlossen</Box>
                                            </Box>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#808080")} Datenerfassung</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#0f1b73")} Entwurf</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#9a9c14")} Abwicklung</Box>
                                                <Box sx={{marginLeft: 2}}>{spanTsx("#fff","#14660d")} Abschluss</Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1}>
                                {legalTransactionProgressArray.map(progress =>
                                    <Grid item xs={12/legalTransactionProgressArray.length} sx={{borderRight: "solid"}}>

                                        <Box sx={{mt: 3, mb: 3, textAlign: "center"}}>
                                            <b><u>{progress.LegalTransactionProgress}</u></b>
                                        </Box>
                                        
                                        {legalTransactionArray
                                            .filter(x => x.Closed_at == undefined)
                                            .filter(x => x.idLegalTransactionProgress === progress.idLegalTransactionProgress)
                                            .map(legalTransaction => 
                                        {
                                            let userNotary = userArray.find(x => x.idUser === legalTransaction.Notary_idUser);
                                            let userEmployee = userArray.find(x => x.idUser === legalTransaction.Employee_idUser);
                                            let userGroup = legalTransaction.UserGroup;

                                            return(
                                                <Box 
                                                    sx={{ cursor: "pointer", mt: 2}} 
                                                    //onClick={() => navigation(`/legaltransaction/${legalTransaction!.idLegalTransaction}`)}
                                                    onClick={() => window.open(`/legaltransaction/${legalTransaction!.idLegalTransaction}`, "_blank")}
                                                >
                                                    {instantViewLegalTransaction(legalTransaction)}
                                                    
                                                    <Box sx={{display: "flex"}}>
                                                        {(userNotary !== undefined) ?
                                                            <Avatar 
                                                                {...stringAvatar(`${userNotary.FirstName} ${userNotary.LastName}`)} 
                                                                alt={`${userNotary.FirstName} ${userNotary.LastName}`}
                                                            />
                                                            : spanTsx("#000","#fff")
                                                        }
                                                        {(userEmployee !== undefined) ?
                                                            <Avatar 
                                                                {...stringAvatar(`${userEmployee.FirstName} ${userEmployee.LastName}`)} 
                                                                alt={`${userEmployee.FirstName} ${userEmployee.LastName}`}
                                                            />
                                                            :
                                                            (legalTransaction.idUserGroup !== null && userGroup !== undefined) ? 
                                                            <>
                                                                <Avatar 
                                                                    {...stringAvatar(`${userGroup}`)} 
                                                                    alt={`${userGroup}`}
                                                                />
                                                                <Group sx={{ml: -2, mt: 2, zIndex: 100}}/>
                                                            </>

                                                            : spanTsx("#fff","#000",null,40)
                                                        }
                                                    </Box> 

                                                </Box>
                                            )
                                        })}
                                    </Grid>
                                )}


                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </>
    )
}