import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, deDE } from "@mui/x-data-grid";
import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { actionCreatorsContactGroup } from "../redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { State } from "../redux/mainReducer";
import { IContactGroup } from "../Interfaces/IContactGroup";
import { CustomeTextField } from "../generic/CustomeTextField";
import { uploadFetch } from "../hooks/useFetch";
import { ShowWasSaved } from "../core/ShowWasSaved";



const columns: GridColDef[] = [
    { 
        field: 'idContactGroup', 
        headerName: 'Nr.', 
        width: 90 
    },
    { 
        field: 'ContactGroup', 
        headerName: 'Gruppe', 
        flex: 1
    }
]


const getEmptyContactGroup = () => {
    return {
        ContactGroup: "",
        idContactGroup: -1,
    } as IContactGroup
}



export const ContactGroupOverview:React.FC = () => {
    const dispatch = useDispatch();
    const contactGroupArray = useSelector((state: State) => state.contactGroup.storeContactGroup);
    const { setContactGroupArray, addUpdateContactGroupArray } = bindActionCreators(actionCreatorsContactGroup, dispatch);    
    //
    const [isOpenEdit,setIsOpenEdit] = useState<boolean>(false);
    const [editContactGroup,setEditContactGroup] = useState(getEmptyContactGroup())
    //
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [wasSaved,setWasSaved] = useState(false);
    const [isLoading,setIsLoading] = useState(false);



    const handleOpen = (localIdContactGroup:number) => {
        let testObject = contactGroupArray.find(x => x.idContactGroup === Number(localIdContactGroup));

        if (testObject === undefined) {
            setEditContactGroup(getEmptyContactGroup())
        }
        else {
            setEditContactGroup(testObject);
        }

        setIsOpenEdit(true);
    }


    const handleSave = () => {
        uploadFetch(
            "/contactgroup",
            (editContactGroup.idContactGroup < 0),
            editContactGroup,
            (res:IContactGroup) => {addUpdateContactGroupArray(res); setIsOpenEdit(false)},
            setWasSuccessfully,
            setWasSaved,
            setIsLoading
        )
    }



    return(
        <>
            <ShowWasSaved wasSaved={wasSaved} setWasSaved={setWasSaved} wasSuccessfullySaved={wasSuccessfully}/>

            <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Mandantengruppe</DialogTitle>
                <DialogContent>
                    <>
                        <Box sx={{mt:2 }}/>
                        <Collapse in={isLoading}><LinearProgress/></Collapse>
                        <Box sx={{mt:2 }}/>
                        <CustomeTextField
                            label="Mandantengruppe"
                            type="string"
                            attr="ContactGroup"
                            object={editContactGroup}
                            setObject={setEditContactGroup}
                            required
                        />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave} disabled={editContactGroup.ContactGroup === "" || isLoading}>Speichern</Button>
                </DialogActions>
            </Dialog>


            <Box sx={{m: 2, mt: 0}}>
                <Typography variant="h4">
                    Mandantengruppen
                    <IconButton sx={{float: "right"}} onClick={() => handleOpen(-1)}><Add/></IconButton>
                </Typography>

                <DataGrid
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={contactGroupArray}
                    columns={columns}
                    getRowId={(row) => row.idContactGroup}
                    onRowClick={(params,event,details) => handleOpen(Number(params.id))}
                    autoHeight
                />
            </Box>
        </>
    )
}