import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { IPostcode } from "../Interfaces/IPostcode";


interface IProps {
  postcodeObject: IPostcode | null | undefined;
  setPostcodeObject: Function;
  required?: boolean;
}


function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }


const PostcodeSearch:React.FC<IProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly IPostcode[]>( (props.postcodeObject != undefined) ?  [props.postcodeObject] : []);
    // const loading = open && options.length === 0;
    const [loading,setLoading] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");


    async function queryBackend(localSearchTerm:string) {
      setLoading(true);

      if (localSearchTerm === "") {
        setLoading(false);
        setOptions([]);
      } else {
        
      {/* /postcode/public/ 
    
        fetch(`/api/searchPostcode/${localSearchTerm}`, {
          headers: {
            "X-APIKEY-LOCAL" : "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
          }
        })
    */}
        fetch(`/api/postcode/public/${localSearchTerm}`, {
          headers: {
            "X-APIKEY-LOCAL" : "ANias9B8DhPf9p38COGd645whGgd8FCm",
          }
        })
        .then(res => {
          if (res.status === 200) { return res.json()}
          else {throw Error(`ERROR GET: ${res.status}`)}
        })
        .then(res => {
          setOptions(res);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        })
      }
    }

    function onChange(event: SyntheticEvent, newObject:IPostcode|null) {
      props.setPostcodeObject(newObject);
    }


    function onInputChange(event: SyntheticEvent | null, searchTerm:string) {
      if (event !== null && event.type === "change") {
        setLoading(true);
        setSearchTerm(searchTerm);
      }
    }


    useEffect(() => {
      const timeOutId = setTimeout(() => queryBackend(searchTerm), 500);
      return () => clearTimeout(timeOutId);
    },[searchTerm])




    return (
        <Autocomplete
          id="searchbar-postcode"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={props.postcodeObject}
          isOptionEqualToValue={(option, value) => option.idPostcode === value.idPostcode}
          getOptionLabel={(option) => `${option.Postcode} ${option.City}` }
          loadingText="Laden..."
          options={options}
          loading={loading}
          onInputChange={onInputChange}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Postleitzahl"
              size="small"
              required={(props.required == undefined) ? true : props.required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
}
export default PostcodeSearch;

