import React, { useState } from "react";
import { ILegalRoleTemplate } from "../../Interfaces/ILegalRoleTemplate";
import { ILegalRoleTemplate_canInvite } from "../../Interfaces/ILegalRoleTemplate_canInvite";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";


interface IProps {
    legalRoleTemplateObject: ILegalRoleTemplate;
    legalRoleTemplateArray: ILegalRoleTemplate[];
    legalRoleTemplateCanInviteArray: ILegalRoleTemplate_canInvite[];
    setLegalRoleTemplateCanInviteArray: Function;
}


export const LegalRoleTemplateCanInviteRow:React.FC<IProps> = (props) => {
    const [legalRoleTemplateCanInviteArray,setLegalRoleTemplateCanInviteArray] = useState(
        props.legalRoleTemplateCanInviteArray.filter(x => x.From_idLegalRoleTemplate === props.legalRoleTemplateObject.idLegalRoleTemplate)
    );
    const [editLegalRoleTemplateCanInviteArray,setEditLegalRoleTemplateCanInviteArray] = useState(legalRoleTemplateCanInviteArray);
    //
    const [isOpenEdit,setIsOpenEdit] = useState(false);

    const handleOpen = () => {
        setEditLegalRoleTemplateCanInviteArray([...legalRoleTemplateCanInviteArray])
        setIsOpenEdit(true);
    }

    const handleClick = (idLegalRoleTemplate:number) => {
        let testObject = editLegalRoleTemplateCanInviteArray.find(x => x.To_idLegalRoleTemplate === idLegalRoleTemplate);

        if (testObject === undefined) {
            setEditLegalRoleTemplateCanInviteArray([
                ...editLegalRoleTemplateCanInviteArray,
                {
                    From_idLegalRoleTemplate: props.legalRoleTemplateObject.idLegalRoleTemplate,
                    To_idLegalRoleTemplate: idLegalRoleTemplate
                } as ILegalRoleTemplate_canInvite
            ])
        } else {
            setEditLegalRoleTemplateCanInviteArray([
                ...editLegalRoleTemplateCanInviteArray.filter(x => x.To_idLegalRoleTemplate !== idLegalRoleTemplate)
            ])
        }
    }

    const handleSave = () => {
        props.setLegalRoleTemplateCanInviteArray([
            ...props.legalRoleTemplateCanInviteArray.filter(x => x.From_idLegalRoleTemplate !== props.legalRoleTemplateObject.idLegalRoleTemplate),
            ...editLegalRoleTemplateCanInviteArray
        ])
        setLegalRoleTemplateCanInviteArray([...editLegalRoleTemplateCanInviteArray]);
        setIsOpenEdit(false);
    }


    return(
        <>
            <Dialog
                open={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
            >
                <DialogTitle>Einladeerlaubnis: {props.legalRoleTemplateObject.LegalRoleTemplate}</DialogTitle>
                <DialogContent>
                    <>
                        Die Rolle <i>{props.legalRoleTemplateObject.LegalRoleTemplate}</i> darf eingeladene Entitäten die folgenden Rollen zuweisen:
                        
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <>
                            {props.legalRoleTemplateArray.map(x =>
                                <ListItem key={`to-${x.idLegalRoleTemplate}`} disablePadding>
                                    <ListItemButton role={undefined} onClick={() => handleClick(x.idLegalRoleTemplate)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={editLegalRoleTemplateCanInviteArray.find(y => y.To_idLegalRoleTemplate === x.idLegalRoleTemplate) !== undefined}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': String(x.idLegalRoleTemplate) }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={`to-text-${x.idLegalRoleTemplate}`} primary={x.LegalRoleTemplate} />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            </>
                        </List>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave}>Übernehmen</Button>
                </DialogActions>
            </Dialog>

            <TableRow>
                <TableCell>{props.legalRoleTemplateObject.LegalRoleTemplate}</TableCell>
                <TableCell>
                    {props.legalRoleTemplateArray
                        .filter(x => legalRoleTemplateCanInviteArray.map(y => y.To_idLegalRoleTemplate).indexOf(x.idLegalRoleTemplate) > -1)
                        .map(x => x.LegalRoleTemplate)
                        .join(", ")
                    }
                </TableCell>
                <TableCell sx={{width: 100}}>
                    <IconButton onClick={handleOpen}><Edit/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )

}