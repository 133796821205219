import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Edit, EditTwoTone } from "@mui/icons-material";
import { InheritPerson, getInheritPerson } from "../InheritPerson";
import { IRequiredFiles } from "../InheritDocument";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { IMarriage } from "../../../../../Interfaces/IMarriage";
import { checkIsDisabled } from "../../../getDisabledHelper_Inherit";
import { IInherit } from "../../../../../Interfaces/IInherit";
import {
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from "../../../../../services/familyTree.service";

interface IProps {
  index: number;
  setIsOpenNew?: Function;
  idMarriage: number;
  inheritObject: IInherit;
  inherit: IInheritHasPerson;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

/**
 * getMarriage Function
 * @param isNew
 * @param idMarriage
 * @param marriageArray
 * @param idPerson
 */
export const getMarriage = (
  isNew: boolean,
  idMarriage: number,
  marriageArray: IMarriage[],
  idPerson: number
) => {
  let testObject = marriageArray.find((x) => x.idMarriage === idMarriage);

  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...marriageArray.map((x) => x.idMarriage)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idMarriage: minId,
      Person1_idPerson: idPerson,
      Person2_idPerson: -1,
      DateOfMarriage: null,
      Devorce_idDocument: null,
      DateOfDevorce: null,
      Marriage_idDocument: -1,
      isDivorced: false,
    } as IMarriage;
  }
};

/**
 * MarriageRow Function
 * @param props
 */
export const MarriageRow: React.FC<IProps> = (props) => {
  /**
   * useStates
   */
  const [currentMarriage, setCurrentMarriage] = useState(
    getMarriage(
      props.setIsOpenNew !== undefined,
      props.idMarriage,
      props.marriageArray,
      props.inherit.idPerson
    )
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(
      currentMarriage.Person2_idPerson,
      props.personArray,
      props.setIsOpenNew !== undefined
    )
  );
  const [editMaririage, setEditMaririage] = useState(currentMarriage);
  const [editPerson, setEditPerson] = useState(currentPerson);
  const [isDeath, setIsDeath] = useState(false);
  const [isOpen, setIsOpen] = useState(props.setIsOpenNew !== undefined);

  /*const [isDevored, setIsDevored] = useState((editMaririage.DateOfDevorce !== null && editMaririage.DateOfDevorce !== ""));*/

  useEffect(() => {
    const currentPerson2 = getInheritPerson(
      currentMarriage.Person2_idPerson,
      props.personArray,
      props.setIsOpenNew !== undefined
    );
    setCurrentPerson(currentPerson2);
  }, [currentMarriage.Person2_idPerson, props.personArray]);

  /**
   * Dialog Functions
   */
  // Dialog + Zell(Aktionene) Function
  const handleOpen = () => {
    setEditMaririage(currentMarriage);
    setEditPerson(currentPerson);
    setIsOpen(true);
  };

  const handleClose = () => {
    if (props.setIsOpenNew !== undefined) {
      handleRemove();
      props.setIsOpenNew(false);
    }
    setIsOpen(false);
  };

  // Dialog + Zell(Aktionene) Function
  const handleRemove = () => {
    props.setPersonHasDocument([
      ...props.personHasDocument.filter(
        (x) => x.idPerson !== currentPerson.idPerson
      ),
    ]);
    props.setPersonArray([
      ...props.personArray.filter((x) => x.idPerson !== currentPerson.idPerson),
    ]);
    props.setMarriageArray([
      ...props.marriageArray.filter(
        (x) => x.idMarriage !== currentMarriage.idMarriage
      ),
    ]);
    setIsOpen(false);
  };

  const handleSave = () => {
    if (props.setIsOpenNew !== undefined) {
      let newMarriage = {
        ...editMaririage,
        Person2_idPerson: currentPerson.idPerson,
      } as IMarriage;
      props.setMarriageArray([...props.marriageArray, newMarriage]);
      props.setPersonArray([...props.personArray, editPerson]);
      props.setIsOpenNew(false);
    } else {
      props.setMarriageArray([
        ...props.marriageArray.map((x) =>
          x.idMarriage === editMaririage.idMarriage ? editMaririage : x
        ),
      ]);
      props.setPersonArray([
        ...props.personArray.map((x) =>
          x.idPerson === editPerson.idPerson ? editPerson : x
        ),
      ]);
      setCurrentMarriage(editMaririage);
      setCurrentPerson(editPerson);
    }
    setIsOpen(false);
  };

  /**
   * Required Files
   */
  const getRequirredArray = () => {
    if (editMaririage.isDivorced) {
      return [
        { Title: "Eheurkunde", isOptional: false, idPersonDocumentType: 2 },
        {
          Title: "Scheidungsurkunde",
          isOptional: false,
          idPersonDocumentType: 3,
        },
      ] as IRequiredFiles[];
    } else {
      return [
        { Title: "Eheurkunde", isOptional: false, idPersonDocumentType: 2 },
      ] as IRequiredFiles[];
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      console.log(formRef.current.checkValidity());

      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <form ref={formRef}>
            <Box sx={{ mt: 2 }} />
            <Typography variant="h5">Ehestatus</Typography>

            <Box sx={{ mt: 3, mb: 10 }}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <TextField
                    label="Datum der Eheschließung"
                    size="small"
                    type="date"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={editMaririage.DateOfMarriage}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setEditMaririage({
                        ...editMaririage,
                        DateOfMarriage: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    sx={{ float: "left" }}
                    control={
                      <Switch
                        checked={editMaririage.isDivorced}
                        onChange={() => {
                          if (editMaririage.isDivorced === true) {
                            setEditMaririage({
                              ...editMaririage,
                              DateOfDevorce: null,
                              isDivorced: !editMaririage.isDivorced,
                            });
                          } else {
                            setEditMaririage({
                              ...editMaririage,
                              isDivorced: !editMaririage.isDivorced,
                            });
                          }
                        }}
                        name="gilad"
                      />
                    }
                    label="Geschieden"
                  />
                </Grid>
                {editMaririage.isDivorced && (
                  <Grid item sm={12}>
                    <TextField
                      label="Datum der Scheidung"
                      size="small"
                      type="date"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={
                        editMaririage.DateOfDevorce === null
                          ? ""
                          : editMaririage.DateOfDevorce
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setEditMaririage({
                          ...editMaririage,
                          DateOfDevorce:
                            event.target.value === ""
                              ? null
                              : event.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <InheritPerson
              inheritObject={props.inheritObject}
              currentPerson={editPerson}
              setCurrentPerson={setEditPerson}
              personArray={props.personArray}
              personHasDocument={props.personHasDocument}
              setPersonHasDocument={props.setPersonHasDocument}
              requiredFiles={getRequirredArray()}
              title={FamilyTreeService.getGenderNaming(
                FamilyTreePersonNodeType.MARRIAGE_NODE,
                editPerson.idGender
              )}
              showDeathSettings={true}
              setIsDeath={setIsDeath}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Abbruch
          </Button>
          <Button variant="contained" onClick={handleOnSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell>{props.index + 1}</TableCell>
        <TableCell>
          {currentMarriage.DateOfMarriage !== "" &&
          currentMarriage.DateOfMarriage !== undefined &&
          currentMarriage.DateOfMarriage !== null
            ? new Date(currentMarriage.DateOfMarriage).toLocaleDateString(
                "de-DE",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )
            : "k.A"}
        </TableCell>
        <TableCell>
          {!currentMarriage.isDivorced
            ? "-"
            : currentMarriage.DateOfDevorce !== "" &&
              currentMarriage.DateOfDevorce !== undefined &&
              currentMarriage.DateOfDevorce !== null
            ? new Date(currentMarriage.DateOfDevorce).toLocaleDateString(
                "de-DE",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )
            : "k.A"}
        </TableCell>
        <TableCell>
          {`${currentPerson.FirstName} ${currentPerson.LastName}`}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
