import { Help } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ICompanyFormation } from "../../../Interfaces/ICompanyFormation";
import { IPostcode } from "../../../Interfaces/IPostcode";
import PostcodeSearch from "../../../generic/PostcodeSearch";
import { useSelector } from "react-redux";
import { State } from "../../../redux/mainReducer";


interface IProps {
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
}

const handlePostcode = (companyFormationObject: ICompanyFormation) => {
    if (companyFormationObject.idPostcode != null 
        && companyFormationObject.idPostcode > 0
        && companyFormationObject.City != undefined
        && companyFormationObject.Postcode != undefined
    ) {
        return {
            idPostcode: companyFormationObject.idPostcode,
            City: companyFormationObject.City,
            Postcode: companyFormationObject.Postcode,
            idCountry: 1
        } as IPostcode
    } else {
        return null;
    }
}



const BaseInformation:React.FC<IProps> = (props) => {
    const businessFormArray = useSelector((state: State) => state.businessFormArray.storeBusinessForm);
    //
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenWarning, setIsOpenWarning] = useState(false);
    // 
    const [postcodeObject, setPostcodeObject] = useState<IPostcode | null | undefined>(handlePostcode(props.companyFormationObject));

    const setPostcodeObjectWrapper = (postcodeObject:IPostcode | null | undefined) => {
        if (postcodeObject != undefined) {
            props.setCompanyFormationObject({
                ...props.companyFormationObject,
                idPostcode: postcodeObject.idPostcode,
                Postcode: postcodeObject.Postcode,
                City: postcodeObject.City,
            } as ICompanyFormation)

        }
        setPostcodeObject(postcodeObject);
    }



    useEffect(() => {
       if (props.companyFormationObject.idBusinessForm === 7) {
        props.setCompanyFormationObject({...props.companyFormationObject, ShareCapital: 1000})
       }  
       else if (props.companyFormationObject.idBusinessForm === 8) {
        props.setCompanyFormationObject({...props.companyFormationObject, ShareCapital: 25000})
       }
        
    },[props.companyFormationObject.idBusinessForm])



    return(
        <>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Box sx={{m: 3, width: 250}}>
                    <Typography>
                        Eine GmbH, also eine Gesellschaft mit beschränkter Haftung, ist eine der gebräuchlichsten Rechtsformen für Unternehmen in Deutschland. Die Stammdaten einer GmbH bilden die grundlegenden Informationen, die zur Gründung und zur Führung des Unternehmens notwendig sind.
                        <p />
                        Zunächst muss die GmbH einen eindeutigen Firmennamen haben, der noch nicht von einer anderen Firma verwendet wird. Dieser Name muss mit dem Zusatz "GmbH" versehen sein.
                        <p />
                        Des Weiteren ist der Sitz der GmbH ein wichtiger Bestandteil der Stammdaten. Der Sitz ist der Ort, an dem das Unternehmen seinen Hauptsitz hat und seinen Geschäftsbetrieb führt.
                        <p />
                        Eine weitere wichtige Information in den Stammdaten ist die Gründungsurkunde. Die Gründungsurkunde ist das offizielle Dokument, das die Gründung der GmbH bescheinigt. Sie enthält den Firmennamen, den Sitz der GmbH, den Gegenstand des Unternehmens sowie die Namen und Adressen der Gründer.
                        <p />
                        Zusätzlich zu diesen grundlegenden Informationen müssen auch die Namen und Adressen der Geschäftsführer und der Gesellschafter in den Stammdaten aufgeführt werden. Die Geschäftsführer sind für die Leitung des Unternehmens verantwortlich, während die Gesellschafter die Eigentümer des Unternehmens sind.
                        <p />
                        Die Stammdaten einer GmbH sind von zentraler Bedeutung für das Unternehmen, da sie die grundlegenden Informationen bereitstellen, die für eine erfolgreiche Gründung und Führung des Unternehmens notwendig sind.
                        <p />
                        <Button 
                            sx={{float: "right"}} 
                            variant="contained"
                            onClick={() => setIsOpen(false)}
                        >Schließen</Button>
                    </Typography>
                </Box>
            </Drawer>


            <Box sx={{mt: 5}} />
            <Typography variant="h4" sx={{mb: 3}}>Unternehmensdaten</Typography>
            <Typography variant="h6" sx={{mb: 3}}>
                Firmentdaten
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <TextField
                        label="Rechtsform"
                        size="small"
                        type="number"
                        fullWidth
                        value={props.companyFormationObject.idBusinessForm}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({...props.companyFormationObject, idBusinessForm: Number(event.target.value)})}
                        select
                    >
                        {businessFormArray.map(x =>
                            <MenuItem 
                                key={`companyFormationObject.idBusinessForm-${x.idBusinessForm}`} 
                                value={x.idBusinessForm}
                                disabled={x.idBusinessForm !== 8 && x.idBusinessForm !== 7 && x.idBusinessForm !== 3}
                                >
                                    {x.BusinessForm}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item sm={8}>
                    <TextField
                        label="Firmenname"
                        size="small"
                        fullWidth
                        value={props.companyFormationObject.CompanyName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({...props.companyFormationObject, CompanyName: event.target.value})}
                        required
                    />
                </Grid>
            </Grid>

            <Box sx={{mt: 5}} />
            <Typography variant="h6" sx={{mb: 3}}>Firmenanschrift</Typography>
            <Grid container spacing={2}>
                <Grid item sm={8}>
                    <TextField
                        label="Straße"
                        size="small"
                        fullWidth
                        value={props.companyFormationObject.Street}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({...props.companyFormationObject, Street: event.target.value})}
                        required
                    />
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        label="Nr"
                        size="small"
                        fullWidth
                        value={props.companyFormationObject.StreetNr}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({...props.companyFormationObject, StreetNr: event.target.value})}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <PostcodeSearch
                        postcodeObject={postcodeObject} 
                        setPostcodeObject={setPostcodeObjectWrapper}
                    />
                </Grid>
            </Grid>

            <Box sx={{mt: 5}} />
            <Typography variant="h6" sx={{mb: 3}}>Gegenstand des Unternehmens</Typography>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <TextField
                        size="small"
                        rows={5}
                        multiline
                        fullWidth
                        value={props.companyFormationObject.PurposeOfTheCompany}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({...props.companyFormationObject, PurposeOfTheCompany: event.target.value})}
                    />
                </Grid>
            </Grid>
    </>
    )
}
export default BaseInformation;