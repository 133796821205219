import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, deDE } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../redux/mainReducer";
import { ITextModuleCategory } from "../Interfaces/ITextModuleCategory";
import { ITextModule } from "../Interfaces/ITextModule";
import { TextModuleEdit } from "./TextModuleEdit";
import { setTextModuleArray } from "../redux/slices/textModule";
import { Add, Delete } from "@mui/icons-material";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreatorsTextModule } from "../redux/actionCreators";
import { deleteFetch, uploadFetch } from "../hooks/useFetch";



const columns: GridColDef[] = [
    { field: 'idTextModule', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'TextModuleCategory', 
      headerName: 'Kategorie', 
      width: 150 
    },
    { field: 'TextModule', 
      headerName: 'Bezeichnung', 
      flex: 1
    },
]


const getTextModule = (idTextModule:number|null, textModuleArray: ITextModule[]) => {
    let currentObject = textModuleArray.find(x => x.idTextModule === Number(idTextModule));

    if (currentObject !== undefined) {
        return currentObject;
    }
    else {
        let tmpId = -1;

        if (textModuleArray.length > 0) {
            let tmpIdArray = Math.min(...textModuleArray.map(x => x.idTextModule)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray -1;
            }
        }

        return {
            idTextModule: tmpId,
            idTextModuleCategory: 1,
            Text: "",
            TextModule: ""
        } as ITextModule
    }
}



export const TextModuleOverview:React.FC = () => {
    const dispatch = useDispatch();
    const textModuleArray = useSelector((state: State) => state.textModuleArray.storeTextModule);
    const { addUpdateTextModuleArray, deleteTextModuleArray } = bindActionCreators(actionCreatorsTextModule, dispatch);
    //
    //const [idTextModule,setIdTextModule] = useState<number|null>(null);
    const [editTextModule,setEditTextModule] = useState(getTextModule(null,textModuleArray));
    const [isOpen,setIsOpen] = useState(false);
    //
    const [isOpenDelete,setIsOpenDelete] = useState(false);


    const handleNew = () => {
        setEditTextModule(getTextModule(null,textModuleArray));
        setIsOpen(true);
    }
    
    const handleEdit = (localIdTextModule:number|string) => {
        setEditTextModule(getTextModule(Number(localIdTextModule),textModuleArray));
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject: ITextModule) => {
        addUpdateTextModuleArray(savedObject);
        setIsOpen(false)
    }

    const handleSave = () => {
        uploadFetch("/textmodule",(editTextModule.idTextModule < 0),editTextModule,handleAfterSave)
    }


    const handleAfterDelete = (localIdTextModule:number) => {
        deleteTextModuleArray(localIdTextModule);
        setIsOpenDelete(false);
        setIsOpen(false);
    }

    const handleDelete = () => {
        deleteFetch("/textmodule/",editTextModule.idTextModule,() => handleAfterDelete(editTextModule.idTextModule))
    }



    return(
        <>
            <Dialog
                open={isOpenDelete}
                onClose={() => setIsOpenDelete(false)}
                maxWidth="xs" fullWidth
            >
                <DialogTitle>Textbaustein löschen</DialogTitle>
                <DialogContent>
                    Sind Sie sicher, dass Sie den Textbaustein <i>{editTextModule.TextModule}</i> unwiederruflich löschen wollen?
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDelete}>Löschen</Button>
                    <Button variant="contained" onClick={() => setIsOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>
                    Textbaustein
                    {(editTextModule.idTextModule > 0) && <IconButton sx={{float: "right"}} onClick={() => setIsOpenDelete(true)}><Delete/></IconButton>}
                </DialogTitle>
                <DialogContent>
                    
                    <TextModuleEdit
                        textModuleObject={editTextModule}
                        setTextModuleObject={setEditTextModule}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave} disabled={editTextModule.TextModule === ""}>Übernehmen</Button>
                </DialogActions>
            </Dialog>



            <Typography variant="h5" sx={{mt: 5}}>
                Textbausteine
                <IconButton sx={{float: "right"}} onClick={handleNew}><Add /></IconButton>
            </Typography>
            <Box sx={{mt: 2}}/>
            <DataGrid
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={textModuleArray}
                columns={columns}
                getRowId={(row) => row.idTextModule}
                onRowClick={(params,event,details) => handleEdit(params.id)}
                autoHeight
            />
        </>
    )
}