import React, { useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ICompanyFormation, ICompanyFormationFullObject } from "../../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../Interfaces/ICompanyFormationHasShareHolder";
import { ICompanyFormationAnswer } from "../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../Interfaces/ICompanyFormationQuestion";
import { IDocument } from "../../../Interfaces/IDocument";
import { ICompanyFormationOption } from "../../../Interfaces/ICompanyFormationOption";
import { IPostcode } from "../../../Interfaces/IPostcode";
import { ShareHolderEdit } from "../../CompanyFormation/Childs/ShareHolder/ShareHolderEdit";
import { checkString } from "../../../core/standardFunctions";
import { CompanyFormationAnswerSummary } from "../../CompanyFormation/Childs/Summary/CompanyFormationAnswerSummary";
import { useSelector } from "react-redux";
import { State } from "../../../redux/mainReducer";
import { DocumentOverview } from "../../../Document/DocumentOverview";
import { Search } from "@mui/icons-material";
import { getFetch } from "../../../hooks/useFetch";



export interface IProps {
    companyFormationObject: ICompanyFormationFullObject;
    setCompanyFormationObject: Function;
}


export const RASummary:React.FC<IProps> = (props) => {
    const genderArray = useSelector((state: State) => state.genderArray.storeGender);
    const academicTitleArray = useSelector((state: State) => state.academicTitleArray.storeAcademicTitle);
    const staticCountryArray = useSelector((state: State) => state.countryArray.storeCountry);

    const [companyFormationObject, setCompanyFormationObject] = useState<ICompanyFormation>(props.companyFormationObject)
    const [shareHolderArray, setShareHolderArray] = useState<IShareHolderFullObject[]>(props.companyFormationObject.ShareHolderArray);
    const [companyFormationShareArray, setCompanyFormationShareArray] = useState<ICompanyFormationShare[]>(props.companyFormationObject.CompanyFormationShareArray);
    const [shareHolderRelation,setShareHolderRelation] = useState<ICompanyFormationHasShareHolder[]>(props.companyFormationObject.CompanyFormationHasShareHolderArray);
    const [companyFormationAnswerArray, setCompanyFormationAnswerArray] = useState<ICompanyFormationAnswer[]>(props.companyFormationObject.CompanyFormationAnswerArray);
    const [documentArray,setDocumentArray] = useState<IDocument[]>(props.companyFormationObject.DocumentArray);

    const [idShareHolder, setIdShareHolder] = useState<number | null>(null);
    //
    const [postCodeObject,setPostCodeObject] = useState<IPostcode|undefined>(undefined);
    const [addressPostCodeObject,setAddressPostCodeObject] = useState<IPostcode|undefined>(undefined);
    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);


    useEffect(() => {
        props.setCompanyFormationObject({
            ...companyFormationObject,
            ShareHolderArray: shareHolderArray,
            CompanyFormationHasShareHolderArray: shareHolderRelation,
            CompanyFormationShareArray: companyFormationShareArray,
            CompanyFormationAnswerArray: companyFormationAnswerArray,
            DocumentArray: documentArray
        } as ICompanyFormationFullObject)
    },[companyFormationObject,shareHolderArray,companyFormationShareArray,shareHolderRelation,companyFormationAnswerArray,documentArray])



    useEffect(() => {
        
        if (props.companyFormationObject.idPostcode > 0) {
            getFetch("/postcode/",props.companyFormationObject.idPostcode,setPostCodeObject);
        }
    },[props.companyFormationObject.idPostcode])



    useEffect(() => {
        if (props.companyFormationObject.Postcode_AddressidPostcode !== null && props.companyFormationObject.Postcode_AddressidPostcode > 0) {
            getFetch("/postcode/",props.companyFormationObject.Postcode_AddressidPostcode,setAddressPostCodeObject);
        } else {
            setAddressPostCodeObject(undefined);
        }
    },[props.companyFormationObject.Postcode_AddressidPostcode])



    return(
        <>
            <Dialog
                open={isLoading}
            >
                <DialogTitle>Bitte warten</DialogTitle>
                <DialogContent>
                    Ihre Anfrage wird derzeitig hochgeladen.

                    <Grid
                        container
                        sx={{mt: 3}}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={3}>
                            {(wasSuccessfully)
                                ? <CircularProgress/>
                                : <Alert severity="error">Es ist ein Fehler aufgetretten!</Alert>
                            }

                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>




            <Dialog open={idShareHolder !== null} onClose={() => setIdShareHolder(null)} maxWidth="md" fullWidth>
                <DialogTitle>{(idShareHolder === null || idShareHolder === 0) ? <>Neue Person</> : <>Person
                    bearbeiten</>}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}>
                        {(idShareHolder !== null) &&
                            <ShareHolderEdit
                                idShareHolder={idShareHolder}
                                handleClose={() => setIdShareHolder(null)}
                                shareHolderArray={shareHolderArray}
                                setShareHolderArray={setShareHolderArray}
                                disableSelectCompanyType={
                                    shareHolderArray
                                        .find(x => x.idShareHolder === idShareHolder)
                                        ?.idBusinessForm === 1
                                }
                            />
                        }
                    </Box>
                </DialogContent>
            </Dialog>




            <Box sx={{mt: 5}}/>
            <Typography variant="h4" sx={{mb: 3}}>Abschluss</Typography>

            <Box sx={{mt: 5}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                Zusammenfassung
            </Typography>
            <Grid container sx={{pl: 10}} spacing={2}>
                <Grid item sm={4}><b>Vereinsname:</b></Grid>
                <Grid item sm={8}>{checkString(props.companyFormationObject.CompanyName)}</Grid>

                <Grid item sm={4}><b>Vereinssitz:</b></Grid>
                <Grid item
                      sm={8}>{checkString(props.companyFormationObject.Street)} {checkString(props.companyFormationObject.StreetNr)}</Grid>

                <Grid item sm={4}></Grid>
                <Grid item sm={8}>
                    {(postCodeObject === undefined) 
                        ? <>k.A.</>
                        : <>{checkString(postCodeObject.Postcode)} {checkString(postCodeObject.City)}</>
                    }
                    
                </Grid>

                { (
                    props.companyFormationObject.AddressStreet !== null
                    || props.companyFormationObject.AddressStreetNr !== null
                    || props.companyFormationObject.Postcode_AddressidPostcode !== null
                  ) && 
                  <>

                    <Grid item sm={4}><b>Vereinsanschrift:</b></Grid>
                    <Grid item
                        sm={8}>{checkString(props.companyFormationObject.AddressStreet)} {checkString(props.companyFormationObject.AddressStreetNr)}</Grid>

                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                        {(addressPostCodeObject === undefined) 
                            ? <>k.A.</>
                            : <>{checkString(addressPostCodeObject.Postcode)} {checkString(addressPostCodeObject.City)}</>
                        }
                        
                </Grid>
                  </>   
                }
            </Grid>

            <CompanyFormationAnswerSummary
                companyFormationAnswerArray={companyFormationAnswerArray}
            />


            <Box sx={{mt: 10}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                Vorstand
            </Typography>
            <Typography>
                Der Vorstand im Sinne des §26 BGB besteht aus {
                    (shareHolderRelation.length === 1) ? `dem ${shareHolderRelation[0].FreeText}.`
                    : shareHolderRelation.map((x,i) =>
                        (i+1 === shareHolderRelation.length) ? `dem ${x.FreeText}.`
                        : `dem ${x.FreeText}, `
                    )
                }
            </Typography>
            
            <Table sx={{mt: 2}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Funktion</TableCell>
                        <TableCell>Titel</TableCell>
                        <TableCell>Geschlecht</TableCell>
                        <TableCell>Vorname</TableCell>
                        <TableCell>Nachname</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shareHolderArray.map(x =>
                        <TableRow>

                            <TableCell sx={{color: "black"}}>
                                {
                                    shareHolderRelation
                                        .filter(y => y.idShareHolder === x.idShareHolder)
                                        .map(x =>
                                            x.FreeText
                                        )
                                        .join(", ")
                                }
                            </TableCell>
                            <TableCell>
                                {(x.idAcademicTitle === 1)
                                    ? ""
                                    : checkString(academicTitleArray.find(y => y.idAcademicTitle === x.idAcademicTitle)?.AcademicTitle)
                                }
                            </TableCell>
                            <TableCell>
                                {checkString(genderArray.find(y => y.idGender === x.idGender)?.Gender)}
                            </TableCell>


                            <TableCell>{x.FirstName}</TableCell>
                            <TableCell>{x.LastName}</TableCell>


                            <TableCell>
                                <IconButton onClick={() => setIdShareHolder(x.idShareHolder)}><Search/></IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Box sx={{mt: 10}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                Dokumente
            </Typography>
            <DocumentOverview
                allowRemove
                allowUpload
                documentArray={documentArray}
                setDocumentArray={setDocumentArray}
                title=""
            />
        </>
    )
}