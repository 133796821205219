import React, { ChangeEvent, useEffect, useState } from "react";
import { IContact, IContactFullObject, ILegalTransactionHasContact } from "../Interfaces/IContact";
import { DataGrid, GridCellEditStopParams, GridColDef, GridRenderCellParams, GridValueGetterParams, MuiEvent, deDE } from "@mui/x-data-grid";
import { Box, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { Add, Check, Close, Delete, Link } from "@mui/icons-material";
import { LegalTransactionAddClient } from "./LegalTransactionAddClient";
import { ILegalRole } from "../Interfaces/ILegalRole";
import { ILegalPhaseFullObject } from "../Interfaces/ILegalPhase";
import { ILegalRole_canInvite } from "../Interfaces/ILegalRole_canInvite";
import { LegalRoleEditDialog } from "./Edit/LegalRole/LegalRoleEditDialog";
import { useSelector } from "react-redux";
import { State } from "../redux/mainReducer";


interface IProps {
  allContactArray: IContact[];
  legalRoleArray: ILegalRole[];
  contactArray: ILegalTransactionHasContact[];
  setContactArray: Function;
  newContactArray: ILegalTransactionHasContact[];
  setNewContactArray: Function;
  setLegalRoleArray?: Function;
  legalRoleCanInviteArray?: ILegalRole_canInvite[];
  setLegalRoleCanInviteArray?: Function;
}


export const LegalTransactionClientsOverview:React.FC<IProps> = (props) => {
  const contactGroupArray = useSelector((state: State) => state.contactGroup.storeContactGroup);

  const deleteClient = (idContact:string|number) => {
    props.setContactArray([
      ...props.contactArray.filter(x => x.idContact !== Number(idContact))
    ])
    props.setNewContactArray([
      ...props.newContactArray.filter(x => x.idContact !== Number(idContact))
    ])
  }

  const handleChangeSelect = (event:ChangeEvent<HTMLInputElement>,idContact:number) => {
    let testObjectContact = props.contactArray.find(x => x.idContact === idContact);
    let testObjectNewContact = props.newContactArray.find(x => x.idContact === idContact);

    if (testObjectContact !== undefined) {
      //console.log("UPDATE 1");
      testObjectContact.idLegalRole = Number(event.target.value);
      props.setContactArray([
        ...props.contactArray.map(x => (x.idContact === idContact) ? testObjectContact : x)
      ])
    }

    if (testObjectNewContact !== undefined) {
      //console.log("UPDATE 2");
      testObjectNewContact.idLegalRole = Number(event.target.value);
      props.setNewContactArray([
        ...props.newContactArray.map(x => (x.idContact === idContact) ? testObjectNewContact : x)
      ])
    }
  }



  const columns: GridColDef[] = [
    { field: 'idContact', headerName: 'Nr.', width: 70 },
    /*{
        field: 'idLegalRole',
        headerName: 'Rolle',
        width: 130,
        type: "singleSelect",
        editable: props.legalRoleArray.length > 0,
        valueOptions: [
            ...props.legalRoleArray.map(x => {
                return {
                    value: x.idLegalRole,
                    label: x.LegalRole
                }
            })
        ],
    },*/
    {
      field: 'idLegalRole',
      headerName: 'Rolle',
      width: 130,
      renderCell: (params: GridRenderCellParams) : React.ReactNode => {
        if (props.legalRoleArray.length === 0) {
          return <>-</>
        } else {
          return(
              <>
                <TextField
                    select
                    fullWidth
                    size="small"
                    label="Rolle"
                    onChange={(event:ChangeEvent<HTMLInputElement>)  => handleChangeSelect(event,Number(params.id))}
                    value={(params.row.idLegalRole === null) ? "" : Number(params.row.idLegalRole)}
                >
                  {props.legalRoleArray.map(x =>
                      <MenuItem key={`idLegalRole-${x.idLegalRole}`} value={x.idLegalRole}>{x.LegalRole}</MenuItem>
                  )}
                </TextField>
              </>
          )
        }
      }
    },
    {
      field: 'idContactGroup',
      headerName: 'Gruppe',
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
          (params.row.idContactGroup == null)
              ? ""
              : contactGroupArray.find(x => x.idContactGroup == params.row.idContactGroup)?.ContactGroup
      ,
    },
    {
      field: 'loginPossible',
      headerName: 'Login',
      width: 70,
      renderCell: (params) => (Boolean(params.value)) ? <Check/> : <Close/>

    },
    {
      field: 'isReceivingEmailEnabled',
      headerName: 'E-Mails',
      width: 70,
      renderCell: (params) => (Boolean(params.value)) ? <Check/> : <Close/>

    },
    { field: 'FirstName', headerName: 'Vorname', width: 130 },
    { field: 'LastName', headerName: 'Nachname', width: 130 },
    { field: 'Email', headerName: 'E-Mail-Adresse',flex: 1 },
    {
      field: 'Actions',
      headerName: "Aktionen",
      width: 90,
      renderCell: (params: GridRenderCellParams) : React.ReactNode => {
        return(
            <>
              <IconButton
                  title='Mandanten öffnen'
                  onClick={() =>{
                    window.open(`/clients/${params.row.idContact}`, "_blank")
                  }}
              ><Link/></IconButton>
              <IconButton
                  title='Mandanten Löschen'
                  onClick={() => deleteClient(params.row.idContact)}
              ><Delete/></IconButton>
            </>
        )
      }
    }
  ];


  /*
  const handleChange = (params: GridCellEditCommitParams, event: MuiEvent) => {
      let testObjectContact = props.contactArray.find(x => x.idContact === Number(params.id));
      let testObjectNewContact = props.newContactArray.find(x => x.idContact === Number(params.id));

      console.log("---------");
      console.log(params.id);
      console.log(params.value);

      if (testObjectContact !== undefined) {
          console.log("UPDATE 1");
          testObjectContact.idLegalRole = (params.value == undefined) ? null : Number(params.value);
          props.setContactArray([
              ...props.contactArray.map(x => (x.idContact == Number(params.id)) ? testObjectContact : x)
          ])
      }

      if (testObjectNewContact !== undefined) {
          console.log("UPDATE 2");
          testObjectNewContact.idLegalRole = (params.value == undefined) ? null : Number(params.value);
          props.setNewContactArray([
              ...props.newContactArray.map(x => (x.idContact == Number(params.id)) ? testObjectNewContact : x)
          ])
          console.log(testObjectNewContact);
          console.log(props.newContactArray.map(x => (x.idContact == Number(params.id)) ? testObjectNewContact : x))
      }
  }
  */


  return(
      <>

        {(props.setLegalRoleArray !== undefined && props.legalRoleCanInviteArray !== undefined && props.setLegalRoleCanInviteArray !== undefined) &&
            <Box sx={{mb: 2}}>
              <LegalRoleEditDialog
                  contactArray={props.contactArray}
                  newContactArray={props.newContactArray}
                  legalRoleArray={props.legalRoleArray}
                  setLegalRoleArray={props.setLegalRoleArray}
                  legalRoleCanInviteArray={props.legalRoleCanInviteArray}
                  setLegalRoleCanInviteArray={props.setLegalRoleCanInviteArray}
              />
              <br/>
            </Box>
        }

        <Typography variant="h5">
          Mandanten
          <LegalTransactionAddClient
              allContactArray={props.allContactArray}
              legalRoleArray={props.legalRoleArray}
              contactArray={props.contactArray}
              setContactArray={props.setContactArray}
              newContactArray={props.newContactArray}
              setNewContactArray={props.setNewContactArray}
          />
        </Typography>

        <DataGrid
            sx={{mt: 2}}
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={[...props.contactArray,...props.newContactArray]}
            columns={columns}
            getRowId={(row) => row.idContact}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            disableRowSelectionOnClick
        />
      </>
  )
}