import { Autocomplete, ListItem, ListItemText, MenuItem, TextField } from '@mui/material'
import { IUser } from '../../Interfaces/IUser'
import { IUserGroup, IUserGroupFullObject } from '../../Interfaces/IUserGroup'
import { Person, Group } from '@mui/icons-material'
import { useState } from 'react'

interface IProps {
  currentUserId: number | string | null
  userArray: IUser[]
  userGroupArray: IUserGroup[]
  reassignUser(value: number | null, userType: 'Mitarbeiter' | 'Gruppe' | 'Keine Zuweisung', index?: number): void
  index?: number
  title?: string | null
  isNotary?: boolean
}

export const UserReassignSingle: React.FC<IProps> = ({
  index,
  userArray,
  title,
  reassignUser,
  currentUserId,
  userGroupArray,
  isNotary
}) => {
  const combinedOptions = [
    ...userArray
      .filter((x) => !x.isNotary)
      .map((x) => ({
        type: 'Mitarbeiter',
        id: x.idUser,
        name: `${x.FirstName} ${x.LastName}`
      })),
    ...userGroupArray.map((group) => ({
      type: 'Gruppe',
      id: group.idUserGroup,
      name: group.UserGroup
    })),
    {
      type: 'Keine Zuweisung',
      id: null,
      name: 'Keine Zuweisung'
    }
  ]

  const [isAutocompleteClosed, setIsAutocompleteClosed] = useState(false)
  const [selectedType, setSelectedType] = useState<'user' | 'group' | 'none'>(
    currentUserId === null ? 'none' : userArray.find((x) => x.idUser === currentUserId) ? 'user' : 'group'
  )
  return (
    <>
      <Autocomplete
        options={combinedOptions}
        groupBy={(option) => option.type}
        clearIcon={null}
        clearOnEscape={false}
        clearOnBlur={false}
        getOptionLabel={(option) => option.name}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              selectedType === 'user' || selectedType === 'none' ? (
                <span>
                  <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zugewiesener Mitarbeiter
                </span>
              ) : (
                <span>
                  <Group style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zugewiesene Gruppe
                </span>
              )
            }
            size='small'
            variant='outlined'
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemText primary={option.name} />
          </ListItem>
        )}
        onChange={(event, newValue) => {
          if (newValue) {
            if (newValue.type === 'Mitarbeiter') {
              reassignUser(newValue.id, newValue.type, index)
              setSelectedType('user')
            } else if (newValue.type === 'Gruppe') {
              reassignUser(newValue.id, newValue.type, index)
              setSelectedType('group')
            } else if (newValue.type === 'Keine Zuweisung') {
              reassignUser(null, newValue.type, index)
              setSelectedType('none')
            }
          } else {
            console.log('newValue is null')
            reassignUser(null, selectedType === 'user' ? 'Mitarbeiter' : 'Gruppe', index)
            setSelectedType('none')
          }
        }}
        value={
          selectedType === 'user' && currentUserId !== null
            ? combinedOptions.find((option) => option.type === 'Mitarbeiter' && option.id === currentUserId)
            : selectedType === 'group' && currentUserId !== null
              ? combinedOptions.find((option) => option.type === 'Gruppe' && option.id === currentUserId)
              : selectedType === 'none'
                ? combinedOptions.find((option) => option.type === 'Keine Zuweisung')
                : null
        }
        disabled={isAutocompleteClosed}
      />
    </>
  )
}
