import React, { useEffect, useState } from "react";
import { IInherit, IInheritFullObject } from "../../Interfaces/IInherit";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { IPersonHasDocument } from "../../Interfaces/IPersonHasDocument";
import { IInheritHasPerson } from "../../Interfaces/IPerson";
import { IMarriage } from "../../Interfaces/IMarriage";
import { IChildren } from "../../Interfaces/IChildren";
import { Inherit } from "./Childs/Dialog/Inherit";
import { FamilyTree } from "./Childs/Components/FamilyTree";
import { MarriageOverview } from "./Childs/Components/Marriage/MarriageOverview";
import { Marriage } from "./Childs/Dialog/Marriage";
import { ChildrenDriver } from "./Childs/Components/Children/ChildrenDriver";
import { Parents } from "./Childs/Dialog/Parents";
import { ExportPersons } from "./Childs/Components/ExportPersons";
import { Strangers } from "./Childs/Dialog/Strangers";
import { Add } from "@mui/icons-material";
import { IDocument } from "../../Interfaces/IDocument";
import {AddParents} from "./Childs/Dialog/AddParents";
import {SiblingsChildrenTable} from "./Childs/Components/siblingsChildren/SiblingsChildrenTable";

interface IProps {
  inheritObject: IInheritFullObject;
  setInheritObject: Function;
}

export const InheritMain: React.FC<IProps> = (props) => {
  const [inheritObject, setInheritObject] = useState<IInherit>(
    props.inheritObject
  );
  const [familyTreeData,setFamilyTreeData] = useState<undefined|IDocument>(props.inheritObject.FamilyTreeData);
  const [personArray, setPersonArray] = useState<IInheritHasPerson[]>(
    props.inheritObject.PersonArray
  );
  const [personHasDocument, setPersonHasDocument] = useState<
    IPersonHasDocument[]
  >(props.inheritObject.PersonHasDocument);
  const [marriageArray, setMarriageArray] = useState<IMarriage[]>(
    props.inheritObject.MarriageArray
  );
  const [childrenArray, setChildrenArray] = useState<IChildren[]>(
    props.inheritObject.ChildrenArray
  );
  //
  const [inherit, setInherit] = useState(
    personArray.find((x) => x.idPerson === props.inheritObject.idPerson)
  );
  const [isNewOpen, setIsNewOpen] = useState(false);

  useEffect(() => {
    const childs = personArray.find(
      (x) => x.idPerson === props.inheritObject.idPerson
    );
    setInherit(childs);
  }, [personArray, props.inheritObject.idPerson]);


  useEffect(() => {
    console.log("InheritMain")
    console.log(personHasDocument)
    let updatedObject = {
      ...inheritObject,
      hasTestament: Boolean(inheritObject.hasTestament),
      ChildrenArray: childrenArray,
      MarriageArray: marriageArray,
      PersonArray: personArray,
      PersonHasDocument: personHasDocument,
      FamilyTreeData: familyTreeData
    } as IInheritFullObject;
    console.log("UPDATE OBJECTS");
    console.log(updatedObject)
    props.setInheritObject(updatedObject);
  }, [
    inheritObject,
    personArray,
    personHasDocument,
    marriageArray,
    childrenArray,
    familyTreeData
  ]);

  const getDeathParentArray = () => {
    if (inherit === undefined) {
      return [];
    } else {
      let foundChildren = childrenArray.find(
        (x) => x.Children_idPerson === inherit.idPerson
      );

      if (foundChildren === undefined) {
        return [] as IInheritHasPerson[];
      } else {
        let returnArray: IInheritHasPerson[] = [];
        let foundFirstParent = personArray.find(
          (x) => x.idPerson === foundChildren?.Parent1_idPerson
        );
        let foundSecondParent = personArray.find(
          (x) => x.idPerson === foundChildren?.Parent2_idPerson
        );

        if (
          foundFirstParent !== undefined &&
          foundFirstParent.DateOfDeath !== null &&
          foundSecondParent !== undefined &&
          foundSecondParent.DateOfDeath !== null
        ) {
          //foundFirstParent.LastName = `und ${foundSecondParent.FirstName} ${foundSecondParent.LastName}`;
          returnArray.push(foundFirstParent);
        } else {
          if (
            foundFirstParent !== undefined &&
            foundFirstParent.DateOfDeath !== null
          ) {
            returnArray.push(foundFirstParent);
          }

          if (
            foundSecondParent !== undefined &&
            foundSecondParent.DateOfDeath !== null
          ) {
            returnArray.push(foundSecondParent);
          }
        }

        return returnArray;
      }
    }
  };

  /*
  {(childrenArray.find(x => x.Children_idPerson === inherit.idPerson) !== undefined) &&
    <ChildrenDriver
        inheritObject={props.inheritObject}
        idPersonInherit={inherit.idPerson}
        parrentPersonArray={getDeathParentArray()}
        personHasDocument={personHasDocument}
        setPersonHasDocument={setPersonHasDocument}
        personArray={personArray}
        setPersonArray={setPersonArray}
        childrenArray={childrenArray}
        setChildrenArray={setChildrenArray}
        marriageArray={marriageArray}
        title={"Geschwister/ Nachkommen"}
    />
} 
<Accordion>
    <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
    <Typography>Eltern</Typography>
    </AccordionSummary>
    <AccordionDetails>
        {childrenArray.find((x) => x.Children_idPerson === inherit.idPerson) !== undefined ? (
            <Parents
                inheritObject={props.inheritObject}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                personArray={personArray}
                setPersonArray={setPersonArray}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
            />
        ) : (
            <>Eltern hinzufügen</>
        )}
    </AccordionDetails>
</Accordion>
  */

  useEffect(() => {
    console.log("UPADATE familyTreeData");
    console.log(familyTreeData);
  },[familyTreeData])

  if (inherit === undefined) {
    return (
      <Alert severity="error">
        Fehler!
        <br />
        Erblasser konnte nicht bestimmt werden.
      </Alert>
    );
  } else {
    return (
      <>
        <Box sx={{ float: "right", display: "flex", gap: "0.5rem" }}>
          <ExportPersons inheritObject={props.inheritObject} />
        </Box>

        <FamilyTree
          personArray={personArray}
          childrenArray={childrenArray}
          marriageArray={marriageArray}
          setPersonArray={setPersonArray}
          setChildrenArray={setChildrenArray}
          setMarriageArray={setMarriageArray}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          setFamilyTreeData={setFamilyTreeData}
         familyTreeData={familyTreeData}/>

        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Erblasser</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Inherit
              inheritObject={inheritObject}
              setInheritObject={setInherit}
              personArray={personArray}
              setPersonArray={setPersonArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Ehe(n)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Marriage
              inheritObject={inheritObject}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
              marriageArray={marriageArray}
              setMarriageArray={setMarriageArray}
              personArray={personArray}
              setPersonArray={setPersonArray}
            />
          </AccordionDetails>
        </Accordion>

        <ChildrenDriver
          inheritObject={props.inheritObject}
          idPersonInherit={inherit.idPerson}
          parrentPersonArray={[inherit]}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          personArray={personArray}
          setPersonArray={setPersonArray}
          childrenArray={childrenArray}
          setChildrenArray={setChildrenArray}
          marriageArray={marriageArray}
          title={"Nachkommen"}
        />

        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Eltern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5">
              Eltern des Erblassers
            </Typography>
            {isNewOpen && (
              <AddParents
                inheritObject={inheritObject}
                setIsOpenNew={setIsNewOpen}
                personArray={personArray}
                setPersonArray={setPersonArray}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
              />
            )}
            {childrenArray.find(
              (x) => x.Children_idPerson === inherit.idPerson
            ) !== undefined ? (
              <Parents
                inheritObject={props.inheritObject}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                personArray={personArray}
                setPersonArray={setPersonArray}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
              />
            ) : (
              <>
                <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
                  Es sind keine Daten vorhanden
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setIsNewOpen(true)}
                  sx={{
                    float: "left",
                    "& .MuiButton-startIcon": {
                      marginRight: "2px", // Adjust the margin-right here
                    },
                    marginBottom: "16px",
                  }}
                  startIcon={
                    <Add
                      sx={{
                        fontSize: 22,
                        position: "relative",
                        bottom: 1,
                        marginRight: "0px",
                      }}
                    />
                  }
                >
                  Eltern
                </Button>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Nahe Verwandte</Typography>
          </AccordionSummary>

          <AccordionDetails>
          <Typography variant="h5">
              Auflistung der nahen Verwandten
            </Typography>
            {childrenArray.find(
              (x) => x.Children_idPerson === inherit.idPerson
            ) !== undefined ? (
              <SiblingsChildrenTable
                inheritObject={inheritObject}
                idPersonInherit={inherit.idPerson}
                firstParentArray={getDeathParentArray()}
                personArray={personArray}
                setPersonArray={setPersonArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
                marraigeArray={marriageArray}
              />
            ) : (
              <>
                <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
                  Fügen Sie die Eltern des Erblassers hinzu
                  um die nahen Verwandten anzugeben.
                </Typography>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {Boolean(props.inheritObject.hasTestament) && (
          <Accordion>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
              <Typography>Sonstige Personen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Strangers
                inheritObject={inheritObject}
                personArray={personArray}
                setPersoArray={setPersonArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonArray}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  }
};
