import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { IRealEstate } from "../../../../Interfaces/IRealEstate";
import { IDistrictCourt } from "../../../../Interfaces/IDistrictCourt";
import DistrictCourtSearch from "../../../../generic/DistrictCourtSearch";


interface IProps {
    currentObject: IRealEstate;
    setCurrentObject: Function;
    marginTop?: number;
}


export const RealEstateLandRegister:React.FC<IProps> = (props) => {
    const handleDistrictCourt = (localObject: IRealEstate) => {
        if (localObject.idDistrictCourt != null
            && localObject.idDistrictCourt > 0
        ) {
            return {
                idDistrictCourt: localObject.idDistrictCourt
            } as IDistrictCourt
        } else {
            return null;
        }
    }

    const setDistrictCourtObjectWrapper = (districtCourtObject: IDistrictCourt | null | undefined) => {
        if (districtCourtObject != undefined) {
            props.setCurrentObject({
                ...props.currentObject,
                idDistrictCourt: districtCourtObject.idDistrictCourt,

            } as IRealEstate)
        }
        setDistrictCourtObject(districtCourtObject);
    }


    const [districtCourtObject, setDistrictCourtObject] = useState<IDistrictCourt | null | undefined>(handleDistrictCourt(props.currentObject));




    return(
        <>
            <Typography variant="h5" sx={{mt: (props.marginTop === undefined) ? 2 : props.marginTop, mb: 1}}>
                Grundbuchinformationen
                <br/>
                <Typography>
                    Falls Sie die Informationen über den Grundbucheintrag Ihres Vertragsgegenstandes haben, so tragen Sie diese bitte ein. Sofern Sie diese Informationen nicht vorliegen haben, ist dies kein Problem. Die Informationen können nachgereicht oder durch unsere Mitarbeiter im Grundbuch geprüft werden.
                </Typography>
            </Typography>
            <Grid container spacing={2}>

                <Grid item sm={12}>
                    <DistrictCourtSearch
                        districtCourtObject={districtCourtObject}
                        setDistrictCourtObject={setDistrictCourtObjectWrapper}
                    />
                </Grid>
                <Grid item sm={8}>
                    <TextField
                        label="Grundbuch"
                        size="small"
                        fullWidth
                        value={props.currentObject.LandRegister}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentObject({...props.currentObject, LandRegister: event.target.value})}
                    />
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        label="Blatt"
                        size="small"
                        fullWidth
                        value={props.currentObject.SheetLandRegister}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentObject({...props.currentObject, SheetLandRegister: event.target.value})}
                    />
                </Grid>

                <Grid item sm={6}>
                    <TextField
                        label="Flurstück"
                        size="small"
                        fullWidth
                        value={props.currentObject.LandParcel}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentObject({...props.currentObject, LandParcel: event.target.value})}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        label="Flur"
                        size="small"
                        fullWidth
                        value={props.currentObject.Parcel}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentObject({...props.currentObject, Parcel: event.target.value})}
                    />
                </Grid>
            </Grid>
        </>
    )
}