import { Box, Dialog, DialogContent, Grid, IconButton, TableHead, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { getFetch, useFetch } from "../../hooks/useFetch";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { JSONTree } from "react-json-tree";
import { Cached, Publish, Search } from "@mui/icons-material";


export const PublicServer:React.FC = () => {
    const [corruptedArray,setCorruptedArray, wasSuccessFullyCorruptedArray] = useFetch<any[]>("/system/sync/corrupted");
    const [stateObject,setStateObject, wasSuccessFullyStateObject] = useFetch<{State: string}>("/system/sync/state");
    //
    const [isOpenCorrupted,setIsOpenCorrupted] = useState(false);

    const reloadState = () => {
        getFetch("/system/sync/state",null,setStateObject)
    }

    const syncAll = () => {
        getFetch("/system/sync/all",null,setStateObject)
    }

    if (
        !wasSuccessFullyCorruptedArray
        || !wasSuccessFullyStateObject
    ) { return <>Error!</> }
    else if (
        corruptedArray === undefined
        || stateObject === undefined
    ) { return <CustomCircularProgress/> }
    else {
        return(
            <>

                <Dialog
                    open={isOpenCorrupted}
                    onClose={() => setIsOpenCorrupted(false)}
                    maxWidth="lg" fullWidth
                >
                    <DialogContent>
                        <JSONTree data={corruptedArray} />
                    </DialogContent>
                </Dialog>


                <Box sx={{m: 2, mt: 0}}>
                    <Typography variant="h4">
                        Öffentlicher Server
                    </Typography>

                    <Box sx={{mt: 5}} />
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <b>Fehlerhafte Synchronisationen</b>
                        </Grid>
                        <Grid item sm={4}>
                            {corruptedArray.length}
                        </Grid>
                        <Grid item sm={4}>
                            <IconButton onClick={() => setIsOpenCorrupted(true)}><Search/></IconButton>
                        </Grid>

                        <Grid item sm={4}>
                            <b>Volle Synchronisation</b>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Status"
                                value={stateObject.State}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <IconButton onClick={() => {setStateObject(undefined); reloadState()}}><Cached/></IconButton>
                            <IconButton onClick={() => {setStateObject(undefined); syncAll()}}><Publish/></IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }

}