import React, { useState, useEffect, useCallback } from 'react'
import {
  Badge,
  Box,
  IconButton,
  Menu,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Delete, WifiOff } from '@mui/icons-material'
import {
  INotification,
  INotificationContentItem,
  INotifcationAction,
  INotificationSettings
} from '../Interfaces/INotification'
import WebSocketManagerInstace from '../services/websocket.service'
import { useNavigate } from 'react-router-dom'

const NotificationComponent: React.FC = () => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isConnected, setIsConnected] = useState(false)
  const navigate = useNavigate()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [notificationSettings, setNotificationSettings] = useState<INotificationSettings | null>(null)
  const [showNotificationsAtStartUpDone, setShowNotificationsAtStartUpDone] = useState(false)

  const [showOnlyUnread, setShowOnlyUnread] = useState(false)

  const calcNotifcationOptionArray = useCallback((notificationBitNumber: number): number[] => {
    const optionen: number[] = []
    let bitPosition = 0

    while (notificationBitNumber !== 0) {
      if ((notificationBitNumber & 1) !== 0) {
        optionen.push(bitPosition + 1)
      }
      notificationBitNumber >>= 1
      bitPosition++
    }

    return optionen
  }, [])

  const sortNotifications = useCallback((a: INotification, b: INotification) => {
    return new Date(b.Created_at).getTime() - new Date(a.Created_at).getTime()
  }, [])

  const updateNotifications = useCallback(
    (newNotifications: INotification[]) => {
      setNotifications((prevNotifications) => {
        const filteredNotifications = prevNotifications.filter(
          (n) => !newNotifications.some((newNotification) => n.idNotification === newNotification.idNotification)
        )
        let notis = [...filteredNotifications, ...newNotifications].sort(sortNotifications)

        notis = notis.map((notification) => {
          const newNotification = newNotifications.find(
            (newNotification) => newNotification.idNotification === notification.idNotification
          )
          if (newNotification && newNotification.isRead !== notification.isRead) {
            return { ...notification, isRead: newNotification.isRead }
          }
          return notification
        })
        return notis
      })
    },
    [sortNotifications]
  )

  const handleNotification = useCallback(
    (notification: INotification | INotification[]) => {
      if (Array.isArray(notification)) {
        updateNotifications(notification)
      } else {
        updateNotifications([notification])
      }
    },
    [updateNotifications]
  )

  const handleSubscriptionChange = useCallback(
    (notificationSettings: INotificationSettings) => {
      const notificationOptionArray = notificationSettings.NotificationOptions
      const notificationSocket = WebSocketManagerInstace.getSocketService('notification')
      if (!notificationSocket) {
        return
      }

      setNotificationSettings((prevNotifications) => {
        if (prevNotifications?.NotificationOptions !== notificationSettings.NotificationOptions) {
          const prevTopics = prevNotifications?.NotificationOptions || []

          const newTopics = notificationOptionArray.filter((topic) => !prevTopics.includes(topic))
          const oldTopics = prevTopics.filter((topic) => !notificationOptionArray.includes(topic))

          newTopics.forEach((topic) => {
            notificationSocket.subscribe_to_topic(topic.toString(), handleNotification)
          })
          console.log('newTopics', newTopics)
          notificationSocket.sendMessage('get_all_notifications_for_topics', newTopics, (message: INotification[]) => {
            handleNotification(message)
            if (
              !showNotificationsAtStartUpDone &&
              notificationSettings.ShowNotificationsAtLogin &&
              message.filter((notification) => !notification.isRead).length > 0
            ) {
              setShowDialog(true)
              setShowNotificationsAtStartUpDone(true)
            }
          })

          oldTopics.forEach((topic) => {
            notificationSocket.unsubscribe_from_topic(topic.toString())
          })
        }
        return notificationSettings
      })

      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notificationOptionArray.includes(notification.idTopic))
      )
    },
    [handleNotification]
  )

  useEffect(() => {
    WebSocketManagerInstace.getSocketService('notification', (connected: boolean) => {
      setIsConnected(connected)
      if (!connected) {
        setShowSnackbar(true)
        setNotificationSettings(null)
        setNotifications([])
      }
    })

    return () => {
      WebSocketManagerInstace.removeSocketService('notification')
    }
  }, [handleNotification])

  useEffect(() => {
    if (isConnected) {
      const notificationSocket = WebSocketManagerInstace.getSocketService('notification')
      if (!notificationSocket) {
        return
      }
      notificationSocket.sendEvent('get_usertopics', handleSubscriptionChange, false)
    }
  }, [isConnected, handleNotification, handleSubscriptionChange])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMarkAsRead = (idNotification: number) => {
    const notificationSocket = WebSocketManagerInstace.getSocketService('notification')
    if (!notificationSocket) {
      return
    }
    notificationSocket.sendMessage('mark_notification_as_read', idNotification, () => {})
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.idNotification === idNotification ? { ...notification, isRead: true } : notification
      )
    )
  }

  const handleNotificationClick = (notification: INotification) => {
    if (!notification.isRead) {
      handleMarkAsRead(notification.idNotification)
    }
    if (notification.Action === INotifcationAction.GOTO) {
      window.open(`/${notification.ReferenceType}/${notification.idReference}`, '_blank')
    }
  }

  const renderNotificationContent = (content: INotificationContentItem[]) => {
    return content.map((item, index) => {
      if (item.type === 'text') {
        return <Typography key={index}>{item.value}</Typography>
      } else if (item.type === 'list') {
        return <ul key={index}>{item.items?.map((listItem, idx) => <li key={idx}>{listItem}</li>)}</ul>
      } else if (item.type === 'linebreak') {
        return <br key={index} />
      } else {
        return null
      }
    })
  }

  const unreadCount = notifications.filter((notification) => !notification.isRead).length

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const handleDeleteNotification = (idNotification: number) => {
    const notificationSocket = WebSocketManagerInstace.getSocketService('notification')
    if (!notificationSocket) {
      return
    }
    notificationSocket.sendMessage('delete_notification', idNotification, () => {})
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.idNotification !== idNotification)
    )
  }

  useEffect(() => {
    if (notifications.filter((notification) => !notification.isRead).length === 0 && showDialog) {
      setShowDialog(false)
    }
  }, [notifications, showDialog])

  if (!isConnected) {
    return (
      <Box>
        <IconButton color='inherit' onClick={handleMenuOpen}>
          <Badge
            badgeContent={'!'}
            color='warning'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <WifiOff />
          </Badge>
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <List>
            <ListItem>
              <ListItemText primary='Verbindung wird hergestellt...' />
            </ListItem>
          </List>
        </Menu>
        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
          <Alert onClose={() => setShowSnackbar(false)} severity='error'>
            Der Benachrichtigungsdienst hat keine Verbindung zum Server.
          </Alert>
        </Snackbar>
      </Box>
    )
  } else {
    return (
      <Box>
        <IconButton color='inherit' onClick={handleMenuOpen}>
          <Badge badgeContent={unreadCount} color='secondary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            maxHeight: window.innerHeight * 0.8,
            maxWidth: window.innerWidth / 2
          }}
          slotProps={{
            paper: {
              style: {
                maxHeight: window.innerHeight * 0.6,
                maxWidth: window.innerWidth * 0.4,
                overflowY: 'auto',
                overflowX: 'auto',
                display: 'block'
              }
            }
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p={2}
            border={1}
            borderColor='grey.300'
            bgcolor='grey.100'
          >
            <Typography variant='subtitle1'>Nur ungelesene Benachrichtigungen anzeigen?</Typography>

            <Box display='flex' alignItems='center'>
              <Switch
                checked={showOnlyUnread}
                onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                name='showAtLogin'
                color='primary'
              />
              <Typography variant='body2' mr={1}>
                {showOnlyUnread ? 'Ja' : 'Nein'}
              </Typography>
            </Box>
          </Box>
          <List sx={{ minWidth: '500px' }}>
            {notifications.length > 0 &&
              (showOnlyUnread ? notifications.filter((notification) => !notification.isRead) : notifications).map(
                (notification, index) => (
                  <React.Fragment key={notification.idNotification}>
                    <ListItem
                      style={{
                        backgroundColor: notification.isRead ? 'inherit' : '#e3f2fd'
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <NotificationsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Box onClick={() => handleNotificationClick(notification)}>{notification.Title}</Box>}
                        secondary={
                          <>
                            <Box onClick={() => handleNotificationClick(notification)}>
                              {renderNotificationContent(notification.Content)}
                            </Box>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                              <Typography variant='caption' color='textSecondary'>
                                {new Date(notification.Created_at).toLocaleString()}
                              </Typography>
                              <IconButton onClick={() => handleDeleteNotification(notification.idNotification)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                    {index < notifications.length - 1 && <Divider variant='inset' component='li' light />}
                  </React.Fragment>
                )
              )}
            {notifications.length === 0 && (
              <ListItem>
                <ListItemText primary='Keine Benachrichtigungen vorhanden' />
              </ListItem>
            )}
          </List>
        </Menu>

        <Dialog
          open={showDialog && notifications.filter((notification) => !notification.isRead).length > 0}
          onClose={handleCloseDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Sie haben folgende ungelesene Benachrichtigungen:'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <List>
                {notifications
                  .filter((notification) => !notification.isRead)
                  .map((notification, index) => (
                    <React.Fragment key={notification.idNotification}>
                      <ListItem
                        onClick={() => handleNotificationClick(notification)}
                        style={{
                          backgroundColor: '#e3f2fd',
                          borderRadius: '8px',
                          marginBottom: '10px'
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <NotificationsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={notification.Title}
                          secondary={
                            <>
                              {renderNotificationContent(notification.Content)}
                              <Typography variant='caption' color='textSecondary'>
                                {new Date(notification.Created_at).toLocaleString()}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      {index < notifications.length - 1 && <Divider variant='inset' component='li' light />}
                    </React.Fragment>
                  ))}
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              Schließen
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
          <Alert onClose={() => setShowSnackbar(false)} severity='error'>
            Der Benachrichtigungsdienst hat keine Verbindung zum Server.
          </Alert>
        </Snackbar>
      </Box>
    )
  }
}

export default NotificationComponent
