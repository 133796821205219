import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { IRequiredFiles } from "../Components/InheritDocument";
import { InheritPerson, getInheritPerson } from "../Components/InheritPerson";
import { IInheritHasPerson } from "../../../../Interfaces/IPerson";
import { IChildren } from "../../../../Interfaces/IChildren";
import { IPersonHasDocument } from "../../../../Interfaces/IPersonHasDocument";
import { IInherit } from "../../../../Interfaces/IInherit";
import DeleteIcon from "@mui/icons-material/Delete";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const divStyle = {
    marginTop: "0px",
    marginBottom: "0px",
  };

  return (
    <div
      role="tabpanel"
      style={divStyle}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, mt: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IProps {
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

const foundParent = (
  isFirstParent: boolean,
  inherit_idPerson: number,
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[]
) => {
  let inherit = getInheritPerson(inherit_idPerson, personArray);
  let foundChildren = childrenArray.find(
    (x) => x.Children_idPerson === inherit.idPerson
  );

  if (foundChildren === undefined) {
    return getInheritPerson(-1, personArray, true);
  } else {
    let idPerson = isFirstParent
      ? foundChildren.Parent1_idPerson
      : Number(foundChildren.Parent2_idPerson);
    return getInheritPerson(idPerson, personArray, false);
  }
};

/**
 * Function if Parents exists
 * @param props
 */
export const Parents: React.FC<IProps> = (props) => {
  const [value, setValue] = useState(0);
  const [firstParent, setFirstParent] = useState(
    foundParent(
      true,
      props.inheritObject.idPerson,
      props.personArray,
      props.childrenArray
    )
  );
  const [secondParent, setSecondParent] = useState(
    foundParent(
      false,
      props.inheritObject.idPerson,
      [...props.personArray, firstParent],
      props.childrenArray
    )
  );
  const [documentArray, setDocumentArray] = useState<IPersonHasDocument[]>([]);
  const [isDeathFirstParent, setIsDeathFirstParent] = useState(
    firstParent.DateOfDeath !== null
  );
  const [isDeathSecondParent, setIsDeathSecondParent] = useState(
    secondParent.DateOfDeath !== null
  );
  const formRef = useRef<HTMLFormElement>(null);
  const [haveSibling, setHaveSibling] = useState<IChildren>();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccesAlert, setShowSuccesAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Dialog Functions
   */
  const handleSave = () => {
    let personArray = [...props.personArray];
    personArray = personArray.map((x) =>
      x.idPerson === firstParent.idPerson ? firstParent : x
    );
    personArray = personArray.map((x) =>
      x.idPerson === secondParent.idPerson ? secondParent : x
    );
    props.setPersonHasDocument([
      ...props.personHasDocument.filter(
        (x) =>
          x.idPerson !== firstParent.idPerson &&
          x.idPerson !== secondParent.idPerson
      ),
      ...documentArray,
    ]);
    props.setPersonArray([...personArray]);
    setShowSuccesAlert(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (formRef.current && formRef.current.checkValidity()) {
      setValue(newValue);
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const handleStop = () => {
    const updateFirstParent = foundParent(
      true,
      props.inheritObject.idPerson,
      props.personArray,
      props.childrenArray
    );
    const updateSecondParent = foundParent(
      false,
      props.inheritObject.idPerson,
      [...props.personArray, firstParent],
      props.childrenArray
    );
    setFirstParent(updateFirstParent);
    setSecondParent(updateSecondParent);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const handleRemoveRecursive = (
    idParentOne: number,
    idParentTwo: number,
    personArray: IInheritHasPerson[],
    childrenArray: IChildren[],
    personHasDocument: IPersonHasDocument[]
  ): [IInheritHasPerson[], IChildren[], IPersonHasDocument[]] => {
    if (haveSibling) {
      setShowErrorAlert(true);
      return [personArray, childrenArray, personHasDocument];
    } else {
      personHasDocument = [
        ...personHasDocument.filter(
          (x) => x.idPerson !== idParentOne && x.idPerson !== idParentTwo
        ),
      ];
      personArray = [
        ...personArray.filter(
          (x) => x.idPerson !== idParentOne && x.idPerson !== idParentTwo
        ),
      ];
      childrenArray = [
        ...childrenArray.filter(
          (x) =>
            x.Parent1_idPerson !== idParentOne &&
            x.Parent2_idPerson !== idParentTwo
        ),
      ];

      /*childrenArray
      .filter((x) => x.Parent1_idPerson === idPerson && x.Children_idPerson !== personArray[0].idPerson)
      .map((x) => {
        let [localPersonArray, localChildrenArray, localPersonHasDocument] =
          handleRemoveRecursive(
            x.Children_idPerson,
            personArray,
            childrenArray,
            personHasDocument
          );
        personArray = [...localPersonArray];
        childrenArray = [...localChildrenArray];
        personHasDocument = [...localPersonHasDocument];
      });*/

      return [personArray, childrenArray, personHasDocument];
    }
  };

  const handleOnDelete = () => {
    let personArray = [...props.personArray];
    let childrenArray = [...props.childrenArray];
    let personHasDocument = [...props.personHasDocument];

    let [resPersonArray, resChildrenArray, resPersonHasDocument] =
      handleRemoveRecursive(
        firstParent.idPerson,
        secondParent.idPerson,
        personArray,
        childrenArray,
        personHasDocument
      );

    props.setPersonHasDocument([...resPersonHasDocument]);

    props.setPersonArray([...resPersonArray]);

    props.setChildrenArray([...resChildrenArray]);
  };

  const deepEqual = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    const updateFirstParent = foundParent(
      true,
      props.inheritObject.idPerson,
      props.personArray,
      props.childrenArray
    );
    const updateSecondParent = foundParent(
      false,
      props.inheritObject.idPerson,
      [...props.personArray, firstParent],
      props.childrenArray
    );

    if (
      deepEqual(firstParent, updateFirstParent) &&
      deepEqual(secondParent, updateSecondParent)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [firstParent, props.personArray, secondParent]);

  /**
   * update Function
   */
  useEffect(() => {
    const updateFirstParent = foundParent(
      true,
      props.inheritObject.idPerson,
      props.personArray,
      props.childrenArray
    );
    const updateSecondParent = foundParent(
      false,
      props.inheritObject.idPerson,
      [...props.personArray, firstParent],
      props.childrenArray
    );
    const getParents = props.childrenArray.find(
      (children) => children.Children_idPerson === props.personArray[0].idPerson
    );
    const firstParent2 = getParents?.Parent1_idPerson;
    const secondParent = getParents?.Parent2_idPerson;
    const haveSiblings = props.childrenArray.find(
      (children) =>
        (children.Parent1_idPerson === firstParent2 ||
          children.Parent1_idPerson === secondParent) &&
        children.Children_idPerson !== props.personArray[0].idPerson
    );
    setHaveSibling(haveSiblings);
    setFirstParent(updateFirstParent);
    setSecondParent(updateSecondParent);
  }, [props.personArray, props.inheritObject.idPerson, props.childrenArray]);

  useEffect(() => {
    if (showSuccesAlert) {
      const timer = setTimeout(() => {
        setShowSuccesAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccesAlert]);

  return (
    <>
      <form ref={formRef}>
        {showErrorAlert && (
          <Alert severity="error" onClose={() => setShowErrorAlert(false)}>
            Bitte entfernen Sie zuerst alle Geschwister des Erblassers bevor Sie
            die Eltern löschen!
          </Alert>
        )}
        <Toolbar sx={{ justifyContent: "space-between", marginTop: "10px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Erstes Elternteil" {...a11yProps(0)} />
            <Tab label="Zweites Elternteil" {...a11yProps(1)} />
          </Tabs>

          <IconButton onClick={handleOnDelete}>
            <DeleteIcon />
          </IconButton>
        </Toolbar>

        <CustomTabPanel value={value} index={0}>
          <InheritPerson
            personArray={props.personArray}
            key="firstParent-1"
            currentPerson={firstParent}
            setCurrentPerson={setFirstParent}
            inheritObject={props.inheritObject}
            personHasDocument={documentArray}
            setPersonHasDocument={setDocumentArray}
            title="Erstes Elternteil"
            showDeathSettings
            requiredFiles={
              isDeathFirstParent
                ? ([
                    {
                      Title: "Sterbeurkunde",
                      isOptional: false,
                      idPersonDocumentType: 1,
                    },
                  ] as IRequiredFiles[])
                : []
            }
            setIsDeath={setIsDeathFirstParent}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <InheritPerson
            personArray={props.personArray}
            key="firstParent-2"
            currentPerson={secondParent}
            setCurrentPerson={setSecondParent}
            inheritObject={props.inheritObject}
            personHasDocument={documentArray}
            setPersonHasDocument={setDocumentArray}
            title="Zweites Elternteil"
            showDeathSettings
            requiredFiles={
              isDeathSecondParent
                ? ([
                    {
                      Title: "Sterbeurkunde",
                      isOptional: false,
                      idPersonDocumentType: 1,
                    },
                  ] as IRequiredFiles[])
                : []
            }
            setIsDeath={setIsDeathSecondParent}
          />
        </CustomTabPanel>
        {showSuccesAlert && (
          <Alert
            severity="success"
            onClose={() => {
              setShowSuccesAlert(false);
            }}
          >
            Erfolgreich gespeichert!
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            disabled={isDisabled}
            variant="contained"
            onClick={handleStop}
          >
            Abbrechen
          </Button>
          <Button
            disabled={isDisabled}
            variant="contained"
            onClick={handleOnSave}
          >
            Speichern
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
