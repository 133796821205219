import React, { useState } from 'react'
import { IUserGroup, IUserGroupFullObject } from '../../Interfaces/IUserGroup'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { CustomCircularProgress } from '../../generic/CustomCircularProgress'
import { deleteFetch, getFetch, uploadFetch, useFetch } from '../../hooks/useFetch'
import { ILegalTransaction } from '../../Interfaces/ILegalTransaction'
import { UserReassign } from '../../User/UserReassign'
import { IUser } from '../../Interfaces/IUser'
import { PopupAlert } from '../../core/PopupAlert'

interface IProps {
  userGroupFullObject: IUserGroupFullObject
  idUserGroup: number
  setIdUserGroup: Function
  userGroupArray: IUserGroup[]
  setUserGroupArray: Function
}

export const UserGroupDelete: React.FC<IProps> = ({
  userGroupFullObject,
  idUserGroup,
  setIdUserGroup,
  userGroupArray,
  setUserGroupArray
}) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  //
  const [isLoading, setIsLoading] = useState(false)
  const [wasRemoved, setWasRemoved] = useState(false)
  const [wasSuccessfullyRemoved, setWasSuccessfullyRemoved] = useState(false)

  const [legalTransactionArray, setLegalTransactionArray] = useState<ILegalTransaction[]>([])
  const [showUserReassignDialog, setShowUserReassignDialog] = useState(false)
  const [showAlertUserReassign, setShowAlertUserReassign] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [showPreventUserRoleChange, setShowPreventUserRoleChange] = useState(false)
  const [roleChange, setRoleChange] = useState(false)
  const [userArray, setUserArray, wasSuccessfullyUserArray] = useFetch<IUser[]>('/user')
  const [uploadledLegalTransactions, setUploadledLegalTransactions] = useState<number>(0)

  const handleOpen = () => {
    setIsLoading(false)
    setWasRemoved(false)
    setWasSuccessfullyRemoved(true)
    setIsOpenConfirm(true)
  }

  const handleAfterUpload = (returnBackend: boolean) => {
    setIsLoading(false)
    setWasRemoved(true)
    setWasSuccessfullyRemoved(returnBackend)

    if (returnBackend) {
      setUserGroupArray([...userGroupArray.filter((x) => x.idUserGroup !== userGroupFullObject.idUserGroup)])
      setIdUserGroup(null)
    }
  }

  const handleRemove = () => {
    setIsLoading(true)
    deleteFetch('/usergroup/', userGroupFullObject.idUserGroup, handleAfterUpload)
  }

  const handleUserChange = (isAborted: boolean) => {
    if (isAborted) {
      //wenn UserChangeDialog abgebrochen wurde, breche Speichern ab und zeige Fehlermeldung
      setAlertText('Bitte weisen Sie vor dem Speichern alle Vorgänge einem anderen Nutzer zu.')
      setShowAlertUserReassign(true)
      setShowUserReassignDialog(false)
    } else {
      //wenn UserChangeDialog gespeichert wurde, speichere Vorgänge
      setShowAlertUserReassign(false)
      setShowUserReassignDialog(false)
      handleSaveLegalTransaction()
    }
  }

  const handleSaveLegalTransaction = () => {
    if (legalTransactionArray.length > 0) {
      legalTransactionArray.forEach((legalTransaction) => {
        if (legalTransaction.Employee_idUser === -1) {
          legalTransaction.Employee_idUser = null
        }
        if (legalTransaction.idUserGroup === -1) {
          legalTransaction.idUserGroup = null
        }
        //remove the usergroup key from the object
        console.log(legalTransaction)
        uploadFetch(
          '/legaltransaction',
          false,
          legalTransaction,
          () => {},
          handleSavedLegalTransaction, //notwendig, da mehrere Vorgänge gespeichert werden müssen und handleSave erst nachdem alle Vorgänge erfolgreich gespeichert wurden aufgerufen werden darf
          () => {},
          () => {}
        )
      })
    }
  }

  const handleSavedLegalTransaction = (uploaded: boolean) => {
    if (!uploaded) {
      //Wenn Vorgang nicht gespeichert werden konnte, zeige Fehlermeldung
      setAlertText('Fehler beim Speichern der übergebenen Vorgänge.')
      setShowAlertUserReassign(true)
    } else {
      //Wenn Vorgang gespeichert wurde, erhöhe Zähler der gespeicherten Vorgänge
      setUploadledLegalTransactions((uploadledLegalTransactions) => {
        const newUploadedLegalTransactions = uploadledLegalTransactions + 1
        checkIfAllTransactionsSaved(newUploadedLegalTransactions) //da state nicht sofort aktualisiert wird, braucht es eine Zwischenfunktion
        return newUploadedLegalTransactions
      })
    }
  }

  const checkIfAllTransactionsSaved = (count: number) => {
    if (count === legalTransactionArray.length) {
      handleRemove()
    }
  }

  const initateUserChange = () => {
    //prüfe, ob Vorgänge bereits geladen wurden
    if (legalTransactionArray.length === 0) {
      getFetch<ILegalTransaction[]>(
        `/legaltransaction/getByUserGroup/`,
        idUserGroup,
        (legaltransactions: ILegalTransaction[]) => {
          if (legaltransactions.length === 0) {
            //wenn Nutzer keine Vorgänge hat, speichere direkt
            handleRemove()
          } else {
            //wenn Nutzer Vorgänge hat, zeige UserChangeDialog
            setLegalTransactionArray(legaltransactions)
            setShowUserReassignDialog(true)
          }
        },
        (successfull: boolean) => {
          if (!successfull) {
            //Wenn Fehler beim Laden der Vorgänge, zeige Fehlermeldung
            setAlertText('Fehler beim Laden der dem Nutzer zugeordneten Vorgänge.')
            setShowAlertUserReassign(true)
          }
        }
      )
    } else {
      setShowUserReassignDialog(true)
    }
  }
  if (!wasSuccessfullyUserArray) {
    return <Alert severity='error'>Fehler!</Alert>
  }
  if (userArray === undefined) {
    return <CustomCircularProgress />
  }
  return (
    <>
      <PopupAlert
        show={showPreventUserRoleChange}
        setShow={setShowPreventUserRoleChange}
        severity='error'
        text={alertText}
      />
      <Dialog open={showUserReassignDialog} fullWidth maxWidth='md'>
        <DialogTitle>
          Der Benutzergruppe <i>{userGroupFullObject.UserGroup}</i> sind noch Vorgänge zugeordnet. Bitte weisen Sie
          diese einem anderen Benutzer zu.
        </DialogTitle>
        <UserReassign
          userId={idUserGroup}
          setLegalTransactionArray={setLegalTransactionArray}
          usergroupArray={[...userGroupArray]
            .filter((userGroup) => userGroup.idUserGroup !== idUserGroup)
            .sort((a, b) => a.idUserGroup - b.idUserGroup)}
          userArray={userArray}
          legalTransactionArray={legalTransactionArray}
          isNotary={false}
        />
        <DialogActions>
          <Button onClick={() => handleUserChange(true)} color='primary'>
            Abbrechen
          </Button>
          <Button onClick={() => handleUserChange(false)} color='primary'>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenConfirm} onClose={() => {}}>
        {isLoading ? (
          <DialogContent>
            <CustomCircularProgress />
          </DialogContent>
        ) : !wasRemoved ? (
          <>
            <DialogContent>
              <Alert severity='warning'>
                Sind Sie sicher, dass Sie die Benutzergruppe <i>{userGroupFullObject.UserGroup}</i> löschen wollen?
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' onClick={initateUserChange}>
                Löschen
              </Button>
              <Button variant='contained' onClick={() => setIsOpenConfirm(false)}>
                Abbruch
              </Button>
            </DialogActions>
          </>
        ) : wasSuccessfullyRemoved ? (
          <>
            <DialogContent>
              <Alert severity='success'>
                Die Benutzergruppe <i>{userGroupFullObject.UserGroup}</i> wurde erfolgreich gelöscht!
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined'>Löschen</Button>
              <Button variant='contained' onClick={() => setIsOpenConfirm(false)}>
                Okay
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <Alert severity='error'>
                Die Benutzergruppe <i>{userGroupFullObject.UserGroup}</i> konnte nicht gelöscht!
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={() => setIsOpenConfirm(false)}>
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <IconButton sx={{ float: 'right' }} onClick={handleOpen}>
        <Delete />
      </IconButton>
    </>
  )
}
