import { Grid, Typography } from "@mui/material";
import React from "react";
import { TextModuleCategoryOverview } from "./TextModuleCategory/TextModuleCategoryOverview";
import { TextModuleOverview } from "./TextModuleOverview";


export const TextModulSectionMain:React.FC = () => {
    return(
        <>
            <Typography variant="h4">
                Textbaustein(e) & -kategorien
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextModuleCategoryOverview/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextModuleOverview/>
                </Grid>
            </Grid>
        </>
    )
} 