import { Add, Check, Clear } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams, deDE } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/mainReducer";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { ITextModuleCategory } from "../../Interfaces/ITextModuleCategory";
import { TextModuleCategoryEdit } from "./TextModuleCategoryEdit";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreatorsTextModuleCategory } from "../../redux/actionCreators";
import { uploadFetch } from "../../hooks/useFetch";

const columns: GridColDef[] = [
    { field: 'idTextModuleCategory', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'isDeleteable', 
      headerName: 'Editierbar', 
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.isDeleteable) === true)
        ? <Check/>
        : <Clear/>
    },
    { field: 'IntoText', 
      headerName: 'Infotext', 
      renderCell: (params: GridRenderCellParams) =>
        (params.row.InfoText === null)
        ? <Check/>
        : <Clear/>
    },
    { field: 'TextModuleCategory', 
      headerName: 'Bezeichnung', 
      flex: 1
    },
]



const getTextModuleCategory = (idTextModuleCategory:number|null, textModuleCategoryArray: ITextModuleCategory[]) => {
    let currentObject = textModuleCategoryArray.find(x => x.idTextModuleCategory === Number(idTextModuleCategory));

    if (currentObject !== undefined) {
        return currentObject;
    }
    else {
        let tmpId = -1;

        if (textModuleCategoryArray.length > 0) {
            let tmpIdArray = Math.min(...textModuleCategoryArray.map(x => x.idTextModuleCategory)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray -1;
            }
        }

        return {
            idTextModuleCategory: tmpId,
            InfoText: "",
            isDeleteable: true,
            TextModuleCategory: ""
        } as ITextModuleCategory
    }
}



export const TextModuleCategoryOverview:React.FC = () => {
    const dispatch = useDispatch();
    const textModuleCategoryArray = useSelector((state: State) => state.textModuleCategoryArray.storeTextModuleCategory);
    const { addUpdateTextModuleCategoryArray } = bindActionCreators(actionCreatorsTextModuleCategory, dispatch);
    //
    const [editTextModule,setEditTextModule] = useState(getTextModuleCategory(null,textModuleCategoryArray));
    const [isOpen,setIsOpen] = useState(false);


    const handleNew = () => {
        setEditTextModule(getTextModuleCategory(null,textModuleCategoryArray));
        setIsOpen(true);
    }
    
    const handleEdit = (localIdTextModule:number|string) => {
        setEditTextModule(getTextModuleCategory(Number(localIdTextModule),textModuleCategoryArray));
        setIsOpen(true);
    }



    const handleAfterSave = (savedObject: ITextModuleCategory) => {
        addUpdateTextModuleCategoryArray(savedObject);
        setIsOpen(false)
    }

    const handleSave = () => {
        uploadFetch("/textmodulecategory",(editTextModule.idTextModuleCategory < 0),editTextModule,handleAfterSave)
    }


    return(
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>
                    Textbausteinkategorie
                    {/*(editTextModule.idTextModule > 0) && <IconButton sx={{float: "right"}} onClick={() => setIsOpenDelete(true)}><Delete/></IconButton>*/}
                </DialogTitle>
                <DialogContent>
                    
                    <TextModuleCategoryEdit
                        textModuleCategory={editTextModule}
                        setTextModuleCategory={setEditTextModule}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave} disabled={editTextModule.TextModuleCategory === "" || !editTextModule.isDeleteable}>Übernehmen</Button>
                </DialogActions>
            </Dialog>



            <Typography variant="h5" sx={{mt: 5}}>
                Textbausteinkategorie
                <IconButton sx={{float: "right"}} onClick={handleNew}><Add /></IconButton>
            </Typography>
            <Box sx={{mt: 2}}/>
            <DataGrid
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={textModuleCategoryArray}
                columns={columns}
                getRowId={(row) => row.idTextModuleCategory}
                onRowClick={(params,event,details) => handleEdit(params.id)}
                autoHeight
            />
        </>
    )
}