import React, { useEffect, useState } from "react";
import { IContact, IContactFullObject, ILegalTransactionHasContact } from "../Interfaces/IContact";
import { Alert, AlertTitle, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel, deDE } from "@mui/x-data-grid";
import { getEmptyCompany, getEmptyContact } from "../Clients/ClientsEdit";
import { ClientBaseData } from "../Clients/ClientBaseData";
import { ILegalRole } from "../Interfaces/ILegalRole";
import { getIsEmail } from "../core/generic_helper";
import { ICompany } from "../Interfaces/ICompany";
import { ClientEmailWarning } from "../Clients/ClientEmailWarning";


interface IProps {
    allContactArray: IContact[];
    legalRoleArray: ILegalRole[];
    contactArray: ILegalTransactionHasContact[];
    setContactArray: Function;
    newContactArray: ILegalTransactionHasContact[];
    setNewContactArray: Function;
}

const columns: GridColDef[] = [
    { field: 'idContact', headerName: 'Nr.', width: 70 },
    { field: 'FirstName', headerName: 'Vorname', width: 130 },
    { field: 'LastName', headerName: 'Nachname', width: 130 },
    { field: 'Email', headerName: 'E-Mail', width: 250 },
    
];

const getEmptyContactChangedId = (contactArray:IContactFullObject[]) => {
    let tmpContact = getEmptyContact();
    let tmpId = -1;
    if (contactArray.length > 0) {
        let arrayTmpId = Math.min(...contactArray.map(x => x.idContact));
        if (arrayTmpId <= tmpId) {
            tmpId = arrayTmpId - 1;
        }
    }
    tmpContact.idContact = tmpId;
    return tmpContact;
}

export const LegalTransactionAddClient:React.FC<IProps> = (props) => {
    const [isOpenAddNew, setIsOpenAddNew] = useState(false);
    const [isOpenNewContact, setIsOpenNewContact] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    //
    const [newContact, setNewContact] = useState(getEmptyContactChangedId(props.newContactArray));
    const [newCompany, setNewCompany] = useState(getEmptyCompany());
    //
    const [defaultIdLegalRole, setDefaultIdLegalRole ] = useState<number|null>(null);
    //const [newContactArray,setNewContactArray] = useState<IContactFullObject[]>([])
    //
    const [hasDuplicateEmailError,setHasDuplicateEmailError] = useState(false);

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([
        ...props.contactArray.map(x => x.idContact),
        ...props.newContactArray.map(x => x.idContact)
    ]);

    useEffect(() => {
        if (props.legalRoleArray.length < 1) {
            setDefaultIdLegalRole(null);
        } else {
            setDefaultIdLegalRole(props.legalRoleArray[0].idLegalRole)
        }
    },[props.legalRoleArray])

    const handleOpen = () => {
        setRowSelectionModel([
            ...props.contactArray.map(x => x.idContact),
            ...props.newContactArray.map(x => x.idContact)
        ])
        setIsOpenAddNew(true);
    }

    const handleClose = () => {
        setIsOpenAddNew(false);
    }

    const handleAdd = () => {
        let filteredArray1 = props.allContactArray.filter(x => rowSelectionModel.indexOf(Number(x.idContact)) >= 0);
        let filteredArray2 = props.newContactArray.filter(x => x.idContact < 0).filter(x => rowSelectionModel.indexOf(Number(x.idContact)) >= 0);
        
        let tmpNewArray:ILegalTransactionHasContact[] = [];
        
        rowSelectionModel
         .filter(x => props.contactArray.map(y => y.idContact).indexOf(Number(x)) < 0)
         .map(x => {
            if (props.newContactArray.map(y => y.idContact).indexOf(Number(x)) > -1) {
                tmpNewArray.push( props.newContactArray.find(y => y.idContact === Number(x))! );
            }
            else if (props.allContactArray.map(y => y.idContact).indexOf(Number(x)) > -1) {
                let newContact = props.allContactArray.find(y => y.idContact === Number(x));

                if (newContact !== undefined) {
                    tmpNewArray.push({
                        ...newContact,
                        idLegalRole: defaultIdLegalRole,
                        CompanyObject: getEmptyCompany(),
                        LegalTransactionArray: []
                    })
                }
            }
        })
        props.setNewContactArray([...tmpNewArray]);
        /*
        props.setNewContactArray([
            ...filteredArray1
                .filter(x => props.contactArray.map(y => y.idContact).indexOf(x.idContact) === -1)
                .map(x => { 
                    if (props.contactArray.map(y => y.idContact).indexOf(x.idContact) > -1) {
                        return props.contactArray.find(y => y.idContact === x.idContact)
                    } else {
                        return {...x, idLegalRole: defaultIdLegalRole} as ILegalTransactionHasContact  
                    }                       
                }),
            ...filteredArray2
                .map(x => { 
                    if ( !("idLegalRole" in x ) || x.idLegalRole === null || props.legalRoleArray.map(y => y.idLegalRole).indexOf(x.idLegalRole) < 0 ) {
                        console.log("LEL")
                        return {...x, idLegalRole: defaultIdLegalRole} as ILegalTransactionHasContact 
                    } else {
                        return x
                    }
                })
        ])
        */
        handleClose();
    }

    const returnCurrentContactSearchArray = (forceFull:boolean = false) => {
        if (searchTerm === "" || forceFull) {
            return [...props.newContactArray.filter(x => x.idContact < 0), ...props.allContactArray];
        } else {
            return [
                ...props.newContactArray
                    .filter(x => x.idContact < 0)
                    .filter(x => 
                        x.FirstName.toLowerCase().includes(searchTerm.toLowerCase())
                        || x.LastName.toLowerCase().includes(searchTerm.toLowerCase())
                        || x.Email.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                ...props.allContactArray
                    .filter(x => 
                        x.FirstName.toLowerCase().includes(searchTerm.toLowerCase())
                        || x.LastName.toLowerCase().includes(searchTerm.toLowerCase())
                        || x.Email.toLowerCase().includes(searchTerm.toLowerCase())
                    )
            ]

        }
    }

    const handleOpenNewContact = () => {
        setHasDuplicateEmailError(false);
        setNewContact(getEmptyContactChangedId(props.newContactArray));
        setNewCompany(getEmptyCompany());
        setIsOpenNewContact(true);
        console.log("set true");
    }

    const handleAddNew = () => {
        let tmpFullObj:ILegalTransactionHasContact = {
            ...newContact,
            CompanyObject: newCompany,
            LegalTransactionArray: [],
            idLegalRole: defaultIdLegalRole
        }
        props.setNewContactArray([
            ...props.newContactArray,
            tmpFullObj
        ])
        setRowSelectionModel([
            ...rowSelectionModel,
            tmpFullObj.idContact
        ])
        setIsOpenNewContact(false);
    }
    useEffect(() => {
        if (newContact !== null && newContact?.Email !== "") {
            setHasDuplicateEmailError(
                props.allContactArray
                    // Sicher selber rausfiltern
                    .filter(x => x.idContact !== newContact!.idContact)
                    .map(x => x.Email.replace(" ","").toLocaleLowerCase())
                    .indexOf(newContact!.Email.replace(" ","").toLocaleLowerCase())
                > -1
            )
        }
    },[newContact?.Email])


    return(
        <>
            <Dialog open={isOpenNewContact} onClose={() => setIsOpenNewContact(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Neuer Mandant</DialogTitle>
                <Collapse in={hasDuplicateEmailError}>
                <Alert severity="warning">
                    <AlertTitle>Warnung: E-Mail-Adresse</AlertTitle>
                    Die ausgewählte E-Mail-Adresse existiert bereits im System und kann nicht verwendet werden!
                </Alert>
            </Collapse>

                <DialogContent>
                    <>
                        
                        <ClientEmailWarning 
                            currentContact={newContact} 
                            contactArray={[...props.allContactArray,...props.contactArray, ...props.newContactArray]} 
                            hasDuplicateEmailError={hasDuplicateEmailError}
                            setHasDuplicateEmailError={setHasDuplicateEmailError}
                        />
                        <Box sx={{mt: 2}}/>
                        <ClientBaseData
                            currentContactObject={newContact}
                            companyObject={newCompany}
                            setCompanyObject={setNewCompany}
                            setCurrentContactObject={setNewContact}
                        />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleAddNew} 
                        variant="contained"
                        disabled={newContact.FirstName === "" || newContact.LastName === "" || newContact.Email === "" || hasDuplicateEmailError}
                    >Hinzufügen</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={isOpenAddNew} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Mandanten zuordnen</DialogTitle>
                <DialogContent>


                    <Box sx={{display: "flex"}}>
                        <TextField
                            sx={{mt: 2}}
                            label="Suche"
                            value={searchTerm}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm( event.target.value)}
                            fullWidth
                        />
                        <IconButton onClick={handleOpenNewContact} sx={{float: "right", mt: 1, ml: 2}}><Add/></IconButton>
                    </Box>



                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={returnCurrentContactSearchArray()}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            
                            //
                            if (searchTerm === "") {
                                setRowSelectionModel([...newRowSelectionModel]);
                            } else {
                                let filteredOldRowSelection:GridRowSelectionModel = [];
                                let oldRowSelectionModel = [...rowSelectionModel];
                                let withFilter = [...returnCurrentContactSearchArray(false)];
                                
                                // id aus oldRowSelectionModel darf nicht in withFilter sein
                                oldRowSelectionModel.map(x => {
                                    if (withFilter.map(y => y.idContact).indexOf(Number(x)) < 0) {
                                        filteredOldRowSelection.push(Number(x))
                                    }
                                })
                                setRowSelectionModel([...filteredOldRowSelection,...newRowSelectionModel]);
                            }
                            
                          }}
                        rowSelectionModel={rowSelectionModel}
                        columns={columns}
                        getRowId={(row) => row.idContact}
                        isRowSelectable={(params: GridRowParams) => props.contactArray.map(x => x.idContact).indexOf(Number(params.row.idContact)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAdd} variant="contained">Hinzufügen</Button>
                </DialogActions>
            </Dialog>

            <IconButton onClick={handleOpen} sx={{float: "right"}}><Add/></IconButton>
        </>
    )
}
