import React, { useState } from "react";
import { ILegalPhase } from "../../Interfaces/ILegalPhase";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { ILegalRequirement } from "../../Interfaces/ILegalRequirement";

interface IProps {
    currentObject: ILegalPhase | ILegalRequirement;
}

export const LegalInfoText:React.FC<IProps> = (props) => {
    const [isOpen,setIsOpen] = useState(false);

    return(
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>Infotext</DialogTitle>
                <DialogContent>
                    {props.currentObject.InfoText}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>Schließen</Button>
                </DialogActions>
            </Dialog>

            {(props.currentObject.InfoText !== null && props.currentObject.InfoText !== "") &&
                <IconButton title="Infotext zur Phase" size="small" onClick={() => setIsOpen(true)}><HelpOutline/></IconButton>
            }
        </>
    )
}