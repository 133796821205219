import { Edge, Node } from "reactflow";
import { IChildren } from "../Interfaces/IChildren";
import { IMarriage } from "../Interfaces/IMarriage";
import { IInheritHasPerson, IPerson } from "../Interfaces/IPerson";

export enum FamilyTreePersonNodeType {
  TESTATOR_NODE = "TESTATOR_NODE",
  PERSON_NODE = "EDITABLE_NODE",
  MARRIAGE_NODE = "MARRIAGE_NODE",
  CHILD_NODE = "CHILD_NODE",
  PARENT_NODE = "PARENT_NODE",
  GESCHWISTER_NODE = "GESCHWISTER_NODE",
  ENKEL_NODE = "ENKEL_NODE",
  GESCHWISTERKIND_NODE = "GESCHWISTERKIND_NODE",
  URENKEL_NODE = "URENKEL_NODE",
  URURENKEL_NODE = "URURENKEL_NODE",
  GROßNEFFE_NODE = "GROßNEFFE_NODE",
  URGROßNEFFE_NODE = "URGROßNEFFE_NODE",
}

export enum FamilyTreeEdgeType {
  Marriage_EDGE = "MARRIAGE_EDGE",
}

export interface FamilyTreePersonNode {
  person: IPerson | IInheritHasPerson;
  allPersons: (IPerson | IInheritHasPerson)[];
}

export const FamilyTreeService = {
  calculateNodeType(
    personArray: IInheritHasPerson[],
    person: IInheritHasPerson,
    childrenArray: IChildren[],
    marriageArray: IMarriage[]
  ) {
    // bestimmen der ErblasserNode
    if (person.idPerson === personArray[0].idPerson) {
      return FamilyTreePersonNodeType.TESTATOR_NODE;
    }

    // bestimmen der MarriageNodes
    if (marriageArray) {
      for (const marriages of marriageArray) {
        if (person.idPerson === marriages.Person2_idPerson) {
          return FamilyTreePersonNodeType.MARRIAGE_NODE;
        }
      }
    }

    // bestimmen der ElternNodes
    if (childrenArray) {
      for (const parents of childrenArray) {
        if (
          (person.idPerson === parents.Parent1_idPerson &&
            parents.Children_idPerson === personArray[0].idPerson) ||
          (person.idPerson === parents.Parent2_idPerson &&
            parents.Children_idPerson === personArray[0].idPerson)
        ) {
          return FamilyTreePersonNodeType.PARENT_NODE;
        }
      }
    }

    // bestimmen der GeschwisterNodes
    if (childrenArray) {
      for (const parents of childrenArray) {
        if (parents.Children_idPerson === personArray[0].idPerson) {
          for (const geschwister of childrenArray) {
            if (
              person.idPerson === geschwister.Children_idPerson &&
              (geschwister.Parent1_idPerson === parents.Parent1_idPerson ||
                geschwister.Parent1_idPerson === parents.Parent2_idPerson ||
                geschwister.Parent2_idPerson === parents.Parent1_idPerson ||
                geschwister.Parent2_idPerson === parents.Parent2_idPerson)
            ) {
              return FamilyTreePersonNodeType.GESCHWISTER_NODE;
            }
          }
        }
      }
    }

    // bestimmen der KinderNodes
    if (childrenArray) {
      for (const childs of childrenArray) {
        if (
          person.idPerson === childs.Children_idPerson &&
          (childs.Parent1_idPerson === personArray[0].idPerson ||
            childs.Parent2_idPerson === personArray[0].idPerson)
        ) {
          return FamilyTreePersonNodeType.CHILD_NODE;
        }
      }
    }

    // bestimmen der EnkelKinder
    if (childrenArray) {
      for (const child of childrenArray) {
        if (
          child.Parent1_idPerson === personArray[0].idPerson ||
          child.Parent2_idPerson === personArray[0].idPerson
        ) {
          for (const grandchild of childrenArray) {
            if (
              person.idPerson === grandchild.Children_idPerson &&
              grandchild.Parent1_idPerson === child.Children_idPerson
            ) {
              return FamilyTreePersonNodeType.ENKEL_NODE;
            }
          }
        }
      }
    }

    // bestimmen der UrenkelKinder
    if (childrenArray) {
      for (const child of childrenArray) {
        if (
          child.Parent1_idPerson === personArray[0].idPerson ||
          child.Parent2_idPerson === personArray[0].idPerson
        ) {
          for (const grandchild of childrenArray) {
            if (grandchild.Parent1_idPerson === child.Children_idPerson) {
              for (const greatGrandChild of childrenArray) {
                if (
                  person.idPerson === greatGrandChild.Children_idPerson &&
                  grandchild.Children_idPerson ===
                    greatGrandChild.Parent1_idPerson
                ) {
                  return FamilyTreePersonNodeType.URENKEL_NODE;
                }
              }
            }
          }
        }
      }
    }

    // bestimmen der UrurenkelKinder
    if (childrenArray) {
      for (const child of childrenArray) {
        if (
          child.Parent1_idPerson === personArray[0].idPerson ||
          child.Parent2_idPerson === personArray[0].idPerson
        ) {
          for (const grandchild of childrenArray) {
            if (grandchild.Parent1_idPerson === child.Children_idPerson) {
              for (const greatGrandChild of childrenArray) {
                if (
                  grandchild.Children_idPerson ===
                  greatGrandChild.Parent1_idPerson
                ) {
                  for (const greatGreatGrandChild of childrenArray) {
                    if (
                      person.idPerson ===
                        greatGreatGrandChild.Children_idPerson &&
                      greatGrandChild.Children_idPerson ===
                        greatGreatGrandChild.Parent1_idPerson
                    ) {
                      return FamilyTreePersonNodeType.URURENKEL_NODE;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // bestimmen der GeschwisterKinderNodes
    if (childrenArray) {
      for (const parents of childrenArray) {
        if (parents.Children_idPerson === personArray[0].idPerson) {
          for (const geschwister of childrenArray) {
            if (
              geschwister.Parent1_idPerson === parents.Parent1_idPerson ||
              geschwister.Parent1_idPerson === parents.Parent2_idPerson ||
              geschwister.Parent2_idPerson === parents.Parent1_idPerson ||
              geschwister.Parent2_idPerson === parents.Parent2_idPerson
            ) {
              for (const geschwisterKind of childrenArray) {
                if (
                  person.idPerson === geschwisterKind.Children_idPerson &&
                  (geschwisterKind.Parent1_idPerson ===
                    geschwister.Children_idPerson ||
                    geschwisterKind.Parent2_idPerson ===
                      geschwister.Children_idPerson)
                ) {
                  return FamilyTreePersonNodeType.GESCHWISTERKIND_NODE;
                }
              }
            }
          }
        }
      }
    }

    // bestimmen der GroßNichten/ GroßNeffen
    if (childrenArray) {
      for (const parents of childrenArray) {
        if (parents.Children_idPerson === personArray[0].idPerson) {
          for (const geschwister of childrenArray) {
            if (
              geschwister.Parent1_idPerson === parents.Parent1_idPerson ||
              geschwister.Parent1_idPerson === parents.Parent2_idPerson ||
              geschwister.Parent2_idPerson === parents.Parent1_idPerson ||
              geschwister.Parent2_idPerson === parents.Parent2_idPerson
            ) {
              for (const geschwisterKind of childrenArray) {
                if (
                  geschwisterKind.Parent1_idPerson ===
                    geschwister.Children_idPerson
                ) {
                  for (const großNeffe of childrenArray) {
                    if (
                      person.idPerson === großNeffe.Children_idPerson &&
                      großNeffe.Parent1_idPerson ===
                        geschwisterKind.Children_idPerson 
                    ) {
                      return FamilyTreePersonNodeType.GROßNEFFE_NODE;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // bestimmen der UrGroßNichten/ UrGroßNeffen
    if (childrenArray) {
      for (const parents of childrenArray) {
        if (parents.Children_idPerson === personArray[0].idPerson) {
          for (const geschwister of childrenArray) {
            if (
              geschwister.Parent1_idPerson === parents.Parent1_idPerson ||
              geschwister.Parent1_idPerson === parents.Parent2_idPerson ||
              geschwister.Parent2_idPerson === parents.Parent1_idPerson ||
              geschwister.Parent2_idPerson === parents.Parent2_idPerson
            ) {
              for (const geschwisterKind of childrenArray) {
                if (
                  geschwisterKind.Parent1_idPerson ===
                    geschwister.Children_idPerson ||
                    geschwisterKind.Parent2_idPerson ===
                      geschwister.Children_idPerson
                ) {
                  for (const großNeffe of childrenArray) {
                    if (
                      großNeffe.Parent1_idPerson ===
                        geschwisterKind.Children_idPerson
                    ) {
                      for (const UrGroßNeffe of childrenArray) {
                        if (
                          person.idPerson === UrGroßNeffe.Children_idPerson &&
                          UrGroßNeffe.Parent1_idPerson ===
                            großNeffe.Children_idPerson
                        ) {
                          return FamilyTreePersonNodeType.URGROßNEFFE_NODE;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    

    return FamilyTreePersonNodeType.PERSON_NODE;
  },

  getGenderNaming(type: string, genderId: number): string {
    let genderMapping: Record<number, string> = {};

    switch (type) {
      case FamilyTreePersonNodeType.CHILD_NODE: {
        genderMapping = {
          1: "Sohn",
          2: "Tochter",
          3: "Kind",
          4: "Kind",
        };
        break;
      }
      case FamilyTreePersonNodeType.ENKEL_NODE: {
        genderMapping = {
          1: "Enkel",
          2: "Enkelin",
          3: "Enkelkind",
          4: "Enkelkind",
        };
        break;
      }
      case FamilyTreePersonNodeType.URENKEL_NODE: {
        genderMapping = {
          1: "Urenkel",
          2: "Urenkelin",
          3: "Urenkelkind",
          4: "Urenkelkind",
        };
        break;
      }
      case FamilyTreePersonNodeType.URURENKEL_NODE: {
        genderMapping = {
          1: "Ururenkel",
          2: "Ururenkelin",
          3: "Ururenkelkind",
          4: "Ururenkelkind",
        };
        break;
      }
      case FamilyTreePersonNodeType.GESCHWISTER_NODE: {
        genderMapping = {
          1: "Bruder",
          2: "Schwester",
          3: "Geschwister",
          4: "Geschwister",
        };
        break;
      }
      case FamilyTreePersonNodeType.MARRIAGE_NODE: {
        genderMapping = {
          1: "Ehemann",
          2: "Ehefrau",
          3: "geehelichte Person",
          4: "geehelichte Person",
        };
        break;
      }
      case FamilyTreePersonNodeType.PARENT_NODE: {
        genderMapping = {
          1: "Vater",
          2: "Mutter",
          3: "Elternteil",
          4: "Elternteil",
        };
        break;
      }

      case FamilyTreePersonNodeType.GESCHWISTERKIND_NODE: {
        genderMapping = {
          1: "Neffe",
          2: "Nichte",
          3: "Geschwisterkind",
          4: "Geschwisterkind",
        };
        break;
      }

      case FamilyTreePersonNodeType.GROßNEFFE_NODE: {
        genderMapping = {
          1: "Großneffe",
          2: "Großnichte",
          3: "Großneffe/ Großnichte",
          4: "Großneffe/ Großnichte",
        };
        break;
      }

      case FamilyTreePersonNodeType.URGROßNEFFE_NODE: {
        genderMapping = {
          1: "Urgroßneffe",
          2: "Urgroßnichte",
          3: "Urgroßneffe/ Urgroßnichte",
          4: "Urgroßneffe/ Urgroßnichte",
        };
        break;
      }

      case FamilyTreePersonNodeType.TESTATOR_NODE: {
        genderMapping = {
          1: "Erblasser",
          2: "Erblasser",
          3: "Erblasser",
          4: "Erblasser",
        };
        break;
      }
    }

    return genderMapping[genderId];
  },

  generateNodesAndEdges(props: {
    personArray: IInheritHasPerson[];
    marriageArray: IMarriage[];
    childrenArray: IChildren[];
  }): {
    nodes: Node<FamilyTreePersonNode>[];
    edges: Edge[];
  } {
    const { personArray, marriageArray, childrenArray } = props;

    const nodes: Node<FamilyTreePersonNode>[] = personArray.map(
      (person: IInheritHasPerson, index: number) => {
        const nodeType = this.calculateNodeType(
          personArray,
          person,
          childrenArray,
          marriageArray
        );

        return {
          id: person.idPerson.toString(),
          position: { x: (index + 1) * 100, y: 200 },
          type: nodeType,
          draggable: false,
          data: {
            person: person,
            allPersons: personArray,
          },
        };
      }
    );

    const mariageEdges = marriageArray.map((marriage: IMarriage) => {
      return {
        id: `mar_${marriage.idMarriage}`,
        source: marriage.Person2_idPerson.toString(),
        target: marriage.Person1_idPerson.toString(),
        type: FamilyTreeEdgeType.Marriage_EDGE, // TODO: type
        data: {
          ...marriage,
        },
      };
    });

    const childrenEdges = childrenArray
      .map((child: IChildren) => {
        if (child.Parent2_idPerson) {
          return {
            id: `child_${child.idChildren}`,
            source: child.Parent2_idPerson.toString(),
            target: child.Children_idPerson.toString(),
            data: child,
          };
        }
        return undefined; // Explicitly return undefined for invalid edges
      })
      .filter((edge) => edge !== undefined);

    const childrenEdges2 = childrenArray
      .map((child: IChildren) => {
        if (child.Parent1_idPerson) {
          return {
            id: `child_${child.idChildren}`,
            source: child.Parent1_idPerson.toString(),
            target: child.Children_idPerson.toString(),
            //type: 'CHILD', // TODO: type
            data: child,
          };
        }
        return undefined; // Explicitly return undefined for invalid edges
      })
      .filter((edge) => edge !== undefined);

    const edges: any = [...mariageEdges, ...childrenEdges, ...childrenEdges2];

    return {
      nodes,
      edges,
    };
  },
};
