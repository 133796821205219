import React, { useEffect, useState } from "react";
import { ILegalPhaseTemplateFullObject } from "../Interfaces/ILegalPhaseTemplate";
import { ILegalTransactionType, ILegalTransactionTypeFullObject } from "../Interfaces/ILegalTransactionType";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { CheckBox, CopyAll, TrendingUp } from "@mui/icons-material";
import { getFetch } from "../hooks/useFetch";
import { ILegalRequirementTemplate } from "../Interfaces/ILegalRequirementTemplate";

interface IProps {
    transactionTypeArray: ILegalTransactionType[];
    legalPhaseTemplateArray: ILegalPhaseTemplateFullObject[];
    setLegalPhaseTemplateArray: Function;
}


export const LegalTransactionTypeCopy:React.FC<IProps> = (props) => {
    const [isOpen,setIsOpen] = useState(false);
    const [justTemplate, setJustTemplate] = useState(true);
    //
    const [idTransactionType,setIdTransactionType] = useState<number|null>(null);
    //
    const [legalTransactionTypeFullObject,setLegalTransactionTypeFullObject] = useState<ILegalTransactionTypeFullObject|undefined>(undefined);
    const [wasSuccessfullyLoad, setWasSuccessfullyLoad] = useState(true);


    const handleOpen = () => {
        setLegalTransactionTypeFullObject(undefined);
        setIdTransactionType(null);
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false)
    }


    useEffect(() => {
        if (idTransactionType !== null) {
            getFetch(
                "/legaltransactiontype/fullObject/",
                idTransactionType,
                setLegalTransactionTypeFullObject,
                setWasSuccessfullyLoad
            )
        }
    },[idTransactionType])


    const handleCopy = () => {
        if (legalTransactionTypeFullObject !== undefined) {
            let minId = -1;
            let newPhaseArray:ILegalPhaseTemplateFullObject[] = [];
            let positionOffset = 0;

            if (props.legalPhaseTemplateArray.length > 0) {
                positionOffset = Math.max(...props.legalPhaseTemplateArray.map(x => x.Position)) +1;
                let minArrayId = Math.min(...props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate))
                if (minArrayId <= minId) {
                    minId = minArrayId-1;
                }
            }

            legalTransactionTypeFullObject.LegalPhaseTemplateArray.map((currentPhase, index) => {
                minId = minId -1;
                let minReqId = -1;
                let newReqPhase:ILegalRequirementTemplate[] = [];

                currentPhase.LegalRequirementArray.map(currentReq => {
                    minReqId = minReqId-1;
                    newReqPhase.push({
                        ...currentReq,
                        idLegalPhaseTemplate: minId,
                        idLegalRequirementTemplate: minReqId
                    } as ILegalRequirementTemplate)
                })

                newPhaseArray.push({
                    ...currentPhase,
                    idLegalPhaseTemplate: minId,
                    LegalRequirementArray: newReqPhase,
                    Position: positionOffset+index
                } as ILegalPhaseTemplateFullObject)
            })
            props.setLegalPhaseTemplateArray([
                ...props.legalPhaseTemplateArray,
                ...newPhaseArray
            ])
            setIsOpen(false);
        }
    }


    return(
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                maxWidth="md" fullWidth
            >
                <DialogTitle>Bestehende Rechtsgeschäftsart kopieren</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}/>
                    
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                        <FormControlLabel sx={{float: "right"}} control={
                            <Checkbox
                                checked={justTemplate}
                                onClick={() => {setJustTemplate(!justTemplate); setLegalTransactionTypeFullObject(undefined); setIdTransactionType(null)}}
                            />}
                        label="Nur Vorlagen" />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                label="Rechtsgeschäftsart"
                                value={(idTransactionType === null) ? "" : idTransactionType}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLegalTransactionTypeFullObject(undefined); setIdTransactionType(Number(event.target.value))}}
                                error={idTransactionType === 0}
                                size="small"
                                fullWidth
                                select
                            >
                                {props.transactionTypeArray
                                    .filter(x => !justTemplate || x.isTemplate == true)
                                    .map(x =>
                                        <MenuItem 
                                            key={`copy-idLegalTransactionType-${x.idLegalTransactionType}`}
                                            value={x.idLegalTransactionType}
                                        >{x.LegalTransactionType}</MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item sm={12}>
                            { (!wasSuccessfullyLoad) ? <>Fehler!</>
                                : (legalTransactionTypeFullObject !== undefined) &&
                                <>
                                    <table style={{width: "100%"}}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Bezeichnung</th>
                                                <th>Upload Mandanten</th>
                                                <th>Upload Mitarbeiter</th>
                                                <th>Auto.<br/> E-Mail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { (legalTransactionTypeFullObject.LegalPhaseTemplateArray.length === 0)
                                                ? <tr><td colSpan={5}>Keine Vollzugsphasen und Bedingungen vorhanden</td></tr>
                                                : legalTransactionTypeFullObject.LegalPhaseTemplateArray.map(x =>
                                                <>
                                                    <tr>
                                                        <td colSpan={5}>{x.LegalPhaseTemplate}</td>
                                                    </tr>
                                                    {x.LegalRequirementArray.map(y => 
                                                        <tr>
                                                            <td width={30}></td>
                                                            <td>{y.LegalRequirementTemplate}</td>
                                                            <td width={100}>{(y.canUploadFilesClient) ? "Ja" : "Nein"}</td>
                                                            <td width={100}>{(y.canUploadFilesEmployees) ? "Ja" : "Nein"}</td>
                                                            <td width={100}>{(y.sendMail) ? "Ja" : "Nein"}</td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                    <Button 
                        variant="contained" 
                        onClick={handleCopy}
                        disabled={legalTransactionTypeFullObject === undefined}
                    >Kopieren</Button>
                </DialogActions>
            </Dialog>



            <IconButton onClick={handleOpen}><CopyAll/></IconButton>
        </>
    )
}