import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward, CheckBoxOutlineBlank, Delete, Edit } from "@mui/icons-material";
import { ILegalRequirement } from "../../../Interfaces/ILegalRequirement";
import { ConfirmDelete } from "../../../core/ConfirmDelete";
import { LegalRequirementEdit } from "./LegalRequirementEdit";

interface IProps {
    currentRequirement: ILegalRequirement;
    legalRequirementArray: ILegalRequirement[];
    setLegalRequirementArray: Function;
}


export const LegalRequirementRow:React.FC<IProps> = (props) => {
    const [currentRequirementEdit, setCurrentRequirementEdit] = useState(props.currentRequirement);
    //
    const [isOpenEdit, setIsOpenEdit] = useState(props.currentRequirement.idLegalRequirement < 0 && props.currentRequirement.LegalRequirement === "");


    const saveReq = () => {
        props.setLegalRequirementArray([
            ...props.legalRequirementArray.map(x => (x.idLegalRequirement === currentRequirementEdit.idLegalRequirement) ? currentRequirementEdit : x)
        ]);
        setIsOpenEdit(false);
    }

    const isFirstDisabled = () => {
        let currentIndex:number = props.legalRequirementArray.map(x => x.idLegalRequirement).indexOf(props.currentRequirement.idLegalRequirement);
        return (currentIndex === 0)
    }
    const isLastDisabled = () => {
        let currentIndex:number = props.legalRequirementArray.map(x => x.idLegalRequirement).indexOf(props.currentRequirement.idLegalRequirement);
        return (currentIndex === props.legalRequirementArray.length-1)
    }

    /*
    const getUp = () => {
        let copyOfArray = [...props.legalRequirementArray];
        let targetPositionn = currentRequirementEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentRequirementEdit};

        if (toChangeObject !== undefined) {
            let
            console.log("--- INSIDE ---")
            toChangeObject.Position = currentRequirementEdit.Position;
            currentObject.Position = currentRequirementEdit.Position-1;
            //copyOfArray.map(x => x.idLegalRequirement === toChangeObject!.idLegalRequirement ? toChangeObject : x );
            copyOfArray.map(x => 
                x.idLegalRequirement === currentObject!.idLegalRequirement ? currentObject :
                (x.idLegalRequirement === toChangeObject?.idLegalRequirement) ? toChangeObject : x );
            console.log(copyOfArray);
            props.setLegalRequirementArray(copyOfArray)
        }
        
        
    }
    */

    const getUp = () => {
        let copyOfArray = [...props.legalRequirementArray];
        let targetPositionn = currentRequirementEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentRequirementEdit};

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalRequirementArray.map(x => x.idLegalRequirement).indexOf(currentObject.idLegalRequirement);
            let targetIndex = props.legalRequirementArray.map(x => x.idLegalRequirement).indexOf(toChangeObject.idLegalRequirement);

            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position-1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position+1;

            props.setLegalRequirementArray(copyOfArray)
        }        
    }

    const getDown = () => {
        let copyOfArray = [...props.legalRequirementArray];
        let targetPositionn = currentRequirementEdit.Position+1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentRequirementEdit};

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalRequirementArray.map(x => x.idLegalRequirement).indexOf(currentObject.idLegalRequirement);
            let targetIndex = props.legalRequirementArray.map(x => x.idLegalRequirement).indexOf(toChangeObject.idLegalRequirement);

            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position+1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position-1;

            props.setLegalRequirementArray(copyOfArray)
        }        
    }


    const handleDelete = () => {
        props.setLegalRequirementArray([
            ...props.legalRequirementArray.filter(x => x.idLegalRequirement !== props.currentRequirement.idLegalRequirement)
        ])
    }


    return(
        <>
            <Dialog
                open={isOpenEdit}
                onClose={() => {}}
                maxWidth="lg" 
                fullWidth
            >
                <DialogTitle>Voraussetzung anpassen</DialogTitle>
                <DialogContent>
                    <LegalRequirementEdit
                        currentRequirement={currentRequirementEdit}
                        setCurrentRequirement={setCurrentRequirementEdit}
                        legalRequirements={props.legalRequirementArray}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>Abbruch</Button>
                    <Button 
                        variant="contained" 
                        onClick={saveReq}
                        disabled={currentRequirementEdit.LegalRequirement === ""}
                    >Übernehmen</Button>
                </DialogActions>
            </Dialog>


            <TableRow>
                <TableCell sx={{width: 20}}>
                </TableCell>
                <TableCell>
                    {props.currentRequirement.LegalRequirement}


                </TableCell>

                <TableCell></TableCell>
                { /*
                <TableCell>
                    {props.legalRequirementArray
                        .filter(x => x.idLegalRequirement === currentRequirementEdit.P)
                        .map(x =>
                            <Typography sx={{mr: 3}} variant="caption">{x.LegalRequirement}</Typography>
                        )
                    }
                </TableCell>
                */}

                <TableCell>
                    <IconButton 
                        sx={{float: "right"}}  
                        size="small"
                        disabled={isLastDisabled()}
                        onClick={getDown}
                    ><ArrowDownward/></IconButton>

                    <IconButton 
                        sx={{float: "right"}}  
                        size="small"
                        disabled={isFirstDisabled()}
                        onClick={getUp}
                    ><ArrowUpward/></IconButton>
                </TableCell>
                <TableCell>{(props.currentRequirement.canUploadFilesClient) ? "Ja" : "Nein"}</TableCell>
                <TableCell>{(props.currentRequirement.canUploadFilesEmployees) ? "Ja" : "Nein"}</TableCell>
                <TableCell>{(props.currentRequirement.sendMail) ? "Ja" : "Nein"}</TableCell>
                <TableCell>
                    <IconButton size="small" onClick={() => {setCurrentRequirementEdit(props.currentRequirement); setIsOpenEdit(true)}}><Edit/></IconButton>
                    <ConfirmDelete itemText="die aktulle Bedingung" functionToDelete={handleDelete}/>
                </TableCell>
            </TableRow>
        </>
    )
}