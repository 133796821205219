import React, { useEffect, useState } from "react";
import { ILegalPhaseTemplateFullObject } from "../Interfaces/ILegalPhaseTemplate";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Add, ArrowDownward, ArrowUpward, CopyAll, Edit } from "@mui/icons-material";
import { ILegalRequirementTemplate } from "../Interfaces/ILegalRequirementTemplate";
import { LegalRequirementRow } from "./LegalRequirementRow";
import { ConfirmDelete } from "../core/ConfirmDelete";
import { LegalPhaseEdit } from "./LegalPhaseEdit";
import { LegalPhaseCopyRequirement } from "./LegalPhaseCopyRequirement";


interface IProps {
    currentPhase: ILegalPhaseTemplateFullObject;
    legalPhaseTemplateArray: ILegalPhaseTemplateFullObject[];
    setLegalPhaseTemplateArray: Function;
    legalRequirementTemplateArray: ILegalRequirementTemplate[];
}


export const LegalPhaseRow:React.FC<IProps> = (props) => {
    const [currentPhaseEdit, setCurrentPhaseEdit] = useState(props.currentPhase);
    const [currentPreconditionArray, setCurrentPreconditionArray] = useState(props.currentPhase.PreconditionArray);
    //
    //const [isOpenEdit, setIsOpenEdit] = useState(props.currentPhase.idLegalPhaseTemplate < 0 && props.currentPhase.LegalPhaseTemplate === "");

    const handleAddNewReq = () => {
        let tmpId = -1;

        if (props.currentPhase.LegalRequirementArray.length > 0) {
            let tmpIdArry = Math.min(...props.currentPhase.LegalRequirementArray.map(x => x.idLegalRequirementTemplate));
            
            if (tmpIdArry <= tmpId) {
                tmpId = tmpIdArry - 1;
            }
        }

        let tmpObject = {...props.currentPhase};
        tmpObject.LegalRequirementArray.push({
            idLegalPhaseTemplate: -1,
            idLegalRequirementTemplate: tmpId,
            canUploadFilesClient: false,
            canUploadFilesEmployees: false,
            hasError: false,
            hasErrorFreeText: false,
            LegalRequirementTemplate: "",
            sendMail: false, 
            EmailBody: null,
            EmailSubject: null,
            Predecessor_idLegalRequirementTemplate: null,
            TextOfError: null,
            Position: props.currentPhase.LegalRequirementArray.length,
            idLegalRequirementState: 10,
            idUser: null,
            isTemplate: false,
            InfoText: null
        } as ILegalRequirementTemplate)

        props.setLegalPhaseTemplateArray([
            ...props.legalPhaseTemplateArray.map(x => (x.idLegalPhaseTemplate === tmpObject.idLegalPhaseTemplate) ? tmpObject : x)
        ])
    }

    const updateArry = (localLegalReqArray:ILegalRequirementTemplate[]) => {
        let tmpObject = {...props.currentPhase};
        tmpObject.LegalRequirementArray = localLegalReqArray;
        props.setLegalPhaseTemplateArray([
            ...props.legalPhaseTemplateArray.map(x => (x.idLegalPhaseTemplate === tmpObject.idLegalPhaseTemplate) ? tmpObject : x)
        ])
    }
    const updateCopyArray = (localLegalReqArray:ILegalRequirementTemplate[]) => {
        let newArray:ILegalRequirementTemplate[] = [];
        let maxPosition =  Math.max(...props.currentPhase.LegalRequirementArray.map(x => x.Position)) +1;

        localLegalReqArray.map((x,i) => {
            x.Position = maxPosition + i;
            newArray.push(x)
        })
        updateArry([...props.currentPhase.LegalRequirementArray,...newArray])
    }

    const isFirstDisabled = () => {
        let currentIndex:number = props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate).indexOf(props.currentPhase.idLegalPhaseTemplate);
        return (currentIndex === 0)
    }
    const isLastDisabled = () => {
        let currentIndex:number = props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate).indexOf(props.currentPhase.idLegalPhaseTemplate);
        return (currentIndex === props.legalPhaseTemplateArray.length-1)
    }

    const getUp = () => {
        let copyOfArray = [...props.legalPhaseTemplateArray];
        let targetPositionn = currentPhaseEdit.Position-1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentPhaseEdit, Position: currentPhaseEdit.Position-1}

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate).indexOf(currentPhaseEdit.idLegalPhaseTemplate);
            let targetIndex = props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate).indexOf(toChangeObject.idLegalPhaseTemplate);
            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position-1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position+1;

            setCurrentPhaseEdit(currentObject);
            props.setLegalPhaseTemplateArray(copyOfArray)
        }        
    }

    const getDown = () => {
        let copyOfArray = [...props.legalPhaseTemplateArray];
        let targetPositionn = currentPhaseEdit.Position+1;
        let toChangeObject = copyOfArray.find(x => x.Position === targetPositionn);
        let currentObject = {...currentPhaseEdit, Position: currentPhaseEdit.Position+1}

        if (toChangeObject !== undefined) {
            let currentIndex = props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate).indexOf(currentPhaseEdit.idLegalPhaseTemplate);
            let targetIndex = props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate).indexOf(toChangeObject.idLegalPhaseTemplate);

            copyOfArray[currentIndex].Position = copyOfArray[currentIndex].Position+1;
            copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position-1;

            setCurrentPhaseEdit(currentObject);
            props.setLegalPhaseTemplateArray(copyOfArray)
        }          
    }

    const handleDelete = () => {
        props.setLegalPhaseTemplateArray([
            ...props.legalPhaseTemplateArray.filter(x => x.idLegalPhaseTemplate !== props.currentPhase.idLegalPhaseTemplate)
        ])
    }

    useEffect(() => {
        props.setLegalPhaseTemplateArray([
            ...props.legalPhaseTemplateArray.map(x => x.idLegalPhaseTemplate === currentPhaseEdit.idLegalPhaseTemplate ? currentPhaseEdit : x )
        ])
    },[currentPhaseEdit])

    return(
        <>


            <TableRow>
                <TableCell colSpan={2}>
                    {props.currentPhase.LegalPhaseTemplate}
                </TableCell>
                <TableCell>
                    {props.currentPhase.PreconditionArray.map(x => 
                        props.legalPhaseTemplateArray
                            .filter(y => y.idLegalPhaseTemplate === x.idLegalPhaseTemplate)
                            .map(y =>
                                <>
                                    <Typography sx={{mr: 3}} variant="caption">{y.LegalPhaseTemplate}</Typography>
                                    <br />
                                </>
                            )
                    )}
                </TableCell>
                <TableCell>
                    <IconButton 
                        sx={{float: "right"}}  
                        size="small"
                        disabled={isLastDisabled()}
                        onClick={getDown}
                    ><ArrowDownward/></IconButton>

                    <IconButton 
                        sx={{float: "right"}}  
                        size="small"
                        disabled={isFirstDisabled()}
                        onClick={getUp}
                    ><ArrowUpward/></IconButton>

                    <IconButton sx={{float: "right"}} size="small" onClick={handleAddNewReq}><Add/></IconButton>

                    <LegalPhaseCopyRequirement
                        idLegalPhaseTemplate={props.currentPhase.idLegalPhaseTemplate}
                        startId={Math.min(
                            ...props.currentPhase.LegalRequirementArray.map(x => x.idLegalRequirementTemplate)
                        )}
                        setArray={updateCopyArray}
                        legalRequirementTemplateArray={props.legalRequirementTemplateArray}
                    />
                </TableCell>
                <TableCell sx={{width: 100}}/>
                <TableCell sx={{width: 100}}/>
                <TableCell sx={{width: 100}}/>
                <TableCell sx={{ width: 150 }}>
                    <LegalPhaseEdit
                        currentPhase={props.currentPhase}
                        currentPhaseEdit={currentPhaseEdit}
                        setCurrentPhaseEdit={setCurrentPhaseEdit}
                        legalPhaseTemplateArray={props.legalPhaseTemplateArray}
                        setLegalPhaseTemplateArray={props.setLegalPhaseTemplateArray}
                    />
                    <ConfirmDelete itemText="die aktulle Phase und deren Bedingungen" functionToDelete={handleDelete}/>
                </TableCell>
            </TableRow>

            {props.currentPhase.LegalRequirementArray.sort((a,b) => a.Position < b.Position ? -1 : 1).map(x =>
                <LegalRequirementRow
                    key={`idLegalRequirementTemplate-${x.idLegalRequirementTemplate}`}
                    currentRequirement={x}
                    legalRequirementArray={props.currentPhase.LegalRequirementArray}
                    setLegalRequirementArray={updateArry}
                />
            )}
        </>
    )
}