import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { Add, Check, Clear } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams, deDE } from "@mui/x-data-grid";
import { LegalRoleEdit } from "./LegalRoleEdit";
import { ILegalRole } from "../../../Interfaces/ILegalRole";
import { ILegalTransactionHasContact } from "../../../Interfaces/IContact";


interface IProps {
  contactArray: ILegalTransactionHasContact[];
  newContactArray: ILegalTransactionHasContact[];
  legalRoleArray: ILegalRole[];
  setLegalRoleArray: Function;
}


export const LegalRoleOverview:React.FC<IProps> = (props) => {
    const [idLegalRole, setIdLegalRole] = useState<number|null>(null);

    const columns: GridColDef[] = [

      { field: 'canInvite', 
        headerName: 'Einladen', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.canInvite)) ? <Check/> : <Clear/>
      },
      { field: 'canUpload', 
        headerName: 'Hochladen', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.canUpload)) ? <Check/> : <Clear/>
      },
      { field: 'viewDataCollection', 
        headerName: 'Datener.', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.viewDataCollection)) ? <Check/> : <Clear/>
      },
      { field: 'viewDraft', 
        headerName: 'Entwurf', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.viewDraft)) ? <Check/> : <Clear/>
      },
      { field: 'viewDate', 
        headerName: 'Termine', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.viewDraft)) ? <Check/> : <Clear/>
      },
      { field: 'viewTransaction', 
        headerName: 'Abwicklung', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.viewTransaction)) ? <Check/> : <Clear/>
      },
      { field: 'viewCertificate', 
        headerName: 'Urkunden', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.viewCertificate)) ? <Check/> : <Clear/>
      },
      { field: 'viewInvoice', 
        headerName: 'Rechnungen', 
        width: 100,
        renderCell: (params: GridRenderCellParams) => (Boolean(params.row.viewInvoice)) ? <Check/> : <Clear/>
      },
      { 
        field: 'Contacts', 
        headerName: 'Mandanten', 
        width: 120,
        renderCell: (params: GridRenderCellParams) => 
          [...props.contactArray, ...props.newContactArray]
            .filter(x => x.idLegalRole === Number(params.row.idLegalRole))
            .length
      },
      { field: 'LegalRole', 
        headerName: 'Bezeichnung', 
        flex: 1
      },
  ]


    return(
        <>
            <Typography variant="h6" sx={{mt: 5}}>
                Rollen
                <IconButton sx={{float: "right"}} onClick={() => setIdLegalRole(-1)}><Add/></IconButton>
            </Typography>
            <br />

            <DataGrid
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={props.legalRoleArray}
                columns={columns}
                getRowId={(row) => row.idLegalRole}
                onRowClick={(params,event,details) => setIdLegalRole(Number(params.id))}
                autoHeight
            />

            <Dialog open={idLegalRole !== null} onClose={() => setIdLegalRole(null)} maxWidth="lg" fullWidth>
                <DialogContent>
                    <>
                        {(idLegalRole !== null) &&
                            <LegalRoleEdit
                                contactArray={props.contactArray}
                                newContactArray={props.newContactArray}
                                idLegalRole={idLegalRole}
                                setIdLegalRole={setIdLegalRole}
                                legalRoleArray={props.legalRoleArray}
                                setLegalRoleArray={props.setLegalRoleArray}
                            />
                        }
                    </>
                </DialogContent>
            </Dialog>
        </>
    )
}