import React, { useEffect, useState } from "react";
import { ILegalPhaseFullObject } from "../../Interfaces/ILegalPhase";
import { Button, Grid, Table, TableBody, Typography, tableCellClasses } from "@mui/material";
import { LegalPhaseRow } from "./LegalPhaseRow";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import { LegalPhaseTableEdit } from "./Edit/LegalPhaseTableEdit";
import { ILegalRequirementState } from "../../Interfaces/ILegalRequirementState";
import { ILegalRole } from "../../Interfaces/ILegalRole";
import { ILegalRole_canInvite } from "../../Interfaces/ILegalRole_canInvite";
import { IDocumentDownloaded } from "../../Interfaces/IDocumentDownloaded";
import { ILegalRequirementTemplate } from "../../Interfaces/ILegalRequirementTemplate";

interface IProps {
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
    setLegalPhaseFullObjectArray: Function;
    legalReqState: ILegalRequirementState[];
    legalRequirementTemplateArray: ILegalRequirementTemplate[];
    //
    legalRoleArray: ILegalRole[];
    setLegalRoleArray: Function;
    legalRoleCanInviteArray: ILegalRole_canInvite[];
    setLegalRoleCanInviteArray: Function;
    downloadedArray?: IDocumentDownloaded[];
}


export const LegalPhaseMain:React.FC<IProps> = (props) => {
    const [legalPhaseEditArray, setLegalPhaseEditArray] = useState([...props.legalPhaseFullObjectArray]);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);

    const handleOpenEdit = () =>  {
        setLegalPhaseEditArray([...props.legalPhaseFullObjectArray]);
        setIsOpenEditDialog(true);
    }


    return(
        <>
            <FullScreenDialog
                isOpen={isOpenEditDialog}
                onClose={() => setIsOpenEditDialog(false)}
                title="Rechtsgeschäftsart"
            >
                <LegalPhaseTableEdit
                    legalPhaseArray={legalPhaseEditArray}
                    setLegalPhaseArray={setLegalPhaseEditArray}
                    setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
                    setIsOpenEditDialog={setIsOpenEditDialog}
                    legalRoleArray={props.legalRoleArray}
                    setLegalRoleArray={props.setLegalRoleArray}
                    legalRoleCanInviteArray={props.legalRoleCanInviteArray}
                    setLegalRoleCanInviteArray={props.setLegalRoleCanInviteArray}
                    legalRequirementTemplateArray={props.legalRequirementTemplateArray}
                />
            </FullScreenDialog>

            <Typography variant="h5" sx={{mb: 3}}>
                Aktueller Statusverlauf
                <Button sx={{float: "right"}} onClick={handleOpenEdit} variant="outlined">Anpassen</Button>
            </Typography>



            <Grid container spacing={1}>
                {props.legalPhaseFullObjectArray.sort((a,b) => a.Position < b.Position ? -1 : 1).map(x =>
                    <LegalPhaseRow
                        key={`idLegalPhase-${x.idLegalPhase}-${x.LegalRequirementArray.length}`}
                        legalPhaseFullObject={x}
                        legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
                        setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
                        legalReqState={props.legalReqState}
                        legalRequirementTemplateArray={props.legalRequirementTemplateArray}
                        downloadedArray={props.downloadedArray}
                    />
                )}
            </Grid>
        </>
    )
}