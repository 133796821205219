import React from "react";
import { ILegalTransaction } from "../Interfaces/ILegalTransaction";
import { DataGrid, GridColDef, GridRenderCellParams, deDE } from "@mui/x-data-grid";
import { IconButton, Typography } from "@mui/material";
import { Link } from "@mui/icons-material";


interface IProps {
    legalTransactionArray: ILegalTransaction[];
}

const columns: GridColDef[] = [
    { field: 'idLegalTransaction', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'LegalTransactionType', 
      headerName: 'Art', 
      width: 150 
    },
    { field: 'LegalTransactionState', 
      headerName: 'Status', 
      width: 150 
    },
    { field: 'Title', 
      headerName: 'Bezeichnung', 
      flex: 1
    },
    { 
        field: 'Actions',
        headerName: "Aktionen",
        width: 90,
        renderCell: (params: GridRenderCellParams) : React.ReactNode => {
            return(
                <>
                    <IconButton
                        title='Rechtsgeschäft öffnen'
                        onClick={() =>{
                            window.open(`/legaltransaction/${params.row.idLegalTransaction}`, "_blank")
                        }}
                    ><Link/></IconButton>
                </>
            )
        }
    }
]


export const ClientsLegalTransactionOverview:React.FC<IProps> = (props) => {
    return(
        <>
            <Typography variant="h5">
                Rechtsgeschäfte
            </Typography>

            <DataGrid
                sx={{mt: 2}}
                autoHeight
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={props.legalTransactionArray}
                columns={columns}
                getRowId={(row) => row.idLegalTransaction}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                disableRowSelectionOnClick
            />
        </>
    )
}