import { DateTimePicker, LocalizationProvider, deDE } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import React from "react";
import { castWithZeroTimezone, getZeroTimezoneStringFromDate } from "./generic_helper";


interface IProps {
    dateValue: string | null;
    setDateValue: Function;
    allowNull: boolean;
    allowPast: boolean;
}


export const CustomeDateTimePicker:React.FC<IProps> = (props) => {

    const fillNumberToString = (targetNumber:number) => {
        if (targetNumber <= 9) {
            return `0${targetNumber}`
        }
        else {
            return `${targetNumber}`
        }
    }

    const getValue = () => {
        let returnValue = castWithZeroTimezone(props.dateValue);

        if (returnValue === null) {
            return new Date();
        } else {
            return returnValue;
        }
    }


    const setValue = (newValue:Date|null) => {
        if (newValue === null) {
            props.setDateValue((props.allowNull) ? null : "")
        }
        else {
            props.setDateValue(getZeroTimezoneStringFromDate(newValue));
            /*
            let year = newValue.getFullYear();
            let month = newValue.getMonth()+1;
            let day = newValue.getDate();
            let hour = newValue.getHours();
            let minut = newValue.getMinutes();

            console.log("day");
            console.log(day);
    
            let dateTimeString = `${year}-${fillNumberToString(month)}-${fillNumberToString(day)} ${fillNumberToString(hour)}:${fillNumberToString(minut)}:00`;

            props.setDateValue(dateTimeString)
            */
        }
    }



    return(
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DateTimePicker
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                value={getValue()}
                onChange={setValue}
                minDateTime={(props.allowPast) ? undefined : new Date()}
            />
        </LocalizationProvider>
    )
}