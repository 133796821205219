import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TableRow } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { PurchaseContractPersonEdit } from "./PurchaseContractPersonEdit";
import { IPurchaseContractHasPerson } from "../../../../Interfaces/IPerson";


interface IProps {
    index: number;
    currentObject: IPurchaseContractHasPerson;
    personArray: IPurchaseContractHasPerson[];
    setPersonArray: Function;
}



export const PurchaseContractPersonRow:React.FC<IProps> = (props) => {
    const [editPerson, setEditPerson] = useState(props.currentObject);
    const [isOpenEdit,setIsOpenEdit] = useState(false);

    const handleRemove = () => {
        props.setPersonArray([
            ...props.personArray.filter(x => x.idPerson !== props.currentObject.idPerson)
        ])
    }

    const handleEdit = () => {
        setEditPerson(props.currentObject);
        setIsOpenEdit(true);
    }

    const handleSave = () => {
        props.setPersonArray([
            ...props.personArray.map(x => x.idPerson === editPerson.idPerson ? editPerson : x)
        ])
        setIsOpenEdit(false);
    }




    return(
        <>
            <Dialog
                open={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
                maxWidth="lg" fullWidth
            >
                <DialogTitle>Person bearbeiten</DialogTitle>
                <DialogContent>
                    <PurchaseContractPersonEdit
                        currentPerson={editPerson}
                        setCurrentPerson={setEditPerson}
                        personArray={props.personArray}
                        showBankInformation
                        showTaxInformation
                        
                    />
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>Abbruch</Button>
                        <Button 
                         variant="contained" 
                         onClick={handleSave} 
                         disabled={editPerson.FirstName === "" || editPerson.LastName === "" || editPerson.idGender < 0}
                        >
                            Übernehmen
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog>



            <TableRow>
                <TableCell>{props.index+1}</TableCell>
                <TableCell>{props.currentObject.FirstName}</TableCell>
                <TableCell>{props.currentObject.LastName}</TableCell>
                <TableCell>
                    <IconButton onClick={handleEdit}><Edit/></IconButton>
                    <IconButton onClick={handleRemove}><Delete/></IconButton>
                    
                </TableCell>
            </TableRow>
        </>
    )
}