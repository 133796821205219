import React, { useState } from "react";
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import PostcodeSearch from "../../../../generic/PostcodeSearch";
import { IRealEstate } from "../../../../Interfaces/IRealEstate";
import { IPostcode } from "../../../../Interfaces/IPostcode";
import { MortgageRealEstateLandRegister } from "../Components/MortgageRealEstateLandRegister";
import { MortgageRealEstateAddress } from "../Components/MortgageRealEstateAddress";



interface IProps {
    realEstate: IRealEstate;
    setRealEstate: Function;
}

const handlePostcode = (localObject: IRealEstate) => {
    if (localObject.idPostcode != null
        && localObject.idPostcode > 0
        && localObject.City != undefined
        && localObject.Postcode != undefined
    ) {
        return {
            idPostcode: localObject.idPostcode,
            City: localObject.City,
            Postcode: localObject.Postcode,
            idCountry: 1
        } as IPostcode
    } else {
        return null;
    }
}


export const RealEstate:React.FC<IProps> = (props) => {

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasAddress(event.target.value === "yes");
    };

    const [postcodeObject, setPostcodeObject] = useState<IPostcode | null | undefined>(handlePostcode(props.realEstate));
    const [hasAddress, setHasAddress] = useState<boolean>(props.realEstate.Street != null && props.realEstate.Street != "");

    const setPostcodeObjectWrapper = (postcodeObject: IPostcode | null | undefined) => {
        if (postcodeObject != undefined) {
            props.setRealEstate({
                ...props.realEstate,
                idPostcode: postcodeObject.idPostcode,
                Postcode: postcodeObject.Postcode,
                City: postcodeObject.City,
            } as IRealEstate)

        }
        setPostcodeObject(postcodeObject);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="property-owned"
                            name="property-owned"
                            value={hasAddress ? "yes" : "no"}
                            onChange={handleToggle}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                            <FormControlLabel value="no" control={<Radio />} label="Nein" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item sx={{ mt: 1 }}>
                    {hasAddress && (
                        <MortgageRealEstateAddress
                            currentObject={props.realEstate}
                            setCurrentObject={props.setRealEstate}
                        />
                    )}
                    {!hasAddress && (
                        <MortgageRealEstateLandRegister
                            currentObject={props.realEstate}
                            setCurrentObject={props.setRealEstate}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}