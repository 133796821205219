import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { LegalRoleCanInviteRow } from "./LegalRoleCanInviteRow";
import { ILegalRole } from "../../../Interfaces/ILegalRole";
import { ILegalRole_canInvite } from "../../../Interfaces/ILegalRole_canInvite";


interface IProps {
    legalRoleArray: ILegalRole[];
    legalRoleCanInviteArray: ILegalRole_canInvite[];
    setLegalRoleCanInviteArray: Function;
}


export const LegalRoleCanInviteTable:React.FC<IProps> = (props) => {

    const contextTsx = () => {
        let filteredArray = props.legalRoleArray.filter(x => Boolean(x.canInvite));

        if (filteredArray.length === 0) {
            return(
                <>
                    <i>Keine Rollen mit Einladeerlaubnis vorhanden.</i>
                </>
            )
        } else {
            return(
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rolle</TableCell>
                                <TableCell>Darf Zuweisen</TableCell>
                                <TableCell sx={{width: 100}}>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {filteredArray.map(x =>
                                <LegalRoleCanInviteRow
                                    key={`idLegalRole-${x.idLegalRole}`}
                                    legalRoleObject={x}
                                    legalRoleArray={props.legalRoleArray}
                                    legalRoleCanInviteArray={props.legalRoleCanInviteArray}
                                    setLegalRoleCanInviteArray={props.setLegalRoleCanInviteArray}
                                />
                            )}
                        </TableBody>
                    </Table>
                </>
            )
        }
    }

    return(
        <>
            <Typography variant="h6" sx={{mt: 5}}>
                Einladerelationen
            </Typography>
            <br />

            {contextTsx()}
        </>
    )
}