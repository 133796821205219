// src/services/socketService.ts

import { io, Socket } from 'socket.io-client'

const SOCKET_SERVER_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : window.location.origin + '/'

enum SocketError {
  UNAUTHORIZED = 'unauthorized',
  CONNECT_ERROR = 'connect_error',
  CONNECT_TIMEOUT = 'connect_timeout',
  ERROR = 'error',
  RECONNECT_ERROR = 'reconnect_error',
  RECONNECT_FAILED = 'reconnect_failed'
}

class GenericSocketService {
  private socket: Socket | null = null
  private namespace: string
  private topics: { [key: string]: (message: any) => void } = {}

  constructor(namespace: string) {
    this.namespace = namespace
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.subscribe_to_topic = this.subscribe_to_topic.bind(this)
    this.setConnectionStatus = this.setConnectionStatus.bind(this)
    this.setFailure = this.setFailure.bind(this)
  }

  public connect(
    connectionChangeCallback?: (connectionStatus: boolean) => void,
    failureCallBack?: (error: SocketError) => void
  ): void {
    const token = localStorage.getItem('SessionToken')
    if (token === '' || token === null) {
      if (failureCallBack) {
        failureCallBack(SocketError.UNAUTHORIZED)
      }
      return
    }
    if (this.socket && this.socket.connected) {
      return
    }

    this.socket = io(`${SOCKET_SERVER_URL}${this.namespace}`, {
      extraHeaders: {
        Authorization: `Bearer ${token}`
      }
    })

    this.socket.on('unauthorized', () => {
      this.setFailure(SocketError.UNAUTHORIZED, failureCallBack)
      this.setConnectionStatus(false, connectionChangeCallback)
    })

    this.socket.on('disconnect', () => {
      this.setConnectionStatus(false, connectionChangeCallback)
      for (let topic in this.topics) {
        this.unsubscribe_from_topic(topic)
      }
    })

    this.socket.on('disconnecting', () => {
      this.setConnectionStatus(false, connectionChangeCallback)
      for (let topic in this.topics) {
        this.unsubscribe_from_topic(topic)
      }
    })

    this.socket.on('connect_error', (error: any) => {
      this.setFailure(SocketError.CONNECT_ERROR, failureCallBack)
      this.setConnectionStatus(false, connectionChangeCallback)
    })

    this.socket.on('connect_timeout', () => {
      this.setFailure(SocketError.CONNECT_TIMEOUT, failureCallBack)
      this.setConnectionStatus(false, connectionChangeCallback)
    })

    this.socket.on('error', (error: any) => {
      this.setFailure(SocketError.ERROR, failureCallBack)
      this.setConnectionStatus(false, connectionChangeCallback)
    })

    this.socket.on('reconnect_error', (error: any) => {
      this.setFailure(SocketError.RECONNECT_ERROR, failureCallBack)
    })

    this.socket.on('reconnect_failed', () => {
      this.setFailure(SocketError.RECONNECT_FAILED, failureCallBack)
    })

    this.socket.on('connect', () => {
      this.setConnectionStatus(true, connectionChangeCallback)
    })

    this.socket.on('reconnect', () => {
      this.setConnectionStatus(true, connectionChangeCallback)
    })
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  public subscribe_to_topic(topic: string, callback: (message: any) => void): void {
    if (this.socket && this.socket.connected) {
      if (topic in this.topics) {
        this.remove_topic_listener(topic)
      }
      this.socket.on(topic, callback)
      this.topics[topic] = callback
    }
  }

  public sendMessage(
    event: string,
    data: any,
    callback: (message: any) => void,
    removeHandlerAtResponse: boolean = true
  ): void {
    this.socket?.off(event)
    if (this.socket) {
      if (data === undefined) {
        this.socket.emit(event)
      } else {
        this.socket.emit(event, data)
      }
      this.socket.on(event, (message: any) => {
        callback(message)
        if (removeHandlerAtResponse) {
          this.socket?.off(event)
        }
      })
    }
  }

  public sendEvent(event: string, callback: (message: any) => void, removeHandlerAtResponse: boolean = true): void {
    this.socket?.off(event)
    if (this.socket) {
      this.socket.emit(event)
      this.socket.on(event, (message: any) => {
        callback(message)
        if (removeHandlerAtResponse) {
          this.socket?.off(event)
        }
      })
    }
  }

  private setConnectionStatus(status: boolean, connectionChangeCallback?: (connectionStatus: boolean) => void): void {
    if (connectionChangeCallback) {
      connectionChangeCallback(status)
    }
  }

  private setFailure(error: SocketError, failureCallBack?: (error: SocketError) => void): void {
    if (failureCallBack) {
      failureCallBack(error)
    }
  }

  private remove_topic_listener(topic: string): void {
    if (this.socket && this.socket.connected && topic in this.topics) {
      this.socket.off(topic, this.topics[topic])
      delete this.topics[topic]
    }
  }

  public unsubscribe_from_topic(topic: string): void {
    if (this.socket && this.socket.connected && topic in this.topics) {
      this.remove_topic_listener(topic)
    }
  }

  unsubscribe_all() {
    let oldTopics = this.topics
    for (let topic in oldTopics) {
      this.unsubscribe_from_topic(topic)
    }
  }
}

class WebsocketManager {
  private socketServices: { [key: string]: GenericSocketService } = {}

  constructor() {
    this.getSocketService = this.getSocketService.bind(this)
  }

  public getSocketService(
    namespace: string,
    connectionChangeCallback?: (connectionStatus: boolean) => void,
    failureCallBack?: (error: SocketError) => void
  ): GenericSocketService {
    if (!this.socketServices[namespace]) {
      this.socketServices[namespace] = new GenericSocketService(namespace)
      this.socketServices[namespace].connect(connectionChangeCallback, failureCallBack)
    }
    return this.socketServices[namespace]
  }

  public removeSocketService(namespace: string): void {
    if (this.socketServices[namespace]) {
      this.socketServices[namespace].disconnect()
      delete this.socketServices[namespace]
    }
  }
}

const WebSocketManagerInstance = new WebsocketManager()

export default WebSocketManagerInstance
export { GenericSocketService, WebsocketManager, SocketError }
