import React, { useEffect, useState } from "react";
import { ICompanyFormationAnswer } from "../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../Interfaces/ICompanyFormationQuestion";
import { Alert, Box, Grid, MenuItem, TextField } from "@mui/material";
import { getCompanyFormationAnswer } from "../../companyFormation_helpers";
import { ICompanyFormationOption } from "../../../../Interfaces/ICompanyFormationOption";
import { useSelector } from "react-redux";
import { State } from "../../../../redux/mainReducer";

interface IProps {
    currentQuestion: ICompanyFormationQuestion;
    companyFormationAnswerArray: ICompanyFormationAnswer[];
    setCompanyFormationAnswerArray: Function
}


export const CompanyFormationAnswerEdit:React.FC<IProps> = (props) => {
    const companyFormationOptionArray = useSelector((state: State) => state.companyFormationOptionArray.storeCompanyFormationOption);

    const [currentOptionArray,setCurrentOptionArray] = useState(companyFormationOptionArray.filter(x => x.idCompanyFormationQuestion === props.currentQuestion.idCompanyFormationQuestion));
    const [currentAnswer,setCurrentAnswer] = useState(getCompanyFormationAnswer(props.currentQuestion.idCompanyFormationQuestion,props.companyFormationAnswerArray));

    const [currentOption,setCurrentOption] = useState(currentOptionArray.find(x => x.idCompanyFormationOption === currentAnswer.idCompanyFormationOption));

    useEffect(() => {
        setCurrentOption(currentOptionArray.find(x => x.idCompanyFormationOption === currentAnswer.idCompanyFormationOption));
        // PRüfen ob bereits im Array
        let testObject = props.companyFormationAnswerArray.find(x => x.idCompanyFormationAnswer === currentAnswer.idCompanyFormationAnswer);

        if (testObject === undefined) {
            props.setCompanyFormationAnswerArray([
                ...props.companyFormationAnswerArray,
                currentAnswer
            ])
        } else {
            props.setCompanyFormationAnswerArray([
                ...props.companyFormationAnswerArray.map(x => x.idCompanyFormationAnswer === currentAnswer.idCompanyFormationAnswer ? currentAnswer : x)
            ])
        }
    },[currentAnswer])


    return(
        <>
            <Grid item sm={12}>
                <b>{props.currentQuestion.CompanyFormationQuestion}</b>
            </Grid>

            {(props.currentQuestion.Description !== null ) &&
                <Grid item sm={12}>
                    <Alert severity="info">
                        {props.currentQuestion.Description}
                    </Alert>
                </Grid>
            }

            <Grid item sm={12}>
                <TextField
                    label="Ausgewählte Option"
                    fullWidth
                    size="small"
                    select
                    value={currentAnswer.idCompanyFormationOption}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => 
                        setCurrentAnswer({
                            ...currentAnswer,
                            idCompanyFormationOption: Number(event.target.value)
                        })
                    }
                >
                    <MenuItem disabled={currentAnswer.idCompanyFormationOption > 0} key="idCompanyFormationOption-noAnswer" value={-1}>-</MenuItem>
                    {currentOptionArray.map(x =>
                        <MenuItem 
                         key={`idCompanyFormationOption-${x.idCompanyFormationOption}`}
                         value={x.idCompanyFormationOption}
                        >
                            {x.CompanyFormationOption}
                        </MenuItem>
                    )}
                </TextField>
            </Grid>

            {(currentOption !== undefined && currentOption.Description !== null) &&
                <Grid item sm={12}>
                    <Alert severity="info">
                        {currentOption.Description}
                    </Alert>
                </Grid>
            }

            {(currentOption !== undefined && Boolean(currentOption.allowNumberValue)) &&
                <Grid item sm={12}>
                    <TextField
                        label="Zahlenwert"
                        fullWidth
                        size="small"
                        type="number"
                        value={(currentAnswer.NumberValue === null) ? "" : currentAnswer.NumberValue}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => 
                            setCurrentAnswer({
                                ...currentAnswer,
                                NumberValue: (event.target.value === "") ? null : Number(event.target.value)
                            })
                        }
                    />
                </Grid>
            }

            {(currentOption !== undefined && Boolean(currentOption.allowTextfield)) &&
                <Grid item sm={12}>
                    <TextField
                        label="Textwert"
                        fullWidth
                        size="small"
                        rows={3}
                        multiline
                        value={(currentAnswer.TextValue === null) ? "" : currentAnswer.TextValue}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => 
                            setCurrentAnswer({
                                ...currentAnswer,
                                TextValue: (event.target.value === "") ? null : event.target.value
                            })
                        }
                    />
                </Grid>
            }



            <Grid item sm={12}>
                <Box sx={{mb: 3}}/>
            </Grid>
        </>
    )
}