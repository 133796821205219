import React, { useEffect, useState } from "react";
import { IDocumentDirectory } from "../../Interfaces/IDocumentDirectory";
import { IDocument } from "../../Interfaces/IDocument";
import { TreeItem } from "@mui/x-tree-view";
import { IDocumentDirectoryTemplate } from "../../Interfaces/IDocumentDirectoryTemplate";
import { IDocumentDirectoryTemplateHasDocument } from "../../Interfaces/IDocumentDirectoryTemplateHasDocument";
import { DocumentDirectoryTemplateDocumentNew } from "./DocumentDirectoryTemplateDocumentNew";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { CreateNewFolder, Delete, Edit, UploadFile } from "@mui/icons-material";
import { DocumentDirectoryTemplateEdit } from "./DocumentDirectoryTemplateEdit";
import { DocumentDirectoryTemplateDocument } from "./DocumentDirectoryTemplateDocument";


interface IProps {
    documentDirecotryObject: IDocumentDirectoryTemplate;
    documentArray: IDocumentDirectoryTemplateHasDocument[];
    documentDirectoryArray: IDocumentDirectoryTemplate[];
    setDocumentArray: Function;
    setDocumentDirectoryArray: Function;
}



export const DocumentDirectoryTemplateTreeEntry:React.FC<IProps> = (props) => {
    const [isOpenNewDirectory,setIsOpenNewDirectory] = useState(false);
    const [isOpenDocument, setIsOpenDocument] = useState(false);
   //
   const [isOpenEdit,setIsOpenEdit] = useState(false);
   const [isMenuOpen,setIsMenuOpen] = useState(false);
   //
   const [mouseX, setMouseX] = useState(0);
   const [mouseY, setMouseY] = useState(0);


   const handleRightClick = (e:React.MouseEvent<HTMLLIElement>) => {
       e.preventDefault();
       e.stopPropagation()
       setMouseX(e.clientX);
       setMouseY(e.clientY);
       setIsMenuOpen(true)
   }

   const handleClickMenu = (fun:Function) => {
       setIsMenuOpen(false);
       fun();
   } 

   const removeMe = (idDocumentDirectoryTemplate:number, tmpDocumentDirectoryArray:IDocumentDirectoryTemplate[], tmpDocumentArray:IDocumentDirectoryTemplateHasDocument[]) 
   : [IDocumentDirectoryTemplate[], IDocumentDirectoryTemplateHasDocument[]] => {
   tmpDocumentDirectoryArray.filter(x => x.Parent_idDocumentDirectoryTemplate === idDocumentDirectoryTemplate).map(x =>{
       [tmpDocumentDirectoryArray, tmpDocumentArray] = removeMe(x.idDocumentDirectoryTemplate,tmpDocumentDirectoryArray,tmpDocumentArray)
   })

   return [tmpDocumentDirectoryArray.filter(x => x.idDocumentDirectoryTemplate !== idDocumentDirectoryTemplate), tmpDocumentArray.filter(x => x.idDocumentDirectoryTemplate !== idDocumentDirectoryTemplate)]
}

   const handleRemove = () => {
    let tmpDocumentDirectoryArray = [...props.documentDirectoryArray];
    let tmpDocumentArray = [...props.documentArray];
    [tmpDocumentDirectoryArray, tmpDocumentArray] = removeMe(props.documentDirecotryObject.idDocumentDirectoryTemplate,tmpDocumentDirectoryArray,tmpDocumentArray)
    props.setDocumentArray([...tmpDocumentArray])
    props.setDocumentDirectoryArray([...tmpDocumentDirectoryArray])
}

    return(
        <>
            <DocumentDirectoryTemplateEdit
                isOpen={isOpenNewDirectory}
                setIsOpen={setIsOpenNewDirectory}
                documentDirectoryArray={props.documentDirectoryArray}
                setDocumentDirectoryArray={props.setDocumentDirectoryArray}
                parent_idDocumentDirectory={props.documentDirecotryObject.idDocumentDirectoryTemplate}
            />

            <DocumentDirectoryTemplateDocumentNew
                documentArray={props.documentArray}
                setDocumentArray={props.setDocumentArray}
                isOpen={isOpenDocument}
                setIsOpen={setIsOpenDocument}
                idDocumentDirecotry={props.documentDirecotryObject.idDocumentDirectoryTemplate}
            />

            <DocumentDirectoryTemplateEdit
                isOpen={isOpenEdit}
                setIsOpen={setIsOpenEdit}
                documentDirectoryArray={props.documentDirectoryArray}
                setDocumentDirectoryArray={props.setDocumentDirectoryArray}
                parent_idDocumentDirectory={props.documentDirecotryObject.idDocumentDirectoryTemplate}
                currentObject={props.documentDirecotryObject}
            />  


            <Menu 
             key={`menu-idDocumentDirectoryTemplate-${props.documentDirecotryObject.idDocumentDirectoryTemplate}`}
             open={isMenuOpen} 
             onClose={() => setIsMenuOpen(false)}
             anchorReference="anchorPosition"
             anchorPosition={{ top: mouseY, left: mouseX }}
            >
                <MenuList dense>
                    <MenuItem onClick={() => handleClickMenu(() => setIsOpenDocument(true))}>
                        <ListItemIcon><UploadFile/></ListItemIcon>
                        <ListItemText>Datei(en) hochladen</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenu(() => setIsOpenNewDirectory(true))}>
                        <ListItemIcon><CreateNewFolder/></ListItemIcon>
                        <ListItemText>Neuer Unterordner</ListItemText>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={() => handleClickMenu(() => setIsOpenEdit(true))}>
                        <ListItemIcon><Edit/></ListItemIcon>
                        <ListItemText>Umbenennen</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleClickMenu(() => handleRemove())}>
                        <ListItemIcon><Delete/></ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem>
                </MenuList>

            </Menu>
             <TreeItem 
                nodeId={`idDocumentDirectory-${props.documentDirecotryObject.idDocumentDirectoryTemplate}`} 
                label={props.documentDirecotryObject.Title}
                onContextMenu={handleRightClick}  
            >
                {props.documentDirectoryArray
                 .filter(x => x.Parent_idDocumentDirectoryTemplate === props.documentDirecotryObject.idDocumentDirectoryTemplate)
                 .map(x =>
                        <DocumentDirectoryTemplateTreeEntry
                            key={`idDocumentDirectoryTemplate-${x.idDocumentDirectoryTemplate}`}
                            documentDirecotryObject={x}
                            documentArray={props.documentArray}
                            documentDirectoryArray={props.documentDirectoryArray}
                            setDocumentArray={props.setDocumentArray}
                            setDocumentDirectoryArray={props.setDocumentDirectoryArray}
                        />
                  )
                }
                {props.documentArray
                 .filter(x => x.idDocumentDirectoryTemplate == props.documentDirecotryObject.idDocumentDirectoryTemplate)
                 .map(x =>
                    <DocumentDirectoryTemplateDocument
                        documentObject={x}
                        documentArray={props.documentArray}
                        setDocumentArray={props.setDocumentArray}
                    />
                 )

                }
            </TreeItem>
        </>
    )
}