import React, { useEffect, useState } from "react";
import { ILegalTransaction, ILegalTransactionFullObject } from "../Interfaces/ILegalTransaction";
import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import { ILegalTransactionType, ILegalTransactionTypeFullObject } from "../Interfaces/ILegalTransactionType";
import { getFetch } from "../hooks/useFetch";
import { IContact, ILegalTransactionHasContact } from "../Interfaces/IContact";
import { uploadFetch } from "../hooks/useFetch";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { LegalTransactionClientsOverview } from "./LegalTransactionClientsOverview";
import { useNavigate } from "react-router-dom";
import { IUser } from "../Interfaces/IUser";
import { ILegalRole } from "../Interfaces/ILegalRole";
import { IUserGroup } from "../Interfaces/IUserGroup";
import { Person, Group } from "@mui/icons-material";
interface IProps {
    idLegalTransaction: number;
    setIdLegalTransaction: Function;
    legalTransactionObjectFullObject: ILegalTransaction | undefined;
    setLegalTransactionObjectFullObject: Function;
    legalTransactionTypeArray: ILegalTransactionType[];
    legalTransactionArray: ILegalTransaction[];
    setLegalTransactionArray: Function;
    userArray: IUser[];
    userGroupArray: IUserGroup[];
}

export const LegalTransactionNew:React.FC<IProps> = (props) => {
    const navigaiton = useNavigate();
    const combinedOptions = [
        ...props.userArray.filter(x => !x.isNotary).map(x => ({
            type: 'Mitarbeiter',
            id: x.idUser,
            name: `${x.FirstName} ${x.LastName}`
        })),
        ...props.userGroupArray.map(group => ({
            type: 'Gruppe',
            id: group.idUserGroup,
            name: group.UserGroup
        }))
    ];
    const [selectedType, setSelectedType] = useState('user');
    const [idLegalTransactionType, setIdLegalTransactionType] = useState(0);
    const [idContact, setIdContact] = useState(-1);
    const [isClosed, setIsClosed] = useState(false);
    const [title,setTitle] = useState("");
    const [idUser, setIdUser] = useState(
        (props.userArray.filter(x => Boolean(x.isNotary)).length === 1) 
        ? props.userArray.filter(x => Boolean(x.isNotary))[0].idUser
        : null
    );
    const [employeeIdUser,setEmployeeIdUser] = useState<number|null>(() => {
        let idUser = Number(localStorage.getItem("idUser"));

        if (props.userArray.filter(x => Boolean(x.isNotary) === false).map(x => x.idUser).indexOf(idUser) > -1) {
            return idUser;
        } else {
            return null;
        }
    });
    const [userGroupId,setUserGroupId] = useState<number|null>(null);
    const [contactArray,setContactArray] = useState<IContact[] | undefined>(undefined);
    const [wasSuccessfullyContactArray, setWasSuccessfullyContactArray] = useState(true);
    //const [contactArray, setContactArray, wasSuccessfullyContactArray] = useFetch<IContact[]>("/contact");
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSavedSuccessfully,setWasSavedSuccessfully] = useState(true);
    //
    const [legalContactArray,setLegalContactArray] = useState<ILegalTransactionHasContact[]>([]);
    //
    const [localLegalTransactionFullObject,setLocalLegalTransactionFullObject] = useState<ILegalTransactionFullObject | undefined>(undefined);
    //
    const [legalTransactionType,setLegalTransactionType] = useState<ILegalTransactionTypeFullObject|null>(null);
    const [legalRoleArray,setLegalRoleArray] = useState<ILegalRole[]>([]);

    useEffect(() => {
        if (legalTransactionType !== null) {
            let tmpLegalRoleArray:ILegalRole[] = [];

            legalTransactionType.LegalRoleTemplateArray.map(x => {
                let tmpObject:ILegalRole = {
                    ...x,
                    idLegalRole: x.idLegalRoleTemplate,
                    idLegalTransaction: -1,
                    LegalRole: x.LegalRoleTemplate
                }
                tmpLegalRoleArray.push(tmpObject);
            })

            setLegalRoleArray(tmpLegalRoleArray);

            setIsLoading(false);
        }
    },[legalTransactionType])

    useEffect(() => {
        if (idLegalTransactionType > 0) {
            setIsLoading(true);
            getFetch("/legaltransactiontype/fullObject/",idLegalTransactionType,setLegalTransactionType)
        }
    },[idLegalTransactionType])

    const handleClose = () => {
        props.setIdLegalTransaction(null);
    }

    // macht eigentlich keinen sinn!
    // wird gespeichert und dann will man nochmal zurück ?
    /*const handleGoBack = () => {
        setIdLegalTransactionType(0);
        setIdContact(0);
        setTitle("");
        //setContactObject(getEmptyContact());
        setContactArray([]);
        setLocalLegalTransactionFullObject(undefined);
        setWasSaved(false);
    }*/

    const handleFinished = () => {
        if (localLegalTransactionFullObject !== undefined) {
            navigaiton(`/legaltransaction/${localLegalTransactionFullObject.idLegalTransaction}`);
            props.setIdLegalTransaction(localLegalTransactionFullObject.idLegalTransaction);
        }
    }


    const savedWrapper = (localLegalTransactionFullObject:ILegalTransactionFullObject) => {
        props.setLegalTransactionArray([
            localLegalTransactionFullObject,
            ...props.legalTransactionArray
            
        ])
        setLocalLegalTransactionFullObject(localLegalTransactionFullObject);
    }

    const handleSave = () => {
        if (idUser !== null) {
            let uploadLegalTransaction:ILegalTransactionFullObject = {
                idLegalTransactionType: idLegalTransactionType,
                idLegalTransactionState: 1,
                idLegalTransactionProgress: 10,
                Created_at: "",
                idLegalTransaction: -1,
                Title: title,
                ContactArray: legalContactArray,
                LegalPhaseArray: [],
                LegalTransactionGwGArray: [],
                CertificateArray: [],
                DraftArray: [],
                DraftMessageArray: [],
                InvoiceArray: [],
                Notary_idUser: idUser,
                RecordNumberArray: [],
                TransactionNumberArray: [],
                LegalRoleArray: [],
                LegalRoleCanInviteArray: [],
                Remarks: null,
                Employee_idUser: employeeIdUser,
                idUserGroup: userGroupId,
                LastPhaseUpdate_at: null,
                Closed_at: null,
                // Nur Intern
                DocumentArray: [],
                DocumentDirectoryArray: [],
                LegalTransactionMeetingArray: [],
                LegalTransactionMeetingEmailArray: [],
                LegalTransaction_has_LegalTransactionSpecialFunctionArray: []
            }
            setIsLoading(true);
            uploadFetch(
                "/legaltransaction/fullObject",
                true,
                uploadLegalTransaction,
                savedWrapper,
                setWasSavedSuccessfully,
                setWasSaved,
                //undefined,
                setIsLoading
            )
        }
    }

    
    useEffect(() => {
        if (props.idLegalTransaction < 0) {
            getFetch<IContact[]>("/contact",undefined,setContactArray,setWasSuccessfullyContactArray)
        }
    },[props.idLegalTransaction])


    const getDialogBodyTsx = () => {
        if (!wasSuccessfullyContactArray ) { return <>Fehler!</> }
        else if (contactArray === undefined || isLoading) { return <><CustomCircularProgress/></> }
        else if (wasSaved) {
            return(
                <>
                    <DialogContent>
                        <Box sx={{mt: 2}}/>
                        
                        {(wasSavedSuccessfully)
                            ? <Alert severity="success">Erfolgreich angelegt!</Alert>
                            // TODO: soll hier der Text spezifischer sein: Falls dies wiederholt auftritt kontaktieren sie uns bitte!
                            : <Alert severity="error">Es liegt ein Fehler mit der Datenverarbeitung vor!</Alert>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Schließen</Button>
                        <Button variant="contained" onClick={handleFinished}>Fortfahren</Button>
                    </DialogActions>
                </>
            )
        }
        else  {
            return(
                <>
                    <DialogContent>
                        <Box sx={{mt: 2}}/>
                        <Grid container spacing={2} sx={{mb: 2}}>
                            <Grid item sm={6}>
                                <TextField
                                    label="Zugewiesener Notar"
                                    value={(idUser === null) ? "" : idUser}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdUser(Number(event.target.value))}
                                    error={idUser === null}
                                    size="small"
                                    fullWidth
                                    select
                                >
                                    {props.userArray.filter(x => Boolean(x.isNotary)).map(x =>
                                        <MenuItem 
                                            key={`new-idUser-${x.idUser}`}
                                            value={x.idUser}
                                        >{x.FirstName} {x.LastName}</MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item sm={6}>
                                <Autocomplete
                                    options={combinedOptions}
                                    groupBy={(option) => option.type}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                selectedType === 'user' ? (
                                                    <span>
                                                        <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                                        Zugewiesener Mitarbeiter
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <Group style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                                        Zugewiesene Gruppe
                                                    </span>
                                                )
                                            }
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            
                                            <ListItemText primary={option.name}/>
                                        </ListItem>
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            if (newValue.type === 'Mitarbeiter') {
                                                setEmployeeIdUser(
                                                   newValue.id
                                                );
                                                setUserGroupId(null);
                                                setSelectedType('user');
                                            } else if (newValue.type === 'Gruppe') {
                                                setEmployeeIdUser(null);
                                                setUserGroupId(
                                                    newValue.id
                                                );
                                                setSelectedType('group');
                                            }
                                        } else {
                                            console.log('newValue is null');
                                            setEmployeeIdUser(null);
                                            setUserGroupId(null);
                                        }
                                    }}
                                    value={
                                        (selectedType === 'user' && employeeIdUser !== null)
                                            ? combinedOptions.find(option => option.type === 'Mitarbeiter' && option.id === employeeIdUser)
                                            : (selectedType === 'group' && userGroupId !== null)
                                            ? combinedOptions.find(option => option.type === 'Gruppe' && option.id === userGroupId)
                                            : null
                                    }
                                    disabled={isClosed}
                                />
                            </Grid>

                            <Grid item sm={4}>
                                <TextField
                                    label="Art"
                                    value={(idLegalTransactionType === 0) ? "" : idLegalTransactionType}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdLegalTransactionType(Number(event.target.value))}
                                    error={idLegalTransactionType === 0}
                                    size="small"
                                    fullWidth
                                    select
                                >
                                    {props.legalTransactionTypeArray.map(x =>
                                        <MenuItem 
                                            key={`new-idLegalTransactionType-${x.idLegalTransactionType}`}
                                            value={x.idLegalTransactionType}
                                        >{x.LegalTransactionType} {(Boolean(x.withDialog)) && <i> (Datenerf. auto. erstellt)</i>}</MenuItem>
                                    )}
                                </TextField>
                            </Grid>

                            <Grid item sm={8}>
                                <TextField
                                    label="Bezeichnung"
                                    value={title}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
                                    error={title === ""}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>


                        {/*
                        .
                        Hier entsteht das nicht reproduzierbare Problem
                        .
                        TODO:Fix doublicate
                        */}                    
                        <LegalTransactionClientsOverview
                            allContactArray={contactArray}
                            legalRoleArray={legalRoleArray}
                            contactArray={[]}
                            setContactArray={()=>{}}
                            newContactArray={legalContactArray}
                            setNewContactArray={setLegalContactArray}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                        <Button 
                            variant="contained" 
                            onClick={handleSave} 
                            disabled={idLegalTransactionType === 0 || idUser === null || idContact === 0 || title === "" }
                        >Speichern</Button>
                    </DialogActions>
                </>
            )
        }
    }
    


    return(
        <>
            <Dialog
                open={props.idLegalTransaction < 0 && props.legalTransactionObjectFullObject === undefined}
                onClose={handleClose}
                maxWidth="md" fullWidth
            >
                <DialogTitle>Neuer Vorgang</DialogTitle>
                
                {getDialogBodyTsx()}

            </Dialog>            
        </>
    )
}

