import React, { useEffect, useState } from "react";
import { IDayOffWork, IDayOffWorkFullObject } from "../Interfaces/IDayOffWork";
import { uploadFetch, useFetch } from "../hooks/useFetch";
import { Alert, Box, Button, Card, CardContent, Collapse, Grid, LinearProgress, MenuItem, Typography } from "@mui/material";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { IDayOffWorkCategory } from "../Interfaces/IDayOffWorkCategory";
import { CustomeTextField } from "../generic/CustomeTextField";
import { DocumentOverview } from "../Document/DocumentOverview";
import { IDocument } from "../Interfaces/IDocument";
import { IUser } from "../Interfaces/IUser";
import { getDaysOffWorkByCategory, getFreeVacation } from "./functions_dayOffWork";
import { IEmploymentContract } from "../Interfaces/IEmploymentContract";


interface IProps {
    idUser: number;
    userArray: IUser[];
    employmentContractArray: IEmploymentContract[];
    idDayOffWork: number;
    dayOffWorkArray: IDayOffWork[];
    setDayOffWorkArray: Function;
    dayOffWorkCategoryArray: IDayOffWorkCategory[];
    enableAdminSeetings: boolean;
    handleClosed: Function;
}



const getEmptyDayOff = (idUser:number) => {
    return {
        idDayOffWork: -1,
        idDayOffWorkCategory: 1,
        Comment: null,
        DaysOfWork: 0,
        FromDate: "",
        ToDate: "",
        idUser: idUser,
        isConfirmed: null,
        LastYearOpenVacation: null,
        TotalVacation: null
    } as IDayOffWork
}



export const DayOffWorkEdit:React.FC<IProps> = ({idUser, userArray, employmentContractArray, idDayOffWork, dayOffWorkArray, setDayOffWorkArray, dayOffWorkCategoryArray, enableAdminSeetings, handleClosed}) => {
    const [dayOffWorkFullObject, setDayOffWorkFullObject, wasSuccessfully] = useFetch<IDayOffWorkFullObject>("/dayoffwork/fullObject/",idDayOffWork);
    const [editDayOffWork, setEditDayOffWor]  = useState(getEmptyDayOff(idUser));
    const [documentArray,setDocumentArray] = useState<IDocument[]>([]);
    //
    const [wasConfirmedNull,setWasConfirmedNull] = useState(true);
    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(true);


    useEffect(() => { 
        if (dayOffWorkFullObject !== undefined) {
            setWasConfirmedNull(dayOffWorkFullObject.isConfirmed === null);
            setEditDayOffWor(dayOffWorkFullObject);
            setDocumentArray(dayOffWorkFullObject.DocumentArray);
        }
    },[dayOffWorkFullObject])

    function getDaysInDateRange(fromDate: Date | string, toDate: Date | string): number {
        fromDate = new Date(fromDate);
        toDate = new Date(toDate);
    
        // Überprüfen, ob beide Daten gültige Date-Objekte sind
        if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
            return 0; // Fehlerfall: ungültige Datumseingaben
        }
    
        // Zeitstempel der beiden Daten
        const fromTime = fromDate.getTime();
        const toTime = toDate.getTime();
    
        // Unterschied in Millisekunden berechnen
        const timeDiff = Math.abs(toTime - fromTime);
    
        // Anzahl der Wochen zwischen den Daten berechnen
        const weeks = Math.floor(timeDiff / (1000 * 3600 * 24 * 7));
    
        // Anzahl der Wochenenden (Samstage und Sonntage)
        let weekends = weeks * 2;
    
        // Start- und Endtag in der Woche
        const startDay = fromDate.getDay();
        const endDay = toDate.getDay();
    
        // Wenn der Starttag später als der Endtag ist, wird ein Wochenende abgezogen
        if (startDay > endDay) {
            weekends += 2;
        } else if (startDay === endDay) {
            // Wenn der Starttag und der Endtag gleich sind und einer davon ein Wochenende ist, wird ein Wochenende abgezogen
            if (startDay === 0 || startDay === 6) {
                weekends += 1;
            }
        } else {
            // Wenn der Starttag früher als der Endtag ist, wird das Wochenende am Anfang und am Ende abgezogen
            if (startDay === 0) { // Starttag ist Sonntag
                weekends += 1;
            } else if (startDay === 6) { // Starttag ist Samstag
                weekends += 2;
            }
    
            if (endDay === 6) { // Endtag ist Samstag
                weekends += 1;
            }
        }
    
        // Gesamtanzahl der Tage abzüglich der Wochenenden
        const totalDaysWithoutWeekends = Math.ceil(timeDiff / (1000 * 3600 * 24)) - weekends +1; // +1 da erster Tag nach zugerechnet werden soll
    
        return totalDaysWithoutWeekends;
    }


    const handleAfterSave = (res:IDayOffWorkFullObject) => {
        let testObject = dayOffWorkArray.find(x => x.idDayOffWork);

        if (testObject === undefined) {
            setDayOffWorkArray([
                ...dayOffWorkArray,
                res
            ])
        }
        else {
            setDayOffWorkArray([
                ...dayOffWorkArray.map(x => x.idDayOffWork === res.idDayOffWork ? res: x)
            ]) 
        }
        handleClosed();
    }

    const handleSave = () => {
        let uploadObject:IDayOffWorkFullObject = {
            ...editDayOffWork,
            DocumentArray: documentArray,
            isConfirmed: (editDayOffWork.isConfirmed === null) ? null : Boolean(editDayOffWork.isConfirmed)
        }
        uploadFetch("/dayoffwork/fullObject",(editDayOffWork.idDayOffWork < 0),uploadObject,handleAfterSave,setWasSuccessfullySaved,() => {},setIsLoading)
    }


    const getCurrelntUserString = () => {
        let testObject = userArray.find( x=> x.idUser === Number(editDayOffWork.Confirmed_idUser));

        if (testObject === undefined) {
            return "FEHLER"
        } else {
            return `${testObject.FirstName} ${testObject.LastName}`
        }
    }



    if (!wasSuccessfully && idDayOffWork > 0) { return <Alert severity="error">Fehler!</Alert> }
    else if (dayOffWorkFullObject === undefined && idDayOffWork > 0) { return <CustomCircularProgress/> }
    else {
        return(
            <>
                <Collapse in={!wasSuccessfullySaved}>
                    <Alert severity="error" sx={{mb: 2}}>
                        Die Fehltage konnten nicht gespeichert werde!
                        <br/>Verfügen Sie über die entsprechenden (System-) Berechtigungen für diesen Schritt?
                    </Alert>
                </Collapse>

                <Collapse in={isLoading}>
                    <LinearProgress />
                </Collapse>

                { (!wasConfirmedNull) &&
                    <Alert severity={editDayOffWork.isConfirmed == true ? "success" : "error"} sx={{mb: 2}}>
                        Diese Fehltage wurden von {getCurrelntUserString()} {editDayOffWork.isConfirmed == true ? "bestätigt" : "abgelehnt"}.
                    </Alert>
                }


                {(enableAdminSeetings && wasConfirmedNull) &&
                    <>
                        <Collapse in={wasConfirmedNull && editDayOffWork.isConfirmed !== null}>
                            <Alert severity="warning" sx={{mb: 2}}>
                                Die Bestätigung oder Ablehnung von Fehltagen kann nach dem Speichern nicht mehr geändert werden!
                            </Alert>
                        </Collapse>

                        <Typography variant="caption">Administration</Typography>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <CustomeTextField
                                            label="Antragsteller"
                                            attr="idUser"
                                            type="select"
                                            object={editDayOffWork}
                                            setObject={setEditDayOffWor}
                                            disabled={true}
                                        >
                                            {userArray.map(x =>
                                                <MenuItem key={`idUser-${x.idUser}`} value={x.idUser}>
                                                    {x.FirstName} {x.LastName}
                                                </MenuItem>
                                            )}
                                        </CustomeTextField>
                                    </Grid>

                                    <Grid item sm={12}>
                                        <CustomeTextField
                                            key={`isconfigmred`}
                                            label="Bestätigt"
                                            attr="isConfirmed"
                                            type="select"
                                            object={(editDayOffWork.isConfirmed === null ) ? {"isConfirmed" : -1} :  editDayOffWork}
                                            setObject={(res:any) => 
                                                setEditDayOffWor({
                                                    ...editDayOffWork,
                                                    isConfirmed: (res.isConfirmed === -1) ? null : res.isConfirmed
                                                } as any)
                                            }
                                            disabled={!wasConfirmedNull}
                                        >
                                            <MenuItem value={-1}>Nicht bestimmt</MenuItem>
                                            <MenuItem value={1}>Bestätigt</MenuItem>
                                            <MenuItem value={0}>Abgelehnt</MenuItem>
                                        </CustomeTextField>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </>
                }

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Von"
                            attr="idDayOffWorkCategory"
                            type="select"
                            object={editDayOffWork}
                            setObject={setEditDayOffWor}
                            disabled={!wasConfirmedNull}
                        >
                            {dayOffWorkCategoryArray.map(x =>
                                <MenuItem key={`idDayOffWorkCategory-${x.idDayOffWorkCategory}`} value={x.idDayOffWorkCategory}>
                                    {x.DayOffWorkCategory}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                    </Grid>
                    {(wasConfirmedNull) &&
                        <Grid item sm={12}>
                            <Typography>
                                { (editDayOffWork.idDayOffWorkCategory === 1)
                                    ? <>Sie haben dieses Jahr {getFreeVacation(idUser,employmentContractArray,dayOffWorkArray)} Tage Urlaub zur Verfügung</>
                                    : (editDayOffWork.idDayOffWorkCategory >= 5)
                                    ? <>Sie waren diesen Jahr {getDaysOffWorkByCategory(idUser,5,dayOffWorkArray,true)} Tage krank gemeldet.</>
                                    : <>Sie haben diesen Jahr {getDaysOffWorkByCategory(idUser,editDayOffWork.idDayOffWorkCategory,dayOffWorkArray)} Tage der Kategorie {dayOffWorkCategoryArray.find(x => x.idDayOffWorkCategory === editDayOffWork.idDayOffWorkCategory)?.DayOffWorkCategory} gebucht.</>

                                }
                            </Typography>
                        </Grid>
                    }
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Von"
                            attr="FromDate"
                            type="date"
                            object={editDayOffWork}
                            setObject={() => {}}
                            onChangeHandler={(event:React.ChangeEvent<HTMLInputElement>) => setEditDayOffWor({
                                ...editDayOffWork,
                                FromDate:  event.target.value, 
                                DaysOfWork: getDaysInDateRange(event.target.value, editDayOffWork.ToDate)
                            } as IDayOffWork) }
                            disabled={!wasConfirmedNull}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Von"
                            attr="ToDate"
                            type="date"
                            object={editDayOffWork}
                            setObject={() => {}}
                            onChangeHandler={(event:React.ChangeEvent<HTMLInputElement>) => setEditDayOffWor({
                                ...editDayOffWork,
                                ToDate:  event.target.value, 
                                DaysOfWork: getDaysInDateRange(editDayOffWork.FromDate, event.target.value)
                            } as IDayOffWork) }
                            disabled={!wasConfirmedNull}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Abwesenheit in Werktage"
                            attr="DaysOfWork"
                            type="number"
                            object={editDayOffWork}
                            setObject={setEditDayOffWor}
                            disabled={!wasConfirmedNull}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Kommentar"
                            attr="Comment"
                            type="string"
                            object={editDayOffWork}
                            setObject={setEditDayOffWor}
                            rows={5}
                            disabled={!wasConfirmedNull}
                        />
                    </Grid>
                </Grid>

                <Box sx={{mt: 3}}/>
                <DocumentOverview
                    documentArray={documentArray}
                    setDocumentArray={setDocumentArray}
                    title="Dokumente"
                    allowRemove={false}
                    allowUpload={wasConfirmedNull}
                />

                <Box sx={{mt: 5, float: "right"}}>
                    {(wasConfirmedNull) ?
                        <>
                            <Button variant="outlined" onClick={() => handleClosed()}>Abbruch</Button>
                            <Button 
                                variant="contained" 
                                sx={{ml: 2}} 
                                onClick={handleSave} 
                                disabled={isLoading || editDayOffWork.FromDate === "" || editDayOffWork.ToDate === ""}
                            >Speichern</Button>
                        </>
                        : <Button variant="contained" onClick={() => handleClosed()}>Okay</Button>
                    }

                    
                </Box>
                
            </>
        )
    }
}