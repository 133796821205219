import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry } from "../../Interfaces/ICountry";
import { ITextModule } from "../../Interfaces/ITextModule";

const initialState =  { storeTextModule: [] as ITextModule[] }; 

const sliceTextModule = createSlice({
    name: "TextModuleARRAY",
    initialState,
    reducers: {
        setTextModuleArray: (state, action: PayloadAction<ITextModule[]>) => {
            state.storeTextModule = [...action.payload];
        },
        addUpdateTextModuleArray: (state, action: PayloadAction<ITextModule>) => {
            let testObject = state.storeTextModule.find(x => x.idTextModule === action.payload.idTextModule);
            
            if (testObject) {
                state.storeTextModule = [...state.storeTextModule.map(x => x.idTextModule === action.payload.idTextModule ? action.payload : x)];
            } else {
                state.storeTextModule = [...state.storeTextModule, action.payload];
            }
        },
        deleteTextModuleArray: (state, action: PayloadAction<number>) => {
            state.storeTextModule = [...state.storeTextModule.filter(x => x.idTextModule !==  action.payload)];
        }
    }
})
export const { setTextModuleArray, addUpdateTextModuleArray, deleteTextModuleArray } = sliceTextModule.actions;
export default sliceTextModule.reducer;