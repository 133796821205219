import React, { useEffect, useState } from "react";
import { IUser, IUserFullObject } from "../../Interfaces/IUser";
import { IDayOffWork } from "../../Interfaces/IDayOffWork";
import { IEmploymentContract } from "../../Interfaces/IEmploymentContract";
import { Box, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { DayOffWorkEdit } from "../DayOffWorkEdit";
import { IDayOffWorkCategory } from "../../Interfaces/IDayOffWorkCategory";
import { useFetch } from "../../hooks/useFetch";
import { DayOffWorkSummaryRows } from "./DayOffWorkSummaryRows";
import { compageDateFirstOneSmallerEqual } from "../../core/generic_helper";
import { DayOffWorkUserSummary } from "./DayOffWorkUserSummary";
import { IHelpCalenderDay } from "../DayOffWorkOverviewTable";


interface IProps {
    baseDate: Date;
    userObject: IUser;
    userArray: IUser[];
    calenderHelpArray: IHelpCalenderDay[];
    employmentContractArray: IEmploymentContract[];
    dayOffWorkArray: IDayOffWork[];
    setDayOffWorkArray: Function;
    dayOffWorkCategoryArray: IDayOffWorkCategory[];
}



export const DayOffWorkUserRow:React.FC<IProps> = ({baseDate, userObject, userArray, calenderHelpArray, employmentContractArray, dayOffWorkArray, setDayOffWorkArray, dayOffWorkCategoryArray}) => {
    const [dayOffWorkUserArray,setDayOffWorkUserArray] = useState<IDayOffWork[]>([]);
    //const [userFullObject, setUserFullObject, wasSuccessfully] = useFetch<IUserFullObject>("/user/",userObject.idUser);
    //
    const [idDayOffWork, setIdDayOffWork] = useState<null|number>(null);
    const [selectIdDayOff,setSelectIdDayOff] = useState<IDayOffWork[]|null>(null);
    //
    const [isOpenSummary,setIsOpenSummary] = useState(false);


    useEffect(() => {
        //console.log(userObject);
        setDayOffWorkUserArray([
            ...dayOffWorkArray
                .filter(x => x.idUser === userObject.idUser)
                .filter(x => new Date(x.FromDate).getFullYear() === baseDate.getFullYear() || new Date(x.ToDate).getFullYear() === baseDate.getFullYear())
        ])
    },[dayOffWorkArray])


    const getSymboleForCategorie = (localDayOffWorkArray: IDayOffWork) => {
        const handleSymbole = (sym:string,isConfirmed:boolean|null) => {
            if (isConfirmed === null) {
                return sym
            }
            else if (Boolean(isConfirmed) === true) {
                return <b>{sym}</b>
            }
            else {
                return <s>{sym}</s>
            }
        }

        if (localDayOffWorkArray.idDayOffWorkCategory === 1 || localDayOffWorkArray.idDayOffWorkCategory === 2) {
            return handleSymbole("U.",localDayOffWorkArray.isConfirmed) 
        } 
        else if (localDayOffWorkArray.idDayOffWorkCategory === 3) {
            return handleSymbole("S.",localDayOffWorkArray.isConfirmed) 
        }
        else if (localDayOffWorkArray.idDayOffWorkCategory === 4) {
            return handleSymbole("Üb.",localDayOffWorkArray.isConfirmed) 
        }
        else {
            return handleSymbole("K.",localDayOffWorkArray.isConfirmed) 
        }
    }


    const getCurrentDate = (currenDate:Date) => {
        let testArray = dayOffWorkUserArray
            .filter(x => compageDateFirstOneSmallerEqual(new Date(x.FromDate),currenDate) && compageDateFirstOneSmallerEqual(currenDate ,new Date(x.ToDate)));

        if (testArray.length === 0) {
            return <TableCell sx={{borderRight: "solid 1px"}} />
        }
        else if (testArray.length === 1) {
            return <TableCell sx={{borderRight: "solid 1px", cursor: "pointer"}} onClick={() => setIdDayOffWork(testArray[0].idDayOffWork)}>
                {getSymboleForCategorie(testArray[0])}
            </TableCell>
        }
        else {
            return <TableCell sx={{borderRight: "solid 1px", cursor: "pointer"}} onClick={() => setSelectIdDayOff(testArray)}>
                {
                    testArray
                     .map(x => getSymboleForCategorie(x))
                }
            </TableCell>
        }
    } 

    
    return(
        <>
            <Dialog open={selectIdDayOff !== null} onClick={() => setSelectIdDayOff(null)} maxWidth="md" fullWidth>
                <DialogContent>
                    <Typography>
                        Bitte klicken Sie auf die gewünschte Fehltage
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Art</TableCell>
                                <TableCell>Angenommen</TableCell>
                                <TableCell>Von</TableCell>
                                <TableCell>Bis</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectIdDayOff?.map(x =>
                                <TableRow onClick={() => {setIdDayOffWork(x.idDayOffWork); setSelectIdDayOff(null)}} sx={{cursor: "pointer"}}>
                                    <TableCell>{dayOffWorkCategoryArray.find(y => y.idDayOffWorkCategory === x.idDayOffWorkCategory)?.DayOffWorkCategory}</TableCell>
                                    <TableCell>{(x.isConfirmed === null) ? "" : (Boolean(x.isConfirmed) === true) ? "Angenommen" : "Abgelehnt"}</TableCell>
                                    <TableCell>{new Date(x.FromDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{new Date(x.ToDate).toLocaleDateString()}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>

            <Dialog open={isOpenSummary} onClick={() => setIsOpenSummary(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <DayOffWorkUserSummary
                        key={`dayoffworkusersummary-${userObject.idUser}`}
                        idUser={userObject.idUser}
                        employmentContractArray={employmentContractArray}
                        dayOffWorkArray={dayOffWorkArray}
                        dayOffWorkCategoryArray={dayOffWorkCategoryArray}
                    />
                </DialogContent>
            </Dialog>


            <Dialog open={idDayOffWork !== null} onClose={() => setIdDayOffWork(null)}>
                <DialogTitle>Fehltag</DialogTitle>
                <DialogContent>
                    {(idDayOffWork !== null) &&
                        <DayOffWorkEdit
                            idUser={userObject.idUser}
                            userArray={userArray}
                            employmentContractArray={employmentContractArray}
                            idDayOffWork={idDayOffWork}
                            dayOffWorkArray={dayOffWorkArray}
                            setDayOffWorkArray={setDayOffWorkArray}
                            dayOffWorkCategoryArray={dayOffWorkCategoryArray}
                            enableAdminSeetings
                            handleClosed={() => setIdDayOffWork(null)}
                        />
                    }

                </DialogContent>
            </Dialog>


            <TableRow>
                <TableCell sx={{borderRight: "solid 1px", cursor: "pointer"}} onClick={() => setIsOpenSummary(true)}>
                    {`${userObject.FirstName} ${userObject.LastName}`}
                </TableCell>
                {calenderHelpArray.map(x => 
                    <>{getCurrentDate(x.Date)}</>
                )}
                <DayOffWorkSummaryRows
                    dayOffWorkUserArray={dayOffWorkUserArray}
                    userFullObject={
                        {
                            ...userObject, 
                            EmploymentContractArray: [...employmentContractArray.filter(x => x.idUser === userObject.idUser)] 
                        } as IUserFullObject
                    }
                    calenderHelpArray={calenderHelpArray}
                />
                
                    
            </TableRow>
        </>
    )
}