import React, { useState } from "react";
import { IDocument } from "../Interfaces/IDocument";
import { Button, Dialog, DialogActions, DialogContent, IconButton, TableCell, TableRow } from "@mui/material";
import { Delete, Download, QueryStats, Search } from "@mui/icons-material";
import { getFetch } from "../hooks/useFetch";
import { IDocumentDownloaded } from "../Interfaces/IDocumentDownloaded";
import { DocumentDownloaded } from "./DocumentDownloaded";
import { IMIMEType } from "../Interfaces/IMIMEType";


interface IProps {
    documentObject: IDocument;
    documentArray: IDocument[];
    setDocumentArray: Function;
    mimeTypeArray: IMIMEType[];
    allowRemove: boolean;
    downloadedArray?: IDocumentDownloaded[];
    justButtons?:boolean
}


export const DocumentRow:React.FC<IProps> = (props) => {
    const [documentObject, setDocumentObject] = useState(props.documentObject);
    //
    const [isOpenDownloaded,setIsOpenDownloaded] = useState(false);

    const base64Download = (localDocumentObject:IDocument) => {
        let currentMimeType = props.mimeTypeArray.find(x => x.idMIMEType === localDocumentObject.idMIMEType)

        if (localDocumentObject.Data !== undefined && currentMimeType !== undefined) {
            let byteCharacters = atob(localDocumentObject.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: currentMimeType.MIMEType});
            let blobUrl = URL.createObjectURL(blob);

            if (currentMimeType.isOpenInBrowser && false) {
                let newTab = window.open();
                newTab!.location.href = blobUrl;   
            }
            else {
                let a = document.createElement("a");
                a.href = blobUrl;
                a.download = localDocumentObject.FileName;
                a.click();
            }
        }
    }

    const wrapperDownload = (localDocumentObject:IDocument) => {
        setDocumentObject(localDocumentObject);
        base64Download(localDocumentObject);
    }

    const handleDownload = () => {
        if (documentObject.Data !== undefined) {
            base64Download(documentObject);
        }
        else {
            getFetch("/document/",documentObject.idDocument,wrapperDownload)
        }
    }

    const handleRemove = () => {
        props.setDocumentArray([
            ...props.documentArray.filter(x => x.idDocument !== documentObject.idDocument)
        ]);
    }


    const downloadedDialog = () => {
        return(
            <>
                <Dialog 
                 open={isOpenDownloaded} 
                 onClose={() => setIsOpenDownloaded(false)}
                 maxWidth="sm" fullWidth
                >
                    <DialogContent>
                        {isOpenDownloaded &&
                            <DocumentDownloaded
                                documentObject={documentObject}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setIsOpenDownloaded(false)}>Schließen</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }


    if (props.justButtons) {
        return(
            <>
                {downloadedDialog()}
                {(documentObject.idDocument > 0) &&
                    <IconButton title="Anzahl Downloads" onClick={() => setIsOpenDownloaded(true)}><Search/></IconButton>
                }
                <IconButton title="Datei herunterladen" onClick={handleDownload}><Download/></IconButton>
                {(props.allowRemove) && <IconButton title="Datei löschen" onClick={handleRemove}><Delete/></IconButton>}
            </>
        )
    } else {
        return(
            <>
                {downloadedDialog()}
                <TableRow>
                    <TableCell>{(documentObject.idDocument < 0) ? <>Neu</> : documentObject.idDocument}</TableCell>
                    {(props.downloadedArray !== undefined) && <TableCell>{props.downloadedArray.filter(x => x.idDocument === documentObject.idDocument).length}</TableCell>}
                    <TableCell>{documentObject.FileName}</TableCell>
                    <TableCell>
                        {(documentObject.idDocument > 0) &&
                            <IconButton title="Anzahl Downloads" onClick={() => setIsOpenDownloaded(true)}><Search/></IconButton>
                        }
                        <IconButton title="Datei herunterladen" onClick={handleDownload}><Download/></IconButton>
                        {(props.allowRemove || documentObject.idDocument < 0) &&
                            <IconButton title="Datei löschen" onClick={handleRemove}><Delete/></IconButton>
                        }
                        
                    </TableCell>
                </TableRow>
            </>
        )
    }
}