import React, { useState } from "react";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { ILegalRequirement } from "../../../Interfaces/ILegalRequirement";
import { ShowSystemVariable } from "../../../core/ShowSystemVariable";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { TextModuleSelector } from "../../../TextModule/TextModuleSelector";
import { CustomEditor } from "../../../core/CustomEditor";

interface IProps {
    currentRequirement: ILegalRequirement;
    setCurrentRequirement: Function;
    legalRequirements: ILegalRequirement[];
}


export const LegalRequirementEdit:React.FC<IProps> = (props) => {
    // Normale State
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(
        (props.currentRequirement.EmailBody === null)
        ? ContentState.createFromText("")
        : ContentState.createFromBlockArray(htmlToDraft(props.currentRequirement.EmailBody).contentBlocks)
      ),);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);

        if (props.currentRequirement) {
            props.setCurrentRequirement({
                ...props.currentRequirement,
                EmailBody: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            })
        }
    };

    return(
        <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <TextField 
                        label="Bedingung"
                        value={props.currentRequirement.LegalRequirement}
                        error={props.currentRequirement.LegalRequirement === ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, LegalRequirement: event.target.value})}
                        size="small"
                        fullWidth
                    /> 
                </Grid>

                <Grid item sm={6}>
                    <TextField 
                        label="Mitarbeiter kann Dateien hochladen"
                        value={(props.currentRequirement.canUploadFilesEmployees == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, canUploadFilesEmployees: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>
                <Grid item sm={6}>
                    <TextField 
                        label="Mandant kann Dateien hochladen"
                        value={(props.currentRequirement.canUploadFilesClient == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, canUploadFilesClient: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                { /*
                <Grid item sm={6}>
                    <TextField 
                        label="Vorgänger (Bedingung)"
                        value={(props.currentRequirement.Predecessor_idLegalRequirement == undefined) ? "" : props.currentRequirement.Predecessor_idLegalRequirement}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, Predecessor_idLegalRequirement: Number(event.target.value)})}
                        size="small"
                        fullWidth
                        select
                    >
                        {props.legalRequirements.map(x =>
                            <MenuItem 
                                key={`predecessor-idLegalRequirement-${x.idLegalRequirement}`}
                                value={x.idLegalRequirement}
                            >{x.LegalRequirement}</MenuItem>
                        )}
                    </TextField> 
                </Grid>
                */}

                <Grid item sm={12}>
                    <TextModuleSelector 
                        key={`transaction-legalrequirementedit`}
                        floatRight
                        idTextModuleCategory={4}
                        txt={props.currentRequirement.InfoText}
                        setTxt={(txt: string) => props.setCurrentRequirement({...props.currentRequirement, InfoText: txt})}
                    />
                    <TextField 
                        label="Infotext"
                        value={(props.currentRequirement.InfoText === null) ? "" : props.currentRequirement.InfoText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({
                            ...props.currentRequirement, 
                            InfoText: (event.target.value === "") ? null : event.target.value
                        })}
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                    /> 
                </Grid>

                <Grid item sm={4}>
                    <TextField 
                        label="Auto. E-Mail versenden (wenn erfüllt)"
                        value={(props.currentRequirement.sendMail == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, sendMail: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                <Grid item sm={8}>
                    <TextField 
                        label="E-Mail Betreff"
                        value={props.currentRequirement.EmailSubject}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, EmailSubject: event.target.value})}
                        size="small"
                        fullWidth
                    /> 
                </Grid>

                <CustomEditor 
                    txt={props.currentRequirement.EmailBody === null ? "" : props.currentRequirement.EmailBody} 
                    setTxt={(txt:string) =>
                        props.setCurrentRequirement({
                            ...props.currentRequirement,
                            EmailBody: (txt === "") ? null : txt
                        })
                    }
                    idTextModuleCategory={3}
                />

                <Grid item sm={6}>
                    <TextField 
                        label="Fehler möglich"
                        value={(props.currentRequirement.hasError == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, hasError: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>
                <Grid item sm={6}>
                    <TextField 
                        label="Freitext bei Fehler erlauben"
                        value={(props.currentRequirement.enableFreeTextError == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, enableFreeTextError: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                <Grid item sm={12}>
                    <TextField 
                        label="Fehlermeldung"
                        value={props.currentRequirement.TextOfError}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, TextOfError: event.target.value})}
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                    /> 
                </Grid>
            </Grid>
        </Box>
    )
}