import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { ILegalPhaseFullObject } from "../../../Interfaces/ILegalPhase";
import { TextModuleSelector } from "../../../TextModule/TextModuleSelector";

interface IProps {
    currentPhase: ILegalPhaseFullObject;
    currentPhaseEdit: ILegalPhaseFullObject;
    setCurrentPhaseEdit: Function;
    legalPhaseArray: ILegalPhaseFullObject[];
    setLegalPhaseArray: Function;
    setIsOpenNew?: Function;
}


export const LegalPhaseDialogEdit:React.FC<IProps> = (props) => {
    const handleSavePhase = () => {
        if (props.legalPhaseArray.map(x => x.idLegalPhase).indexOf(props.currentPhase.idLegalPhase) === -1) {
            props.setLegalPhaseArray([
                ...props.legalPhaseArray,
                props.currentPhaseEdit
            ])
        } else {
            props.setLegalPhaseArray([
                ...props.legalPhaseArray.map(x => (x.idLegalPhase === props.currentPhaseEdit.idLegalPhase) ? props.currentPhaseEdit : x)
            ])
        }
        setIsOpenEdit(false);
        (props.setIsOpenNew) && props.setIsOpenNew(false);
    }
    const [isOpenEdit, setIsOpenEdit] = useState(props.currentPhase.idLegalPhase < 0 && props.currentPhase.LegalPhase === "");
    const [isOpenAddPrecondition,  setIsOpenAddPrecondition] = useState(false);
    //
    const [precIdLegalReq, setPrecIdLegalReq] = useState<number | null>(null);

    const hanldeCloseEdit = () => {
        props.setCurrentPhaseEdit(props.currentPhase);
        setIsOpenEdit(false);
        (props.setIsOpenNew) && props.setIsOpenNew(false);
    }

    const handleAddPredcondigiont = () => {
        if (precIdLegalReq !== null) {
            let testObject = props.legalPhaseArray.find(x => x.idLegalPhase === precIdLegalReq);

            if (testObject !== undefined) {
                props.setCurrentPhaseEdit({
                    ...props.currentPhaseEdit,
                    PreconditionArray: [...props.currentPhaseEdit.PreconditionArray, testObject]
                } as ILegalPhaseFullObject);
                setIsOpenAddPrecondition(false);
            }
        }
    }

    const handleRemovePrecondition = (idLegalPhase:number) => {
        props.setCurrentPhaseEdit({
            ...props.currentPhaseEdit,
            PreconditionArray: [...props.currentPhaseEdit.PreconditionArray.filter(x => x.idLegalPhase !== idLegalPhase)]
        } as ILegalPhaseFullObject);

    }

    return(
        <>
            <Dialog
                open={isOpenAddPrecondition}
                onClose={() => setIsOpenAddPrecondition(false)}
                maxWidth="md" 
            >
                <DialogTitle>Vorgänger hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{mt: 2}}
                        label="Vorgänger"
                        value={(precIdLegalReq === null) ? "" : precIdLegalReq}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPrecIdLegalReq(Number(event.target.value))}
                        size="small"
                        fullWidth
                        select
                    >
                        {props.legalPhaseArray
                            .filter(y => y.idLegalPhase !== props.currentPhase.idLegalPhase)
                            .map(x =>
                                <MenuItem
                                    key={`idLegalPhase-${x.idLegalPhase}`}
                                    disabled={(props.currentPhaseEdit.PreconditionArray
                                            .filter(y => y.idLegalPhase !== props.currentPhase.idLegalPhase)
                                            .map(y => y.idLegalPhase)
                                            .indexOf(x.idLegalPhase) 
                                            !== -1
                                    )}
                                    value={x.idLegalPhase}
                                >{x.LegalPhase}</MenuItem>
                        )}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAddPrecondition(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddPredcondigiont}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={isOpenEdit}
                onClose={() => {}}
                maxWidth="lg" 
                fullWidth
            >
                <DialogTitle>Phase anpassen</DialogTitle>
                <DialogContent>
                    <TextField 
                        label="Phase"
                        sx={{mt: 2}}
                        value={props.currentPhaseEdit.LegalPhase}
                        error={props.currentPhaseEdit.LegalPhase === ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPhaseEdit({...props.currentPhaseEdit, LegalPhase: event.target.value})}
                        size="small"
                        fullWidth
                    /> 
                    <TextModuleSelector 
                        key={`legalphasedialogedit`}
                        floatRight
                        idTextModuleCategory={4}
                        txt={props.currentPhaseEdit.InfoText}
                        setTxt={(txt: string) => props.setCurrentPhaseEdit({...props.currentPhaseEdit, InfoText: txt})}
                    />
                    <TextField 
                        label="Infotext"
                        //sx={{mt: 2}}
                        value={(props.currentPhaseEdit.InfoText === null) ? "" : props.currentPhaseEdit.InfoText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                            (event.target.value === "")
                                ? props.setCurrentPhaseEdit({...props.currentPhaseEdit, InfoText: null})
                                : props.setCurrentPhaseEdit({...props.currentPhaseEdit, InfoText: event.target.value})
                        }
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                    /> 

                    <Typography variant="h6" sx={{mt: 3}}>
                        Vorgänger
                        <IconButton sx={{float: "right"}} onClick={() => { setPrecIdLegalReq(null); setIsOpenAddPrecondition(true); }}>
                            <Add/>
                        </IconButton>
                    </Typography>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vorgänger</TableCell>
                                <TableCell sx={{maxWidth: 30}}>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.currentPhaseEdit.PreconditionArray.map(x =>
                                <TableRow>
                                    <TableCell>{x.LegalPhase}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleRemovePrecondition(x.idLegalPhase)}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={hanldeCloseEdit}>Abbruch</Button>
                    <Button 
                        variant="contained" 
                        onClick={handleSavePhase}
                        disabled={props.currentPhaseEdit.LegalPhase === ""}
                    >Übernehmen</Button>
                </DialogActions>
            </Dialog>

            <IconButton 
                size="small"
                 onClick={() => { props.setCurrentPhaseEdit(props.currentPhase); setIsOpenEdit(true)}}>
            <Edit/></IconButton>
        </>
    )

}
