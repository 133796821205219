import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ChildrenRow } from "./ChildrenRow";
import { ChildrenAddDialog } from "./ChildrenAddDialog";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { IChildren } from "../../../../../Interfaces/IChildren";
import { IInherit } from "../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../Interfaces/IMarriage";
import { GrandChildrenAddDialog } from "./grandChildrenAddDialog";
import { GreatGrandChildrenAddDialog } from "./greatGrandChildrenAddDialog";
import { Add } from "@mui/icons-material";
import { GreatGreatGrandChildrenAddDialog } from "./greatGreatGrandChildrenAddDialog";

export interface IChildrenTableColDef {
  titleFirstParent: string;
  isSecondParentEnabled: boolean;
}

interface IProps {
  inheritObject: IInherit;
  idPersonInherit: number;
  firstParentArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  marraigeArray: IMarriage[];
}

export const ChildrenTable: React.FC<IProps> = (props) => {
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [isNewOpen2, setIsNewOpen2] = useState(false);
  const [isNewOpen3, setIsNewOpen3] = useState(false);
  const [isNewOpen4, setIsNewOpen4] = useState(false);
  const [childrens, setChildrens] = useState<IChildren[]>([]);
  const [grandChildrens, setGrandChildrens] = useState<IChildren[]>([]);
  const [greatGrandChildrens, setGreatGrandChildrens] = useState<IChildren[]>(
    []
  );
  const [greatGreatGrandChildrens, setGreatGreatGrandChildrens] = useState<
    IChildren[]
  >([]);

  useEffect(() => {
    const parentChildren = props.childrenArray.filter(
      (children) => children.Parent1_idPerson === props.personArray[0].idPerson
    );
    setChildrens(parentChildren);
  }, [props.childrenArray, props.personArray]);

  useEffect(() => {
    const childIds = childrens.map((child) => child.Children_idPerson);
    const grandChildrens = props.childrenArray.filter((child) =>
      childIds.includes(child.Parent1_idPerson)
    );
    setGrandChildrens(grandChildrens);
  }, [props.childrenArray, childrens]);

  useEffect(() => {
    const grandChildsIds = grandChildrens.map(
      (grandChild) => grandChild.Children_idPerson
    );
    const greatGrandChildrens = props.childrenArray.filter((child) =>
      grandChildsIds.includes(child.Parent1_idPerson)
    );
    setGreatGrandChildrens(greatGrandChildrens);
  }, [grandChildrens, props.childrenArray]);

  useEffect(() => {
    const greatGrandChildsIds = greatGrandChildrens.map(
      (greatGrandChild) => greatGrandChild.Children_idPerson
    );
    const greatGreatGrandChildrens = props.childrenArray.filter((child) =>
      greatGrandChildsIds.includes(child.Parent1_idPerson)
    );
    setGreatGreatGrandChildrens(greatGreatGrandChildrens);
  }, [greatGrandChildrens, props.childrenArray]);

  const hasDeceasedChildren = props.personArray.some(
    (person) =>
      childrens
        .map((child) => child.Children_idPerson)
        .includes(person.idPerson) && person.DateOfDeath
  );

  const hasDeceasedGrandChildren = props.personArray.some(
    (person) =>
      grandChildrens
        .map((grandchild) => grandchild.Children_idPerson)
        .includes(person.idPerson) && person.DateOfDeath
  );

  const hasDeceasedGreatGrandChildren = props.personArray.some(
    (person) =>
      greatGrandChildrens
        .map((greatGrandChildren) => greatGrandChildren.Children_idPerson)
        .includes(person.idPerson) && person.DateOfDeath
  );

  return (
    <>
      <Typography variant="h5">Auflistung der Nachkommen</Typography>

      <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
        Bitte erfassen Sie alle Nachkommen von{" "}
        {props.firstParentArray
          .map((x) => `${x.FirstName} ${x.LastName}`)
          .join(" und ")}
      </Typography>

      {isNewOpen && (
        <ChildrenAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew={setIsNewOpen}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
        />
      )}
      {isNewOpen2 && (
        <GrandChildrenAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew2={setIsNewOpen2}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
        />
      )}
      {isNewOpen3 && (
        <GreatGrandChildrenAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew2={setIsNewOpen3}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
        />
      )}

      {isNewOpen4 && (
        <GreatGreatGrandChildrenAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew2={setIsNewOpen4}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
        />
      )}

      <Typography variant="h6">Kinder</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
            <TableCell sx={{ width: "22%" }}>Name</TableCell>
            <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
            <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
            <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {childrens.map((x, idx) => (
            <ChildrenRow
              inheritObject={props.inheritObject}
              idPersonInherit={props.idPersonInherit}
              key={`idChildren-${x.idChildren}`}
              index={idx}
              currentChildren={x}
              setIsOpenNew={setIsNewOpen}
              personArray={props.personArray}
              setPersonArray={props.setPersonArray}
              personHasDocument={props.personHasDocument}
              setPersonHasDocument={props.setPersonHasDocument}
              childrenArray={props.childrenArray}
              setChildrenArray={props.setChildrenArray}
            />
          ))}
        </TableBody>
        <br></br>
        <Button
          variant="contained"
          onClick={() => setIsNewOpen(true)}
          sx={{
            float: "left",
            "& .MuiButton-startIcon": {
              marginRight: "2px", // Adjust the margin-right here
            },
          }}
          startIcon={
            <Add
              sx={{
                fontSize: 22,
                position: "relative",
                bottom: 1,
                marginRight: "0px",
              }}
            />
          }
        >
          Kind
        </Button>
      </Table>

      <br />

      {hasDeceasedChildren && (
        <>
          <Typography variant="h6">Enkelkinder</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
                <TableCell sx={{ width: "22%" }}>Name</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
                <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grandChildrens.map((x, idx) => (
                <ChildrenRow
                  inheritObject={props.inheritObject}
                  idPersonInherit={props.idPersonInherit}
                  key={`idChildren-${x.idChildren}`}
                  index={idx}
                  currentChildren={x}
                  setIsOpenNew={setIsNewOpen2}
                  personArray={props.personArray}
                  setPersonArray={props.setPersonArray}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={props.setPersonHasDocument}
                  childrenArray={props.childrenArray}
                  setChildrenArray={props.setChildrenArray}
                />
              ))}
            </TableBody>
            <br></br>
            <Button
              variant="contained"
              onClick={() => setIsNewOpen2(true)}
              sx={{
                float: "left",
                "& .MuiButton-startIcon": {
                  marginRight: "2px", // Adjust the margin-right here
                },
              }}
              startIcon={
                <Add
                  sx={{
                    fontSize: 22,
                    position: "relative",
                    bottom: 1,
                    marginRight: "0px",
                  }}
                />
              }
            >
              Enkelkind
            </Button>
          </Table>
        </>
      )}

      <br />

      {hasDeceasedGrandChildren && (
        <>
          <Typography variant="h6"> Urenkelkinder</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
                <TableCell sx={{ width: "22%" }}>Name</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
                <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {greatGrandChildrens.map((x, idx) => (
                <ChildrenRow
                  inheritObject={props.inheritObject}
                  idPersonInherit={props.idPersonInherit}
                  key={`idChildren-${x.idChildren}`}
                  index={idx}
                  currentChildren={x}
                  setIsOpenNew={setIsNewOpen3}
                  personArray={props.personArray}
                  setPersonArray={props.setPersonArray}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={props.setPersonHasDocument}
                  childrenArray={props.childrenArray}
                  setChildrenArray={props.setChildrenArray}
                />
              ))}
            </TableBody>
            <br></br>
            <Button
              variant="contained"
              onClick={() => setIsNewOpen3(true)}
              sx={{
                float: "left",
                "& .MuiButton-startIcon": {
                  marginRight: "2px", // Adjust the margin-right here
                },
              }}
              startIcon={
                <Add
                  sx={{
                    fontSize: 22,
                    position: "relative",
                    bottom: 1,
                    marginRight: "0px",
                  }}
                />
              }
            >
              Urenkelkind
            </Button>
          </Table>
        </>
      )}

      <br />

      {hasDeceasedGreatGrandChildren && (
        <>
          <Typography variant="h6"> Ururenkelkinder</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
                <TableCell sx={{ width: "22%" }}>Name</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
                <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {greatGreatGrandChildrens.map((x, idx) => (
                <ChildrenRow
                  inheritObject={props.inheritObject}
                  idPersonInherit={props.idPersonInherit}
                  key={`idChildren-${x.idChildren}`}
                  index={idx}
                  currentChildren={x}
                  setIsOpenNew={setIsNewOpen4}
                  personArray={props.personArray}
                  setPersonArray={props.setPersonArray}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={props.setPersonHasDocument}
                  childrenArray={props.childrenArray}
                  setChildrenArray={props.setChildrenArray}
                />
              ))}
            </TableBody>
            <br></br>
            <Button
              variant="contained"
              onClick={() => setIsNewOpen4(true)}
              sx={{
                float: "left",
                "& .MuiButton-startIcon": {
                  marginRight: "2px", // Adjust the margin-right here
                },
              }}
              startIcon={
                <Add
                  sx={{
                    fontSize: 22,
                    position: "relative",
                    bottom: 1,
                    marginRight: "0px",
                  }}
                />
              }
            >
              Ururenkelkind
            </Button>
          </Table>
        </>
      )}
    </>
  );
};
