import React, { useEffect, useState } from "react";
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams, GridValueGetterParams, deDE } from "@mui/x-data-grid";
import { Add, Check, Close } from "@mui/icons-material";
import { useFetch } from "../hooks/useFetch";
import { ILegalTransactionType, ILegalTransactionTypeFullObject } from "../Interfaces/ILegalTransactionType";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { LegalTransactionTypeEdit } from "../LegalTransactionType/LegalTransactionTypeEdit";
import { ClientsEdit } from "./ClientsEdit";
import { IContact } from "../Interfaces/IContact";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { ShowWasSaved } from "../core/ShowWasSaved";
import { useSelector } from "react-redux";
import { State } from "../redux/mainReducer";




export const ClientsOverview: React.FC = () => {
    const params = useParams();
    const navigation = useNavigate();

    const contactGroupArray = useSelector((state: State) => state.contactGroup.storeContactGroup);

    const [contactArray, setcontactArray, wasLocalSuccessfullycontactArray] = useFetch<IContact[]>("/contact");
    const [idContact, setidContact] = useState<number | null>((params.idContact == undefined) ? null : Number(params.idContact));
    //
    const [isOpenAreYourSure,setIsOpenAreYourSure] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);
    // Suche
    const [searchTerm, setSearchTerm] = useState("");
    // 
    const [wasRemoved,setWasRemoved] = useState(false);



    const columns: GridColDef[] = [
        { field: 'idContact', headerName: 'Nr.', width: 70 },
        { 
            field: 'idContactGroup', 
            headerName: 'Gruppe', 
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
                (params.row.idContactGroup == null) 
                ? ""
                : contactGroupArray.find(x => x.idContactGroup == params.row.idContactGroup)?.ContactGroup 
            ,
        },
        { 
            field: 'last_login_at', 
            headerName: 'Letzte Anmeldung', 
            width: 160,
            renderCell: (params) => (params.value === null) ? "" : new Date(params.value).toLocaleString()
        },
        { 
            field: 'loginPossible', 
            headerName: 'Login', 
            width: 70,
            renderCell: (params) => (Boolean(params.value)) ? <Check/> : <Close/>
        },

        { 
            field: 'isReceivingEmailEnabled', 
            headerName: 'E-Mails', 
            width: 70,
            renderCell: (params) => (Boolean(params.value)) ? <Check/> : <Close/>

        },
        { field: 'FirstName', headerName: 'Vorname', width: 170 },
        { field: 'LastName', headerName: 'Nachname', width: 170 },
        { field: 'Email', headerName: 'E-Mail-Adresse', flex: 1 },
    ];




    const handleClose = () => {
        setIsOpenAreYourSure(true)
    }

    const handleFinalOpen = () => {
        setIsOpenAreYourSure(false);
        setidContact(null);
    }

    const handleChangeIdContact = (params: GridRowParams<any>) => {
        setidContact(Number(params.row.idContact));
        navigation(`/clients/${params.row.idContact}`);
    }


    const handleSearchTerm = () => {
        if (contactArray === undefined) {
            return [];
        }
        else if (searchTerm === "") {
            return contactArray
        } else {
            return contactArray.filter(x =>
                String(x.LastName).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.FirstName).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.PhoneNumber).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || String(x.Email).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        }
    }

    const handleAfterRemove = () => {
        if (contactArray !== undefined && idContact !== null)  {
            setcontactArray([
                ...contactArray.filter(x => x.idContact !== idContact)
            ])
            setWasRemoved(true);
            setidContact(null);
            navigation(`/clients`);
        }
    }


    if (contactArray == undefined) { return <><CustomCircularProgress /></> }
    return (
        <>
            <ShowWasSaved
                wasSaved={wasSaved}
                setWasSaved={setWasSaved}
                wasSuccessfullySaved={wasSavedSuccesfully}
            />


            <ShowWasSaved
                wasSaved={wasRemoved}
                setWasSaved={setWasRemoved}
                wasSuccessfullySaved={true}
                text="Erfolgreich gelöscht!"
            />

            <Dialog
                open={isOpenAreYourSure}
                onClose={() => {}}
            >
                <DialogTitle>Warnung</DialogTitle>
                <DialogContent>
                    Sind Sie sicher, dass Sie den Mandanten ohne Speichern schließen wollen?
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleFinalOpen}>Schließen</Button>
                    <Button variant="contained" onClick={() => setIsOpenAreYourSure(false)} color="success">Abbruch</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={idContact !== null}
                onClose={handleClose}
                maxWidth="xl" fullWidth
            >
                <ClientsEdit
                    key={idContact}
                    idContact={idContact}
                    setidContact={setidContact}
                    contactArray={contactArray}
                    setContactArray={setcontactArray}
                    //
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setWasSaved={setWasSaved}
                    setWasSavedSuccesfully={setWasSavedSuccesfully}
                    handleAfterRemove={handleAfterRemove}
                    handleClose={handleClose}
                />
            </Dialog>


            <Box sx={{ m: 2, mt: 0 }}>
                <Typography variant="h4">
                    Mandanten
                    <IconButton sx={{ float: "right" }} onClick={() => setidContact((idContact != null && idContact == -1 ) ? idContact-1 : -1)}><Add /></IconButton>
                </Typography>

                <TextField 
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />


                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            getRowId={(row) => row.idContact}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 25,
                                    },
                                },
                            }}
                            autoHeight
                            onRowClick={(params, event, details) => handleChangeIdContact(params)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
