import React, { useEffect, useState } from "react";
import { ILegalRoleTemplate } from "../../Interfaces/ILegalRoleTemplate";
import { Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { Add, Check, Clear } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams, deDE } from "@mui/x-data-grid";
import { LegalRoleTemplateEdit } from "./LegalRoleTemplateEdit";


interface IProps {
    legalRoleTemplateArray: ILegalRoleTemplate[];
    setLegalRoleTemplateArray: Function;
}

const columns: GridColDef[] = [

    { field: 'canInvite', 
      headerName: 'Einladen', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.canInvite))
        ? <Check/>
        : <Clear/>
    },
    { field: 'canUpload', 
      headerName: 'Hochladen', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.canUpload))
        ? <Check/>
        : <Clear/>
    },
    { field: 'viewDataCollection', 
      headerName: 'Datener.', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.viewDataCollection))
        ? <Check/>
        : <Clear/>
    },
    { field: 'viewDraft', 
      headerName: 'Entwurf', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.viewDraft))
        ? <Check/>
        : <Clear/>
    },
    { field: 'viewDate', 
      headerName: 'Termine', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.viewDraft))
        ? <Check/>
        : <Clear/>
    },
    { field: 'viewTransaction', 
      headerName: 'Abwicklung', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.viewTransaction))
        ? <Check/>
        : <Clear/>
    },
    { field: 'viewCertificate', 
      headerName: 'Urkunden', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.viewCertificate))
        ? <Check/>
        : <Clear/>
    },
    { field: 'viewInvoice', 
      headerName: 'Rechnungen', 
      width: 100,
      renderCell: (params: GridRenderCellParams) =>
        (Boolean(params.row.viewInvoice))
        ? <Check/>
        : <Clear/>
    },
    { field: 'LegalRoleTemplate', 
      headerName: 'Bezeichnung', 
      flex: 1
    },
]


export const LegalRoleTemplateOverview:React.FC<IProps> = (props) => {
    const [idLegalRole, setIdLegalRole] = useState<number|null>(null);


    return(
        <>
            <Typography variant="h6" sx={{mt: 5}}>
                Rollen
                <IconButton sx={{float: "right"}} onClick={() => setIdLegalRole(-1)}><Add/></IconButton>
            </Typography>
            <br />

            <DataGrid
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                rows={props.legalRoleTemplateArray}
                columns={columns}
                getRowId={(row) => row.idLegalRoleTemplate}
                onRowClick={(params,event,details) => setIdLegalRole(Number(params.id))}
                autoHeight
            />

            <Dialog open={idLegalRole !== null} onClose={() => setIdLegalRole(null)} maxWidth="lg" fullWidth>
                <DialogContent>
                    <>
                        {(idLegalRole !== null) &&
                            <LegalRoleTemplateEdit
                                idLegalRoleRemplate={idLegalRole}
                                setIdLegalRoleRemplate={setIdLegalRole}
                                legalRoleTemplateArray={props.legalRoleTemplateArray}
                                setLegalRoleTemplateArray={props.setLegalRoleTemplateArray}
                            />
                        }
                    </>
                </DialogContent>
            </Dialog>
        </>
    )
}