import React, { useEffect, useRef, useState } from "react";
import { ILegalTransaction, ILegalTransactionCompanyFormation, ILegalTransactionFullObject, ILegalTransactionInherit, ILegalTransactionMortgage, ILegalTransactionPurchaseContract, isILegalTransactionInherit } from "../Interfaces/ILegalTransaction";
import { deleteFetch, uploadFetch, useFetch } from "../hooks/useFetch";
import { Alert, Autocomplete, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, LinearProgress, ListItem, ListItemIcon, ListItemText, MenuItem, Step, StepLabel, Stepper, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ILegalTransactionType } from "../Interfaces/ILegalTransactionType";
import { ICompanyFormationFullObject } from "../Interfaces/ICompanyFormation";
import { InheritMain } from "./Inherit/InheritMain";
import { ILegalPhaseFullObject } from "../Interfaces/ILegalPhase";
import { LegalPhaseMain } from "./LegalPhase/LegalPhaseMain";
import { LegalTransactionNew } from "./LegalTransactionNew";
import { IContact, ILegalTransactionHasContact } from "../Interfaces/IContact";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { ILegalTransactionState } from "../Interfaces/ILegalTransactionState";
import { LegalTransactionClientsOverview } from "./LegalTransactionClientsOverview";
import { IDocument } from "../Interfaces/IDocument";
import { DocumentOverview } from "../Document/DocumentOverview";
import { ILegalTransactionProgress } from "../Interfaces/ILegalTransactionProgress";
import { IInheritFullObject } from "../Interfaces/IInherit";
import { AddCircle, Delete, Group, Person } from "@mui/icons-material";
import { IUser } from "../Interfaces/IUser";
import { ILegalRequirementState } from "../Interfaces/ILegalRequirementState";
import { ILegalRole } from "../Interfaces/ILegalRole";
import { ILegalRole_canInvite } from "../Interfaces/ILegalRole_canInvite";
import { IDocumentDownloaded } from "../Interfaces/IDocumentDownloaded";
import { CompanyFormationMain } from "./CompanyFormation/CompanyFormationMain";
import { LegalTransactionDraftOverview } from "./LegalTransactionDraftOverview";
import { IDraftMessageFullObject } from "../Interfaces/IDraftMessage";
import { ILegalRequirementTemplate } from "../Interfaces/ILegalRequirementTemplate";
import { IPurchaseContractFullObject } from "../Interfaces/IPurchaseContract";
import { PurchaseContractMain } from "./PurchaseContract/PurchaseContractMain";
import { IDocumentDirectory } from "../Interfaces/IDocumentDirectory";
import { DocumentDirectoryOverview } from "./DocumentDirectory/DocumentDirectoryOverview";
import { IDocumentDirectoryHasDocument } from "../Interfaces/IDocumentDirectoryHasDocument";
import { ILegalTransactionLog } from "../Interfaces/ILegalTransactionLog";
import { ILegalTransactionMeeting } from "../Interfaces/ILegalTransactionMeeting";
import { IMeetingEmailTemplate } from "../Interfaces/IMeetingEmailTemplate";
import { LegalTransactionMeetingOverview } from "./LegalTransactionMeeting/LegalTransactionMeetingOverview";
import { ILegalTransactionMeetingEmail } from "../Interfaces/ILegalTransactionMeetingEmail";
import {CompanyChangeMain} from "./CompanyChange/CompanyChangeMain";
import {RASummary} from "./RegisteredAssociation/Childs/RASummary";
import {
    ILegalTransaction_has_LegalTransactionSpecialFunction
} from "../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction";
import {SpecialFunctionOverview} from "./LegalTransactionFunctions/SpecialFunctionOverview";
import { ILegalTransactionRecordNumber } from "../Interfaces/ILegalTransactionRecordNumber";
import { ILegalTransactionTransactionNumber } from "../Interfaces/ILegalTransactionTransactionNumber";
import { ILegalTransactionGwGFullObject } from "../Interfaces/ILegalTransactionGwG";
import { IMortgageFullObject } from "../Interfaces/IMortgage";
import {MortgageMain} from "./Mortgage/MortgageMain";
import { IUserGroup } from "../Interfaces/IUserGroup";


interface IProps {
    idLegalTransaction: number;
    setIdLegalTransaction: Function;
    legalTransactionArray: ILegalTransaction[];
    setLegalTransactionArray: Function;
    legalStateArray: ILegalTransactionState[];
    legalTypeArray: ILegalTransactionType[];
    legalProgressArray: ILegalTransactionProgress[];
    legalReqState: ILegalRequirementState[];
    userArray: IUser[];
    userGroupArray: IUserGroup[];
    meetingEmailTemplateArray: IMeetingEmailTemplate[];
    //
    isLoading: boolean;
    setIsLoading: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
    handleAfterRemove: Function;

}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const getEmptyLegalTransaction = () => {
    return {
        idLegalTransaction: -1,
        Created_at: "2020-02-02",
        Closed_at: null,
        idContact: -1,
        idLegalTransactionState: 1,
        idLegalTransactionType: -1,
        idLegalTransactionProgress: 10,
        idUserGroup: null,
        UserGroup: "",
        Title: "",
        Notary_idUser: -1,
        Remarks: null,
        Employee_idUser: null,
        LastPhaseUpdate_at: null
    } as ILegalTransaction
}


export const LegalTransactionEdit: React.FC<IProps> = (props) => {

    const combinedOptions = [
        ...props.userArray.filter(x => !x.isNotary).map(x => ({
            type: 'Mitarbeiter',
            id: x.idUser,
            name: `${x.FirstName} ${x.LastName}`
        })),
        ...props.userGroupArray.map(group => ({
            type: 'Gruppe',
            id: group.idUserGroup,
            name: group.UserGroup
        }))
    ];
    
    const [legalTransactionFullObject, setLegalTransactionFullObject, wasSuccessfully] = useFetch<
        ILegalTransactionFullObject
        | ILegalTransactionCompanyFormation
        | ILegalTransactionInherit
        | ILegalTransactionPurchaseContract
        | ILegalTransactionMortgage
    >("/legaltransaction/fullObject/", props.idLegalTransaction);
    const [legalRequirementTemplateArray, setLegalRequirementTemplateArray, wasSuccessfullyLegalRequirementTemplateArray] = useFetch<ILegalRequirementTemplate[]>("/legalrequirementtemplate")
    //
    const [isClosed, setIsClosed] = useState(false);
    const [isAutocompleteClosed, setIsAutocompleteClosed] = useState(false);

    //
    const [recordNumberArray, setRecordNumberArray] = useState<ILegalTransactionRecordNumber[]>([]);
    const [transactionNumberArray, setTransactionNumberArray] = useState<ILegalTransactionTransactionNumber[]>([]);
    //
    const [legalTransactionObject, setLegalTransactionObject] = useState(getEmptyLegalTransaction());
    const [certificationArray, setCertificationArray] = useState<IDocument[]>([]);
    const [invoiceArray, setInvoiceArray] = useState<IDocument[]>([]);
    const [draftArray, setDraftArray] = useState<IDocument[]>([]);
    //
    const [draftMessageArray, setDraftMessageArray] = useState<IDraftMessageFullObject[]>([]);
    //
    const [documentArray, setDocumentArray] = useState<IDocumentDirectoryHasDocument[]>([]);
    const [documentDirectoryArray, setDocumentDirectoryArray] = useState<IDocumentDirectory[]>([]);
    // Termine
    const [meetingArray, setMeetingArray] = useState<ILegalTransactionMeeting[]>([]);
    const [meetingEmailArray, setMeetingEmailArray] = useState<ILegalTransactionMeetingEmail[]>([]);
    //
    const [downloadedArray, setDownloadedArray, wasSuccessfullyDownloadedArray] = useFetch<IDocumentDownloaded[]>("/documentdownloaded");
    //
    const [logArray, setLogArray] = useState<ILegalTransactionLog[]>([]);
    //const [legalTransactionCompanyFormation,setLegalTransactionCompanyFormation] = useState<ILegalTransactionCompanyFormation|null>(null);
    //const [legalTransactionInherit,setLegalTransactionInherit] = useState<ILegalTransactionInherit|null>(null);
    //
    const [companyFormation, setCompanyFormation] = useState<ICompanyFormationFullObject | null>(null);
    const [inheritObject, setInheritObject] = useState<IInheritFullObject | null>(null);
    useEffect(() => {
        console.log("legalTransactionEdit")
        console.log(inheritObject)
    }, [inheritObject])
    const [purchaseContractObject, setPurchaseContractObject] = useState<IPurchaseContractFullObject | null>(null);
    const [mortgageObject, setMortgageObject] = useState<IMortgageFullObject| null>(null);

    const [legalPhaseFullObjectArray, setLegalPhaseFullObjectArray] = useState<ILegalPhaseFullObject[]>([]);
    const [legalRoleArray, setLegalRoleArray ] = useState<ILegalRole[]>([]);
    const [legalRoleCanInviteArray, setLegalRoleCanInviteArray ] = useState<ILegalRole_canInvite[]>([]);

    const [gwgArray, setGwgArray] = useState<ILegalTransactionGwGFullObject[]>([]);
    //
    const [currenTab, setCurrenTab] = useState(0);
    //

    const [wasSuccessfullyLoadContact, setWasSuccessfullyLoadContact] = useState(true);
    //
    const [allContactArray, setAllContactArray, wasLocalSuccessfullyAllContactArray] = useFetch<IContact[]>("/contact");

    const [contactArray, setContactArray] = useState<ILegalTransactionHasContact[]>([]);
    const [newContactArray, setNewContactArray] = useState<ILegalTransactionHasContact[]>([]);
    // 
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    //
    const [legalTransaction_has_LegalTransactionSpecialFunctionArray, setLegalTransaction_has_LegalTransactionSpecialFunctionArray] = useState<ILegalTransaction_has_LegalTransactionSpecialFunction[]>([]);

    const [selectedType, setSelectedType] = useState('user');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrenTab(newValue);
    };

    useEffect(() => {
        if (legalTransactionFullObject !== undefined) {
            setIsClosed(legalTransactionFullObject.Closed_at !== null || legalTransactionFullObject.idLegalTransactionState === 100)
            if ("CompanyFormation" in legalTransactionFullObject) {
                setCurrenTab(0);
                setCompanyFormation(legalTransactionFullObject.CompanyFormation);
            } else if ("InheritObject" in legalTransactionFullObject) {
                setCurrenTab(0);
                setInheritObject(legalTransactionFullObject.InheritObject);
            } else if ("PurchaseContractObject" in legalTransactionFullObject) {
                setCurrenTab(0);
                setPurchaseContractObject(legalTransactionFullObject.PurchaseContractObject);
            } else if ("MortgageObject" in legalTransactionFullObject) {
                console.log(legalTransactionFullObject.MortgageObject)
                setCurrenTab(0);
                setMortgageObject(legalTransactionFullObject.MortgageObject);
            } else if ("LegalPhaseArray" in legalTransactionFullObject && legalTransactionFullObject.LegalPhaseArray.length === 0) {
                setCurrenTab(4);
            } else {
                setCurrenTab(3);
            }

            setNewContactArray([]);

            setLegalTransactionObject(legalTransactionFullObject);
            setSelectedType(() => {
                return legalTransactionFullObject.Employee_idUser !== null || legalTransactionFullObject.idUserGroup === null ? 'user' : 'group'
            });

            setGwgArray(legalTransactionFullObject.LegalTransactionGwGArray);
            //setLegalPhaseFullObjectArray(legalTransactionFullObject.LegalPhaseArray);
            setContactArray(legalTransactionFullObject.ContactArray);
            setLegalRoleArray(legalTransactionFullObject.LegalRoleArray);
            setLegalRoleCanInviteArray(legalTransactionFullObject.LegalRoleCanInviteArray);

            setCertificationArray(legalTransactionFullObject.CertificateArray);
            setInvoiceArray(legalTransactionFullObject.InvoiceArray);
            setDraftArray(legalTransactionFullObject.DraftArray);

            setDraftMessageArray(legalTransactionFullObject.DraftMessageArray);
            // Setzen der Ablage
            setDocumentDirectoryArray(legalTransactionFullObject.DocumentDirectoryArray);
            setDocumentArray(legalTransactionFullObject.DocumentArray);
            // Termine
            setMeetingArray(legalTransactionFullObject.LegalTransactionMeetingArray);
            setMeetingEmailArray(legalTransactionFullObject.LegalTransactionMeetingEmailArray);

            //Recordnumber und Transactionnumber
            //prüfe ob Recordnumber und Transactionnumber vorhanden sind, wenn nicht, dann füge ein leeres Element hinzu
            if (legalTransactionFullObject.RecordNumberArray.length === 0) {
                setRecordNumberArray([{ RecordNumberEntry: '' }]);
            } else {
                setRecordNumberArray(legalTransactionFullObject.RecordNumberArray);
            }
            if (legalTransactionFullObject.TransactionNumberArray.length === 0) {
                setTransactionNumberArray([{ TransactionNumberEntry: '' }]);
            } else {
                setTransactionNumberArray(legalTransactionFullObject.TransactionNumberArray);
            }
            setLegalTransaction_has_LegalTransactionSpecialFunctionArray(legalTransactionFullObject.LegalTransaction_has_LegalTransactionSpecialFunctionArray);

            // Prüfen nach Log
            if (legalTransactionFullObject.LogArray !== undefined) {
                setLogArray(legalTransactionFullObject.LogArray);
            }

            /// map: LegalPhase aufbereiten, falls Postion nicht vorhanden
            setLegalPhaseFullObjectArray([
                ...legalTransactionFullObject.LegalPhaseArray.map((currentLegalPhase, idx) =>
                    (currentLegalPhase.Position !== null) ? currentLegalPhase
                        : {...currentLegalPhase, Position: idx + 1}
                )
            ])
            /// end: map
        }
    }, [legalTransactionFullObject])

    const getContentInformations = () => {
        if (legalTransactionObject.idLegalTransactionType === 1 && companyFormation !== null) {
            return (
                <CompanyFormationMain
                    companyFormationObject={companyFormation}
                    setCompanyFormationObject={setCompanyFormation}
                    legalTransactionObject={legalTransactionObject}
                />
            )
        } else if (legalTransactionObject.idLegalTransactionType === 2 && inheritObject !== null) {
            return (
                <>
                    <InheritMain
                        inheritObject={inheritObject}
                        setInheritObject={setInheritObject}/>
                </>
            )
        } else if (legalTransactionObject.idLegalTransactionType === 3 && purchaseContractObject !== null) {
            return (
                <>
                    <PurchaseContractMain
                        purchaseContractObject={purchaseContractObject}
                        setPurchaseContractObject={setPurchaseContractObject}
                    />
                </>
            )
        } else if (legalTransactionObject.idLegalTransactionType === 12 && companyFormation !== null) {
            return (
                <CompanyChangeMain
                    companyFormationObject={companyFormation}
                    setCompanyFormationObject={setCompanyFormation}
                    legalTransactionObject={legalTransactionObject}
                />
            )
        } else if (legalTransactionObject.idLegalTransactionType === 6 && companyFormation !== null) {
            return (
                <RASummary
                    companyFormationObject={companyFormation}
                    setCompanyFormationObject={setCompanyFormation}

                />
            )
        } else if (legalTransactionObject.idLegalTransactionType === 104 && mortgageObject !== null) {
            return (
                <MortgageMain
                    mortgageObject={mortgageObject}
                    setMortgageObject={setMortgageObject}

                />
            )
        } else {
            return <>Keine Daten</>
        }
    }

    const handleSaveWrapper = (currentObject: ILegalTransactionFullObject) => {
        props.setLegalTransactionArray([
            ...props.legalTransactionArray.map(x => x.idLegalTransaction === currentObject.idLegalTransaction ? currentObject : x)
        ])
        props.setIdLegalTransaction(null);
    }



    const handleSave = () => {

        let uploadObject: ILegalTransactionFullObject | ILegalTransactionInherit | ILegalTransactionCompanyFormation | ILegalTransactionPurchaseContract | ILegalTransactionMortgage = {
            ...legalTransactionObject,
            LegalPhaseArray: legalPhaseFullObjectArray,
            LegalTransactionGwGArray: gwgArray,
            ContactArray: [...contactArray, ...newContactArray],
            CertificateArray: certificationArray,
            InvoiceArray: invoiceArray,
            DraftArray: draftArray,
            DraftMessageArray: draftMessageArray,
            LegalRoleArray: legalRoleArray,
            LegalRoleCanInviteArray: legalRoleCanInviteArray,
            // Create_Datum Anpassen
            // Termine
            LegalTransactionMeetingArray: meetingArray,
            LegalTransactionMeetingEmailArray: meetingEmailArray,

            // Nur intern
            DocumentArray: documentArray,
            DocumentDirectoryArray: documentDirectoryArray,
            LegalTransaction_has_LegalTransactionSpecialFunctionArray: legalTransaction_has_LegalTransactionSpecialFunctionArray,

            //Recordnumber und Transactionnumber
            //remove empty recordnumbers
            RecordNumberArray: recordNumberArray.filter(x => x.RecordNumberEntry !== ''),
            TransactionNumberArray: transactionNumberArray.filter(x => x.TransactionNumberEntry !== '')
        }




        if (companyFormation !== null && legalTransactionFullObject?.idLegalTransactionType === 1) {
            uploadObject = {...uploadObject, CompanyFormation: companyFormation}
        }
        if (inheritObject !== null && legalTransactionFullObject?.idLegalTransactionType === 2) {
            uploadObject = {...uploadObject, InheritObject: inheritObject}
        }
        if (purchaseContractObject !== null && legalTransactionFullObject?.idLegalTransactionType === 3) {
            uploadObject = {...uploadObject, PurchaseContractObject: purchaseContractObject}
        }
        if (mortgageObject !== null && legalTransactionFullObject?.idLegalTransactionType === 104) {
            uploadObject = {...uploadObject, MortgageObject: mortgageObject}
        }

        uploadFetch(
            "/legaltransaction/fullObject",
            (legalTransactionObject.idLegalTransaction < 0),
            uploadObject,
            handleSaveWrapper,
            props.setWasSavedSuccesfully,
            props.setWasSaved,
            props.setIsLoading
        )
    }

    const handleDelete = () => {
        deleteFetch("legaltransaction", props.idLegalTransaction, props.handleAfterRemove)
    }

    //RecordNumber und TransactionNumber

    const transactionInputRef = useRef<HTMLInputElement>(null);
    const recordInputRef = useRef<HTMLInputElement>(null);
    const [focusTransaction, setFocusTransaction] = useState(false);
    const [focusRecord, setFocusRecord] = useState(false);

    useEffect(() => {
        if (focusTransaction && transactionInputRef.current) {
            transactionInputRef.current.focus();
            setFocusTransaction(false);
        }
    }, [focusTransaction]);
    
    useEffect(() => {
        if (focusRecord && recordInputRef.current) {
            recordInputRef.current.focus();
            setFocusRecord(false);
        }
    }, [focusRecord]);


    const handleDeleteRecordNumber = (index: number) => {
        if (recordNumberArray.length > 1) {
            const newArray = recordNumberArray.filter((_, i) => i !== index);
            setRecordNumberArray(newArray);
        }
    }

    const handleAddRecordNumber = () => {
        if (recordNumberArray[recordNumberArray.length - 1].RecordNumberEntry !== '') {
            setRecordNumberArray([...recordNumberArray, { RecordNumberEntry: '' }]);
            setFocusRecord(true);
        }
    }

    const handleChangeRecordNumber = (index: number, value: string) => {
        setRecordNumberArray([
            ...recordNumberArray.map((x, i) => (i === index) ? { RecordNumberEntry: value } : x)
        ])
    }
    const exportPDF = () => {
        if (legalTransactionFullObject !== undefined) {
            fetch(`/api/legaltransaction/export/pdf`,{
                method: "POST",
                headers :  { 'Authorization': `Bearer ${localStorage.getItem("SessionToken")}`, 'Content-Type': 'application/json' },
                body : JSON.stringify(legalTransactionFullObject)
            })
            .then(res => {
                if (res.status === 200) { return res.json() }
                else {throw Error(`ERROR GET: ${res.status}`)}
            })
            .then(res => {
                const linkSource = `data:application/pdf;base64,${res.pdf}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = res.fileName;
                downloadLink.click()
            })
            .catch((error) => {
                // console.error(error);
            })
        }
    }


    const handleDeleteTransactionNumber = (index: number) => {
        if (transactionNumberArray.length > 1) {
            const newArray = transactionNumberArray.filter((_, i) => i !== index);
            setTransactionNumberArray(newArray);
        }
    }

    const handleChangeTransactionNumber = (index: number, value: string) => {
        setTransactionNumberArray([
            ...transactionNumberArray.map((x, i) => (i === index) ? { TransactionNumberEntry: value } : x)
        ])
        
    }

    const handleAddTransactionNumber = () => {
        if (transactionNumberArray[transactionNumberArray.length - 1].TransactionNumberEntry !== '') {
            setTransactionNumberArray([...transactionNumberArray, { TransactionNumberEntry: '' }]);
            setFocusTransaction(true);
        }
    }


    if (!wasSuccessfully || !wasSuccessfullyLegalRequirementTemplateArray || !wasLocalSuccessfullyAllContactArray) {
        return <>Fehler!</>
    } else if ((legalTransactionFullObject === undefined && legalRequirementTemplateArray === undefined && props.idLegalTransaction > 0) || allContactArray === undefined
    ) {
        return <CustomCircularProgress/>
    } else {
        return (
            <>
                <Dialog open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
                    <DialogTitle>Löschen</DialogTitle>
                    <DialogContent>
                        Sind Sie sich sicher, dass Sie den aktuellen Vorgang löschen wollen?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpenDelete(false)}>Abbruch</Button>
                        <Button onClick={handleDelete}>Löschen</Button>
                    </DialogActions>
                </Dialog>


                <DialogContent>
                    <LegalTransactionNew
                        idLegalTransaction={props.idLegalTransaction}
                        setIdLegalTransaction={props.setIdLegalTransaction}
                        legalTransactionObjectFullObject={legalTransactionFullObject}
                        setLegalTransactionObjectFullObject={setLegalTransactionFullObject}
                        legalTransactionTypeArray={props.legalTypeArray}
                        legalTransactionArray={props.legalTransactionArray}
                        setLegalTransactionArray={props.setLegalTransactionArray}
                        userArray={props.userArray}
                        userGroupArray={props.userGroupArray}
                    />

                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="h5">
                            {(props.idLegalTransaction > 0 && legalTransactionFullObject !== undefined) ? legalTransactionFullObject.Title : "Neues Rechtsgeschäft"}
                        </Typography>

                        {(props.idLegalTransaction > 0 && legalTransactionFullObject !== undefined) &&
                            <Box>
                                <Button variant="outlined" onClick={() => exportPDF()}>Export Nebenakte</Button>
                                <IconButton disabled={isClosed} sx={{float: "right"}} onClick={() => setIsOpenDelete(true)}><Delete/></IconButton>
                            </Box>
                        }
                    </Box>

                    {(props.isLoading) && <><LinearProgress sx={{mt: 1}}/></>}

                    {(isClosed) &&
                        <Alert sx={{mt: 2}} severity="warning">
                            Dieser Vorgang ist bereits geschlossen und kann nicht mehr angepasst werden.
                        </Alert>
                    }

                    <Collapse in={legalTransactionObject !== undefined && legalTransactionObject.idLegalTransactionState === 1}>
                        <Alert severity="info">
                            Im Status "Entwurf" kann der Mandant im Mandantenportal nicht auf diesen Vorgang zugreifen und erhält keine E-Mails dazu.
                        </Alert>
                    </Collapse>
                    

                    <Box sx={{mt: 2}}/>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField
                                label="Bezeichnung"
                                value={legalTransactionObject.Title}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLegalTransactionObject({
                                    ...legalTransactionObject,
                                    Title: event.target.value
                                })}
                                size="small"
                                fullWidth
                                disabled={isClosed}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <TextField
                                label="Art"
                                value={legalTransactionObject.idLegalTransactionType}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLegalTransactionObject({
                                    ...legalTransactionObject,
                                    idLegalTransactionType: Number(event.target.value)
                                })}
                                disabled={legalTransactionObject.idLegalTransaction > 0}
                                size="small"
                                fullWidth
                                select
                            >
                                {props.legalTypeArray.map(x =>
                                    <MenuItem
                                        key={`idLegalTransactionType-${x.idLegalTransactionType}`}
                                        value={x.idLegalTransactionType}
                                    >{x.LegalTransactionType}</MenuItem>
                                )}
                            </TextField>
                        </Grid>

                        <Grid item sm={3}>
                            <TextField
                                label="Zugewiesener Notar"
                                value={legalTransactionObject.Notary_idUser}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLegalTransactionObject({
                                    ...legalTransactionObject,
                                    Notary_idUser: Number(event.target.value)
                                })}
                                size="small"
                                fullWidth
                                select
                                disabled={isClosed}
                            >
                                {props.userArray.filter(x => Boolean(x.isNotary)).map(x =>
                                    <MenuItem
                                        key={`Notary_idUser-${x.idUser}`}
                                        value={x.idUser}
                                    >{x.FirstName} {x.LastName}</MenuItem>
                                )}
                            </TextField>
                        </Grid>


                        <Grid item sm={3}>
                            <Autocomplete
                                options={combinedOptions}
                                groupBy={(option) => option.type}
                                getOptionLabel={(option) => option.name}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            selectedType === 'user' ? (
                                                <span>
                                                    <Person style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                                    Zugewiesener Mitarbeiter
                                                </span>
                                            ) : (
                                                <span>
                                                    <Group style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                                    Zugewiesene Gruppe
                                                </span>
                                            )
                                        }
                                        size="small"
                                        variant="outlined"
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <ListItem {...props} key={option.id}>
                                        
                                        <ListItemText primary={option.name}/>
                                    </ListItem>
                                )}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        if (newValue.type === 'Mitarbeiter') {
                                            setLegalTransactionObject({
                                                ...legalTransactionObject,
                                                Employee_idUser: newValue.id,
                                                idUserGroup: null
                                            });
                                            setSelectedType('user');
                                        } else if (newValue.type === 'Gruppe') {
                                            setLegalTransactionObject({
                                                ...legalTransactionObject,
                                                idUserGroup: newValue.id,
                                                Employee_idUser: null
                                            });
                                            setSelectedType('group');
                                        }
                                    } else {
                                        console.log('newValue is null');
                                        setLegalTransactionObject({
                                            ...legalTransactionObject,
                                            Employee_idUser: null,
                                            idUserGroup: null
                                        });
                                    }
                                }}
                                value={
                                    (selectedType === 'user' && legalTransactionObject.Employee_idUser !== null)
                                        ? combinedOptions.find(option => option.type === 'Mitarbeiter' && option.id === legalTransactionObject.Employee_idUser)
                                        : (selectedType === 'group' && legalTransactionObject.idUserGroup !== null)
                                        ? combinedOptions.find(option => option.type === 'Gruppe' && option.id === legalTransactionObject.idUserGroup)
                                        : null
                                }
                                disabled={isAutocompleteClosed}
                            />
                        </Grid>



                        <Grid item sm={3}>
                            <TextField
                                label="Phase"
                                value={legalTransactionObject.idLegalTransactionProgress}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLegalTransactionObject({
                                    ...legalTransactionObject,
                                    idLegalTransactionProgress: Number(event.target.value)
                                })}
                                size="small"
                                fullWidth
                                select
                                disabled={isClosed}
                            >
                                {props.legalProgressArray.map(x =>
                                    <MenuItem
                                        key={`idLegalTransactionProgress-${x.idLegalTransactionProgress}`}
                                        value={x.idLegalTransactionProgress}
                                    >{x.LegalTransactionProgress}</MenuItem>
                                )}
                            </TextField>
                        </Grid>

                        <Grid item sm={3}>
                            <TextField
                                label="Status"
                                value={legalTransactionObject.idLegalTransactionState}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLegalTransactionObject({
                                    ...legalTransactionObject,
                                    idLegalTransactionState: Number(event.target.value)
                                })}
                                size="small"
                                fullWidth
                                select
                                disabled={isClosed}
                            >
                                {props.legalStateArray.map(x =>
                                    <MenuItem
                                        key={`idLegalTransactionState-${x.idLegalTransactionState}`}
                                        value={x.idLegalTransactionState}
                                    >{x.LegalTransactionState}</MenuItem>
                                )}
                            </TextField>
                        </Grid>

                        <Grid item sm={3}>
                            {transactionNumberArray.map((x, index) => (
                                <Box key={`TransactionNumber-${index}`} display="flex" alignItems="center" mb={2}>
                                    <TextField
                                        label={`Vorgangsnummer (ARNOtop)`}
                                        id = {`TransactionNumber-${index}`}
                                        value={x.TransactionNumberEntry}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeTransactionNumber(index, event.target.value)}
                                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {if (event.target.value === '') {handleDeleteTransactionNumber(index)}}}
                                        size="small"
                                        fullWidth
                                        disabled={isClosed}
                                        sx={{ mr: 2 }}
                                        inputRef={index === transactionNumberArray.length - 1 ? transactionInputRef : null}
                                    />
                                    {transactionNumberArray.length > 1 && index < transactionNumberArray.length - 1 && transactionNumberArray[index + 1]?.TransactionNumberEntry !== '' && (
                                        <IconButton onClick={() => handleDeleteTransactionNumber(index)}>
                                            <Delete />
                                        </IconButton>
                                    )}
                                    {index === transactionNumberArray.length - 1 && x.TransactionNumberEntry !== '' && (
                                        <IconButton onClick={handleAddTransactionNumber}>
                                            <AddCircle />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                        </Grid>

                        <Grid item sm={3}>
                            {recordNumberArray.map((x, index) => (
                                <Box key={`RecordNumber-${index}`} display="flex" alignItems="center" mb={2}>
                                        <TextField
                                            label={`Urkundennummer (ARNOtop)`}
                                            id = {`RecordNumber-${index}`}
                                            value={x.RecordNumberEntry}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeRecordNumber(index, event.target.value)}
                                            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {if (event.target.value === '') {handleDeleteRecordNumber(index)}}}
                                            size="small"
                                            fullWidth
                                            disabled={isClosed}
                                            sx={{ mr: 2 }}
                                            inputRef={index === recordNumberArray.length - 1 ? recordInputRef : null}
                                        />
                                    {recordNumberArray.length > 1 && index < recordNumberArray.length - 1 && recordNumberArray[index + 1].RecordNumberEntry !== '' && (
                                        <IconButton onClick={() => handleDeleteRecordNumber(index)}>
                                            <Delete />
                                        </IconButton>
                                    )}
                                    {index === recordNumberArray.length - 1 && x.RecordNumberEntry !== '' && (
                                        <IconButton onClick={handleAddRecordNumber}>
                                            <AddCircle />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                        </Grid>
                    </Grid>


                    <Box sx={{mt: 5, height: 30, mb: 5}}>
                        <Stepper activeStep={legalTransactionFullObject?.idLegalTransactionProgress} alternativeLabel>
                            {props.legalProgressArray.map(x =>
                                <Step
                                    key={x.idLegalTransactionProgress}
                                    completed={Number(legalTransactionFullObject?.idLegalTransactionProgress) > x.idLegalTransactionProgress}
                                    active={Number(legalTransactionFullObject?.idLegalTransactionProgress) === x.idLegalTransactionProgress}
                                >
                                    <StepLabel>{x.LegalTransactionProgress}</StepLabel>
                                </Step>
                            )}
                        </Stepper>
                    </Box>

                    <Box sx={{mt: 2}}/>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

                        <Tabs value={currenTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Datenerfassung" {...a11yProps(0)}/>
                            <Tab label="Entwurf" {...a11yProps(1)} />
                            <Tab label="Termine" {...a11yProps(2)}/>
                            <Tab label="Abwicklung" {...a11yProps(3)} />
                            <Tab label="Mandanten" {...a11yProps(4)} />
                            <Tab label="Urkunden" {...a11yProps(5)} />
                            <Tab label="Rechnungen" {...a11yProps(6)} />
                            <Tab label="Interne Datenablage" {...a11yProps(7)} />
                            <Tab label="Funktionen" {...a11yProps(8)} />
                            {(logArray.length > 0) && <Tab label="Log" {...a11yProps(9)} />}

                        </Tabs>
                    </Box>
                    <CustomTabPanel value={currenTab} index={0}>
                        {getContentInformations()}
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={1}>
                        <LegalTransactionDraftOverview
                            draftArray={draftArray}
                            setDraftArray={setDraftArray}
                            draftMessageArray={draftMessageArray}
                            setDraftMessageArray={setDraftMessageArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={2}>
                        <LegalTransactionMeetingOverview
                            idLegalTransactionType={legalTransactionObject.idLegalTransactionType}
                            meetingEmailTemplateArray={props.meetingEmailTemplateArray}
                            meetingArray={meetingArray}
                            setMeetingArray={setMeetingArray}
                            meetingEmailArray={meetingEmailArray}
                            setMeetingEmailArray={setMeetingEmailArray}

                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={3}>
                        <LegalPhaseMain
                            legalPhaseFullObjectArray={legalPhaseFullObjectArray}
                            setLegalPhaseFullObjectArray={setLegalPhaseFullObjectArray}
                            legalReqState={props.legalReqState}
                            legalRequirementTemplateArray={legalRequirementTemplateArray!}
                            legalRoleArray={legalRoleArray}
                            setLegalRoleArray={setLegalRoleArray}
                            legalRoleCanInviteArray={legalRoleCanInviteArray}
                            setLegalRoleCanInviteArray={setLegalRoleCanInviteArray}
                            downloadedArray={downloadedArray}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={currenTab} index={4}>
                        <LegalTransactionClientsOverview
                            allContactArray={allContactArray}
                            legalRoleArray={legalRoleArray}
                            contactArray={contactArray}
                            setContactArray={setContactArray}
                            newContactArray={newContactArray}
                            setNewContactArray={setNewContactArray}
                            //
                            setLegalRoleArray={setLegalRoleArray}
                            legalRoleCanInviteArray={legalRoleCanInviteArray}
                            setLegalRoleCanInviteArray={setLegalRoleCanInviteArray}
                        />
                        {/*
                    <ClientBaseData
                        currentContactObject={contactObject}
                        setCurrentContactObject={setContactObject} 
                        companyObject={companyObject}
                        setCompanyObject={setCompanyObject}
                    />
                    */}
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={5}>
                        <DocumentOverview
                            key="certification"
                            allowRemove={true}
                            allowUpload={true}
                            title="Urkunden"
                            documentArray={certificationArray}
                            setDocumentArray={setCertificationArray}
                            downloadedArray={downloadedArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={6}>
                        <DocumentOverview
                            key="invoice"
                            allowRemove={true}
                            allowUpload={true}
                            title="Rechnungen"
                            documentArray={invoiceArray}
                            setDocumentArray={setInvoiceArray}
                            downloadedArray={downloadedArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={7}>
                        <DocumentDirectoryOverview
                            idLegalTransaction={props.idLegalTransaction}
                            documentArray={documentArray}
                            documentDirectoryArray={documentDirectoryArray}
                            setDocumentArray={setDocumentArray}
                            setDocumentDirectoryArray={setDocumentDirectoryArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel index={8} value={currenTab}>
                        <SpecialFunctionOverview
                            idLegalTransaction={props.idLegalTransaction}
                            legalTransaction_has_LegalTransactionSpecialFunctionArray={legalTransaction_has_LegalTransactionSpecialFunctionArray}
                            setLegalTransaction_has_LegalTransactionSpecialFunctionArray={setLegalTransaction_has_LegalTransactionSpecialFunctionArray}
                            gwgArray={gwgArray}
                            setGwgArray={setGwgArray}
                            legalPhaseFullObjectArray={legalPhaseFullObjectArray}  legalReqState={props.legalReqState}/>
                    </CustomTabPanel>

                    <CustomTabPanel value={currenTab} index={9}>
                        <Typography variant="h6">Log</Typography>
                        <Grid sx={{mt: 2}} container spacing={1}>
                            {logArray.map(x =>
                                <>
                                    <Grid item xs={4} sm={2}>
                                        {new Date(x.Timestamp).toLocaleString("de-DE")}
                                    </Grid>
                                    <Grid item xs={8} sm={10}>{x.Log}</Grid>
                                </>
                            )}
                        </Grid>
                    </CustomTabPanel>


                </DialogContent>
                <DialogActions>
                    <Button
                        variant={(isClosed) ? "contained" : "outlined"}
                        onClick={() => props.setIdLegalTransaction(null)}
                    >Schließen</Button>
                    {(!isClosed)
                        &&
                        <Button variant="contained" onClick={handleSave} disabled={props.isLoading}>Speichern</Button>
                    }
                </DialogActions>
            </>
        )
    }
}

