import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBusinessForm } from "../../Interfaces/IBusinessForm";


const initialState =  { storeBusinessForm: [] as IBusinessForm[] }; 

const sliceBusinessForm = createSlice({
    name: "BusinessFormARRAY",
    initialState,
    reducers: {
        setBusinessFormArray: (state, action: PayloadAction<IBusinessForm[]>) => {
            state.storeBusinessForm = [...action.payload];
        },
        addUpdateBusinessFormrArray: (state, action: PayloadAction<IBusinessForm>) => {
            let testObject = state.storeBusinessForm.find(x => x.idBusinessForm === action.payload.idBusinessForm);
            
            if (testObject) {
                state.storeBusinessForm = [...state.storeBusinessForm.map(x => x.idBusinessForm === action.payload.idBusinessForm ? action.payload : x)];
            } else {
                state.storeBusinessForm = [action.payload, ...state.storeBusinessForm];
            }
        },
    }
})
export const { setBusinessFormArray, addUpdateBusinessFormrArray } = sliceBusinessForm.actions;
export default sliceBusinessForm.reducer;