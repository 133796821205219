import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDistrictCourt } from "../../Interfaces/IDistrictCourt";


const initialState =  { storeDistrictCourt: [] as IDistrictCourt[] }; 

const sliceDistrictCourt = createSlice({
    name: "DISTRICTCOURTARRAY",
    initialState,
    reducers: {
        setDistrictCourtArray: (state, action: PayloadAction<IDistrictCourt[]>) => {
            state.storeDistrictCourt = [...action.payload];
        },
        addUpdateDistrictCourtArray: (state, action: PayloadAction<IDistrictCourt>) => {
            let testObject = state.storeDistrictCourt.find(x => x.idDistrictCourt === action.payload.idDistrictCourt);
            
            if (testObject) {
                state.storeDistrictCourt = [...state.storeDistrictCourt.map(x => x.idDistrictCourt === action.payload.idDistrictCourt ? action.payload : x)];
            } else {
                state.storeDistrictCourt = [action.payload, ...state.storeDistrictCourt];
            }
        },
    }
})
export const { setDistrictCourtArray, addUpdateDistrictCourtArray } = sliceDistrictCourt.actions;
export default sliceDistrictCourt.reducer;