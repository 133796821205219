import React, { useState } from "react";
import { IDocument } from "../Interfaces/IDocument";
import { IDraftMessage, IDraftMessageFullObject } from "../Interfaces/IDraftMessage";
import { Button, Grid, Typography } from "@mui/material";
import { ViewDraftMessage } from "./DraftMessage/ViewDraftMessage";
import { NewDraftMessage } from "./DraftMessage/NewDraftMessage";
import { DraftDocumentOverview } from "./DraftMessage/DraftDocumentOverview";


interface IProps {
    draftArray: IDocument[];
    setDraftArray: Function;
    draftMessageArray: IDraftMessageFullObject[];
    setDraftMessageArray: Function;
}


export const LegalTransactionDraftOverview:React.FC<IProps> = (props) => {
    const [idDraftMessageParent,setIdDraftMessageParent] = useState<number|null>(null);

    
    return (
        <>
            <DraftDocumentOverview
                draftArray={props.draftArray}
                setDraftArray={props.setDraftArray}
            />

            <Typography variant="h5" sx={{mb: 3}}>
                Kommentare
                <NewDraftMessage
                    draftMessageArray={props.draftMessageArray}
                    setDraftMessageArray={props.setDraftMessageArray}
                    idDraftMessageParent={idDraftMessageParent}
                    setIdDraftMessageParent={setIdDraftMessageParent}
                />

            </Typography>
            <Grid container spacing={2}>
                {(props.draftMessageArray.length === 0) &&
                    <Grid item xs={12}>
                        <>--- Keine Kommentare vorhanden ---</>
                    </Grid>
                }


                {(props.draftMessageArray.map(x =>
                    <Grid item xs={12}>
                    <ViewDraftMessage
                        key={x.idDraftMessage}
                        draftMessageArray={props.draftMessageArray}
                        setDraftMessageArray={props.setDraftMessageArray}
                        draftMessageObject={x}
                        setIdDraftMessageParent={setIdDraftMessageParent}
                        enableStateEdit
                    />
                    </Grid>
                ))}
            </Grid>

        </>
    )
}