import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { IUser } from "../../Interfaces/IUser";
import { IRole } from "../../Interfaces/IRole";
import { State } from "../../redux/mainReducer";
import { UserSetPassword } from "../UserSetPassword";
import PostcodeSearchV2 from "../../generic/PostcodeSearchV2";
import { BankDropwdown } from "./BankDropwdown";



interface IProps {
    userEdit: IUser;
    setUserEdit: Function;
}

const genEmptyUser = () => {
    return {
        Email: "",
        FirstName: "",
        idRole: 3,
        idUser: -1,
        isBlocked: false,
        isNotary: false,
        LastName: "",
        Login: "",
        GenerateNewPassword: true,
        idGender: 1,
        // Erweiterung MA
        Birthplace: null,
        Birthdate: null,
        Street: null,
        StreetNr: null,
        idPostcode: null,
        IBAN: null,
        idBank: null,
        InsuranceNumber: null,
        TaxIdentificationNumber: null,
        idHealthInsurance: null,
        isUserEditEnabled: true,
        // Grundbuchamt
        LandRegister_Editor: null,
        LandRegister_Username: null
    } as IUser
}


export const UserBaseInformation:React.FC<IProps> = ({ userEdit, setUserEdit  }) => {
    //
    const genderArray = useSelector((state: State) => state.genderArray.storeGender);






    return(
        <>

            <Box sx={{mt: 2}}/>
            <Grid container spacing={2}>




                <Grid item xs={2}>
                    <TextField
                        select
                        label="Geschlecht"
                        size="small"
                        value={userEdit.idGender}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({ ...userEdit, idGender: Number(event.target.value) })}
                        fullWidth
                    >
                        {genderArray.map(x =>
                            <MenuItem value={x.idGender}>{x.Gender}</MenuItem>
                        )}
                    </TextField>
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        label="Vorname"
                        size="small"
                        value={userEdit.FirstName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({ ...userEdit, FirstName: event.target.value })}
                        error={userEdit.FirstName === ""}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        label="Nachname"
                        size="small"
                        value={userEdit.LastName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({ ...userEdit, LastName: event.target.value })}
                        error={userEdit.LastName === ""}
                        fullWidth
                    />
                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Typography variant="h6">Geburtsinformationen</Typography>
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        label="Geburtsdatum"
                        size="small"
                        value={(userEdit.Birthdate === null) ? "" : userEdit.Birthdate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({
                                ...userEdit, 
                                Birthdate: (event.target.value === "")  ? null : event.target.value
                        })}
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        label="Geburtsort"
                        size="small"
                        value={(userEdit.Birthplace === null) ? "" : userEdit.Birthplace}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({
                                ...userEdit, 
                                Birthplace: (event.target.value === "")  ? null : event.target.value
                        })}
                        fullWidth
                    />
                </Grid>

                <Grid item sm={12}>
                    <Typography variant="h6">Anschrift</Typography>
                </Grid>
                <Grid item sm={8}>
                    <TextField
                        label="Straße"
                        size="small"
                        value={(userEdit.Street === null) ? "" : userEdit.Street}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({
                                ...userEdit, 
                                Street: (event.target.value === "")  ? null : event.target.value
                        })}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        label="Nr"
                        size="small"
                        value={(userEdit.StreetNr === null) ? "" : userEdit.StreetNr}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({
                                ...userEdit, 
                                StreetNr: (event.target.value === "")  ? null : event.target.value
                        })}
                        fullWidth
                    />
                </Grid>

                <Grid item sm={12}>
                    <PostcodeSearchV2
                        idPostcode={userEdit.idPostcode}
                        setIdPostcode={(idPostcode:number|null) => setUserEdit({
                            ...userEdit, 
                            idPostcode: idPostcode
                        })}
                        allowNull
                        required={false}
                    />
                </Grid>


                <Grid item sm={12}>
                    <Typography variant="h6">Bank</Typography>
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        label="IBAN"
                        size="small"
                        value={(userEdit.IBAN === null) ? "" : userEdit.IBAN}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserEdit({
                                ...userEdit, 
                                IBAN: (event.target.value === "")  ? null : event.target.value
                        })}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12}>
                    <BankDropwdown
                        idBank={userEdit.idBank}
                        setIdBank={(idBank:number|null) => setUserEdit({
                            ...userEdit, 
                            idBank: idBank
                        })}
                    />
                </Grid>
            </Grid>


        </>
    )
}