import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ILegalRole } from "../../../Interfaces/ILegalRole";
import { ILegalTransactionHasContact } from "../../../Interfaces/IContact";


interface IProps {
    contactArray: ILegalTransactionHasContact[];
    newContactArray: ILegalTransactionHasContact[];
    idLegalRole: number;
    setIdLegalRole: Function;
    legalRoleArray: ILegalRole[];
    setLegalRoleArray: Function;
}


const getLegalRole = (idLegalRole:number,legalRoleArray:ILegalRole[]) => {
    let testObject = legalRoleArray.find(x => x.idLegalRole === idLegalRole);

    if (testObject !== undefined) {
        return testObject;
    } else {
        let minId = Math.min(...legalRoleArray.map(x => x.idLegalRole)) -1;

        if (minId >= -1) {
            minId = -2;
        }

        return {
            idLegalRole: minId,
            idLegalTransaction: -1,
            LegalRole: "",
            canInvite: false,
            canUpload: false,
            viewCertificate: false,
            viewDataCollection: false,
            viewDate: false,
            viewDraft: false,
            viewInvoice: false,
            viewTransaction: false,
            
        } as ILegalRole
    }
}


export const LegalRoleEdit:React.FC<IProps> = (props) => {
    const [currentObject, setCurrentObject] = useState(getLegalRole(props.idLegalRole,props.legalRoleArray));
    const [editObject, setEditObject] = useState(currentObject);


    const handleSave = () => {
        let testObject = props.legalRoleArray.find(x => x.idLegalRole === editObject.idLegalRole);

        if (testObject === undefined) {
            props.setLegalRoleArray([
                ...props.legalRoleArray,
                editObject
            ])
        } else {
            props.setLegalRoleArray([
                ...props.legalRoleArray.map(x => x.idLegalRole === editObject.idLegalRole ? editObject : x)
            ])
        }

        props.setIdLegalRole(null);
    }

    const removeMe = () => {
        props.setLegalRoleArray([
            ...props.legalRoleArray.filter(x => x.idLegalRole !== props.idLegalRole)
        ]);
        props.setIdLegalRole(null);
    }

    const contextRemoveButton = () => {
        if (
            [...props.contactArray, ...props.newContactArray]
             .filter(x => x.idLegalRole === Number(props.idLegalRole))
             .length 
            > 0
        ) {
             
            return  <Box sx={{float: "right"}}>
                      <Typography variant="caption"><i>Löschbar nur ohne Mandanten</i></Typography>
                    </Box>
        } else {
            return <IconButton sx={{float: "right"}} onClick={removeMe}><Delete/></IconButton>
        }
    }


    return(
        <>
            <Typography variant="h6" sx={{mb: 3}}>
                { (props.idLegalRole === -1) ? "Neue Rolle" : currentObject.LegalRole }
                {(props.idLegalRole !== -1) && contextRemoveButton()}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Bezeichnung"
                        size="small"
                        value={editObject.LegalRole}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEditObject({ ...editObject, LegalRole: event.target.value })}
                        error={editObject.LegalRole === ""}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={3}>
                    Schreiben
                </Grid>
                <Grid item xs={9}>
                    <FormControlLabel 
                        label="Einladen"
                        control={
                            <Checkbox
                                checked={editObject.canInvite}
                                onChange={() => setEditObject({ ...editObject, canInvite: !editObject.canInvite })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                    <FormControlLabel 
                        label="Hochladen"
                        control={
                            <Checkbox
                                checked={editObject.canUpload}
                                onChange={() => setEditObject({ ...editObject, canUpload: !editObject.canUpload })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                </Grid>

                <Grid item xs={3}>
                    Lesen
                </Grid>
                <Grid item xs={9}>
                    <FormControlLabel 
                        label="Datenerfassung"
                        control={
                            <Checkbox
                                checked={editObject.viewDataCollection}
                                onChange={() => setEditObject({ ...editObject, viewDataCollection: !editObject.viewDataCollection })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                    <FormControlLabel 
                        label="Entwurf"
                        control={
                            <Checkbox
                                checked={editObject.viewDraft}
                                onChange={() => setEditObject({ ...editObject, viewDraft: !editObject.viewDraft })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                    <FormControlLabel 
                        label="Termine"
                        control={
                            <Checkbox
                                checked={editObject.viewDate}
                                onChange={() => setEditObject({ ...editObject, viewDate: !editObject.viewDate })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                    <FormControlLabel 
                        label="Abwicklung"
                        control={
                            <Checkbox
                                checked={editObject.viewTransaction}
                                onChange={() => setEditObject({ ...editObject, viewTransaction: !editObject.viewTransaction })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                    <FormControlLabel 
                        label="Urkunden"
                        control={
                            <Checkbox
                                checked={editObject.viewCertificate}
                                onChange={() => setEditObject({ ...editObject, viewCertificate: !editObject.viewCertificate })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                    <FormControlLabel 
                        label="Rechnungen"
                        control={
                            <Checkbox
                                checked={editObject.viewInvoice}
                                onChange={() => setEditObject({ ...editObject, viewInvoice: !editObject.viewInvoice })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                    />
                </Grid>
            </Grid>

            <Box sx={{mt: 3}}/>
            
            <Button sx={{float: "right"}} disabled={editObject.LegalRole === ""} variant="contained" onClick={handleSave}>Übernehmen</Button>
            <Button sx={{float: "right", mr: 2}} variant="outlined" onClick={() => props.setIdLegalRole(null)}>Abbruch</Button>
        </>
    )
}