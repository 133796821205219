import React, { useState } from "react";
import { ILegalTransactionMeeting } from "../../Interfaces/ILegalTransactionMeeting";
import { ILegalTransactionMeetingEmail } from "../../Interfaces/ILegalTransactionMeetingEmail";
import { IMeetingEmailTemplate } from "../../Interfaces/IMeetingEmailTemplate";
import { Dialog, DialogContent, DialogTitle, IconButton, TableCell, TableRow } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { LegalTransactionMeetingEdit } from "./LegalTransactionMeetingEdit";
import { castWithZeroTimezone } from "../../core/generic_helper";


interface IProps {
    meetingObject: ILegalTransactionMeeting;
    meetingArray: ILegalTransactionMeeting[];
    setMeetingArray: Function;
    meetingEmailArray: ILegalTransactionMeetingEmail[];
    setMeetingEmailArray: Function;
    meetingEmailTemplateArray: IMeetingEmailTemplate[];    
}


export const LegalTransactionMeetingRow:React.FC<IProps> = (props) => {
    const [isOpenEdit,setIsOpenEdit] = useState(false);
    const [editMeetingObject,setEditMeetingObject] = useState(props.meetingObject);
    const [editMeetingEmailArray,setEditMeetingEmailArray] = useState(props.meetingEmailArray.filter(x => x.idLegalTransactionMeeting === props.meetingObject.idLegalTransactionMeeting));

    const getTillDate = () => {
        let meeting_time = new Date(castWithZeroTimezone(props.meetingObject.Meeting_at!)!);
        let returnValie = new Date(meeting_time.setMinutes(meeting_time.getMinutes() + props.meetingObject.DurationInMin))

        return returnValie.toLocaleTimeString()
    }



    const handleOpenEdit = () => {
        setEditMeetingObject(props.meetingObject);
        setEditMeetingEmailArray(props.meetingEmailArray.filter(x => x.idLegalTransactionMeeting === props.meetingObject.idLegalTransactionMeeting))
        setIsOpenEdit(true);
    }

    const handleSave = () => {
        props.setMeetingArray([
            ...props.meetingArray.map(x => x.idLegalTransactionMeeting === editMeetingObject.idLegalTransactionMeeting ? editMeetingObject : x)
        ])
        props.setMeetingEmailArray([
            ...props.meetingEmailArray.filter(x => x.idLegalTransactionMeeting !== editMeetingObject.idLegalTransactionMeeting),
            ...editMeetingEmailArray
        ])
        setIsOpenEdit(false);
    }
    

    const handleRemoveMe = () => {
        props.setMeetingArray([
            ...props.meetingArray.filter(x => x.idLegalTransactionMeeting !== editMeetingObject.idLegalTransactionMeeting)
        ])
        props.setMeetingEmailArray([
            ...props.meetingEmailArray.filter(x => x.idLegalTransactionMeeting !== editMeetingObject.idLegalTransactionMeeting),
        ])
        setIsOpenEdit(false);
    }


    return(
        <>
            <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Termin bearbeiten</DialogTitle>
                <DialogContent>
                    <LegalTransactionMeetingEdit
                        meetingObject={editMeetingObject}
                        setMeetingObject={setEditMeetingObject}
                        meetingEmailTemplateArray={props.meetingEmailTemplateArray}
                        meetingEmailArray={editMeetingEmailArray}
                        setMeetingEmailArray={setEditMeetingEmailArray}
                        setAddNew={setIsOpenEdit}
                        handleSave={handleSave}
                    />
                </DialogContent>
            </Dialog>



            <TableRow>
                <TableCell>{new Date(castWithZeroTimezone(props.meetingObject.Meeting_at)!).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(castWithZeroTimezone(props.meetingObject.Meeting_at)!).toLocaleTimeString()}</TableCell>
                <TableCell>{getTillDate()}</TableCell>
                <TableCell>{props.meetingObject.LegalTransactionMeeting}</TableCell>
                <TableCell>{props.meetingEmailArray.filter(x => x.idLegalTransactionMeeting === props.meetingObject.idLegalTransactionMeeting).length}</TableCell>
                <TableCell>
                    <IconButton size="small" onClick={handleOpenEdit}><Edit/></IconButton>
                    <IconButton size="small" onClick={handleRemoveMe}><Delete/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}