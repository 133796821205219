import React, { useState } from "react";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { ILanguageSectionFullObject } from "../../Interfaces/ILanguageSection";
import { Alert, Button, Collapse, Dialog, DialogContent, LinearProgress, Typography } from "@mui/material";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import { LanguageSectionEdit } from "./LanguageSectionEdit";


interface IProps {
    idLanguageSection: number;
    setIdLanguageSection: Function;
}


export const LanguageSectionWrapper:React.FC<IProps> = (props) => {
    const [languageSectionFullObject,setLanguageSectionFullObject,wasSuccessfully] = useFetch<ILanguageSectionFullObject>("/languagesection/fullObject/",props.idLanguageSection);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);

    const getIsDisabled = () => {
        if (languageSectionFullObject !== undefined) {
            return languageSectionFullObject
                .LanguageEntryArray
                .filter(x => x.KeyValue === "" || x.de === "" || x.en === "" || x.fr === "" )
                .length > 0
        }
    }

    const handleAfterSave = () => {
        props.setIdLanguageSection(null);
    }

    const handleSave = () => {
        if (languageSectionFullObject !== undefined) {
            let uploadObject = {
                ...languageSectionFullObject,
                isPublic: Boolean(languageSectionFullObject.isPublic)
            }
            uploadFetch("/languagesection/fullObject",(languageSectionFullObject.idLanguageSection < 0),uploadObject,handleAfterSave,setWasSuccessfullySaved,undefined,setIsLoading)
        }
    }


    if (languageSectionFullObject === undefined) {
        return(
            <>
                <Dialog open={true}>
                    <DialogContent>
                        {
                            (wasSuccessfully)
                            ? <CustomCircularProgress/>
                            : <Alert severity="error">Ein Fehler liegt vor!</Alert>
                        }
                    </DialogContent>
                </Dialog>
            </>
        )
    }
    else {
        return(
            <>
                <FullScreenDialog
                    isOpen={true}
                    onClose={() => props.setIdLanguageSection(null)}
                    title="Sprackpaket/-sektion"
                >
                    <>
                        <Typography variant="h5">
                            Sprachpaket: {languageSectionFullObject.LanguageSection}
                            
                            <Button onClick={handleSave} disabled={getIsDisabled() || isLoading} variant="contained" sx={{float: "right"}}>Speichern</Button>
                        </Typography>
                        <br />
                        <Collapse in={!wasSuccessfullySaved}>
                            <Alert severity="error">Beim Speichern ist ein Fehler aufgetretten!</Alert>
                        </Collapse>
                        <Collapse in={isLoading}>
                            <LinearProgress/>
                        </Collapse>
                        <br/>
                        <LanguageSectionEdit
                            languageSectionFullObject={languageSectionFullObject}
                            setLanguageSectionFullObject={setLanguageSectionFullObject}
                        />
                    </>
                </FullScreenDialog>
            </>
        )

    }
}