import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Grid, IconButton, Typography } from "@mui/material";
import { Delete, Download, UploadFile } from "@mui/icons-material";
import { IPersonHasDocument } from "../../../../Interfaces/IPersonHasDocument";
import { useSelector } from "react-redux";
import { State } from "../../../../redux/mainReducer";
import { IDocument } from "../../../../Interfaces/IDocument";
import { getFetch } from "../../../../hooks/useFetch";


export interface IRequiredFiles {
    Title: string;
    isOptional: boolean;
    idPersonDocumentType: number;
}

interface IProps {
    requiredFile: IRequiredFiles;
    idPerson: number;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    disableEdit?:boolean;
}


const getDocumentObject = (idPerson:number, requiredFile: IRequiredFiles, personHasDocument: IPersonHasDocument[]) => {
    //console.log("--------------")
    //console.log(idPerson)
    //console.log(requiredFile)
    //console.log(personHasDocument)

    let testObject = personHasDocument.filter(x => x.idPersonDocumentType === requiredFile.idPersonDocumentType).find(x => x.idPerson === idPerson);

    //console.log(testObject)

    if (testObject !== undefined) {
        return testObject;
    } else {
        let minId = Math.min(...personHasDocument.map(x => x.idDocument)) -1;

        if (minId > 0) {
            minId = -1;
        }

        return {
            FileName: "",
            idDocument: minId,
            idMIMEType: 1,
            idPerson: idPerson,
            idPersonDocumentType: requiredFile.idPersonDocumentType
        } as IPersonHasDocument
    }
}



export const InheritDocument:React.FC<IProps> = (props) => {
    const mimeTypeArray = useSelector((state: State) => state.mimeTypeArray.storeAcademicTitle);

    const [currentObject, setCurrentObject] = useState(getDocumentObject(props.idPerson, props.requiredFile, props.personHasDocument));
    const fileUpload = useRef<HTMLInputElement>(null);

    useEffect (() => {
        const documentObject = getDocumentObject(props.idPerson, props.requiredFile, props.personHasDocument);

        setCurrentObject(documentObject);
    }, [ props.personHasDocument])

    const [documentObject, setDocumentObject] = useState<IDocument|null>(null);

    const base64Download = (localDocumentObject:IPersonHasDocument | IDocument) => {
        let findMimeType = mimeTypeArray.find(x => x.idMIMEType === localDocumentObject.idMIMEType);

        if (findMimeType !== undefined && localDocumentObject.Data !== undefined) {
            let byteCharacters = atob(localDocumentObject.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: findMimeType.MIMEType});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = blobUrl;
            a.download = localDocumentObject.FileName;
            a.click();
        }
    }

    async function fileToBase64(file: File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }

    const saveDocument = async (file:File) => {
        let findMimeType = mimeTypeArray.find(x => x.MIMEType === file.type);
        
        if (findMimeType !== undefined) {
            let tmpObject:IPersonHasDocument = {
                ...currentObject,
                FileName:  String(file.name),
                idMIMEType: findMimeType.idMIMEType,
                Data: await fileToBase64(file)
            }

            setCurrentObject({...tmpObject})

            let findObject = props.personHasDocument.find(x => x.idDocument === tmpObject.idDocument);

            if (findObject === undefined) {
                props.setPersonHasDocument([
                    ...props.personHasDocument,
                    tmpObject
                ])
            } else {
                props.setPersonHasDocument([
                    ...props.personHasDocument.map(x => x.idDocument === tmpObject.idDocument ? tmpObject : x )
                ])
            }
        }
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files?.length === 1) {
            saveDocument(event.target.files[0])
        }
    }


    const handleRemove = () => {
        setCurrentObject({...currentObject, Data: undefined})
        props.setPersonHasDocument([
            ...props.personHasDocument.filter(x => x.idPerson !== props.idPerson && x.idPersonDocumentType === props.requiredFile.idPersonDocumentType)
        ])
    }


    const wrapperSetDocumentObject = (localDocument:IDocument) => {
        setDocumentObject(localDocument);
        base64Download(localDocument)
    }


    const handleDownload = () => {
        if (documentObject !== null) {
            base64Download(documentObject);
        } 
        else if (currentObject.Data !== undefined) {
            base64Download(currentObject);
        }
        else {
            getFetch("/document/",currentObject.idDocument,wrapperSetDocumentObject)
        }
    }

    return(
        <>
            <input hidden type="file" ref={fileUpload} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))} onChange={handleUpload}/>

            <Grid item sm={12}>
                <Alert
                    severity={ (currentObject.Data !== undefined || currentObject.idDocument > 0) ? "success" : (props.requiredFile.isOptional) ? "info" : "warning"}
                    action={
                        (currentObject.Data === undefined && currentObject.idDocument < 0)
                        ? <Box sx={{mt: 0.5}}><IconButton onClick={() => fileUpload.current?.click()}><UploadFile/></IconButton></Box>
                        : <Box sx={{mt: 0.5}}>
                            <IconButton onClick={handleDownload}><Download/></IconButton>
                            {(props.disableEdit === undefined || props.disableEdit === false) && <IconButton onClick={handleRemove}><Delete/></IconButton>}
                        </Box>
                    }
                >
                    {props.requiredFile.Title} 
                    <br/><Typography variant="caption">{(props.requiredFile.isOptional) ? "(optional)" : "(notwendiges Dokument)"}</Typography>
                </Alert>
            </Grid>
        </>
    )
}