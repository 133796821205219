import React from "react";
import { ITimeRecording } from "../../Interfaces/ITimeRecording";
import { ITimeRecordingBreak } from "../../Interfaces/ITimeRecordingBreak";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { TimeRecordBreakRow } from "./TimeRecordBreakRow";


interface IProps {
    timeRecordingBreakArray: ITimeRecordingBreak[];
    setTimeRecordingBreakArray: Function;
}


export const TimeRecordBreakTable:React.FC<IProps> = (props) => {

    const handleAddNew = () => {
        let tempId = -2;

        if (props.timeRecordingBreakArray.length > 0) {
            let tempIdArray = Math.min(...props.timeRecordingBreakArray.map(x => x.idTimeRecordingBreak)) -1;

            if (tempIdArray <= tempId) {
                tempId = tempIdArray -1;
            }
        }


        let newObject:ITimeRecordingBreak = {
            idTimeRecording: -1,
            idTimeRecordingBreak: tempId,
            isRegular: false,
            Title: "",
            Total: 0
        }

        props.setTimeRecordingBreakArray([
            ...props.timeRecordingBreakArray,
            newObject
        ])
    }



    return(
        <>
            <Typography variant="h6">
                Pausen
                <IconButton sx={{float: "right"}} onClick={handleAddNew}><Add/></IconButton>
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Regulär</TableCell>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Dauer</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.timeRecordingBreakArray.map(x => 
                        <TimeRecordBreakRow
                            key={`idTimeRecordingBreak-${x.idTimeRecordingBreak}`}
                            timeRecordingBreakObject={x}
                            timeRecordingBreakArray={props.timeRecordingBreakArray}
                            setTimeRecordingBreakArray={props.setTimeRecordingBreakArray}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}