import React, { useState } from "react";
import { ICompanyFormation } from "../../../../Interfaces/ICompanyFormation";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import BaseInformation from "../BaseInformation";
import { IShareHolderFullObject } from "../../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../../Interfaces/ICompanyFormationHasShareHolder";
import { Shares } from "../gmbh/Shares";


export interface IProps {
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    companyFormationShareArray: ICompanyFormationShare[];
    setCompanyFormationShareArray: Function;
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    setShareHolderRelation: Function;
}


export const DialogEditShares:React.FC<IProps> = (props) => {
    const [editCompanyFormationObject,setEditCompanyFormationObject] = useState(props.companyFormationObject);
    const [editShareHolderArray,setEditShareHolderArray] = useState(props.shareHolderArray);
    const [editShareHolderRelationArray,setEditShareHolderRelationArray] = useState(props.shareHolderRelation);
    const [editSharesArray,setEditSharesArray] = useState(props.companyFormationShareArray);
    //
    const [hasWarning,setHasWarning] = useState(false);
    //
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setEditCompanyFormationObject(props.companyFormationObject);
        setEditShareHolderArray(props.shareHolderArray);
        setEditShareHolderRelationArray(props.shareHolderRelation);
        setEditSharesArray(props.companyFormationShareArray);

        setIsOpen(true);
    }

    const getIsDisabled = () => {
        return false
    }

    const handleSave = () => {
        props.setCompanyFormationObject(editCompanyFormationObject);
        props.setCompanyFormationShareArray(editSharesArray);
        props.setShareHolderArray(editShareHolderArray);
        props.setShareHolderRelation(editShareHolderRelationArray);
        setIsOpen(false);
    }

    return(
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen}
                maxWidth="lg" fullWidth
            >
                <DialogContent>
                    <Shares
                        companyFormationObject={editCompanyFormationObject}
                        setCompanyFormationObject={setEditCompanyFormationObject}
                        shareHolderArray={editShareHolderArray}
                        setShareHolderArray={setEditShareHolderArray}
                        companyFormationShareArray={editSharesArray}
                        setCompanyFormationShareArray={setEditSharesArray}
                        shareHolderRelation={editShareHolderRelationArray}
                        setShareHolderRelation={setEditShareHolderRelationArray}
                        minShareValue={
                            (editCompanyFormationObject.idBusinessForm === 7) ? 100
                            : (editCompanyFormationObject.idBusinessForm === 8) ? 25000
                            : (editCompanyFormationObject.idBusinessForm === 9) ? 50000
                            : 0
                        }
                        minShareWarning={(editCompanyFormationObject.idBusinessForm === 7) ? 1000 : undefined}
                        maxShareWarning={(editCompanyFormationObject.idBusinessForm === 7) ? 25000 : undefined}
                        setHasWarning={setHasWarning}
                    />
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" disabled={hasWarning} onClick={handleSave}>Übernehmen</Button>
                </DialogActions>
            </Dialog>

            <Button variant="outlined" sx={{float: "right"}} onClick={handleOpen}>Editieren</Button>
        </>
    )
}