import { Alert, Snackbar } from "@mui/material";
import React from "react";


interface IProps {
    wasSaved: boolean;
    setWasSaved: Function;
    wasSuccessfullySaved: boolean;
    text?: string;
}


export const ShowWasSaved:React.FC<IProps> = (props) => {
    const handleClose = () => {
        props.setWasSaved(false)
    }

    return(
        <>
            <Snackbar 
                sx={{mt: 10}}
                open={props.wasSaved} 
                autoHideDuration={2000} 
                onClose={handleClose}
                anchorOrigin={{horizontal: "center", vertical: "top"}}
            >
                {
                (props.wasSuccessfullySaved)
                    ? <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {(props.text === undefined) ? "Erfolgreich gespeichert!" : props.text} 
                     </Alert>
                    : <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>Es liegt ein Fehler vor!</Alert>
                }
            </Snackbar>
        </>
    )
}