import React, { useEffect, useState } from "react";
import { Box, Button, Fade, Table, TableHead, Typography } from "@mui/material";
import { MarriageOverview } from "../Components/Marriage/MarriageOverview";
import { getInheritPerson } from "../Components/InheritPerson";
import { IInheritHasPerson } from "../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../Interfaces/IPersonHasDocument";
import { IMarriage } from "../../../../Interfaces/IMarriage";
import { IInherit } from "../../../../Interfaces/IInherit";

interface IProps {
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    marriageArray: IMarriage[];
    setMarriageArray: Function;
}


export const Marriage:React.FC<IProps> = (props) => {
    const [currentPerson, setCurrentPerson] = useState(
        getInheritPerson(
            props.inheritObject.idPerson, 
            props.personArray
        )
    )
    const [wasMarriad,setWasMarriad] = useState<boolean|null>((props.marriageArray.length > 0) ? true : null);

    useEffect(() => {
        const updatedPerson = getInheritPerson(
            props.inheritObject.idPerson,  
            props.personArray
        );
        setCurrentPerson(updatedPerson);
    }, [props.personArray, props.inheritObject.idPerson]);

    


    return(
        <>
            <Box>
                <MarriageOverview
                    inheritObject={props.inheritObject}
                    currentPerson={currentPerson}
                    marriageArray={props.marriageArray}
                    personArray={props.personArray}
                    personHasDocument={props.personHasDocument}
                    setMarriageArray={props.setMarriageArray}
                    setPersonArray={props.setPersonArray}
                    setPersonHasDocument={props.setPersonHasDocument}
                />
            </Box>
        </>
    )
}