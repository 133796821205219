import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, deDE } from "@mui/x-data-grid";
import { LegalTransactionTypeEdit } from "./LegalTransactionTypeEdit";
import { Add, Check, Close, Delete } from "@mui/icons-material";
import { uploadFetch, useFetch } from "../hooks/useFetch";
import { ILegalTransactionType, ILegalTransactionTypeFullObject } from "../Interfaces/ILegalTransactionType";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { ShowWasSaved } from "../core/ShowWasSaved";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { ILegalRequirementTemplate } from "../Interfaces/ILegalRequirementTemplate";
import { LegalRequirementEdit } from "./LegalRequirementEdit";
import { setLegalRequirementState } from "../redux/slices/legalRequirementState";

const columnsLegalTransactionType: GridColDef[] = [
    { field: 'idLegalTransactionType', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'isTemplate', 
      headerName: 'Vorlage', 
      width: 90, 
      renderCell: (params) => 
        (Boolean(params.row.isTemplate))
        ? <Check/>
        : <Close/>
    },
    { field: 'withDialog', 
      headerName: 'Dialog', 
      width: 90, 
      renderCell: (params) => 
        (Boolean(params.row.withDialog))
        ? <Check/>
        : <Close/>
    },
    { field: 'LegalTransactionType', 
      headerName: 'Art', 
      flex: 1
    }
]

export const columnsLegalRequirementTemplate: GridColDef[] = [
    { field: 'idLegalRequirementTemplate', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'canUploadFilesEmployees', 
      headerName: 'Datenupload Mitarbeiter', 
      width: 150, 
      renderCell: (params) => 
        (Boolean(params.row.canUploadFilesEmployees))
        ? <Check/>
        : <Close/>
    },
    { field: 'canUploadFilesClient', 
      headerName: 'Datenupload Mandanten', 
      width: 150, 
      renderCell: (params) => 
        (Boolean(params.row.canUploadFilesClient))
        ? <Check/>
        : <Close/>
    },
    { field: 'sendMail', 
      headerName: 'Auto. E-Mail', 
      width: 150, 
      renderCell: (params) => 
        (Boolean(params.row.sendMail))
        ? <Check/>
        : <Close/>
    },
    { field: 'LegalRequirementTemplate', 
      headerName: 'Maßnahme', 
      flex: 1
    }
]



export const LegalTransactionTypeOverview:React.FC = () => {
    const [transactionTypeArray, setTransactionTypeArray, wasSuccessfullyTransactionTypeArray] = useFetch<ILegalTransactionType[]>("/legaltransactiontype");
    const [requirementArray, setrequirementArray, wasSuccessfullyRequirementArray] = useFetch<ILegalRequirementTemplate[]>("/legalrequirementtemplate");
    //
    const [idLegalTransactionType, setIdLegalTransactionType] = useState<number|null>(null);
    const [idLegalRequirementTemplate, setIdLegalRequirementTemplate] = useState<number|null>(null);
    const [currentRequirementTemplate,setCurrentRequirementTemplate] = useState<ILegalRequirementTemplate|null>(null);
    //
    const [isOpenAreYourSure,setIsOpenAreYourSure] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [wasSaved,setWasSaved] = useState(false);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);
    // 
    const [wasRemoved,setWasRemoved] = useState(false);


    const handleClose = () => {
        setIsOpenAreYourSure(true)
    }

    const handleFinalOpen = () => {
        setIsOpenAreYourSure(false);
        setIdLegalTransactionType(null);
    }

        const handleAfterRemove = () => {
        if (transactionTypeArray !== undefined && idLegalTransactionType !== null)  {
            setTransactionTypeArray([
                ...transactionTypeArray.filter(x => x.idLegalTransactionType !== idLegalTransactionType)
            ])
            setWasRemoved(true);
            setIdLegalTransactionType(null);
        }
    }

    const wrappeHandleSaveRequirement = (localRequirement:ILegalRequirementTemplate) => {
        if (currentRequirementTemplate !== null && requirementArray !== undefined) {
            if (currentRequirementTemplate?.idLegalRequirementTemplate < 0) {
                setrequirementArray([
                    ...requirementArray,
                    localRequirement
                ]) }
            else {
                setrequirementArray([
                    ...requirementArray
                        .map(x => x.idLegalRequirementTemplate === localRequirement.idLegalRequirementTemplate ? localRequirement : x)
                ]) 
            }
            setIsLoading(false);
            setWasSaved(true);
            setCurrentRequirementTemplate(null);
        }
    }


    const handleSaveRequirement = () => {
        if (currentRequirementTemplate !== null) {
            setIsLoading(true)
            let uploadObject = {
                ...currentRequirementTemplate,
                canUploadFilesClient: Boolean(currentRequirementTemplate.canUploadFilesClient),
                canUploadFilesEmployees: Boolean(currentRequirementTemplate.canUploadFilesEmployees),
                hasError: Boolean(currentRequirementTemplate.hasError),
                hasErrorFreeText: Boolean(currentRequirementTemplate.hasErrorFreeText),
                isTemplate: Boolean(currentRequirementTemplate.isTemplate),
                sendMail: Boolean(currentRequirementTemplate.sendMail),
            } as ILegalRequirementTemplate
            uploadFetch("/legalrequirementtemplate",(Number(currentRequirementTemplate?.idLegalRequirementTemplate) < 0),uploadObject,wrappeHandleSaveRequirement)
        }

    }



    if (!wasSuccessfullyTransactionTypeArray || !wasSuccessfullyRequirementArray) { return <>Fehler!</> }
    else if (transactionTypeArray == undefined || requirementArray === undefined) { return <><CustomCircularProgress/></> }  
    else {
        return (
                <Box sx={{m: 2, mt: 0}}>
                    <ShowWasSaved 
                        wasSaved={wasSaved}
                        setWasSaved={setWasSaved}
                        wasSuccessfullySaved={wasSuccessfullySaved}
                    />


                    <Dialog
                        open={isOpenAreYourSure}
                        onClose={() => {}}
                    >
                        <DialogTitle>Warnung</DialogTitle>
                        <DialogContent>
                            Sind Sie sicher, dass Sie die Rechtsgeschäftart ohne speichern schließen wollen?
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleFinalOpen}>Schließen</Button>
                            <Button variant="contained" onClick={() => setIsOpenAreYourSure(false)} color="success">Abbruch</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={currentRequirementTemplate !== null}
                        onClose={() => setCurrentRequirementTemplate(null)}
                        maxWidth="lg" fullWidth
                    >
                        <DialogTitle>Maßnahme</DialogTitle>
                        <DialogContent>
                            {(isLoading) && <><LinearProgress sx={{ mt: 1 }} /></>}
                            { (currentRequirementTemplate !== null) &&
                                <LegalRequirementEdit
                                    currentRequirement={currentRequirementTemplate}
                                    legalRequirements={[]}
                                    setCurrentRequirement={setCurrentRequirementTemplate}
                                />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => setCurrentRequirementTemplate(null)}>Schließen</Button>
                            <Button variant="contained" disabled={currentRequirementTemplate?.LegalRequirementTemplate === ""} onClick={handleSaveRequirement} color="success">Speichern</Button>
                        </DialogActions>
                    </Dialog>

                    <FullScreenDialog
                        isOpen={idLegalTransactionType !== null}
                        onClose={handleClose}
                        title="Rechtsgeschäftsart"
                        customCloseBtnFunc={() => setIdLegalTransactionType(null)}
                    >
                        <LegalTransactionTypeEdit
                            key={`idLegalTransactionType-${idLegalTransactionType}`}
                            idLegalTransactionType={(idLegalTransactionType === null) ? -1 : idLegalTransactionType}
                            setIdLegalTransactionType={setIdLegalTransactionType}
                            //
                            transactionTypeArray={transactionTypeArray}
                            setTransactionTypeArray={setTransactionTypeArray}
                            legalRequirementTemplateArray={requirementArray}
                            //
                            setWasSaved={setWasSaved}
                            setWasSavedSuccessfully={setWasSuccessfullySaved}
                            handleAfterRemove={handleAfterRemove}
                        />
                    </FullScreenDialog>

                    <Typography variant="h4">
                        Rechtsgeschäftsarten
                    </Typography>

                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">
                                Rechtsgeschäftsarten
                                <IconButton sx={{float: "right"}} onClick={() => setIdLegalTransactionType(-1)}><Add/></IconButton>
                            </Typography>
                            <br/>
                            <DataGrid
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={transactionTypeArray}
                                columns={columnsLegalTransactionType}
                                getRowId={(row) => row.idLegalTransactionType}
                                onRowClick={(params,event,details) => setIdLegalTransactionType(Number(params.row.idLegalTransactionType))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">
                                Maßnahmenkatalog
                                <IconButton sx={{float: "right"}} onClick={() => {
                                    setCurrentRequirementTemplate({
                                        idLegalPhaseTemplate: null,
                                        idLegalRequirementTemplate: -1,
                                        canUploadFilesClient: false,
                                        canUploadFilesEmployees: false,
                                        hasError: false,
                                        hasErrorFreeText: false,
                                        LegalRequirementTemplate: "",
                                        sendMail: false, 
                                        EmailBody: null,
                                        EmailSubject: null,
                                        Predecessor_idLegalRequirementTemplate: null,
                                        TextOfError: null,
                                        Position: 0,
                                        idLegalRequirementState: 10,
                                        idUser: null,
                                        isTemplate: true
                                    } as ILegalRequirementTemplate)
                                }}><Add/></IconButton>
                            </Typography>
                            <br/>
                            <DataGrid
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={requirementArray.filter(x => x.isTemplate)}
                                columns={columnsLegalRequirementTemplate}
                                getRowId={(row) => row.idLegalRequirementTemplate}
                                onRowClick={(params,event,details) => {
                                    let testObject = requirementArray.find(x => x.idLegalRequirementTemplate === Number(params.id));
                                    if (testObject === undefined) {
                                        setCurrentRequirementTemplate(null);
                                    } else {
                                        setCurrentRequirementTemplate(testObject);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{display: "flex"}}>

                            { /*
                            <Collapse orientation="horizontal" in={idLegalTransactionType !== null}>   
                                <Box sx={{width: 1200, minHeight: 800, p: 2}}>                               
                                    <LegalTransactionTypeEdit
                                        key={`idLegalTransactionType-${idLegalTransactionType}`}
                                        idLegalTransactionType={(idLegalTransactionType === null) ? -1 : idLegalTransactionType}
                                        setIdLegalTransactionType={setIdLegalTransactionType}
                                    />
                                </Box>
                            </Collapse>
                            */}
                    </Box>
                </Box>
        )
    }
}