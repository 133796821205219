import React, { useEffect, useState } from "react";
import { ChildrenTable } from "./ChildrenTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IChildren } from "../../../../../Interfaces/IChildren";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { ExpandMore } from "@mui/icons-material";
import { IInherit } from "../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../Interfaces/IMarriage";

interface IProps {
  inheritObject: IInherit;
  idPersonInherit: number;
  parrentPersonArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  marriageArray: IMarriage[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  depth?: number;
  title: string;
}

export const ChildrenDriver: React.FC<IProps> = (props) => {
  /*{deathChildren.length > 0 && (
    <ChildrenDriver
      inheritObject={props.inheritObject}
      idPersonInherit={props.idPersonInherit}
      key={`children-child-${deathChildren.length}-${
        props.depth === undefined ? 0 : props.depth + 1
      }`}
      depth={props.depth === undefined ? 0 : props.depth + 1}
      parrentPersonArray={deathChildren}
      personArray={props.personArray}
      setPersonArray={props.setPersonArray}
      personHasDocument={props.personHasDocument}
      setPersonHasDocument={props.setPersonHasDocument}
      childrenArray={props.childrenArray}
      setChildrenArray={props.setChildrenArray}
      marriageArray={props.marriageArray}
    />
  )}*/
  //const [titleBool, setTitleBool] = useState(false);
  const [currentChildrenPage, setCurrentChildrenPage] = useState(0);
  /*const [localChildrenArray, setLocalChildrenArray] = useState(
    props.childrenArray.filter(
      (x) =>
        props.parrentPersonArray
          .map((y) => y.idPerson)
          .indexOf(x.Parent1_idPerson) > -1
    )
  );
  const [deathChildren, setDeathChildren] = useState<IInheritHasPerson[]>([]);

  useEffect(() => {
    props.setChildrenArray([
      ...props.childrenArray.filter(
        (x) =>
          props.parrentPersonArray
            .map((y) => y.idPerson)
            .indexOf(x.Parent1_idPerson) === -1
      ),
      ...localChildrenArray,
    ]);
  }, [localChildrenArray]);

  useEffect(() => {
    let testPersonArray = props.personArray.filter(
      (x) =>
        localChildrenArray.map((y) => y.Children_idPerson).indexOf(x.idPerson) >
        -1
    );
    setDeathChildren([
      ...testPersonArray
        .filter((x) => x.idPerson !== props.idPersonInherit)
        .filter((x) => x.DateOfDeath !== null),
    ]);
  }, [localChildrenArray, props.personArray]);*/

  /*const handleBackForChild = () => {
    setCurrentChildrenPage(0);
  };*/

  const handleAccordionChange = (event: any, isExpanded: boolean) => {
  };

  //von {props.parrentPersonArray.map(x => `${x.FirstName} ${x.LastName}`).join(" und ")}

  return (
    <>
      <Accordion onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{props.title}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <ChildrenTable
            inheritObject={props.inheritObject}
            idPersonInherit={props.idPersonInherit}
            firstParentArray={props.parrentPersonArray}
            personArray={props.personArray}
            setPersonArray={props.setPersonArray}
            personHasDocument={props.personHasDocument}
            setPersonHasDocument={props.setPersonHasDocument}
            childrenArray={props.childrenArray}
            setChildrenArray={props.setChildrenArray}
            marraigeArray={props.marriageArray}
          />
        </AccordionDetails>
      </Accordion>

      
    </>
  );
};
