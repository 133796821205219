import React, { useState } from "react";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { IBank } from "../../Interfaces/IBank";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, MenuItem, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";


interface IProps {
    idBank: number | null;
    setIdBank: Function;
}



export const BankDropwdown:React.FC<IProps> = (props) => {
    const [bankArray, setBankArray, wasSuccessfullyBank] = useFetch<IBank[]>("/bank");
    //
    const [isNewOpen,setIsNewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //
    const [newBankObject, setNewBankObject] = useState({
        idBank: -1,
        Bank: "",
        BIC: ""
    } as IBank);


    const handleNewBank = () => {
        setNewBankObject({
            idBank: -1,
            Bank: "",
            BIC: ""
        } as IBank);
        setIsNewOpen(true);
    }


    const handleAfterSave = (resObject:IBank) => {
        if (bankArray !== undefined) {
            setBankArray([
                ...bankArray,
                resObject
            ])
            props.setIdBank(resObject.idBank);
            setIsNewOpen(false);
        }
        setIsLoading(false);
    }

    const handleSave = () => {
        setIsLoading(true);
        uploadFetch("/bank",true,newBankObject,handleAfterSave)
    }


    if (!wasSuccessfullyBank) { return <Alert severity="error">Die Bank-Liste konnte nicht geladen werden!</Alert> }
    else if (bankArray === undefined) { return <>Bitte warten...</> }
    else {
        return(
            <>
                <Dialog open={isNewOpen} onClose={() => setIsNewOpen(false)}>
                    <DialogTitle>Neue Bank anlegen</DialogTitle>
                    <DialogContent>
                        {(isLoading) && <LinearProgress/>}
                        <Grid sx={{mt: 2}} container spacing={2}>
                            <Grid item xs={5}>
                                <TextField
                                    label="BIC"
                                    size="small"
                                    value={newBankObject.BIC}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewBankObject({ ...newBankObject, BIC: event.target.value })}
                                    error={newBankObject.BIC === ""}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={7}>
                                <TextField
                                    label="Bank"
                                    size="small"
                                    value={newBankObject.Bank}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewBankObject({ ...newBankObject, Bank: event.target.value })}
                                    error={newBankObject.Bank === ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsNewOpen(false)}>Abbruch</Button>
                        <Button 
                            variant="contained" 
                            onClick={handleSave}
                            disabled={isLoading || newBankObject.BIC === "" || newBankObject.Bank === ""}
                        >Speichern</Button>
                    </DialogActions>
                </Dialog>



                <Box sx={{display: "flex"}}>
                    <TextField
                        label="Ausgewählte Bank"
                        value={(props.idBank === null) ? "" : props.idBank}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                            props.setIdBank(Number(event.target.value))
                        }}
                        size="small"
                        fullWidth
                        select
                    >
                        {bankArray.map(x => 
                            <MenuItem key={`idBank-${x.idBank}`} value={x.idBank}>{x.BIC} {x.Bank}</MenuItem>
                        )}
                    </TextField>
                    <IconButton sx={{float: "right"}} onClick={handleNewBank}><Add/></IconButton>
                </Box>
            </>
        )
    }
}