import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { Alert, Dialog, DialogContent, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { ShowWasSaved } from "../core/ShowWasSaved";
import { DataGrid, GridColDef, deDE } from "@mui/x-data-grid";
import { ISystemMail } from "../Interfaces/ISystemMail";
import { SystemMailEdit } from "./SystemMailEdit";
import { Box } from "@mui/system";
import { EmailSignatureEdit } from "../Admin/Settings/EmailSignatureEdit";


const columns: GridColDef[] = [
    { field: 'idSystemMail', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'Title', 
      headerName: 'Bezeichnung', 
      flex: 1
    },
]




export const SystemMailOverview:React.FC = () => {
    const [mailArray, setMailArray, wasSuccessfullyMailArray] = useFetch<ISystemMail[]>("/systemmail");
    //
    const [showPublic, setShowPublic] = useState(false);
    //
    const [idSystemMail, setIdSystemMail] = useState<number|null>(null);
    //
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);
    //
    const [scopeArray,setCcopeArray] = React.useState(localStorage.getItem("scope")?.split(","))

    if (!wasSuccessfullyMailArray) {
        return <Alert severity="error">Fehler!</Alert> 
    }
    else if (mailArray === undefined) {
        return <CustomCircularProgress/>
    } else {
        return(
            <>
                <ShowWasSaved
                    wasSaved={wasSaved}
                    setWasSaved={setWasSaved}
                    wasSuccessfullySaved={wasSavedSuccesfully}
                />
                

                <Dialog open={idSystemMail !== null} onClose={() => setIdSystemMail(null)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        {(idSystemMail !== null) &&
                            <SystemMailEdit 
                                key={`idSystemMail-${idSystemMail}`}
                                currentId={idSystemMail}
                                setCurrentId={setIdSystemMail}
                                currentArray={mailArray}
                                setCurrentArray={setMailArray}
                                setWasSaved={setWasSaved}
                                setWasSavedSuccesfully={setWasSavedSuccesfully}
                            />
                        }

                    </DialogContent>
                </Dialog>

                <Typography variant="h4">
                    Systemnachrichten
                </Typography>

                {(scopeArray !== undefined && scopeArray.indexOf("admin") > -1) &&
                    <EmailSignatureEdit/>
                }

                <Typography variant="h5" sx={{mt: 5}}>
                    E-Mail-Vorlagen
                    <FormGroup sx={{float: "right"}}>
                        <FormControlLabel  control={
                            <Switch 
                                checked={showPublic} 
                                onChange={() => setShowPublic(!showPublic)}
                            />
                        } label="Öffentlicher Server" />
                    </FormGroup>
                </Typography>
                <Box sx={{mt: 2}}/>
                <DataGrid
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={mailArray.filter(x => Boolean(x.isPublic) === showPublic)}
                    columns={columns}
                    getRowId={(row) => row.idSystemMail}
                    onRowClick={(params,event,details) => setIdSystemMail(Number(params.id))}
                />
            </>
        )
    }

    
}