import React, { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { IGlobalMail } from "../../Interfaces/IGlobalMail";
import { Alert, Box, Button, Collapse, Dialog, Grow, Typography } from "@mui/material";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { DataGrid, GridColDef, deDE } from "@mui/x-data-grid";
import { GlobalMailEdit } from "./GlobalMailEdit";

const options:Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };


const columns: GridColDef[] = [
    { field: 'idGlobalMail', 
      headerName: 'Nr.', 
      width: 90 
    },
    { field: 'Send_at', 
      headerName: 'Vom', 
      width: 250,
      renderCell: (params) => <>{new Date(params.row.Send_at).toLocaleString("de-DE",options)}</>
    },
    { field: 'Title', 
      headerName: 'Bezeichnung', 
      flex: 1
    },
    { field: 'Correct', 
      headerName: 'Erfolgreich', 
      width: 90 
    },
    { field: 'Error', 
      headerName: 'Fehlerhaft', 
      width: 90 
    },
    { field: 'Total', 
      headerName: 'Total', 
      width: 90 
    },
]


export const GlobalMailOverview:React.FC = () => {
    const [mailArray, setMailArray, wasSuccessfullyMailArray] = useFetch<IGlobalMail[]>("/globalmail");
    //
    const [idGlobalMail, setIdGlobalMail] = useState<number|null>(null);
    //
    const [wasSaved, setWasSaved] = useState(false);
    const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);


    if (!wasSuccessfullyMailArray) {
        return <Alert severity="error">Fehler!</Alert> 
    }
    else if (mailArray === undefined) {
        return <CustomCircularProgress/>
    } else {
        return(
            <>
                <Collapse in={idGlobalMail !== null}>
                    <Box>
                        {(idGlobalMail !== null) &&
                            <GlobalMailEdit
                                key={`idGlobalMail-${idGlobalMail}`}
                                currentId={idGlobalMail}
                                setCurrentId={setIdGlobalMail}
                                currentArray={mailArray}
                                setCurrentArray={setMailArray}
                                setWasSaved={setWasSaved}
                                setWasSavedSuccesfully={setWasSavedSuccesfully}
                            />
                        }
                    </Box>
                </Collapse>


                <Typography variant="h5" sx={{mt: 5}}>
                    Globale E-Mail (an alle Mandaten mit Login)
                    <Grow in={idGlobalMail === null}>
                        <Button sx={{float: "right"}} variant="contained" onClick={() => setIdGlobalMail(-1)}>Neue E-Mail</Button>
                    </Grow>
                </Typography>
                <Box sx={{mt: 2}}/>

                <DataGrid
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={mailArray}
                    columns={columns}
                    getRowId={(row) => row.idGlobalMail}
                    onRowClick={(params,event,details) => setIdGlobalMail(Number(params.id))}
                />
            </>
        )
    }
}