import React, { useState } from "react";
import { IUser } from "../../Interfaces/IUser";
import { IDayOffWork } from "../../Interfaces/IDayOffWork";
import { IDayOffWorkCategory } from "../../Interfaces/IDayOffWorkCategory";
import { Box, Button, Collapse, Grid, MenuItem, TextField } from "@mui/material";
import { DayOffWorkUserSummary } from "./DayOffWorkUserSummary";
import { IEmploymentContract } from "../../Interfaces/IEmploymentContract";
import { DayOffWorkEdit } from "../DayOffWorkEdit";


interface IProps {
    userArray: IUser[];
    employmentContractArray: IEmploymentContract[];
    dayOffWorkCategoryArray: IDayOffWorkCategory[];
    dayOffWorkArray: IDayOffWork[];
    setDayOffWorkArray: Function;
    handleClose: Function;
}



export const DayOffWorkNew:React.FC<IProps> = ({userArray, employmentContractArray, dayOffWorkCategoryArray, dayOffWorkArray, setDayOffWorkArray, handleClose }) => {
    const [idUser, setIdUser] = useState<number|null>(null);
    //
    const [currentPage, setCurrentPage] = useState(0);


    if (currentPage === 0) {
        return(
            <>
                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={12}>
                        <TextField
                            select
                            label="Mitarbeiter"
                            size="small"
                            value={idUser === null ? "" : idUser}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdUser(Number(event.target.value))}
                            fullWidth
                        >
                            {userArray.map(x =>
                                <MenuItem key={`idUser-${x.idUser}`} value={x.idUser}>{x.FirstName} {x.LastName}</MenuItem>
                            )}
                        </TextField>
                    </Grid> 
                </Grid>

                <Collapse in={idUser !== null}>
                    <DayOffWorkUserSummary
                        key={`dayoffworkusersummary-${idUser}`}
                        idUser={idUser}
                        employmentContractArray={employmentContractArray}
                        dayOffWorkArray={dayOffWorkArray}
                        dayOffWorkCategoryArray={dayOffWorkCategoryArray}
                    />
                </Collapse>

                <Grid container justifyContent="flex-end" sx={{mt: 5}}>
                    <Button variant="outlined" onClick={() => handleClose()}>Abbruch</Button>
                    <Button variant="contained" sx={{ml: 2}} disabled={idUser === null} onClick={() => setCurrentPage(currentPage + 1)}>Weiter</Button>
                </Grid>


            </>
        )
    }
    else if (currentPage === 1 && idUser !== null) {
        return(
            <>
                <DayOffWorkEdit
                    idUser={idUser}
                    userArray={userArray}
                    employmentContractArray={employmentContractArray}
                    dayOffWorkArray={dayOffWorkArray}
                    setDayOffWorkArray={setDayOffWorkArray}
                    dayOffWorkCategoryArray={dayOffWorkCategoryArray}
                    idDayOffWork={-1}
                    enableAdminSeetings
                    handleClosed={() => handleClose()}
                />
            </>
        )
    }  
    else {
        return <>Error!</>
    }
}