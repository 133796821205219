import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { StrangersTable } from "../Components/Strangers/StrangersTable";
import { IInherit } from "../../../../Interfaces/IInherit";
import { IInheritHasPerson } from "../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../Interfaces/IPersonHasDocument";


interface IProps {
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersoArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
}



export const Strangers:React.FC<IProps> = (props) => {

    return(
        <>
            <StrangersTable
                inheritObject={props.inheritObject}
                personArray={props.personArray}
                setPersoArray={props.setPersoArray}
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={props.setPersonHasDocument}
            />

        </>
    )
}