import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { PurchaseContractPersonEdit } from "./PurchaseContractPersonEdit";
import { PurchaseContractPersonRow } from "./PurchaseContractPersonRow";
import { IPurchaseContractHasPerson } from "../../../../Interfaces/IPerson";


interface IProps {
    idPruchaseContractRelationType: number;
    title: string;
    personArray: IPurchaseContractHasPerson[];
    setPersonArray: Function;
}

export const getPurchaseContractPerson = (idPerson:number,idPurchaseContractRelationType:number, personArray:IPurchaseContractHasPerson[],isNew:boolean = false) => {
    let testObject = personArray.find(x => x.idPerson === idPerson);
    if (isNew === false && testObject !== undefined) {
        return testObject;
    } else {
        let minId = Math.min(...personArray.map(x => x.idPerson)) -1;
        if (minId >= 0) {
            minId = -1;
        }

        return {
            idPerson: minId,
            Birthday: "",
            Birthplace: "",
            DateOfDeath: null,
            FirstName: "",
            idAcademicTitle: 1,
            idCountry: 1,
            idGender: -1,
            idInherit: -1,
            idPostcode: null,
            LastName: "",
            PlaceOfDeath: null,
            Street: "",
            Relation_idPerson: null,
            StreetAditional: "",
            StreetNr: "",
            Surname: null,
            idBusinessForm: 1,
            idPurchaseContractRelationType: idPurchaseContractRelationType,

            Email: null,
            PhoneNumber: null,

            TaxNumber: null,
            IBAN: null,
            BIC: null,
            Bank: null,
        } as IPurchaseContractHasPerson
    }
}


export const PurchaseContractPersonOverview:React.FC<IProps> = (props) => {
    const [newPerson,setNewPerson] = useState(getPurchaseContractPerson(-1,props.idPruchaseContractRelationType,props.personArray,true));
    const [isOpenNew,setIsOpenNew] = useState(false);


    const handleOpenNew = () => {
        setNewPerson(getPurchaseContractPerson(-1,props.idPruchaseContractRelationType,props.personArray,true));
        setIsOpenNew(true);
    }

    const handleAdd = () => {
        props.setPersonArray([
            ...props.personArray,
            newPerson
        ])
        setIsOpenNew(false);
    }


    return(
        <>
            <Dialog
                open={isOpenNew}
                onClose={() => setIsOpenNew(false)}
                maxWidth="lg" fullWidth
            >
                <DialogTitle>Person hinzufügen</DialogTitle>
                <DialogContent>
                    <PurchaseContractPersonEdit
                        currentPerson={newPerson}
                        setCurrentPerson={setNewPerson}
                        personArray={props.personArray}
                        showBankInformation
                        showTaxInformation
                        
                    />
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenNew(false)}>Abbruch</Button>
                        <Button 
                         variant="contained" 
                         onClick={handleAdd} 
                         disabled={newPerson.FirstName === "" || newPerson.LastName === "" || newPerson.idGender < 0}
                        >
                            Übernehmen
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog>



            <Box sx={{mt: 5}}/>
            <Typography variant="h6" >
                {props.title}
                <Button sx={{float: "right"}} variant="contained" onClick={handleOpenNew}>Hinzufügen</Button>
            </Typography>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        <TableCell>Vorname</TableCell>
                        <TableCell>Nachname</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.personArray.filter(x => x.idPurchaseContractRelationType === props.idPruchaseContractRelationType).map((x,idx) => 
                        <PurchaseContractPersonRow
                            key={`idPerson-${x.idPerson}`}
                            index={idx}
                            currentObject={x}
                            personArray={props.personArray}
                            setPersonArray={props.setPersonArray}
                        />
                    )}
                </TableBody>
            </Table>
        </>
    )
}