import React, { useEffect, useState } from "react";
import { IDocument } from "../Interfaces/IDocument";
import { useFetch } from "../hooks/useFetch";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IDocumentDownloaded } from "../Interfaces/IDocumentDownloaded";
import { IContact } from "../Interfaces/IContact";

interface IProps {
    documentObject: IDocument;
}


export const DocumentDownloaded:React.FC<IProps> = (props) => {

    const [contactArray,setContactArray,wasSuccessfullyContactArray] = useFetch<IContact[]>("/contact");
    const [documentDownloadedArray,setDocumentDownloadedArray,wasSuccessfullyDocumentDownloadedArray] = useFetch<IDocumentDownloaded[]>("/documentdownloaded");
    //
    const [uniquideIdContactArray,setUniquideIdContactArray] = useState<number[]>([]);

    const timeStringWithoutTimezone = (currentDate:Date) => {
        //console.log(currentDate)
        let userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
        return new Date(currentDate.getTime() + userTimezoneOffset).toLocaleString();
    }


    useEffect(() => {
        if (contactArray !== undefined && documentDownloadedArray !== undefined) {
            let tmpArray = [
                ...documentDownloadedArray
                    .filter(x => x.idDocument === props.documentObject.idDocument)
                    .map(x => x.idContact)
            ];
            var unique = tmpArray.filter((value, index, array) => array.indexOf(value) === index);
            setUniquideIdContactArray([...unique]);
        }
    },[contactArray,documentDownloadedArray])


    if (!wasSuccessfullyContactArray || !wasSuccessfullyDocumentDownloadedArray) { return <>Error</> }
    else if (contactArray === undefined || documentDownloadedArray === undefined) {
        return <CustomCircularProgress/>
    }
    else {
        return(
            <>
                <Typography>
                    Das Dokuement <i>{props.documentObject.FileName}</i> wurde 
                    insgesamt {documentDownloadedArray.filter(x => x.idDocument === props.documentObject.idDocument).length} runtergeladen:

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Mandant</TableCell>
                                <TableCell>Frühster Download</TableCell>
                                <TableCell>Spätester Download</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {uniquideIdContactArray.map(localIdContact => {
                                let currentContact = contactArray.find(x => x.idContact === localIdContact);
                                let ownDownloaded = documentDownloadedArray
                                    .filter(x => x.idDocument === props.documentObject.idDocument)
                                    .filter(x => x.idContact === localIdContact);
                                
                                let sortedByTimeStamp = ownDownloaded.sort((a,b) => new Date(a.Timestamp) < new Date(b.Timestamp) ? -1 : 1);
                                //console.log(sortedByTimeStamp)

                                return(
                                    <TableRow>
                                        <TableCell>{currentContact?.FirstName} {currentContact?.LastName}</TableCell>
                                        <TableCell>{(ownDownloaded.length > 0) ? timeStringWithoutTimezone(new Date(ownDownloaded[0].Timestamp)) : <>k.A.</>}</TableCell>
                                        <TableCell>{(ownDownloaded.length > 0) ? timeStringWithoutTimezone(new Date(ownDownloaded[ownDownloaded.length-1].Timestamp)) : <>k.A.</>}</TableCell>
                                        <TableCell>{ownDownloaded.length}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Typography>
            </>
        )
    }
}