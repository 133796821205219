import { ICompanyFormationAnswer } from "../../Interfaces/ICompanyFormationAnswer";


export const getCompanyFormationAnswer = (
    idCompanyFormationQuestion: number,
    companyFormationAnswerArray:ICompanyFormationAnswer[],
    defaultIdCompanyFormationOption: number = -1
    
) => {
    let foundObject = companyFormationAnswerArray.find(x => x.idCompanyFormationQuestion === idCompanyFormationQuestion);

    if (foundObject !== undefined) {
        return foundObject
    } else {
        let minId = Math.min(...companyFormationAnswerArray.map(x => x.idCompanyFormationAnswer)) -1;

        if (minId > -1) {
            minId = -1;
        }

        return {
            idCompanyFormation: -1,
            idCompanyFormationAnswer: minId,
            idCompanyFormationOption: defaultIdCompanyFormationOption,
            idCompanyFormationQuestion: idCompanyFormationQuestion,
            NumberValue: null,
            TextValue: null
        } as ICompanyFormationAnswer
    }
}