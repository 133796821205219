import React, { useEffect, useState } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { $generateHtmlFromNodes } from '@lexical/html';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Box } from "@mui/system";
import { IGlobalMail } from "../../Interfaces/IGlobalMail";
import { uploadFetch } from "../../hooks/useFetch";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { CustomEditor } from "../../core/CustomEditor";

interface IProps {
    currentId: number;
    setCurrentId: Function;
    currentArray: IGlobalMail[];
    setCurrentArray: Function;
    setWasSaved: Function;
    setWasSavedSuccesfully: Function;
}

export const GlobalMailEdit:React.FC<IProps> = (props) => {
    const [systemMailOrg, setSystemMailOrg] = useState(() => {
        let testObject = props.currentArray.find(x => x.idGlobalMail === props.currentId);
        
        if (testObject === undefined) {
            return {
                Body: "",
                idGlobalMail: -1,
                Subject: "",
                Title: "",
            } as IGlobalMail
        } else {
            return testObject;
        }
    });
    const [systemMailObject, setSystemMailObject] = useState(systemMailOrg);

    const [isOpenConfirmSend,setIsOpenConfirmSend] = useState(false);
    const [isOpenShowResult,setIsOpenShowResult] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);

    const isDisabled = () => {
        if (systemMailObject !== undefined) {
            return (
                systemMailObject.Title === ""
                || systemMailObject.Subject === ""
                || systemMailObject.Body === ""
            )
        } else {
            return true;
        }
    }

    const wrapperSaved = (localObject:IGlobalMail) => {
        props.setCurrentArray([
            ...props.currentArray,
            localObject
        ])

        setSystemMailOrg(localObject);
        setSystemMailObject(localObject);
        setIsOpenConfirmSend(false);
        setIsOpenShowResult(true);
        setIsLoading(false);
    }

    const handleSave = () => {
        if (systemMailObject !== undefined) {
            setIsLoading(true)
            let uploadObeject:IGlobalMail = {
                ...systemMailObject,
                Send_at: undefined,
                Correct: undefined,
                Error: undefined,
                Total: undefined
            }
            uploadFetch(`/globalmail`, 
                true, 
                uploadObeject, 
                wrapperSaved, 
                props.setWasSavedSuccesfully,
                props.setWasSaved
            );
        }
    }



    if (systemMailOrg === undefined || systemMailObject === undefined) {
        return <Alert severity="error">Fehler!</Alert>
    } else {
        return(
            <>
                <Dialog 
                    open={isOpenShowResult}
                    onClose={() => setIsOpenShowResult(false)}
                >
                    <DialogTitle>Versendet!</DialogTitle>
                    <DialogContent>
                        <Alert severity="success">
                            Ihre E-Mail wurde erfolgreich versendet!
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b>Erfolgreich</b></td>
                                        <td>{systemMailOrg.Correct}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Fehlerhaft</b></td>
                                        <td>{systemMailOrg.Error}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td><b>{systemMailOrg.Total}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpenShowResult(false)} variant="contained">Ok</Button>
                    </DialogActions>
                </Dialog>


                <Dialog 
                    open={isLoading}
                    onClose={() => {}}
                    maxWidth="sm" fullWidth
                >
                    <DialogContent>
                        <CustomCircularProgress/>
                    </DialogContent>
                </Dialog>




                <Dialog 
                    open={isOpenConfirmSend}
                    onClose={() => setIsOpenConfirmSend(false)}
                >
                    <DialogTitle>Senden bestätigen</DialogTitle>
                    <DialogContent>
                        Sind Sie sicher, dass Sie die E-Mail an alle
                        Mandanten mit einem aktiven Loginzugang senden wollen?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpenConfirmSend(false)} variant="outlined">Abbruch</Button>
                        <Button onClick={handleSave} variant="contained" disabled={isDisabled()}>Senden an alle Mandanten</Button>
                    </DialogActions>
                </Dialog>



                <Typography variant="h5">
                    {`${systemMailOrg.Title}`}
                    
                    <Button onClick={() => setIsOpenConfirmSend(true)} sx={{float: "right", ml: 2}} variant="contained" disabled={isDisabled()}>Senden</Button>
                    <Button onClick={() => props.setCurrentId(null)} sx={{float: "right"}} variant="outlined">Abbruch</Button>
                </Typography>

                <Box sx={{mt: 2}}/>

                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <TextField
                            label="Titel (nur intern)"
                            size="small"
                            value={systemMailObject.Title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({ ...systemMailObject, Title: event.target.value })}
                            error={systemMailObject.Title === ""}
                            fullWidth
                        />
                    </Grid>

                    <Grid item sm={8}>
                        <TextField
                            label="Betreff"
                            size="small"
                            value={systemMailObject.Subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSystemMailObject({ ...systemMailObject, Subject: event.target.value })}
                            error={systemMailObject.Subject === ""}
                            fullWidth
                        />
                    </Grid>

                    <CustomEditor idTextModuleCategory={1} txt={systemMailObject.Body} setTxt={(txt:string) => setSystemMailObject({ ...systemMailObject, Body: txt})}/>
                </Grid>
            </>
        )
    }

}