import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { IChildren } from "../../../../../Interfaces/IChildren";
import { IInherit } from "../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../Interfaces/IMarriage";
import { Add } from "@mui/icons-material";
import { SiblingsChildrenRow } from "./SiblingsChildrenRow";
import { SiblingsAddDialog } from "./SiblingAddDialog";
import { NieceAndNephewAddDialog } from "./NieceAndNephewAddDialog";
import { GrandNieceAndGrandNephewAddDialog } from "./GrandNieceAndGrandNephewAddDialog";
import { GreatGrandNieceAndGreatGrandNephewAddDialog } from "./GreatGrandNieceAndGreatGrandNephewAddDialog";

export interface IChildrenTableColDef {
  titleFirstParent: string;
  isSecondParentEnabled: boolean;
}

interface IProps {
  inheritObject: IInherit;
  idPersonInherit: number;
  firstParentArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  marraigeArray: IMarriage[];
}

export const SiblingsChildrenTable: React.FC<IProps> = (props) => {
  const [firstParent, setFirstParent] = useState<number>();
  const [secondParent, setSecondParent] = useState<number>();
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [isNewOpen2, setIsNewOpen2] = useState(false);
  const [isNewOpen3, setIsNewOpen3] = useState(false);
  const [isNewOpen4, setIsNewOpen4] = useState(false);
  const [siblings, setSiblings] = useState<IChildren[]>([]);
  const [nieceAndNephew, setNieceAndNephew] = useState<IChildren[]>([]);
  const [grandNieceAndGrandNephew, setGrandNieceAndGrandNephew] = useState<
    IChildren[]
  >([]);
  const [
    greatGrandNieceAndGreatGrandNephew,
    setGreatGrandNieceAndGreatGrandNephew,
  ] = useState<IChildren[]>([]);

  useEffect(() => {
    const getParents = props.childrenArray.find(
      (children) => children.Children_idPerson === props.personArray[0].idPerson
    );
    const firstParent = getParents?.Parent1_idPerson;
    const secondParent = getParents?.Parent2_idPerson;

    setFirstParent(firstParent);
    if (secondParent) setSecondParent(secondParent);

    const getSiblings = props.childrenArray.filter(
      (children) =>
        (children.Parent1_idPerson === firstParent ||
          children.Parent1_idPerson === secondParent) &&
        children.Children_idPerson !== props.personArray[0].idPerson
    );
    setSiblings(getSiblings);
  }, [props.childrenArray, props.personArray]);

  useEffect(() => {
    const siblingsIds = siblings.map((child) => child.Children_idPerson);
    const nieceAndNephew = props.childrenArray.filter((child) =>
      siblingsIds.includes(child.Parent1_idPerson)
    );
    setNieceAndNephew(nieceAndNephew);
  }, [props.childrenArray, siblings]);

  useEffect(() => {
    const nieceAndNephewIds = nieceAndNephew.map(
      (nieceOrNephew) => nieceOrNephew.Children_idPerson
    );
    const grandNieceAndGrandNephew = props.childrenArray.filter((child) =>
      nieceAndNephewIds.includes(child.Parent1_idPerson)
    );
    setGrandNieceAndGrandNephew(grandNieceAndGrandNephew);
  }, [nieceAndNephew, props.childrenArray]);

  useEffect (() => {
    const grandNieceAndGrandNephewIds = grandNieceAndGrandNephew.map(
      (nieceOrNephew) => nieceOrNephew.Children_idPerson
    );
    const greatGrandNieceAndGreatGrandNephew = props.childrenArray.filter((child) =>
      grandNieceAndGrandNephewIds.includes(child.Parent1_idPerson)
    );
    setGreatGrandNieceAndGreatGrandNephew(greatGrandNieceAndGreatGrandNephew);

  }, [grandNieceAndGrandNephew, props.childrenArray])

  const hasDeceasedSiblings = props.personArray.some(
    (person) =>
      siblings
        .map((sibling) => sibling.Children_idPerson)
        .includes(person.idPerson) && person.DateOfDeath
  );

  const hasDeceasedNieceOrNephew = props.personArray.some(
    (person) =>
      nieceAndNephew
        .map((nieceOrNephew) => nieceOrNephew.Children_idPerson)
        .includes(person.idPerson) && person.DateOfDeath
  );

  const hasDeceasedGrandNieceOrGrandNephew = props.personArray.some(
    (person) =>
      grandNieceAndGrandNephew
        .map(
          (grandNieceOrGrandNephew) => grandNieceOrGrandNephew.Children_idPerson
        )
        .includes(person.idPerson) && person.DateOfDeath
  );

  return (
    <>
      <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
        Bitte erfassen Sie alle weiteren Nachkommen von den Eltern des
        Erblassers
      </Typography>

      {isNewOpen && (
        <SiblingsAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew={setIsNewOpen}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
          firstParentId={firstParent}
          secondParentId={secondParent}
        />
      )}
      {isNewOpen2 && (
        <NieceAndNephewAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew={setIsNewOpen2}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
          firstParentId={firstParent}
          secondParentId={secondParent}
        />
      )}

      {isNewOpen3 && (
        <GrandNieceAndGrandNephewAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew={setIsNewOpen3}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
          firstParentId={firstParent}
          secondParentId={secondParent}
        />
      )}

      {isNewOpen4 && (
        <GreatGrandNieceAndGreatGrandNephewAddDialog
          inheritObject={props.inheritObject}
          parentPersonArray={props.firstParentArray}
          setIsOpenNew={setIsNewOpen4}
          personArray={props.personArray}
          setPersonArray={props.setPersonArray}
          personHasDocument={props.personHasDocument}
          setPersonHasDocument={props.setPersonHasDocument}
          childrenArray={props.childrenArray}
          setChildrenArray={props.setChildrenArray}
          marriageArray={props.marraigeArray}
          firstParentId={firstParent}
          secondParentId={secondParent}
        />
      )}

      <Typography variant="h6">Geschwister</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
            <TableCell sx={{ width: "22%" }}>Name</TableCell>
            <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
            <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
            <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {siblings.map((x, idx) => (
            <SiblingsChildrenRow
              inheritObject={props.inheritObject}
              idPersonInherit={props.idPersonInherit}
              key={`idChildren-${x.idChildren}`}
              index={idx}
              currentChildren={x}
              setIsOpenNew={setIsNewOpen}
              personArray={props.personArray}
              setPersonArray={props.setPersonArray}
              personHasDocument={props.personHasDocument}
              setPersonHasDocument={props.setPersonHasDocument}
              childrenArray={props.childrenArray}
              setChildrenArray={props.setChildrenArray}
            />
          ))}
        </TableBody>
        <br></br>
        <Button
          variant="contained"
          onClick={() => {
            setIsNewOpen(true);
          }}
          sx={{
            float: "left",
            "& .MuiButton-startIcon": {
              marginRight: "2px", // Adjust the margin-right here
            },
          }}
          startIcon={
            <Add
              sx={{
                fontSize: 22,
                position: "relative",
                bottom: 1,
                marginRight: "0px",
              }}
            />
          }
        >
          Geschwister
        </Button>
      </Table>

      <br />

      {hasDeceasedSiblings && (
        <>
          <Typography variant="h6">Nichte/ Neffe</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
                <TableCell sx={{ width: "22%" }}>Name</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
                <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nieceAndNephew.map((x, idx) => (
                <SiblingsChildrenRow
                  inheritObject={props.inheritObject}
                  idPersonInherit={props.idPersonInherit}
                  key={`idChildren-${x.idChildren}`}
                  index={idx}
                  currentChildren={x}
                  setIsOpenNew={setIsNewOpen2}
                  personArray={props.personArray}
                  setPersonArray={props.setPersonArray}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={props.setPersonHasDocument}
                  childrenArray={props.childrenArray}
                  setChildrenArray={props.setChildrenArray}
                />
              ))}
            </TableBody>
            <br></br>
            <Button
              variant="contained"
              onClick={() => {
                setIsNewOpen2(true);
              }}
              sx={{
                float: "left",
                "& .MuiButton-startIcon": {
                  marginRight: "2px", // Adjust the margin-right here
                },
              }}
              startIcon={
                <Add
                  sx={{
                    fontSize: 22,
                    position: "relative",
                    bottom: 1,
                    marginRight: "0px",
                  }}
                />
              }
            >
              Nichte/ Neffe
            </Button>
          </Table>
        </>
      )}

      <br />

      {hasDeceasedNieceOrNephew && (
        <>
          <Typography variant="h6">Großnichte/ Großneffe</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
                <TableCell sx={{ width: "22%" }}>Name</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
                <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grandNieceAndGrandNephew.map((x, idx) => (
                <SiblingsChildrenRow
                  inheritObject={props.inheritObject}
                  idPersonInherit={props.idPersonInherit}
                  key={`idChildren-${x.idChildren}`}
                  index={idx}
                  currentChildren={x}
                  setIsOpenNew={setIsNewOpen3}
                  personArray={props.personArray}
                  setPersonArray={props.setPersonArray}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={props.setPersonHasDocument}
                  childrenArray={props.childrenArray}
                  setChildrenArray={props.setChildrenArray}
                />
              ))}
            </TableBody>
            <br></br>
            <Button
              variant="contained"
              onClick={() => setIsNewOpen3(true)}
              sx={{
                float: "left",
                "& .MuiButton-startIcon": {
                  marginRight: "2px", // Adjust the margin-right here
                },
              }}
              startIcon={
                <Add
                  sx={{
                    fontSize: 22,
                    position: "relative",
                    bottom: 1,
                    marginRight: "0px",
                  }}
                />
              }
            >
              Großnichte/ Großneffe
            </Button>
          </Table>
        </>
      )}

      <br />

      {hasDeceasedGrandNieceOrGrandNephew && (
        <>
          <Typography variant="h6">Urgroßnichte/ Urgroßneffe</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "22%" }}>Nr.</TableCell>
                <TableCell sx={{ width: "22%" }}>Name</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 1</TableCell>
                <TableCell sx={{ width: "22%" }}>Elternteil 2</TableCell>
                <TableCell sx={{ width: "12%" }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {greatGrandNieceAndGreatGrandNephew.map((x, idx) => (
                <SiblingsChildrenRow
                  inheritObject={props.inheritObject}
                  idPersonInherit={props.idPersonInherit}
                  key={`idChildren-${x.idChildren}`}
                  index={idx}
                  currentChildren={x}
                  setIsOpenNew={setIsNewOpen4}
                  personArray={props.personArray}
                  setPersonArray={props.setPersonArray}
                  personHasDocument={props.personHasDocument}
                  setPersonHasDocument={props.setPersonHasDocument}
                  childrenArray={props.childrenArray}
                  setChildrenArray={props.setChildrenArray}
                />
              ))}
            </TableBody>
            <br></br>
            <Button
              variant="contained"
              onClick={() => setIsNewOpen4(true)}
              sx={{
                float: "left",
                "& .MuiButton-startIcon": {
                  marginRight: "2px", // Adjust the margin-right here
                },
              }}
              startIcon={
                <Add
                  sx={{
                    fontSize: 22,
                    position: "relative",
                    bottom: 1,
                    marginRight: "0px",
                  }}
                />
              }
            >
              Urgroß- Nichte/ Neffe
            </Button>
          </Table>
        </>
      )}
    </>
  );
};
