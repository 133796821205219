import React, { useEffect, useState } from "react";
import { ILegalRequirement } from "../../Interfaces/ILegalRequirement";
import { Alert, Button, Grid, MenuItem, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { LegalRequirementDocuments } from "./LegalRequirementDocuments";
import { ILegalRequirementState } from "../../Interfaces/ILegalRequirementState";
import { IDocumentDownloaded } from "../../Interfaces/IDocumentDownloaded";
import { LegalInfoText } from "./LegalInfoText";


interface IProps {
    legalRequirement: ILegalRequirement;
    legalRequirementArray: ILegalRequirement[];
    setLegalRequirementArray: Function;
    isNotDisabled: boolean;
    legalReqState: ILegalRequirementState[];
    downloadedArray?: IDocumentDownloaded[];
}


export const LegalRequirementRow:React.FC<IProps> = (props) => {
    //const [legalRequirement, setLegalRequirement] = useState(props.legalRequirement);

    const setLegalRequirement = (updatedLegalReq:ILegalRequirement) => {
        props.setLegalRequirementArray([
            ...props.legalRequirementArray
                .map(x => x.idLegalRequirement === updatedLegalReq.idLegalRequirement ? updatedLegalReq : x)
        ])
    }

    const getCurrentValue = () => {
        if (props.legalRequirement.isComplete) 
            { return 1 }
        else if (props.legalRequirement.hasError)
            { return 2 }
        else
            { return 0 }
    }

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegalRequirement({
            ...props.legalRequirement,
            idLegalRequirementState: Number(event.target.value)
        })
        /*
        let tmpValue = Number(event.target.value)

        if (tmpValue === 0) {
            setLegalRequirement({...legalRequirement, isComplete: false, hasError: false})
        }
        else if (tmpValue === 2 && legalRequirement.enableError) {
            setLegalRequirement({...legalRequirement, 
                isComplete: false, 
                hasError: true
            })
        }
        else {
            setLegalRequirement({...legalRequirement, 
                isComplete: true
            })
        }
        */
    }

    /*
    useEffect(() => {
        props.setLegalRequirementArray([
            ...props.legalRequirementArray.map(x => 
                x.idLegalRequirement === legalRequirement.idLegalRequirement
                ? legalRequirement : x
            )
        ])
    },[legalRequirement])
    */

    return(
        <>
            <Grid item sm={3} lg={1}></Grid>

            <Grid item sm={9} lg={11}>
                <Alert 
                
                variant={ (props.isNotDisabled) ? "standard" : "outlined"}
                severity={
                    (props.legalRequirement.idLegalRequirementState === 40) ? "success"
                    : (props.legalRequirement.enableError && props.legalRequirement.idLegalRequirementState === 30) ? "error" 
                    : (props.legalRequirement.idLegalRequirementState === 15 || props.legalRequirement.idLegalRequirementState === 20) ? "warning"
                    : "info"
                }
                action={
                    <>
                        <LegalInfoText currentObject={props.legalRequirement}/>
                        {(props.isNotDisabled) 
                            ?
                                <TextField
                                    label="Status"
                                    size="small"
                                    value={props.legalRequirement.idLegalRequirementState}
                                    onChange={handleChangeValue}
                                    sx={{width: 200}}
                                    select
                                >
                                    {props.legalReqState.map(x =>
                                        <MenuItem 
                                            key={`idLegalRequirementState-${x.idLegalRequirementState}`} 
                                            value={x.idLegalRequirementState}
                                            disabled={x.idLegalRequirementState === 30 && !props.legalRequirement.enableError}
                                        >{x.LegalRequirementState}</MenuItem>
                                    )}
                                     {/*
                                    <MenuItem value={0}>Offen</MenuItem>
                                    <MenuItem value={1}>Erledigt</MenuItem>
                                    <MenuItem value={2} disabled={!legalRequirement.enableError}>Fehler</MenuItem>
                                     */}

                                </TextField>
                            : <i style={{marginTop: 2}}>Keine Änderungen möglich</i>
                        }

                    </>
                  }
                >
                    {props.legalRequirement.LegalRequirement}
                    <LegalRequirementDocuments
                        legalRequirementObject={props.legalRequirement}
                        setLegalRequirementObject={setLegalRequirement}
                        isNotDisabled={props.isNotDisabled}
                        downloadedArray={props.downloadedArray}
                    />
                    {(props.legalRequirement.sendMail == true) && <Typography variant="caption">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(E-Mail wird versendet)</Typography>}


                </Alert>

            </Grid>
        </>
    )
}