import React, { useEffect, useState } from "react";
import { IDocument } from "../../Interfaces/IDocument";
import { IDocumentDirectory } from "../../Interfaces/IDocumentDirectory";
import { Box, Button, Typography } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { IDocumentDirectoryTemplateHasDocument } from "../../Interfaces/IDocumentDirectoryTemplateHasDocument";
import { IDocumentDirectoryTemplate } from "../../Interfaces/IDocumentDirectoryTemplate";
import { DocumentDirectoryTemplateTreeEntry } from "./DocumentDirectoryTemplateTreeEntry";
import { DocumentDirectoryTemplateEdit } from "./DocumentDirectoryTemplateEdit";


interface IProps {
    documentArray: IDocumentDirectoryTemplateHasDocument[];
    documentDirectoryArray: IDocumentDirectoryTemplate[];
    setDocumentArray: Function;
    setDocumentDirectoryArray: Function;
}


export const getDocumentDir = (targetId:number,documentDirectoryArray:IDocumentDirectoryTemplate[],forceNew:boolean = false, Parent_idDocumentDirectory:number|null = null) => {
    let testObject = documentDirectoryArray.find(x => x.idDocumentDirectoryTemplate === targetId);

    if (testObject !== undefined && forceNew === false) {
        return testObject
    } else {
        let tmpId = -1;

        if (documentDirectoryArray.length > 0) {
            let tmpIdArray = Math.min(...documentDirectoryArray.map(x => x.idDocumentDirectoryTemplate)) -1;

            if (tmpIdArray <= tmpId) {
                tmpId = tmpIdArray -1;
            }
        }

        return {
            idDocumentDirectoryTemplate : tmpId,
            Parent_idDocumentDirectoryTemplate: Parent_idDocumentDirectory,
            idLegalTransactionType: -1,
            Title: "",
        } as IDocumentDirectoryTemplate
    }
} 


export const DocumentDirectoryTemplateOverview:React.FC<IProps> = (props) => {
    const [isOpenNew, setIsOpenNew] = useState(false);

    const handleAddDirecotry = () => {
        setIsOpenNew(true);
        /*
        let newDirectory = getDocumentDir(-1,props.documentDirectoryArray,true);
        props.setDocumentDirectoryArray([
            ...props.documentDirectoryArray,
            newDirectory
        ])
        */
    }

    return (
        <>
            <DocumentDirectoryTemplateEdit
                isOpen={isOpenNew}
                setIsOpen={setIsOpenNew}
                documentDirectoryArray={props.documentDirectoryArray}
                setDocumentDirectoryArray={props.setDocumentDirectoryArray}
                parent_idDocumentDirectory={null}
            />

            <Typography variant="h6" sx={{mt: 5}}>
                Interne Datenablage
                <Button variant="outlined" sx={{float: "right"}} onClick={handleAddDirecotry}>Neuer Ordner</Button>
            </Typography>
            <br />


            <Box sx={{ minHeight: 220, flexGrow: 1, maxWidth: 300 }}>
            <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMore/>}
                defaultExpandIcon={<ChevronRight />}
                multiSelect
            >
                {props.documentDirectoryArray
                 .filter(x => x.Parent_idDocumentDirectoryTemplate === null)
                 .map(x =>
                        <DocumentDirectoryTemplateTreeEntry
                            key={`idDocumentDirectoryTemplate-${x.idDocumentDirectoryTemplate}`}
                            documentDirecotryObject={x}
                            documentArray={props.documentArray}
                            documentDirectoryArray={props.documentDirectoryArray}
                            setDocumentArray={props.setDocumentArray}
                            setDocumentDirectoryArray={props.setDocumentDirectoryArray}
                        />
                 )
                }
             </TreeView>
             </Box>
        </>
    )

}