import React, { useState } from "react";
import { PhaseEdit } from "./PhaseEdit";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { ILegalRequirementTemplate } from "../Interfaces/ILegalRequirementTemplate";
import { ShowSystemVariable } from "../core/ShowSystemVariable";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { useSelector } from "react-redux";
import { State } from "../redux/mainReducer";
import { TextModuleSelector } from "../TextModule/TextModuleSelector";
import { CustomEditor } from "../core/CustomEditor";


interface IProps {
    currentRequirement: ILegalRequirementTemplate;
    setCurrentRequirement: Function;
    legalRequirements: ILegalRequirementTemplate[];
}


export const LegalRequirementEdit:React.FC<IProps> = (props) => {
    // Redux
    const legalRequirementStateArray = useSelector((state: State) => state.legalRequirementStateArray.storeLegalRequirementState);
    // Normale State
    {/*
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(
        (props.currentRequirement.EmailBody === null)
        ? ContentState.createFromText("")
        : ContentState.createFromBlockArray(htmlToDraft(props.currentRequirement.EmailBody).contentBlocks)
      ),);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);

        if (props.currentRequirement) {
            props.setCurrentRequirement({
                ...props.currentRequirement,
                EmailBody: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            })
        }
    };
    */}



    return(
        <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <TextField 
                        label="Bedingung"
                        value={props.currentRequirement.LegalRequirementTemplate}
                        error={props.currentRequirement.LegalRequirementTemplate === ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, LegalRequirementTemplate: event.target.value})}
                        size="small"
                        fullWidth
                    /> 
                </Grid>

                <Grid item sm={6}>
                    <TextField 
                        label="Mitarbeiter kann Dateien hochladen"
                        value={(props.currentRequirement.canUploadFilesEmployees == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, canUploadFilesEmployees: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>
                <Grid item sm={6}>
                    <TextField 
                        label="Mandant kann Dateien hochladen"
                        value={(props.currentRequirement.canUploadFilesClient == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, canUploadFilesClient: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                <Grid item sm={5}>
                    <TextField 
                        label="Startstatus"
                        value={props.currentRequirement.idLegalRequirementState}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, idLegalRequirementState: Number(event.target.value)})}
                        size="small"
                        fullWidth
                        select
                    >
                        {legalRequirementStateArray.map(x =>
                            <MenuItem 
                                key={`idLegalRequirementState-${x.idLegalRequirementState}`}
                                value={x.idLegalRequirementState}
                            >{x.LegalRequirementState}</MenuItem>
                        )}
                    </TextField> 
                </Grid>

                <Grid item sm={3}>
                    <TextField 
                        label="Vorlage im MA-Katalog"
                        disabled={props.currentRequirement.idLegalPhaseTemplate === null}
                        value={(props.currentRequirement.isTemplate == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, isTemplate: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                <Grid item sm={4}>
                    <TextField 
                        label="Vorgänger (Bedingung)"
                        value={(props.currentRequirement.Predecessor_idLegalRequirementTemplate == undefined) ? "" : props.currentRequirement.Predecessor_idLegalRequirementTemplate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, Predecessor_idLegalRequirementTemplate: Number(event.target.value)})}
                        size="small"
                        fullWidth
                        select
                    >
                        {props.legalRequirements.map(x =>
                            <MenuItem 
                                key={`predecessor-idLegalRequirementTemplate-${x.idLegalRequirementTemplate}`}
                                value={x.idLegalRequirementTemplate}
                            >{x.LegalRequirementTemplate}</MenuItem>
                        )}
                    </TextField> 
                </Grid>

                <Grid item sm={12}>
                    <TextModuleSelector 
                        key={`type-legalrequirementedit`}
                        floatRight
                        idTextModuleCategory={4}
                        txt={props.currentRequirement.InfoText}
                        setTxt={(txt: string) => props.setCurrentRequirement({...props.currentRequirement, InfoText: txt})}
                    />
                    <TextField 
                        label="Infotext"
                        value={(props.currentRequirement.InfoText === null) ? "" : props.currentRequirement.InfoText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({
                            ...props.currentRequirement, 
                            InfoText: (event.target.value === "") ? null : event.target.value
                        })}
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                    /> 
                </Grid>

                <Grid item sm={4}>
                    <TextField 
                        label="Auto. E-Mail versenden (wenn erfüllt)"
                        value={(props.currentRequirement.sendMail == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, sendMail: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                <Grid item sm={8}>
                    <TextField 
                        label="E-Mail Betreff"
                        value={props.currentRequirement.EmailSubject}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, EmailSubject: event.target.value})}
                        size="small"
                        fullWidth
                    /> 
                </Grid>

                <CustomEditor 
                    txt={props.currentRequirement.EmailBody === null ? "" : props.currentRequirement.EmailBody} 
                    setTxt={(txt:string) =>
                        props.setCurrentRequirement({
                            ...props.currentRequirement,
                            EmailBody: (txt === "") ? null : txt
                        })
                    }
                    idTextModuleCategory={3}
                />

                <Grid item sm={6}>
                    <TextField 
                        label="Fehler möglich"
                        value={(props.currentRequirement.hasError == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, hasError: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>
                <Grid item sm={6}>
                    <TextField 
                        label="Freitext bei Fehler erlauben"
                        value={(props.currentRequirement.hasErrorFreeText == true) ? "true" : "false"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, hasErrorFreeText: (event.target.value === "true")})}
                        size="small"
                        fullWidth
                        select
                    >
                        <MenuItem value="true">Ja</MenuItem>
                        <MenuItem value="false">Nein</MenuItem>
                    </TextField> 
                </Grid>

                <Grid item sm={12}>
                    <TextField 
                        label="Fehlermeldung"
                        value={props.currentRequirement.TextOfError}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentRequirement({...props.currentRequirement, TextOfError: event.target.value})}
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                    /> 
                </Grid>
            </Grid>
        </Box>
    )
}