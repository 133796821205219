import React, { useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { IDayOffWork } from "../Interfaces/IDayOffWork";
import { IUser } from "../Interfaces/IUser";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { IEmploymentContract } from "../Interfaces/IEmploymentContract";
import { DayOffWorkUserRow } from "./Childs/DayOffWorkUserRow";
import { IDayOffWorkCategory } from "../Interfaces/IDayOffWorkCategory";
import { DayOffWorkNew } from "./Childs/DayOffWorkNew";
import { DayOffWorkOverviewTable } from "./DayOffWorkOverviewTable";



export const DayOffWorkOverview:React.FC = () => {
    const [dayOffWorkArray, setDayOffWorkArray, setWasSuccessfully] = useFetch<IDayOffWork[]>("/dayoffwork");
    const [dayOffWorkCategoryArray, setDayOffWorkCategoryArray, setWasSuccessfullyCategory] = useFetch<IDayOffWorkCategory[]>("/dayoffworkcategory");
    const [userArray, setUserArray, setWasSuccessfullyUsserArray] = useFetch<IUser[]>("/user");
    const [employmentContractArray, setEmploymentContract, setWasSuccessfullyEmploymentContract] = useFetch<IEmploymentContract[]>("/employmentcontract");

    //
    





    if (
        !setWasSuccessfully 
        || !setWasSuccessfullyUsserArray 
        || !setWasSuccessfullyEmploymentContract 
        || !setWasSuccessfullyCategory
    ) { return <Alert severity="error">Fehler!</Alert> }
    else if (
        dayOffWorkArray === undefined 
        || userArray === undefined 
        || employmentContractArray === undefined
        || dayOffWorkCategoryArray === undefined
    ) { return <CustomCircularProgress/> }
    else {
        return(
            <>
                <Typography variant="h6">
                    Fehltage: Personalkalender
                </Typography>

                <DayOffWorkOverviewTable
                    userArray={userArray}
                    employmentContractArray={employmentContractArray}
                    dayOffWorkCategoryArray={dayOffWorkCategoryArray}
                    dayOffWorkArray={dayOffWorkArray}
                    setDayOffWorkArray={setDayOffWorkArray}
                />
            </>
        )
    }
}