import React, { useState } from "react";
import { ISetting } from "../../Interfaces/ISignature";
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { CustomeTextField } from "../../generic/CustomeTextField";
import { uploadFetch } from "../../hooks/useFetch";

interface IProps {
    settingObject: ISetting;
    settingArray: ISetting[];
    setSettingArray: Function;
}



export const SysRegRow: React.FC<IProps> = ({ settingObject, settingArray, setSettingArray }) => {
    const [editSetting, setEditSetting] = useState(settingObject);
    //
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const handleAfterSave = (res: ISetting) => {
        setSettingArray([
            ...settingArray.map(x => x.idSetting === res.idSetting ? res : x)
        ])
        setIsOpenEdit(false);
    }
    const handleSave = () => {
        uploadFetch("/setting", false, {...editSetting, syncPublic: false}, handleAfterSave,() => {},() => {}, setIsLoading)
    }

    return (
        <>
            <Dialog open={isOpenEdit} maxWidth="md" fullWidth onClose={() => setIsOpenEdit(false)}>
                <DialogTitle>Bearbeitung: {editSetting.SettingKey}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }} />
                    <Collapse in={isLoading} >
                        <LinearProgress sx={{mb: 2}}/>
                    </Collapse>
                    <CustomeTextField
                        label="Value"
                        attr="SettingValue"
                        object={editSetting}
                        setObject={setEditSetting}
                        type="string"
                        rows={5}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>Abbruch</Button>
                    <Button variant="contained" disabled={isLoading || editSetting.SettingValue === null } onClick={handleSave}>Speichern</Button>
                </DialogActions>
            </Dialog>


            <TableRow>
                <TableCell>{settingObject.SettingKey}</TableCell>
                <TableCell>{(settingObject.SettingValue !== null && settingObject.SettingValue.length > 300) ? `${settingObject.SettingValue.substring(0, 300)}...` : settingObject.SettingValue}</TableCell>
                <TableCell>
                    <IconButton onClick={() => setIsOpenEdit(true)} size="small"><Edit /></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}