import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Login from "./Login";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { IConfig } from "../Interfaces/IConfig";

interface IProps {
    msg?: string;
    children: React.ReactElement;
    configObject: IConfig;
}


const SecurityDriver:React.FC<IProps> = (props) => {
    const [isLoading,setIsLoading] = useState(true);
    const [isLogged,setIsLogged] = useState(false);
    const [msg, setMsg] = useState<string | null>(null);

    useEffect(() => {
        let sessionToken = localStorage.getItem('SessionToken');

        if (sessionToken === "" || sessionToken === null) {
            setIsLogged(false);
            setIsLoading(false);
        } else {
            fetch("/api/oauth",{
                method: "GET",
                headers : { "Authorization" : "Bearer " + localStorage.getItem('SessionToken') }
            })
            .then(res => {
                if (res.status == 200) {
                    setIsLogged(true);
                    setIsLoading(false);
                } else {throw Error(String(res.status))}
            })
            .catch( error => {
                if (String(error).includes("401")) {
                    setMsg("Ihre Session ist abgelaufen.")
                } else if (String(error).includes("404")) {
                    setMsg("Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort");
                } else if (String(error).includes("403")) {
                    setMsg(`Ihr Konto wurde deaktiviert!`);
                } else {
                    setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
                }
                setIsLogged(false);
                setIsLoading(false);
          })
        }
    },[])

    /*
    localStorage.setItem('SessionToken', res["SessionToken"]);
    localStorage.setItem('idUser', res["idUser"]);
    localStorage.setItem('role', res["Role"]);
    localStorage.setItem('scope', res["Scope"]);
    */


    if (isLoading) { return <CustomCircularProgress /> }
    else if (isLogged) { return props.children }
    else { return <Login msg={msg} configObject={props.configObject}/>}

}
export default SecurityDriver;