
import {Grid} from "@mui/material";
import React from "react";

export const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const checkString = (stringToCheck: string | undefined | null) => {
    if (stringToCheck == undefined || stringToCheck === "") {
        return "k.A."
    } else {
        return stringToCheck;
    }
}

export const getBirthday = (object: any) => {
    if(object.Birthday !== null)
    {
        return new Date(object.Birthday).toLocaleString("de-DE",options)
    }
    else{
        return "Keine Angabe"
    }
}

export const getDateOfDeath = (object: any) => {
    if(object.DateOfDeath !== null)
    {
        return new Date(object.DateOfDeath).toLocaleString("de-DE",options)
    }
    else{
        return "Keine Angabe"
    }
}

export const formatDate = (date: string | null | undefined) => {
    if(!date) {
        return "Keine Angabe"
    }

    return new Date(date).toLocaleString("de-DE",options);
}
