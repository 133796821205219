import React, {useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid,
    IconButton,
    InputLabel, MenuItem, Select, SelectChangeEvent,
    TextField
} from "@mui/material";
import {ILegalTransactionSpecialFunctions} from "../../Interfaces/ILegalTransactionSpecialFunctions";
import {
    ILegalTransaction_has_LegalTransactionSpecialFunction
} from "../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction";
import {uploadFetch, useFetch} from "../../hooks/useFetch";
import {Add, Delete} from "@mui/icons-material";
import {ILegalTransactionFullObject} from "../../Interfaces/ILegalTransaction";
import {IDocumentDownloaded} from "../../Interfaces/IDocumentDownloaded";
import {CustomCircularProgress} from "../../generic/CustomCircularProgress";
import {HandelsregisterCheck} from "./HandelsregisterCheck/HandelsregisterCheck";
import {ILegalTransactionGwGFullObject} from "../../Interfaces/ILegalTransactionGwG";
import {ILegalPhaseFullObject} from "../../Interfaces/ILegalPhase";
import {GwGMain} from "./GwG/GwGMain";
import {ChangeLegalPhaseCondition} from "./ChangeLegalPhaseCondition";
import {ILegalRequirementState} from "../../Interfaces/ILegalRequirementState";
import {ShareholderListCheck} from "./ShareHolderList/ShareHolderListCheck";


interface IProps {
    idLegalTransaction: number;
    legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[];
    setLegalTransaction_has_LegalTransactionSpecialFunctionArray: Function;
    gwgArray: ILegalTransactionGwGFullObject[];
    setGwgArray: Function;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
    legalReqState: ILegalRequirementState[];

}

const getEmptySpecialFunction = (idLegalTransaction: number, legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[]) => {

    return {
        idLegalTransaction_has_LegalTransactionSpecialFunction: -1,
        idLegalTransaction: idLegalTransaction,
        idLegalRequirement: -1,
        idLegalRequirementState: -1,
        idSF: -1,
        Function_Field1: new Date().toISOString().split('T')[0],
        Function_Field2: '',
        Function_Field3: '',
        Function_Field4: '',
        Res_Field1: ''
    } as ILegalTransaction_has_LegalTransactionSpecialFunction;
};


export const SpecialFunctionOverview: React.FC<IProps> = (props) => {
    const [specialFunctionArray, setSpecialFunctionArray, wasSuccessfullSpecialFunctionArray] = useFetch<ILegalTransactionSpecialFunctions[]>("/legaltransactionspecialfunctions");
    const [isOpen, setIsOpen] = useState(false)
    const [addedSpecialFunction, setaddedSpecialFunction] = useState<ILegalTransaction_has_LegalTransactionSpecialFunction>(
        getEmptySpecialFunction(props.idLegalTransaction, props.legalTransaction_has_LegalTransactionSpecialFunctionArray));

    const handleClose = () => {
        setaddedSpecialFunction(getEmptySpecialFunction(props.idLegalTransaction, props.legalTransaction_has_LegalTransactionSpecialFunctionArray))
        setIsOpen(false)
    };

    const handleOpen = () => {
        setIsOpen(true)
    };

    const handleChange = (event: SelectChangeEvent) => {
        setaddedSpecialFunction({...addedSpecialFunction, idSF: Number(event.target.value)})
    }


    const addSpecialFunction = (idSF: number) => {
        let minId = -1;
        if (props.legalTransaction_has_LegalTransactionSpecialFunctionArray.length > 0) {
            let minIdArray = Math.min(...props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(x => x.idLegalTransaction_has_LegalTransactionSpecialFunction)) - 1;

            if (minIdArray <= minId) {
                minId = minIdArray - 1;
            }
        }

        let newObject =  {
            idLegalTransaction_has_LegalTransactionSpecialFunction: minId,
            idLegalTransaction: props.idLegalTransaction,
            idLegalRequirement: -1,
            idLegalRequirementState: -1,
            idSF: idSF,
            Function_Field1: new Date().toISOString().split('T')[0],
            Function_Field2: '',
            Function_Field3: '',
            Res_Field1: ''
        } as ILegalTransaction_has_LegalTransactionSpecialFunction;

        props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray([...props.legalTransaction_has_LegalTransactionSpecialFunctionArray, newObject]);
    };

    const deleteSpecialFunction = (index: number) => {
        const updatedArray = props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter((_, i) => i !== index);
        props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(updatedArray);
    };

    const handleFieldChangeOnImport = (index: number, updates: { [key: string]: string }) => {
        const updatedArray = props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, ...updates };
                if (updatedItem.idSF === 3) {
                    updatedItem.Function_Field3 = "1";
                }
                if (updatedItem.idSF === 1) {
                    let followUpState = Number(updatedItem.Function_Field3) === 2 ? 2 : 1;
                    updatedItem.Function_Field3 = followUpState.toString();
                }
                return updatedItem;
            }
            return item;
        });
        props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(updatedArray);
    };

    const handleSave = () => {
        if (addedSpecialFunction.idSF === 1) {
            addSpecialFunction(addedSpecialFunction.idSF);
        }
        if (addedSpecialFunction.idSF === 2) {
            let minId = -1;

            if (props.gwgArray.length > 0) {
                let minIdArray = Math.min(...props.gwgArray.map(x => x.idLegalTransactionGwG)) - 1;

                if (minIdArray <= minId) {
                    minId = minIdArray - 1
                }
            }

            let newObject = {
                idLegalTransactionGwG: minId,
                idLegalTransaction: -1,
                idLegalRequirement: null,
                LegalTransactionGwGEntryArray: [],
                total: 0,
                title: "GwG Kaufvertrag"
            } as ILegalTransactionGwGFullObject

            props.setGwgArray([
                ...props.gwgArray,
                newObject
            ])
        }
        if (addedSpecialFunction.idSF === 3) {
            addSpecialFunction(addedSpecialFunction.idSF);
        }
        handleClose()
    }

    useEffect(() => {
        console.log("Test")
         console.log(props.legalTransaction_has_LegalTransactionSpecialFunctionArray)
    });

    if (!wasSuccessfullSpecialFunctionArray) {
        return <>Fehler!</>
    } else if (specialFunctionArray === undefined) {
        return <CustomCircularProgress/>
    } else {
        return (
            <>

                <Dialog onClose={handleClose} open={isOpen}>
                    <DialogTitle style={{minWidth: '600px'}}>Funktion hinzufügen</DialogTitle>
                    <DialogContent>
                        Bitte wählen sie eine Funktion aus

                        <FormControl fullWidth sx={{mt: 1}}>
                            <InputLabel id="demo-simple-select-label">Funktion</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Funktion"
                                value={(addedSpecialFunction.idSF).toString()}
                                onChange={handleChange}
                            >
                                {specialFunctionArray.map(item => (
                                    <MenuItem key={item.idSF} value={item.idSF}>{item.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>schliessen</Button>
                        <Button variant="contained" onClick={handleSave}>Speichern</Button>

                    </DialogActions>
                </Dialog>

                <Button sx={{float: "right"}} onClick={handleOpen}>Funktion hinzufügen</Button>

                {(props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter(x => x.idSF === 1).length > 0) &&
                    <HandelsregisterCheck idLegalTransaction={props.idLegalTransaction}
                                          legalTransaction_has_LegalTransactionSpecialFunctionArray={props.legalTransaction_has_LegalTransactionSpecialFunctionArray}
                                          setLegalTransaction_has_LegalTransactionSpecialFunctionArray={props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray}
                                          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray} legalReqState={props.legalReqState} deleteSpecialFunction={deleteSpecialFunction} handleFieldChangeOnImport={handleFieldChangeOnImport}></HandelsregisterCheck>

                }
                {(props.gwgArray.length > 0) &&
                    <GwGMain gwgArray={props.gwgArray} setGwgArray={props.setGwgArray}
                             legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}></GwGMain>
                }

                {(props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter(x => x.idSF === 3).length > 0) &&
                    <ShareholderListCheck idLegalTransaction={props.idLegalTransaction}
                                          legalTransaction_has_LegalTransactionSpecialFunctionArray={props.legalTransaction_has_LegalTransactionSpecialFunctionArray}
                                          setLegalTransaction_has_LegalTransactionSpecialFunctionArray={props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray}
                                          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray} legalReqState={props.legalReqState} deleteSpecialFunction={deleteSpecialFunction}  handleFieldChangeOnImport={handleFieldChangeOnImport}></ShareholderListCheck>

                }
            </>
        )
    }
}