import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILandMarking } from "../../Interfaces/ILandMarking";


const initialState =  { storeLandMarking: [] as ILandMarking[] }; 

const sliceLandMarking = createSlice({
    name: "LANDMARKINGARRAY",
    initialState,
    reducers: {
        setLandMarkingArray: (state, action: PayloadAction<ILandMarking[]>) => {
            state.storeLandMarking = [...action.payload];
        },
        addUpdateLandMarkingArray: (state, action: PayloadAction<ILandMarking>) => {
            let testObject = state.storeLandMarking.find(x => x.idLandMarking === action.payload.idLandMarking);
            
            if (testObject) {
                state.storeLandMarking = [...state.storeLandMarking.map(x => x.idLandMarking === action.payload.idLandMarking ? action.payload : x)];
            } else {
                state.storeLandMarking = [action.payload, ...state.storeLandMarking];
            }
        },
    }
})
export const { setLandMarkingArray, addUpdateLandMarkingArray } = sliceLandMarking.actions;
export default sliceLandMarking.reducer;