import { Grid, IconButton, Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { Handle, Node, Position } from "reactflow";
import { Edit, Star, CancelSharp } from "@mui/icons-material";
import {
  FamilyTreePersonNode,
  FamilyTreeService,
} from "../../../../../services/familyTree.service";
import { EditableNode } from "../../../../../core/TreeEditor";
import { formatDate } from "../../../../../core/standardFunctions";

interface IProps {
  data: FamilyTreePersonNode;
}

export const GenericPersonNode = memo(
  (props: Node<FamilyTreePersonNode & EditableNode>) => {
    const { type } = props;
    const { person: data, onNodeEdit } = props.data;

    const getBorderColor = (idGender: number, DateOfDeath: string | null | undefined) => {
      if (DateOfDeath) {
        return "#d3d3d3";
      } else {
        switch (idGender) {
          case 1:
            return "#ADD8E6";
          case 2:
            return "#F08080";
          case 3:
            return "#90ee90";
          case 4:
          default:
            return "#90ee90";
        }
      }
    };

    const getBackgroundColor = (
        idGender: number,
        DateOfDeath: string | null | undefined
    ) => {
      if (DateOfDeath) {
        return "#d3d3d3";
      }
      else if(DateOfDeath === undefined)
      {
          return "#90ee90";
      }
      else {
        switch (idGender) {
          case 1:
            return "#ADD8E6";
          case 2:
            return "#F08080";
          case 3:
            return "#90ee90";
          case 4:
          default:
            return "#90ee90";
        }
      }
    };

    const getGenderName = (nodeType: string, genderId: number): string => {
      return FamilyTreeService.getGenderNaming(nodeType, genderId);
    };

    return (
      <>
        <Grid
          container
          style={{
            border: `1px solid ${getBorderColor(
              data.idGender,
              data.DateOfDeath
            )}`,
            borderRadius: 10,
            background: "#fff",
            width: "250px",
            boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Grid item width={"100%"}>
            <div
              style={{
                borderRadius: "6px 6px 0 0",
                backgroundColor: getBackgroundColor(
                  data.idGender,
                  data.DateOfDeath
                ),
                color: "black",
                wordBreak: "break-word",
                textAlignLast: "center",
              }}
            >
              <Box p={1} width={"100%"}>
                <Typography variant="h5">
                  {/* TODO: handle default case better */}
                  {getGenderName(type || "", data.idGender)}
                </Typography>
              </Box>
            </div>
            <div
              style={{
                backgroundColor: getBackgroundColor(data.idGender, data.DateOfDeath),
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              <Box px={2}>
                <Typography noWrap variant="h6">
                  {data.FirstName}
                </Typography>
                <Typography noWrap variant="h6">
                  {data.LastName}
                </Typography>
              </Box>
            </div>
            <Box px={1} py={1}>
              <Box display="flex" alignItems="center">
                <Star fontSize="small" />
                <Typography variant="body1" marginLeft={1}>
                  {formatDate(data.Birthday)}
                </Typography>
              </Box>
              {data.DateOfDeath && (
                <Box display="flex" alignItems="center">
                  <CancelSharp fontSize="small" />
                  <Typography variant="body1" marginLeft={1}>
                    {formatDate(data.DateOfDeath)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* Add if condition to show the button or not */}
            <Grid item>
              <IconButton
                aria-label="edit"
                size="large"
                onClick={() => onNodeEdit(props)}
              >
                <Edit fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </>
    );
  }
);
