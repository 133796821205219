import React, { useEffect, useState } from "react";
import { ILegalTransactionGwGFullObject } from "../../../Interfaces/ILegalTransactionGwG";
import { ILegalPhaseFullObject } from "../../../Interfaces/ILegalPhase";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import { CustomeTextField } from "../../../generic/CustomeTextField";
import { Delete, Edit } from "@mui/icons-material";
import { GwGEntry } from "./GwGEntry";
import { ILegalTransactionGwGEntry } from "../../../Interfaces/ILegalTransactionGwGEntry";
import {DocumentOverview} from "../../../Document/DocumentOverview";

interface IProps {
    gwgObject: ILegalTransactionGwGFullObject;
    gwgArray: ILegalTransactionGwGFullObject[];
    setGwgArray: Function;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
}


export const GwGElement:React.FC<IProps> = ({gwgObject,gwgArray,setGwgArray,legalPhaseFullObjectArray}) => {
    const [editGwgObject,setEditGwgObject] = useState(gwgObject);
    const [gwgEntryArray,setGwgEntryArray] = useState(gwgObject.LegalTransactionGwGEntryArray);

    const [isOpenEdit,setIsOpenEdit] = useState(false);

    useEffect(() => {
        setGwgArray([
            ...gwgArray.map(x => x.idLegalTransactionGwG === editGwgObject.idLegalTransactionGwG
                ?  {...editGwgObject, LegalTransactionGwGEntryArray: gwgEntryArray}
                : x
            )
        ])
    },[editGwgObject,gwgEntryArray])


    const addGwGEntry = (isPaid:boolean) => {
        let minId = -1;

        if (gwgEntryArray.length > 0) {
            let minIdArray = Math.min(...gwgEntryArray.map(x => x.idLegalTransactionGwGEntry)) -1;

            if (minIdArray <= minId) {
                minId = minIdArray -1;
            }
        }

        let newEntry:ILegalTransactionGwGEntry = {
            idLegalTransactionGwGEntry: minId,
            idLegalTransactionGwG: editGwgObject.idLegalTransactionGwG,
            currentValue: 0,
            isPaid: isPaid,
            wasPaid: isPaid, // Kein Fehler! isPaid = ZAhlung oder Forderung, wasPaid: wurde vorderung gezahglt
            title: "",
            currentRate: 0,
            extraCosts: 0,
            DocumentArray: []
        }

        setGwgEntryArray([
            ...gwgEntryArray,
            newEntry
        ])
    }

    const getSumWasPaid = () => {
        let paidSum = gwgEntryArray.filter(x => x.wasPaid).map(x => x.currentValue).reduce((a,b) => a+b,0)
        return paidSum.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
    }


    const getSumIsNotPaid = (asStrin:boolean = true) => {
        let wasPaidSum = gwgEntryArray
            .filter(x => x.isPaid == false)
            .map(x => x.currentValue + x.currentRate + x.extraCosts)
            .reduce((a,b) => a+b,0);

        if (asStrin) {
            return wasPaidSum.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
        } else {
            return wasPaidSum
        }
        
    }
    const getDiffIsNotPaid = () => {
        let wasPaidSum = gwgEntryArray
            .filter(x => x.isPaid == false)
            .map(x => x.currentValue + x.currentRate + x.extraCosts)
            .reduce((a,b) => a+b,0);
        let wasPaidSumPaid = gwgEntryArray
            .filter(x => x.isPaid == false)
            .filter(x => x.wasPaid)
            .map(x => x.currentValue + x.currentRate + x.extraCosts)
            .reduce((a,b) => a+b,0);
        return (wasPaidSum-wasPaidSumPaid).toLocaleString("de-DE", { style: "currency", currency: "EUR" })
    }


    const getDiffPaid = (isPaid:boolean = true) => {
        let paidSum = gwgEntryArray.filter(x => x.isPaid).map(x => x.currentValue).reduce((a,b) => a+b,0)
        let wasPaidSum = gwgEntryArray
            .filter(x => x.isPaid == false)
            .filter(x => x.wasPaid)
            .map(x => x.currentValue + x.currentRate + x.extraCosts)
            .reduce((a,b) => a+b,0);
        return (editGwgObject.total -paidSum -wasPaidSum).toLocaleString("de-DE", { style: "currency", currency: "EUR" })
    }

    const getBackgroundColor = () => {
        let paidSum = gwgEntryArray.filter(x => x.wasPaid).map(x => x.currentValue).reduce((a,b) => a+b,0);

        if (paidSum > editGwgObject.total) {
            return "#ffe0d4"
        } else {
            // Berechnung der Nähe
            const proximity = Math.abs(editGwgObject.total - paidSum);
            // Maximalwert für die Differenz (du kannst dies anpassen)
            const maxProximity = editGwgObject.total; 

            // Berechnung der Transparenz (zwischen 0 und 1)
            const alpha = Math.max(0, 1 - (proximity / maxProximity));

            // Rückgabe der Hintergrundfarbe in rgb-Format
            return `rgb(183, 213, 172, ${alpha})`;
        }
      }

    const handleDelete = () => {
        const tempArray = gwgArray.filter(x => x.idLegalTransactionGwG != editGwgObject.idLegalTransactionGwG)
        setGwgArray(tempArray)
    };

    return(
        <>
            <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <CustomeTextField
                        label="Titel"
                        attr="title"
                        object={editGwgObject}
                        setObject={setEditGwgObject}
                        type="string"
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpenEdit(false)}>Okay</Button>
                </DialogActions>
            </Dialog>

            <Card variant="outlined">
            <CardContent>
                <Typography variant="h6">
                    {editGwgObject.title}
                    <IconButton sx={{mb: 1, float: "right"}} onClick={handleDelete}><Delete/></IconButton>
                    <IconButton sx={{mb: 1, float: "right"}} onClick={() => setIsOpenEdit(true)}><Edit/></IconButton>
                </Typography>
                
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Button variant="outlined"sx={{mb: 1}} onClick={() => addGwGEntry(true)}>Neue Zahlung</Button>
                    
                </Grid>
                <Grid item sm={6} >
                    <Button variant="outlined"sx={{mb: 1, float: "right"}} onClick={() => addGwGEntry(false)}>Neue Ablöseforderung</Button>
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Kaufsumme"
                        attr="total"
                        object={editGwgObject}
                        setObject={setEditGwgObject}
                        type="euro"
                    />
                </Grid>

                <Grid item sm={6}>
                    <Typography sx={{mt: 1, backgroundColor: getBackgroundColor(), p: 1, m: 1}}>
                        Gezahlt {getSumWasPaid()} von {editGwgObject.total.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
                        <br/>übrig {getDiffPaid()}
                        <br/><br/>
                        Anteil an Verkäufer: {(editGwgObject.total-Number(getSumIsNotPaid(false))).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}

                    </Typography>

                </Grid>

                <Grid item sm={6} sx={{ borderLeft: 1}}>
                    <Typography sx={{mt: 1, p: 1, m: 1}}>
                        Ablöseforderung {getSumIsNotPaid()}
                        <br/>davon offen {getDiffIsNotPaid()}
                    </Typography>
                </Grid>

                <Grid item sm={6} sx={{ borderTop: 1}}>
                    <Box sx={{mr: 1}}>
                    {gwgEntryArray.filter(gwgEntry => gwgEntry.isPaid == true).map(gwgEntry =>
                        <GwGEntry
                            key={`gwg-entry-${gwgEntry.idLegalTransactionGwGEntry}`}
                            gwgEntryObject={gwgEntry}
                            gwgEntryArray={gwgEntryArray}
                            setGwgEntryArray={setGwgEntryArray}
                        />
                    )}
                    </Box>
                </Grid>

                <Grid item sm={6} sx={{ borderTop: 1, borderLeft: 1}}>
                    {gwgEntryArray.filter(gwgEntry => gwgEntry.isPaid == false).map(gwgEntry =>
                        <GwGEntry
                            key={`gwg-entry-${gwgEntry.idLegalTransactionGwGEntry}`}
                            gwgEntryObject={gwgEntry}
                            gwgEntryArray={gwgEntryArray}
                            setGwgEntryArray={setGwgEntryArray}
                        />
                    )}
                </Grid>
                <Grid item sm={12} sx={{borderTop: 1, mt: 1}}/>
            </Grid>
            </CardContent>
            </Card>
        </>
    )
}