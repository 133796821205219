import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, IconButton, TableCell, TableRow } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { InheritPerson } from "../InheritPerson";
import { IRequiredFiles } from "../InheritDocument";
import { IInheritHasPerson } from "../../../../../Interfaces/IPerson";
import { IInherit } from "../../../../../Interfaces/IInherit";
import { IPersonHasDocument } from "../../../../../Interfaces/IPersonHasDocument";
import { checkIsDisabled } from "../../../getDisabledHelper_Inherit";

interface IProps {
    index: number;
    currentPerson: IInheritHasPerson; 
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersoArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    setIsOpenNew?: Function;
}

export const StrangersRow:React.FC<IProps> = (props) => {
    const [editPerson,setEditPerson] = useState(props.currentPerson);
    //
    const [isDeath, setIsDeath] = useState(false);
    const [isOpen, setIsOpen] = useState(props.setIsOpenNew !== undefined);


    const handleOpen = () => {
        setEditPerson(props.currentPerson);
        setIsOpen(true);
    }

    const handleClose = () => {
        if (props.setIsOpenNew !== undefined) {
            props.setIsOpenNew(false);
        }
        setIsOpen(false);
    }

    const handleRemove = () => {
        props.setPersoArray([
            ...props.personArray.filter(x => x.idPerson !== props.currentPerson.idPerson)
        ])
    } 

    const handleSave = () => {
        let testObject = props.personArray.find(x => x.idPerson === editPerson.idPerson);

        if (testObject === undefined) {
            props.setPersoArray([
                ...props.personArray,
                {...editPerson, isStranger: true}
            ])
        } else {
            props.setPersoArray([
                ...props.personArray.map(x => x.idPerson === editPerson.idPerson ? editPerson : x)
            ])
        }
        handleClose()
    }

    return(
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                maxWidth="lg" fullWidth
            >
                <DialogContent>
                    <InheritPerson
                        currentPerson={editPerson}
                        setCurrentPerson={setEditPerson}
                        inheritObject={props.inheritObject}
                        personArray={props.personArray}
                        personHasDocument={props.personHasDocument}
                        setPersonHasDocument={props.setPersonHasDocument}
                        requiredFiles={ [] as IRequiredFiles[]}
                        title="Sonstige Person"
                        showDeathSettings={true}
                        setIsDeath={setIsDeath}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                    <Button variant="contained" disabled={checkIsDisabled(props.inheritObject,isDeath,editPerson)} onClick={handleSave}>Übernehmen</Button>
                </DialogActions>
            </Dialog>


            <TableRow>
                <TableCell>{props.index+1}</TableCell>
                <TableCell>{props.currentPerson.FirstName}</TableCell>
                <TableCell>{props.currentPerson.LastName}</TableCell>
                <TableCell>
                    {
                        (props.currentPerson.Birthday === undefined || props.currentPerson.Birthday === "")
                        ? <>k.A.</>
                        : new Date(props.currentPerson.Birthday).toLocaleDateString()
                    }
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleOpen}><Edit/></IconButton>
                    <IconButton onClick={handleRemove}><Delete/></IconButton>
                </TableCell>
            </TableRow>


        </>
    )
}